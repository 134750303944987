import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footer: {
    bottom: 0,
    width: "100%",
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {"Copyright © "}
      {new Date().getFullYear()} {"Rad Social Networks LLC"}
    </Typography>
  );
}

export default function Footer() {
  const classes = useStyles();
  return (
    <Box className={classes.footer} pt={1} pl={1} pr={1}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item>
          <Link className="light-themed" href="/about" variant="body2" style={{ marginLeft: 8 }}>
            {"About"}
          </Link>
        </Grid>
        <Grid item>
          <Link className="light-themed" href="/contact" variant="body2">
            {"Contact"}
          </Link>
        </Grid>
        <Grid item>
          <Link className="light-themed" href="/content_guidelines" variant="body2">
            {"Guidelines"}
          </Link>
        </Grid>
        <Grid item>
          <Link className="light-themed" href="/privacy" variant="body2">
            {"Privacy"}
          </Link>
        </Grid>
        <Grid item>
          <Link className="light-themed" href="/terms" variant="body2" style={{ marginRight: 8 }}>
            {"Terms"}
          </Link>
        </Grid>
      </Grid>
      <Box mt={1} mb={1}>
        <center>
          <Copyright />
        </center>
      </Box>
    </Box>
  );
}
