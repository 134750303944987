import { parseISO, format } from 'date-fns'

/* 
 * Dates from the database will be in UTC time
 * This converts them into local JS dates with the current browser's time zone
 */
export function railsUTCtoLocalTime(dt) {
  if (dt == null) { return null }
  return parseISO(dt + "Z")
}

/*
 * When sending selected dates to the database, we have to reverse the above process
 * This converts the local date to an ISO string, what rails is expecting
 */
export function jsLocalTimeToUTC(dt) {
  if (dt == null) { return null }
  const date = new Date(dt).toISOString()
  return date.substring(0, 16).replace(/T/, ' ')
}

export function railsUTCtoFriendlyDate(dt) {
  if (dt == null) { return null }

  const d = new Date(); // now, or the specific date in question
  const tz = d.toLocaleString('en', {timeZoneName: 'short'}).split(' ').pop();

  // See: https://date-fns.org/v2.23.0/docs/format
  return format(railsUTCtoLocalTime(dt), "eee, MMM do yyyy") + tz
}

export function railsUTCtoICSDate(dt) {
  if (dt == null) { return null }
  // See: https://date-fns.org/v2.23.0/docs/format
  // And: https://github.com/nwcell/ics.js/
  return format(railsUTCtoLocalTime(dt), "M/d/yyyy h:mm aaa")
}

export function railsUTCtoFriendlyLocalTime(dt) {
  if (dt == null) { return null }

  const d = new Date(); // now, or the specific date in question
  const tz = d.toLocaleString('en', {timeZoneName: 'short'}).split(' ').pop();

  // See: https://date-fns.org/v2.23.0/docs/format
  return format(railsUTCtoLocalTime(dt), "eeee, MMM do yyyy @ h:mm aa") + " " + tz
}