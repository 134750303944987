/*
 * 1. The uploaded image is resized to within 1080x1080
 * 2. However the preview-canvas is set w/ width: 100%, so it will be smaller
 * This takes a canvas touch and gets the original x/y vals from (1)
 */

const imageXTouch = function(evt) {
  //let rect = $('#canvas-preview')[0].getBoundingClientRect()
  let x = 0;

  if (evt.touches && evt.touches.length > 0) {
    console.warn("TOUCHES", evt.touches)
    x = evt.touches[0].clientX
  } else {
    x = evt.offsetX
  }

  let natWidth = $('#canvas-preview')[0].width
  let resizedWidth = $('#canvas-preview')[0].clientWidth

  return parseInt(x * (natWidth / resizedWidth))
}

const imageYTouch = function(evt) {
  let rect = $('#canvas-preview')[0].getBoundingClientRect()
  let y = 0

  if (evt.touches && evt.touches.length > 0) {
    console.warn("TOUCHES", evt.touches)
    y = evt.touches[0].clientY - rect.top
  } else {
    y = evt.offsetY
  }

  let natHeight = $('#canvas-preview')[0].height
  let resizedHeight = $('#canvas-preview')[0].clientHeight

  return parseInt(y * (natHeight / resizedHeight))
}

export { imageXTouch, imageYTouch };
