import { AppBar, Tab, Tabs } from "@material-ui/core";
import Communities from "components/events/Communities";
import DialogSetLocation from "components/events/DialogSetLocation";
import EventList from "components/events/EventList";
import { detectLocation } from "components/events/EventService";
import Posts from "components/posts/Posts";
import { LocationContext } from "components/profile/LocationContext";
import CommonStyles from "components/styles/CommonStyles";
import PostStyles from "components/styles/PostStyles";
import TabPanel from "components/TabPanel";
import PageDescription from "components/PageDescription";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { railsUTCtoFriendlyLocalTime } from "services/dates.service.js";
import "stylesheets/events.scss";
import Cookies from "vendor/js.cookie.min.mjs";

export default function Events(props) {
  const classes = PostStyles();
  const commonStyles = CommonStyles();

  let defaultTab = "find_communities";

  /* AppBar / Tab Stuff */

  // Get page from the URL
  let { subpage } = useParams();

  if (subpage) {
    // overidden from URL
    defaultTab = subpage;
  } else {
    // Not in URL? Get from cookie, default to following_communities
    defaultTab = Cookies.get("lastMenuEvents") || "find_communities";
  }

  const [tab, setTab] = React.useState(defaultTab);

  const initialValues = {
    my_events: [],
    my_communities: [], // for use with posting to communities
    page: 0,
    loading: false,
  };
  const [values, setValues] = useState(initialValues);

  function fetchDataMy() {
    setValues((state) => ({
      ...state,
      ["loading"]: true,
    }));

    $.ajax({
      url: `/my_events_communities.json`,
      type: "GET",
      async: window.rails_env != "test",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      success: function (data) {
        // Create localized friendly dates from rails' UTC
        let my_events = data.my_events;
        for (let i = 0; i < my_events.length; i++) {
          my_events[i].dt_start_friendly = railsUTCtoFriendlyLocalTime(
            my_events[i].dt_start
          );
        }

        setValues((state) => ({
          ...state,
          my_events: my_events,
          my_communities: data.my_communities,
          loading: false,
        }));
      },
      cache: false,
      contentType: false,
      processData: false,
    });
  }

  useEffect(() => {
    fetchDataMy();
  }, []);

  let initialLocationContext = detectLocation(window.current_user);
  initialLocationContext.distance = Cookies.get("communities_distance") || 50;
  initialLocationContext.dialogOpen = false;

  const [locationContext, setLocationContext] = useState(
    initialLocationContext
  );

  function handleClickTab(clickedTab) {
    // In Events we switch tabs via changing the URL and re-rendering

    let url = `/events/${clickedTab}`;

    // don't change url if it's the current page
    if (url != window.location.pathname) {
      // side effect: fetchData is called
      props.history.push(url);
      setTab(clickedTab);
      Cookies.set("lastMenuEvents", clickedTab, { expires: 1 });
    }
  }

  return (
    <div>
      <AppBar position="static" color="inherit" elevation={1}>
        <Tabs
          classes={{
            root: commonStyles.tabsRoot,
            scroller: commonStyles.tabsScroller,
          }}
          variant="fullWidth"
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
        >
          {/*<Tab
            onClick={() => {
              handleClickTab("following_communities");
            }}
            label="Posts"
            value={"following_communities"}
            id="index-0"
          />*/}
          <Tab
            onClick={() => {
              handleClickTab("find_communities");
            }}
            label="Communities"
            value={"find_communities"}
            id="index-1"
          />
          <Tab
            onClick={() => {
              handleClickTab("events");
            }}
            label="Events"
            value={"events"}
            id="index-2"
          />
        </Tabs>
      </AppBar>

      <LocationContext.Provider value={[locationContext, setLocationContext]}>
        <DialogSetLocation />
        <div
          style={{
            flexGrow: 1,
            display: values.loading ? "none" : "block",
          }}
        >
          {/*<TabPanel
            id={`tabpanel-following`}
            value={tab}
            index="following_communities"
          >
            <PageDescription text="This page shows posts and events for communities you have joined." />
            {
              tab == "following_communities" && (
                <Posts
                  history={history}
                  tab="events"
                  my_communities={values.my_communities}
                />
              )
            }
          </TabPanel>*/}
          <TabPanel id={`tabpanel-events`} value={tab} index="find_communities">
            <PageDescription text="This page helps you find communities whose posts and events you may like." />

            {
              tab == "find_communities" && 
                <Communities />
            }
          </TabPanel>
          <TabPanel id={`tabpanel-events`} value={tab} index="events">
            <PageDescription text="This page helps you find kink events to attend, either in-person or virtual." />

            {
              tab == "events" && 
                <EventList my_events={values.my_events} />
            }
          </TabPanel>
        </div>
      </LocationContext.Provider>
    </div>
  );
}
