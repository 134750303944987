import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import { Box } from "@material-ui/core";
import { Link, Typography } from "@material-ui/core";
import { Link as RLink } from "react-router-dom";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Pagination]);
import ChatIcon from "@material-ui/icons/ChatBubbleOutline";
import Dialog from "@material-ui/core/Dialog";
import CommonStyles from "components/styles/CommonStyles";
import UserList from "components/UserList";
import UserAvatar from "components/UserAvatar";
import Comments from "components/posts/Comments";
import CreateComment from "components/posts/CreateComment";
import PostStyles from "components/styles/PostStyles";
import Heart from "components/posts/Heart";
import AssetWide from "components/posts/AssetWide";
import EventAbbrev from "components/events/EventAbbrev";
import Flag from "components/Flag";
import goBack from "go_back";
//import { PostBrowseContext } from "components/posts/PostBrowseContext";
import LinkedText from "components/LinkedText";
import utils from "components/posts/utils";
import * as PostService from "../../services/posts.service";
import { ThemeContext } from "components/ThemeContext";
import { makeStyles } from "@material-ui/core";

/*
 * Display a post.
 *
 * Variants:
 * feed: meant for the stories 'feed', e.g. one after another, scrolling
 * story: meant for StoryView, e.g. 3s per image and videos shown as a slideshow
 */

const useStyles = makeStyles((theme) => ({
  postContainerFeed: {
    paddingTop: 12,
    [theme.breakpoints.up(470)]: {
      paddingTop: 12,
      border: '1px solid',
      borderColor: (theme.palette.type == "dark" ? "#4866ff" : "#7d24e0"), // theme.palette.text.disabled,
      borderRadius: '2px'
    }
  },
  postContainerStory: {
    border: "none",
    marginTop: 0,
    paddingTop: 0
  },
  userHeaderFeed: {
    'display': 'flex',
    'alignItems': 'center',
    'flexDirection': 'row',
    marginBottom: 8
  },
  userHeaderStory: (props) => ({
    'display': 'flex',
    'alignItems': 'center',
    'flexDirection': 'row',
    background: (props.theme_type == 'dark' ? "linear-gradient(141deg, rgba(0,0,0,.75) 50%, rgba(255,255,255,.75) 100%)" : "linear-gradient(141deg, rgba(255,255,255,.75) 50%, rgba(0,0,0,.75) 100%)"),
    width: '100%',
    zIndex: 50000,
    position: 'absolute', /* relative? */
    paddingTop: 4,
    paddingBottom: 4
  }),
  assetContainerFeed: {

  },
  assetContainerStory: {
    display: "flex",
    alignItems: "center",
    justifyContent: 'center'    
  },
  postFooterFeed: {
    marginLeft: 4,
    marginRight: 4
  },
  postFooterStory: (props) => ({
    background: (props.theme_type == 'dark' ? "linear-gradient(to bottom, rgba(0,0,0,.55) 70%, rgba(0,0,0,.55) 100%)" : "linear-gradient(to bottom, rgba(255,255,255,.55) 70%, rgba(180,180,180,.55) 100%)"),
    width: '100%',
    position: 'absolute',
    marginTop: -42,
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 4,    
  }),
  flexCols: {
    'display': 'flex',
    'alignItems': 'center',
    'flexDirection': 'row'
  },
  hasMargin: {
    marginLeft: 12,
    marginRight: 12
  },
  hasMargin4: {
    marginLeft: 4,
    marginRight: 4
  }
}));

export default function Post(props) {
  let [themeContext, setThemeContext] = useContext(ThemeContext);

  //const classes = PostStyles();
  const classes = useStyles({ theme_type: themeContext.type });
  const commonStyles = CommonStyles();

  let post_tab = props.post_tab

  const initialValues = {
    post: props.post,
    background: ""
  };
  const [values, setValues] = useState(initialValues);

  //let [postBrowseContext, setPostBrowseContext] = useContext(PostBrowseContext);

  // show_all_comments={false} show_follow={false}

  //let callbackResize = null;

  useEffect(() => {
    // Window resized or orientation changed: resize #messages
    if (props.variant == 'story') {
      let ht = `${window.innerHeight - 0}px`;
      console.log('resize_to', window.innerHeight - 0)
      $(`#asset-container-${props.post.id}`).height(ht)
    }

    // $(window).on("resize", () => {
    //   console.log("=== resize!")
    //   if (callbackResize) {
    //     clearTimeout(callbackResize);
    //   }
    //   callbackResize = setTimeout(() => {
    //     resize();
    //   }, 150);
    // });
  }, []);


  function chat(e) {
    $(`#post-input-text-${values.post.id}`).focus();
    if (props.callbackPauseStory) {
      props.callbackPauseStory()
    }
  }

  /*
  $('video').on('ended', function(vid) {
    console.log('Video has ended!', vid);
  });
  */

  function commentPosted(comment) {
    values.post.comments.push(comment);
    setValues((state) => ({
      ...state,
      ["comments"]: values.post.comments,
      ["num_comments"]: values.post.num_comments + 1,
    }));
  }

  // function posted(content) {
  //   let comment = {
  //     user: {
  //       id: window.current_user.id,
  //       username: window.current_user.username,
  //     },
  //     user_id: window.current_user.id,
  //     liked: true,
  //     likes_num: 1,
  //     content: content,
  //     created_ago: "just now",
  //   };
  //   values.post.comments.push(comment);
  //   setValues((state) => ({
  //     ...state,
  //     ["comments"]: values.post.comments,
  //     ["num_comments"]: values.post.num_comments + 1,
  //   }));
  // }
  function follow_poster(user_id) {
    utils.follow(user_id);
    let post = values.post;
    post.user.following = true;

    setValues((state) => ({
      ...state,
      post: post,
    }));

    if (Number.isInteger(window.current_user.stats?.following)) {
      window.current_user.stats.following =
        window.current_user.stats.following + 1;

      if (window.current_user.stats.following == 3) {
        window.toastr.success("Nice work! Welcome to KinkyKin!")
        setTimeout(function() {
          window.location.reload();
        }, 1500)
      }
    }

    // For use by NewUser.jsx -- trigger an event when the user follows someone
    // if (postBrowseContext.callbackFollowingUsersChanged) {
    //   postBrowseContext.callbackFollowingUsersChanged(
    //     window.current_user.stats.following
    //   );
    // }

    // See: Posts.jsx
    window.postsForceRender = true;
  }

  function unfollow_poster(user_id) {
    utils.unfollow(user_id);
    let post = values.post;
    post.user.following = false;
    setValues((state) => ({
      ...state,
      ["post"]: post,
    }));

    if (Number.isInteger(window.current_user.stats?.following)) {
      window.current_user.stats.following =
        window.current_user.stats.following - 1;
    }

    // For use by NewUser.jsx -- trigger an event when the user unfollows someone
    // if (postBrowseContext.callbackFollowingUsersChanged) {
    //   postBrowseContext.callbackFollowingUsersChanged(
    //     window.current_user.stats.following
    //   );
    // }

    // See: Posts.jsx
    window.postsForceRender = true;
  }

  // function follow_liker(index, user_id) {
  //   utils.follow(user_id);
  //   let post = values.post;
  //   post.likers[index].following = true;
  //   setValues((state) => ({
  //     ...state,
  //     ["post"]: post,
  //   }));
  // }

  // function unfollow_liker(index, user_id) {
  //   utils.unfollow(user_id);
  //   let post = values.post;
  //   post.likers[index].following = false;
  //   setValues((state) => ({
  //     ...state,
  //     ["post"]: post,
  //   }));
  // }

  // Post was hidden and not returned by the back-end (e.g. the user is a non-mod and didn't post this content)
  // Do not show post at all
  // See: posts_controller#show
  if (values.post.hidden && current_user.moderator_level <= 1000) {
    return (
      <div style={{ padding: 12 }}>
        <p>This post was removed for violating our content guidelines.</p>
        <p>
          <a onClick={goBack}>Go Back</a>
        </p>
      </div>
    );
  }

  let user = values.post.user;
  if (!user) {
    return <center>This post's creator does not exist.</center>;
  }
  let isMe = user.id == window.current_user.id;

  useEffect(() => {
    let background_gradient = props.post.background_gradient
    // if (props.background) {
    //   background_gradient = `linear-gradient(180deg, ${post.background.colorA} 0%, ${post.background.colorB} 100%)`
    // } else {
    //   let colors = ["#ffb6bb", "#ffe691", "#95d5ee", '#EF767A', '#B8F2E6', '#AED9E0', '#966B9D', '#90FFDC', '#8DE4FF', '#E3C0D3', '#94E8B4', '#F6AE2D', '#DFFDFF']
    //   let color1 = colors[Math.floor(Math.random() * colors.length)];
    //   let color2 = colors[Math.floor(Math.random() * colors.length)];
    //   background_gradient = `linear-gradient(180deg, ${color1} 0%, ${color2} 100%)`
    // }

    setValues((state) => ({
      ...state,
      background: background_gradient
    }));

  }, [])

  PostService.disableRightClick();

  // See: https://www.tutorialdocs.com/tutorial/swiper/api-pagination.html
  // let swiperPagination = {
  //   el: ".swiper-pagination",
  //   clickable: false,
  //   renderBullet: (index, className) => {
  //     return '<span class="' + className + '"></span>';
  //   },
  // };

  function callbackLike(val) {
    let post = values.post;
    post.liked = val;
    if (val) {
      post.likes_num = post.likes_num + 1;
    } else {
      post.likes_num = post.likes_num - 1;
    }

    setValues((state) => ({
      ...state,
      ["post"]: post,
    }));
  }

  // Profile pic dialog
  const [openDialogLikers, setOpenDialogLikers] = useState(false);

  function showDialogLikers() {
    $.ajax({
      url: `/posts/${values.post.id}/likers`,
      type: "GET",
      async: window.rails_env != "test",
      success: function (data) {
        let post = values.post;
        post.likers = data;

        setValues((state) => ({
          ...state,
          ["post"]: post,
        }));
      },
      cache: false,
      contentType: false,
    });
    setOpenDialogLikers(true);
  }

  function closeDialogLikers() {
    setOpenDialogLikers(false);
  }

  // function showBrowseDialog() {
  //   setPostBrowseContext((state) => ({
  //     ...state,
  //     dialog_open: true,
  //     cur_post_id: values.post.id,
  //   }));
  // }

  // let following_tag_text = "";
  // if (post_tab == "kinks") {
  //   let arr = [];

  //   // Step 1: bold the tags we're following
  //   let content = "";
  //   let matches = [];
  //   if (props.post && props.post.content) {
  //     matches = props.post.content.match(/#(\w+)/g);
  //     if (matches && matches.length > 0) {
  //       // unique-ify the array
  //       matches = matches.filter((v, i, a) => a.indexOf(v) === i);
  //     }
  //   }
  //   if (matches && matches.length > 0) {
  //     for (let i = 0; i < matches.length; i++) {
  //       let tag = matches[i].replace(/^#/, "");
  //       if (window.current_user.following_tag_names.indexOf(tag) > -1) {
  //         arr.push("#" + tag);
  //       }
  //     }
  //   }

  //   following_tag_text = arr.join(", ");
  // }

  let community = values.post.community;
  // hack: for event posts, we have to dig into the event object. Should just be in post.community
  if (values.post.event && values.post.event.community) {
    community = values.post.event.community;
  }

  // Admin sets the post to either approved or rejected
  function setCommunityStatus(post_id, status) {
    var reject_reason;
    if (status == 2) {
      reject_reason = prompt(
        "What reason should you give for the rejection? (optional)",
        ""
      );
    }

    $.ajax({
      url: `/posts/${post_id}/set_community_status`,
      type: "POST",
      async: window.rails_env != "test",
      data: {
        status: status,
        reject_reason: reject_reason,
        authenticity_token: window.auth_token,
      },
      success: function (data) {
        let post = values.post;
        post.community_status = status;

        setValues((state) => ({
          ...state,
          post: post,
        }));

        let msg = "";
        if (status == 1) {
          msg = "Post approved!";
        }
        if (status == 2) {
          msg = "Post rejected!";
        }
        window.toastr.success(msg);
      },
    });
  }

  function imageClass() {
    return (themeContext.type == "dark" ? "no-dark-mode" : "")
  }

  function assetClick(evt) {
    console.log("event", evt)
    if (props.callbackAssetClick) {
      props.callbackAssetClick()
    }
  }

  let postContainer = (props.variant == 'story' ? classes.postContainerStory : classes.postContainerFeed)
  let userHeader = (props.variant == 'story' ? classes.userHeaderStory : classes.userHeaderFeed)
  let assetContainer = (props.variant == 'story' ? classes.assetContainerStory : classes.assetContainerFeed)
  let postFooter = (props.variant == 'story' ? classes.postFooterStory : classes.postFooterFeed)
// style={containerOverrides}
  return (
    <div className={postContainer} id={`post-index-${values.post.id}`}>
      {/* Post was NOT filtered out but IS hidden: moderator or content owner sees this */}
      {values.post.hidden && (
        <div style={{ padding: 12 }}>
          <p>
            This post was <b>removed</b> for violating our content guidelines
            and cannot be seen except by the post's creator and the Kin
            moderation team.
          </p>
          <p>
            If you believe this was in error, please contact{" "}
            <b>support@kinkkin.com</b>. Be sure to mention{" "}
            <b>Case #{values.post.case_id}</b>.
          </p>
        </div>
      )}

      {/*Avatar + username + community + follow/unfollow*/}
      {/*story-header-${props.story_id}*/}
      <div className={userHeader}>
        <div style={{ flexBasis: "70px" }}>
          <center>
            <UserAvatar
              url={user.avatar_url}
              border_color={user.color_background}
              size="small"
            />
          </center>
        </div>
        <div style={{ flexGrow: 1 }}>
          <Link to={`/${user.username}`} component={RLink} color="inherit">
            <span>{user.username}</span>
          </Link>
          {
            community && community.name && (
                <span>&nbsp;·&nbsp; 
                  <Link to={`/community/${community.id}`} component={RLink} color="inherit">
                    {community.name}
                  </Link>
                </span>
              )
          }

          {props.show_follow && (
            <span>
              {!isMe && <span>&nbsp;•&nbsp;</span>}
              {!isMe && !user.following && (
                <a className="hand" className={`follow-${user.id}`} onClick={() => follow_poster(user.id)}>
                  <b>
                    <font color="#5271ff">follow</font> 
                  </b>
                </a>
              )}
              {!isMe && user.following && (
                <span>
                  {window.current_user.stats.following > 3 && (
                    <a className="hand" className={`unfollow-${user.id}`} onClick={() => unfollow_poster(user.id)}>
                      <b>
                        <font color="#5271ff">unfollow</font>
                      </b>
                    </a>
                  )}
                  {window.current_user.stats.following <= 3 && (
                    <b>
                      <font color="#DDD">unfollow</font>
                    </b>
                  )}
                </span>
              )}
            </span>
          )}
        </div>
      </div>

      {/* asset: image/video */}
      <div id={`asset-container-${values.post.id}`} className={assetContainer} onClick={assetClick} style={ { background: values.background } }>
        {
          !values.post.event && (
            <React.Fragment>
              {/* >1 Image */}
              <AssetWide
                asset={values.post.asset}
                variant={props.variant}
                assetClick={assetClick}
                callbackVideoSeek={props.callbackVideoSeek}
                autoplay_video={props.variant == 'story' ? true : false}
              />
            </React.Fragment>
          )
        }

        {
          values.post.event && (
            <React.Fragment>
              <EventAbbrev event={values.post.event} />

              {/*<div className={classes.hasMargin}>
                <Typography variant="subtitle2">
                  Only users who watch this event or RSVP can see this post.
                </Typography>
              </div>*/}
            </React.Fragment>
          )
        }

        {/* https://framework7.io/react/swiper.html
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
          ToDo: stop all videos on swipe    
         */}
      </div>

      {/* hearts, comment, link, flag */}
      <div className={`${postFooter} story-interaction-${props.story_id}`}>
        <div className={classes.flexCols} style={ { height: 42 } }>
          <div style={{ flexBasis: 150, display: 'flex' }}>
            <span className="hand">
              <Heart
                size={24}
                type="posts"
                id={values.post.id}
                liked={values.post.liked}
                callbackLike={callbackLike}
              />
            </span>
            &nbsp;
            <a className="hand chat-icon" onClick={chat}>
              <ChatIcon />
            </a>
            &nbsp;
            {parseInt(values.post.likes_num || 0) > 0 && (
              <span>
                {values.post.user_id == window.current_user.id && (
                  <span>
                    <a onClick={showDialogLikers}>
                      {values.post.likes_num}&nbsp;heart
                      {values.post.likes_num > 1 ? "s" : ""}
                    </a>
                  </span>
                )}
                {values.post.user_id != window.current_user.id && (
                  <span>
                    {values.post.likes_num}&nbsp;heart
                    {values.post.likes_num > 1 ? "s" : ""}
                  </span>
                )}
              </span>
            )}
            {/*&nbsp; &nbsp;
            {props.variant == "abbrev" && (
              <span>
                <a className="zoom-icon hand" onClick={showBrowseDialog}>
                  <img src="/static/icons/zoom.svg" height={24} className={ imageClass() } />
                </a>
                &nbsp;&nbsp;
              </span>
            )}*/}
          </div>
          <div style={{ flexGrow: 1 }}></div>
          <div style={{ flexBasis: 52, textAlign: "right" }}>
            {/*{window.location.pathname != `/p/${values.post.id}` && (*/}
              <Link to={"/p/" + values.post.id} component={RLink} color="inherit">
                <img
                  id={`link-to-post-${values.post.id}`}
                  src="/static/icons/link.svg"
                  height={24}
                  className={`hand ${imageClass()}` }
                  style={{ marginRight: 4 }}
                />
              </Link>
            {/*)}*/}
            {!values.post.event_id && (
              <Flag type="Post" id={values.post.id} key={values.post.id} />
            )}
          </div>
        </div>
      </div>


      {
        values.post.user.id == window.current_user.id && (
          <div style={{ color: "rgb(138, 138, 138)", fontSize: "10px" }}>
            <p>
              {/*Created {values.post.created_ago} ago.
              {values.post.perms == 0 && <span>&nbsp;Kin&nbsp;only. </span>}
              {values.post.perms == 1 && <span>&nbsp;Followers&nbsp;only. </span>}
              {values.post.perms == 2 && (
                <span>&nbsp;Available&nbsp;to&nbsp;all. </span>
              )}*/}
              <span>
                Expires in {values.post.expires_at}. {values.post.views_num}
                &nbsp;view{values.post.views_num > 1 && "s"}.
                {values.post.clicks_num > 0 && (
                  <span>
                    {" "}
                    {values.post.clicks_num}&nbsp;click
                    {values.post.clicks_num > 1 && "s"}.
                  </span>
                )}{" "}
                <a
                  className="delete-post hand"
                  onClick={() => props.callbackDelete(values.post.id)}
                >
                  Delete
                </a>
                &nbsp;|&nbsp;
                {!values.post.event && (
                  <Link
                    className="edit-post"
                    to={`/p/edit/${values.post.id}`}
                    component={RLink}
                    color="inherit"
                  >
                    Edit
                  </Link>
                )}
              </span>
            </p>
          </div>
        )
      }

      {/* post_tab == "kinks" && (
        <div style={{ color: "rgb(138, 138, 138)", fontSize: "10px" }}>
          <p>
            Visible because you follow{" "}
            <LinkedText content={following_tag_text} />
          </p>
        </div>
      */}

      {post_tab == "events" && community && (
        <div style={{ color: "rgb(138, 138, 138)", fontSize: "10px" }}>
          <p>
            Visible because you are a member of{" "}
            <Link to={`/community/${community.id}`} component={RLink} color="inherit">
              <span>{community.name}</span>
            </Link>
          </p>
          {(community.owner_id == window.current_user.id ||
            values.post.user_id == window.current_user.id) && (
            <p>
              Moderation status:&nbsp;
              {values.post.community_status == 0 && (
                <span>
                  <b>Not yet approved</b>
                </span>
              )}
              {values.post.community_status == 1 && (
                <span>
                  <b>Approved</b>
                </span>
              )}
              {values.post.community_status == 2 && (
                <span>
                  <b>Rejected</b>
                  {values.post.community_reject_reason && (
                    <span> - {values.post.community_reject_reason}</span>
                  )}
                </span>
              )}

              {
                // community requires post approvals and current user is an admin?
                community?.member_approvals_posts &&
                  community?.admin_ids?.indexOf(window.current_user.id) > -1 && (
                    <span>
                      {" "}
                      |{" "}
                      <b>
                        {(values.post.community_status == 0 ||
                          values.post.community_status == 2) && (
                          <a
                            onClick={() => {
                              setCommunityStatus(values.post.id, 1);
                            }}
                          >
                            Approve
                          </a>
                        )}
                        {values.post.community_status == 1 && (
                          <span>Approve</span>
                        )}
                      </b>{" "}
                      |{" "}
                      <b>
                        {(values.post.community_status == 0 ||
                          values.post.community_status == 1) && (
                          <a
                            onClick={() => {
                              setCommunityStatus(values.post.id, 2);
                            }}
                          >
                            Reject
                          </a>
                        )}
                        {values.post.community_status == 2 && (
                          <span>Reject</span>
                        )}
                      </b>
                    </span>
                  )
              }
            </p>
          )}
        </div>
      )}

      {/* post's text content and comment thread (up to 3) */}
      <div>
        {values.post.content && (
          <div
            style={{ marginBottom: 8, marginTop: 8, paddingLeft: 4, paddingRight: 4 }}
          >
            <LinkedText content={values.post.content} />
            {/* <span className="comment-text" dangerouslySetInnerHTML={{'__html': TextTransformationService.textToLinkedURL(values.post.content) }}></span>*/}
          </div>
        )}

        {/* user comments */}
        <div
          style={{ marginBottom: 8, marginTop: 8, paddingLeft: 4, paddingRight: 4 }}
        >
          <Comments
            post={values.post}
            comments={values.post.comments}
            variant={props.variant}
          />
        </div>
      </div>

      {/* join the conversation */}
      <div
        style={{ marginBottom: 8, marginTop: 8, paddingLeft: 4, paddingRight: 4 }}
      >
        <CreateComment post_id={values.post.id} posted={commentPosted} source="post" />
      </div>

      <Dialog
        id="dialog-likers"
        open={openDialogLikers}
        keepMounted={false}
        onClose={closeDialogLikers}
        scroll="paper"
        classes={{ paper: commonStyles.dialog90 }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={{ marginTop: 12 }}>
          <UserList show_follow={true} users={values.post.likers || []} />
        </div>
      </Dialog>
    </div>
  );
}
