import React, { useCallback, useState, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';
import { Button, TextField, FormControlLabel, Select, MenuItem, InputLabel, FormHelperText } from '@material-ui/core';
import FontPicker from "font-picker-react";
import { SwatchesPicker } from "react-color"

import { CreatePostContext } from 'components/posts/CreatePostContext'

export default function TextEditor(props) {
  let fonts = [ 'Anton', 'Kanit', 'Open Sans', 'Pacifico', 'Arbutus', 'Domine', 'Dancing Script', 'Covered By Your Grace', 'Norican', 'Roboto Slab', 'Fira Mono', 'Major Mono', 'Source Code Pro' ]
  let categories = ["sans-serif", "serif", "handwriting", "monospace"]

  const [context, setContext] = useContext(CreatePostContext)

  function handleChangeFont(nextFont) {
    setContext(state => ({
      ...state,
      ['font']: nextFont.family,
      ['event']: 'image_editor::text::changed'
    }))
  }

  function handleChangedSize(event) {
    let val = event.target.value

    setContext(state => ({
      ...state,
      ['size']: val,
      ['event']: 'image_editor::text::changed'
    }));
  }

  function handleChangedColor(color) {
    setContext(state => ({
      ...state,
      ['color']: color.hex,
      ['event']: 'image_editor::text::changed'
    }));

    toggleColorPicker()
  }

  function toggleColorPicker() {
    let picker = $('#text-color-picker .swatches-picker')

    let show = false
    if (picker.css('display') == 'none') {
      show = true
    }

    if (show) {
      picker.show()
    } else {
      picker.hide()
    }
  }

  function handleChangeText() {
    let value = event.target.value

    clearTimeout(context.timeoutType)

    setContext(state => ({
      ...state,
      ['text']: value
    }));

    let timeoutType = setTimeout(() => {
      setContext(state => ({
        ...state,
        ['event']: 'image_editor::text::changed' // See: ImageEditor.jsx
      }));
    }, 300)
  }

  return (
    <Box id='menu-text'>
      <FontPicker
        apiKey="AIzaSyBmezob8g1uyPr0vmBqaNkGfcHGy5jLIqM"
        activeFontFamily={context.font}
        families={fonts}
        categories={categories}
        onChange={handleChangeFont}
      />

      <div style={{ display: 'flex', alignItems: 'center', maxWidth: '300' }}>
        <div id='text-color-picker' style={{ flexBasis: 120, height: 35, position: 'relative' }}>
          <div onClick={toggleColorPicker} style={{ background: context.color, height: 35, width: 48, cursor: 'pointer', display: 'block', position: 'relative' }}>&nbsp;</div>
        {/* See: image_editor.scss swatches-picker */}
          <SwatchesPicker
            color={ context.color }
            onChangeComplete={ handleChangedColor }
            width={290}
            height={240}
          />
        </div>
        <div id='text-size-changer' style={{ 'flexBasis': '90px', 'height': '40px', 'position': 'relative', 'display': 'flex', 'alignItems': 'center' }}>
          <span>Size:</span>
          <input id="mask-size-slider" value={context.size} type="range" min="5" max="300" step="4" data-orientation="horizontal" onChange={handleChangedSize} />
        </div>
      </div>
      <div style={{ 'display': 'flex', 'alignItems': 'flex-start' }}>
        <TextField
          variant="outlined"
          margin="none"
          fullWidth
          id="text-text"
          value={context.text || ''}
          error={false}
          onChange={handleChangeText}
          inputProps={{
            autoComplete: "off"
          }}
        />        
      </div>
    </Box>
  )
}
