import React, { useCallback, useState, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/DeleteForever';

import { CreatePostContext } from 'components/posts/CreatePostContext'
import PostStyles from 'components/styles/PostStyles'

export default function Stickers(props) {
  const stickers = window.stickers

  const classes = PostStyles();

  const [context, setContext] = useContext(CreatePostContext)

  function save() {
    setContext(state => ({
      ...state,
      ['event']: 'image_editor::sticker::save'
    }));
  }

  function del() {
    setContext(state => ({
      ...state,
      ['event']: 'image_editor::sticker::delete'
    }));
  }
  
  const handleChangeSticker = (event) => {
    let val = event.target.id

    setContext(state => ({
      ...state,
      ['currentSticker']: val,
      ['event']: 'image_editor::sticker::chosen'
    }));
  }

  const handleChangeStickerSize = (event) => {
    let val = event.target.value

    setContext(state => ({
      ...state,
      ['currentStickerSize']: parseInt(val),
      ['event']: 'image_editor::sticker::resize'
    }));
  }

  let clickedSticker = null
  let size = context.currentStickerSize; // default to whatever's already chosen in the menu
  // if the user has clicked a sticker in the image editor, switch to that sticker's size
  if (context.selectedUpload && context.selectedUpload.state && context.selectedUpload.state.clickedSticker) {
    clickedSticker = context.selectedUpload.state.clickedSticker
    size = clickedSticker.size
  }

  return (
    <div id='menu-stickers'>
      <div style={{ maxHeight: '112px', overflowY: 'auto' }}>
        {
          stickers.map(sticker => {
            let selected = 'borderNotSelectedWhite';
            if (clickedSticker && sticker == clickedSticker.id) {
              selected = 'borderSelected';
            }
            let fn = `${sticker}.svg`;

            return (
              <img className={`hand ${selected}`} src={`/static/stickers/${fn}`} id={sticker} key={sticker} width={24} height={24} onClick={handleChangeSticker} />
            )
          })
        }
      </div>
      {
        clickedSticker && 
          <div className={classes.flexCols} style={{ alignContent: 'center' }}>
            <div style={{ 'flexBasis': '100px' }}>
              Sticker #{clickedSticker.sequence_id} size: 
            </div>
            <div style={{ 'flexBasis': '50px' }}>
              <input id="sticker-size-slider" value={context.currentStickerSize} type="range" min="5" max="40" step="2" data-orientation="horizontal" onChange={handleChangeStickerSize} />
            </div>
            <div style={{ 'flexBasis': '40px' }}>
              &nbsp;&nbsp;<font color='black'><img src='/static/icons/icon-save.svg' onClick={save} width={20} height={20} /></font>
            </div>
            <div style={{ 'flexBasis': '30px' }}>
              <font color='red'><DeleteIcon onClick={del} /></font>
            </div>
          </div>
      }
      {
        !clickedSticker &&
          <div>
            Click a sticker to add to the picture
          </div>
      }
    </div>
  );
}
