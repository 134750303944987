import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import { Container } from "@material-ui/core";
import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Link as RLink } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import Loading from "components/Loading";
import EventAbbrev from "components/events/EventAbbrev";
import { railsUTCtoFriendlyLocalTime } from "services/dates.service.js";
import { LocationContext } from "components/profile/LocationContext";
import CommonStyles from "components/styles/CommonStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 614,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    marginBottom: 8,
    marginLeft: 'auto',
    marginRight: 'auto',
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
    border: "1px solid",
    borderColor: (theme.palette.type == "dark" ? "#765f00" : "#FFCD00")
  },
}));

export default function EventList(props) {
  const classes = useStyles();

  const commonStyles = CommonStyles();
  const initialValues = {
    searchQuery: "",
    querySearchedOn: "",
    events: [],
    include_virtual: true,
    page: 0,
    loading: true,
  };

  //let eventsPassedIn = Array.isArray(props.events)

  const [values, setValues] = useState(initialValues);

  let [locationContext, setLocationContext] = useContext(LocationContext);

  function getEvents() {
    setValues((state) => ({
      ...state,
      ["loading"]: true,
    }));

    if (locationContext.city_id) {
      $.ajax({
        url: `/events.json?city_id=${locationContext.city_id}&distance=${locationContext.distance}&include_virtual=${values.include_virtual}`,
        type: "GET",
        async: window.rails_env != "test",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        success: function (data) {
          // Create localized friendly dates from rails' UTC
          let events = data.events;
          for (let i = 0; i < events.length; i++) {
            events[i].dt_start_friendly = railsUTCtoFriendlyLocalTime(
              events[i].dt_start
            );
          }

          setValues((state) => ({
            ...state,
            events: events,
            loading: false,
          }));
        },
        cache: false,
        contentType: false,
        processData: false,
      });
    }
  }

  useEffect(() => {
    getEvents();
  }, [
    values.page,
    values.include_virtual,
    locationContext.city_id,
    locationContext.distance,
  ]);

  function handleChangeIncludeVirtual(evt) {
    const { name, value } = evt.target;
    setValues((state) => ({
      ...state,
      include_virtual: evt.target.checked,
    }));
  }

  // Typing in the search bar
  const handleChangeSearch = (event) => {
    let value = event.target.value;

    // Update the textarea
    setValues((state) => ({
      ...state,
      searchQuery: value,
    }));
  };

  function handleClickSearch() {
    let query = values.searchQuery;

    $.ajax({
      url: `/events/search?q=${query}`,
      type: "GET",
      async: window.rails_env != "test",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      success: function (data) {
        setValues((state) => ({
          ...state,
          events: data,
          querySearchedOn: query,
          loading: false,
        }));
      },
      error: function (data) {
        setValues((state) => ({
          ...state,
          ["loading"]: false,
        }));
        console.error("data", data);
        window.toastr.error(data.responseJSON.error);
      },
    });
  }

  function clearSearch() {
    setValues((state) => ({
      ...state,
      events: [],
      querySearchedOn: "",
      searchQuery: "",
      loading: true,
    }));
    getEvents();
  }

  function handleClickLocation() {
    setLocationContext((state) => ({
      ...state,
      dialogOpen: true,
    }));
  }

  return (
    <div style={ { paddingBottom: 8 } }>
      {props.my_events.length > 0 && (
        <div className={classes.container}>
          <div style={{ paddingBottom: 8 }}>
            <b>Events you have RSPV'd to:</b>
            <ul>
              {props.my_events.map(function (event, index) {
                return (
                  <li key={event.id}>
                    <Link to={`/event/${event.id}`} component={RLink} color="inherit">
                      {event.name}
                    </Link>{" "}
                    - {event.dt_start_friendly}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}

      <div className={classes.container}>
        <div
          style={{
            width: "100%",
            maxWidth: 300,
            margin: "auto",
            paddingTop: 12,
            marginBottom: 12,
          }}
        >
          <TextField
            id="input-event-search"
            placeholder="Search…"
            multiline={false}
            value={values.searchQuery || ""}
            onChange={handleChangeSearch}
            error={false}
            InputProps={{
              endAdornment: (
                <SearchIcon
                  color="primary"
                  className="hand"
                  onClick={handleClickSearch}
                />
              ),
            }}
            inputProps={{
              autoComplete: "off",
            }}
            fullWidth
            size="small"
            variant="outlined"
            margin="none"
          />
        </div>
      </div>

      <div id="events-list" className={classes.container}>
        {/*See also: Communities.jsx, code should match*/}
        {/*      <LocationContext.Provider value={[locationContext, setLocationContext]}>
          <DialogSetLocation />
        </LocationContext.Provider>
  */}

        {
          !values.loading && (
            <div style={{ flexGrow: 1 }}>
              <div style={{ paddingBottom: 8, fontSize: "1rem", fontWeight: 400, fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}>
                Within{" "}
                <a onClick={handleClickLocation} className="hand">
                  {locationContext.distance}
                </a>
                {" "}miles of{" "}
                <a onClick={handleClickLocation} className="hand">
                  {locationContext.city}, {locationContext.region}
                </a>

                <div
                  className={commonStyles.flexRow}
                  style={{ alignItems: "center" }}
                >
                  <div style={{ flexGrow: 1 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.include_virtual}
                          onChange={handleChangeIncludeVirtual}
                          name="include-virtual"
                          color="primary"
                        />
                      }
                      label="Include virtual events?"
                    />

                    {/*{
                      !eventsPassedIn && window.current_user.confirmed_at &&
                        <div style={ { float: 'right', marginTop: 12 } }>
                          <a id="create-community" href="/community/new">New Community</a>&nbsp;&nbsp;
                        </div>
                    }*/}
                  </div>
                </div>
              </div>
              {values.querySearchedOn && (
                <div>
                  <Typography variant="h5" gutterBottom={true}>
                    Results for <i>{values.querySearchedOn}:</i>&nbsp;
                    <font color="red">
                      <CloseIcon className="hand" onClick={clearSearch} />
                    </font>
                  </Typography>
                </div>
              )}
              <div className={commonStyles.flexCol} style={{ width: "100%" }}>
                {values.events && values.events.length > 0 && (
                  <div>
                    {values.events.map(function (event, index) {
                      return (
                        <EventAbbrev event={event} key={event.id}>
                          {event.name}
                        </EventAbbrev>
                      );
                    })}
                  </div>
                )}
                {values.events && values.events.length == 0 && (
                  <div>
                    <br />
                    <br />
                    <center>~ No Events Found ~</center>
                    <br />
                    <br />
                    <br />
                  </div>
                )}
              </div>
            </div>
          )
        }
        <Loading loading={values.loading} />
      </div>
    </div>
  );
}
