import React, { useState, useEffect } from "react";
import $ from "jquery";

import {
  Button,
  TextField,
  Select,
  InputLabel,
  FormHelperText,
  FormControl,
} from "@material-ui/core";

import { CirclePicker } from "react-color";
import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Cookies from "vendor/js.cookie.min.mjs";
import Dob from "components/profile/dob";
import UserAvatar from "components/UserAvatar";
import colors_background from "colors_background";
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "300px",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    width: "100%",
    maxWidth: 200,
    "margin-bottom": theme.spacing(2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(0),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

export default function Registration() {
  const classes = useStyles();

  function urlParam(name) {
    var results = new RegExp("[?&]" + name + "=([^&#]*)").exec(
      window.location.search
    );
    return results !== null ? results[1] || 0 : false;
  }

  function getRandomSubarray(arr, size) {
    var shuffled = arr.slice(0),
      i = arr.length,
      temp,
      index;
    while (i--) {
      index = Math.floor((i + 1) * Math.random());
      temp = shuffled[index];
      shuffled[index] = shuffled[i];
      shuffled[i] = temp;
    }
    return shuffled.slice(0, size);
  }

  // Default values for the various inputs/selects
  // Note: also used by sign up button: if any are empty or errors, it's disabled
  const initialValues = {
    dob_entered: false,
    age_ver_failed: Cookies.get("age_ver_failed") == "true",
    dob_mm: Cookies.get("dob_mm") || "",
    dob_dd: Cookies.get("dob_dd") || "",
    dob_yy: Cookies.get("dob_yy") || "",
    invite_code: urlParam("i") || "",
    username: "",
    password: "",
    email: "",
    pronouns_id: "she / her",
    valid_invite_code: false,
    captcha_value: "",
  };

  let requiredInputs = [
    "dob_mm",
    "dob_dd",
    "dob_yy",
    "username",
    "password",
    "email",
    "pronouns_id",
    "color_background",
  ];
  if (window.rails_env != "test") {
    requiredInputs.push("captcha_value");
  }

  // Form values state
  const [values, setValues] = useState(initialValues);
  // FE Errors hash: { fieldname: 'front end error' }
  const [errorsFE, setErrorsFE] = useState({});
  // BE Errors hash: { fieldname: 'username is taken' }
  const [errorsBE, setErrorsBE] = useState({});
  // Which fields have been visited then left: { fieldname: true (or false0) }
  const [blurred, setBlurred] = useState({});
  // Keep timeout across state so we're able to cancel it
  const [timeoutValidate, setTimeoutValidate] = useState(null);

  useEffect(() => {
    if (urlParam("i")) {
      initiateBackEndValidation({
        ...values,
        ["invite_code"]: urlParam("i"),
      });
    }

    setValues((state) => ({
      ...state,
      reduced_colors_background: getRandomSubarray(colors_background, 14),
      color_background:
        colors_background[Math.floor(Math.random() * colors_background.length)],
    }));
  }, []);

  useEffect(() => {
    gtag("event", "reg_page_loaded", {
      event_category: "registration",
      event_label: "label",
      value: 0,
    });
  });

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    let required = event.target.required;

    setValues((state) => ({
      ...state,
      [name]: value,
    }));

    // Don't validate on keystroke until the user has completed a first attempt
    if (blurred[name] || (name == "invite_code" && value.length == 7)) {
      validate(name, value, required);
    }
  };

  const handleChangeDob = (event) => {
    handleChange(event);
    Cookies.set(event.target.name, event.target.value, { expires: 1 });
  };

  /*
  const handleChangeLocation = (vals) => {
    setValues(state => ({
      ...state,
      'country_id': parseInt(vals.country_id || -1),
      'region_id': parseInt(vals.region_id || -1),
      'city_id': parseInt(vals.city_id || -1)
    }));
  }
  */

  // User has moved focus out of a field
  const handleBlur = (event) => {
    let name = event.target.name;
    let value = event.target.value.trim();
    let required = event.target.required;

    //validateFrontend(name, value, required)
    if (!blurred[name]) {
      validate(name, value, required);
    }

    setBlurred((state) => ({
      ...state,
      [name]: true,
    }));
  };

  const validate = (name, value, required) => {
    validateFrontend(name, value, required);
    if (name == "username" || name == "email" || name == "invite_code") {
      initiateBackEndValidation({
        ...values,
        [name]: value,
      });
    }
  };

  // Only triggers after user has stopped typing for >1s
  const initiateBackEndValidation = (vals) => {
    clearTimeout(timeoutValidate);
    setTimeoutValidate(
      setTimeout(() => {
        validateBackend(vals);
      }, 400)
    );
  };

  const validateBackend = (vals) => {
    $.ajax({
      method: "POST",
      url: "/signup-validate",
      data: vals,
      async: window.rails_env != "test",
    }).done(function (data) {
      setValues((state) => ({
        ...state,
        invite_user: data.invite_user,
        valid_invite_code: data["invite_code"] == null,
      }));

      setErrorsBE((state) => ({
        ...state,
        ["invite_code"]: data["invite_code"],
        ["username"]: data["username"],
        ["email"]: data["email"],
      }));
    });
    clearTimeout(timeoutValidate);
  };

  // Front-end validations
  const validationsFE = {
    username: {
      regex: /^\w{3,20}$/,
      error:
        "Username can only contain letters, numbers and underscore. It must be 3+ letters long.",
    },
    password: {
      regex:
        /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,128})/,
      error:
        "Password must be 6+ letters long and contain a number and/or an uppercase letter",
    },
    email: {
      regex: /\S+@\S+\.\S+/,
      error: "Invalid email address.",
    },
    invite_code: {
      regex: /^\w{7}$/,
      error: "Invite codes should be 7 characters and a-z, A-Z or 0-9",
    },
  };

  const validateFrontend = (name, value, required) => {
    let error = "";

    const rules = validationsFE[name];
    if (rules) {
      if (rules.regex && value && !rules.regex.test(value)) {
        error = rules.error;
      }
      if (required && !value.trim()) {
        error = "Required";
      }
    }
    setErrorsFE((state) => ({
      ...state,
      [name]: error,
    }));
  };

  const verifyDOB = () => {
    let dob = new Date(
      values.dob_yy + "-" + values.dob_mm + "-" + values.dob_dd
    );
    let days_diff = Math.floor((Date.now() - dob) / (1000 * 60 * 60 * 24));
    let days_18 = 18 * 365 + Math.floor(18 / 4); // 18 years + leap days
    if (days_diff < days_18) {
      Cookies.set("age_ver_failed", true, { expires: 1 });
      setValues((state) => ({
        ...state,
        age_ver_failed: true,
        dob_entered: true,
      }));

      gtag("event", "age_ver_failed", {
        event_category: "registration",
        event_label: "label",
        value: 1,
      });
    } else {
      Cookies.set("age_ver_failed", false);
      setValues((state) => ({
        ...state,
        dob_entered: true,
      }));

      gtag("event", "age_ver_succeeded", {
        event_category: "registration",
        event_label: "label",
        value: 1,
      });
    }
  };

  const allFieldsValid = () => {
    let retval = true;
    requiredInputs.forEach((element) => {
      if (
        values[element] == null ||
        values[element] == "" ||
        errorsBE[element] ||
        errorsFE[element]
      ) {
        retval = false;
        //console.log("failed: ", element, values[element], errorsBE[element], errorsFE[element])
      }
    });
    return retval;
  };

  function onChange(value) {
    setValues((state) => ({
      ...state,
      captcha_value: value,
    }));
  }

  function handleChangedColor(value) {
    //console.log("value", value.hex);
    setValues((state) => ({
      ...state,
      color_background: value.hex,
    }));
  }

  function trackSignupClicked() {
    gtag("event", "signup_clicked", {
      event_category: "registration",
      event_label: "label",
      value: 2,
    });
  }

  return (
    <Box className={classes.container} p={4} mt={3}>
      <img className={classes.logo} src={"/logo-heart-kinkykin-light.svg"} />
      <Typography>
        <center>
          Sign up to share photos and
          <br />
          attend events with other 18+ kinksters.
        </center>
      </Typography>

      <div style={{ display: values.age_ver_failed ? "block" : "none" }}>
        <Typography>
          Sorry, you're not allowed to register at this time.
        </Typography>
      </div>

      <form
        className={classes.form}
        action="/registration"
        method="POST"
        noValidate
      >
        <div
          style={{
            display: values.age_ver_failed ? "none" : "block",
            width: "100%",
          }}
        >
          {/* Step 1: dob check */}
          <div
            style={{
              display: values.dob_entered ? "none" : "block",
              width: "100%",
            }}
          >
            <Box mt={3} mb={3}>
              <Typography>
                <b>Please confirm your date of birth:</b>
              </Typography>
            </Box>

            <Dob
              allow_null={true}
              dob_dd={values.dob_dd}
              dob_mm={values.dob_mm}
              dob_yy={values.dob_yy}
              handleChange={handleChangeDob}
            />
            <FormHelperText
              error={!!errorsFE["dob_mm"] || !!errorsFE["dob_dd"]}
            >
              {errorsFE["dob_mm"] || errorsFE["dob_dd"]}
            </FormHelperText>

            <Box mt={5}>
              <Button
                id="btn-continue"
                fullWidth
                variant="contained"
                color="primary"
                disabled={
                  values.dob_yy == "" ||
                  values.dob_mm == "" ||
                  values.dob_dd == "" ||
                  (values.invite_code != "" && !values.valid_invite_code)
                }
                onClick={verifyDOB}
              >
                Continue
              </Button>
            </Box>
          </div>

          <div style={{ display: values.dob_entered ? "block" : "none" }}>
            <input
              name="authenticity_token"
              type="hidden"
              value={window.auth_token}
            />
            <TextField
              id="input-username"
              name="username"
              label="Username"
              value={values["username"] || ""}
              error={!!errorsFE["username"] || !!errorsBE["username"]}
              onBlur={handleBlur}
              onChange={handleChange}
              inputProps={{
                autoComplete: "off",
                "data-valid":
                  values["username"] &&
                  !errorsFE["username"] &&
                  !errorsBE["username"],
              }}
              required
              autoFocus
              size="small"
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <FormHelperText
              error={!!errorsFE["username"] || !!errorsBE["username"]}
            >
              {errorsFE["username"] || errorsBE["username"]}
            </FormHelperText>
            <TextField
              id="input-email"
              name="email"
              label="Email Address"
              value={values["email"] || ""}
              error={!!errorsFE["email"]}
              onBlur={handleBlur}
              onChange={handleChange}
              inputProps={{ autoComplete: "off" }}
              required
              size="small"
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <FormHelperText error={!!errorsFE["email"] || !!errorsBE["email"]}>
              {errorsFE["email"] || errorsBE["email"]}
            </FormHelperText>
            <TextField
              id="input-password"
              name="password"
              label="Password"
              type="password"
              value={values["password"] || ""}
              error={!!errorsFE["password"]}
              onBlur={handleBlur}
              onChange={handleChange}
              inputProps={{ autoComplete: "new-password" }}
              required
              size="small"
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <FormHelperText error={!!errorsFE["password"]}>
              {errorsFE["password"]}
            </FormHelperText>

            <Box mt={2}>
              <FormControl variant="outlined">
                <InputLabel id="pronouns-label">Pronouns:</InputLabel>
                <Select
                  native
                  id="select-pronouns"
                  name="pronouns_id"
                  labelId="pronouns-label"
                  value={values["pronouns_id"]}
                  label="Pronouns"
                  onChange={handleChange}
                  style={{ minWidth: 140 }}
                >
                  <option value={0}>she / her</option>
                  <option value={1}>he / him</option>
                  <option value={2}>they / them</option>
                  <option value={3}>ze / zir</option>
                  <option value={4}>xe / xem</option>
                  <option value={5}>sie / hir</option>
                  <option value={null}>n / a</option>
                </Select>
              </FormControl>
            </Box>

            {/* <Location country_id={values.country_id} region_id={values.region_id} city_id={values.city_id} callback={handleChangeLocation} /> */}

            {window.invite_only_mode && (
              <div>
                <Box mt={3} mb={1}>
                  <Typography>
                    <b>Enter your invite code (optional):</b>
                  </Typography>
                </Box>

                <TextField
                  id="input-invite-code"
                  name="invite_code"
                  label="Invite Code"
                  value={values.invite_code}
                  error={!!errorsFE["invite_code"] || !!errorsBE["invite_code"]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={{ autoComplete: "off", maxLength: 7 }}
                  size="small"
                  fullWidth
                  variant="outlined"
                />
                <FormHelperText
                  error={!!errorsFE["invite_code"] || !!errorsBE["invite_code"]}
                >
                  {errorsFE["invite_code"] || errorsBE["invite_code"]}
                </FormHelperText>

                <p>
                  Invite codes look like:{" "}
                  <b>
                    <code>TFz83Op</code>
                  </b>
                </p>

                {values.invite_user && (
                  <div>
                    <Box mt={3} mb={1}>
                      <Typography>
                        <b>Invited by:</b>
                      </Typography>
                    </Box>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ flexBasis: "70px" }}>
                        <UserAvatar
                          username={values.invite_user.username}
                          url={values.invite_user.avatar_url}
                          border_color={values.invite_user.color_background}
                          size="small"
                        />
                      </div>
                      <div style={{ flexGrow: 1 }}>
                        <Typography>{values.invite_user.username}</Typography>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            <Box mt={2}>
              <InputLabel id="colors-label">Pick a color:</InputLabel>
              <br />
              <div
                style={{
                  background: values.color_background,
                  width: "100%",
                  height: "24px",
                  marginTop: 0,
                  marginBottom: 8,
                }}
              />
              <CirclePicker
                color={values.color_background}
                colors={values.reduced_colors_background}
                onChangeComplete={handleChangedColor}
                triangle="hide"
                width={"100%"}
              />
            </Box>

            <input type="hidden" value={values.captcha_value} />

            <br />
            <ReCAPTCHA
              sitekey={window.recaptcha_key}
              onChange={onChange}
              style={{ display: "inline-block" }}
            />

            <Button
              id="btn-signup"
              type="submit"
              fullWidth
              disabled={!allFieldsValid()}
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={trackSignupClicked}
            >
              Sign Up
            </Button>
          </div>
        </div>
      </form>
    </Box>
  );
}
