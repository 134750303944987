import React, { useCallback, useState, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';
import { CreatePostContext } from 'components/posts/CreatePostContext'

export default function FilterButtons(props) {
  const filters = [ 'normal', 'clarendon', 'gingham', 'moon', 'lark', 'reyes', 'juno', 'slumber', 'crema', 'ludwig', 'aden', 'perpetua', 'amaro', 'mayfair', 'rise', 'hudson', 'valencia', 'xpro2', 'sierra', 'willow', 'lofi', 'inkwell', 'hefe', 'nashville', 'stinson', 'vesper', 'earlybird', 'brannan', 'sutro', 'toaster', 'walden', '1977', 'kelvin', 'maven', 'ginza', 'skyline', 'dogpatch', 'brooklyn', 'helena', 'ashby', 'charmes' ]

  const [context, setContext] = useContext(CreatePostContext)

  const handleChangeFilter = (event) => {
    let val = event.target.id

    setContext(state => ({
      ...state,
      ['currentFilter']: val
    }));
  }

  return (
    <Box id='menu-filters'>
      <ul className="hs full">
        {
          filters.map(filter => {
            let selected = '';
            if (filter == context.currentFilter) {
              selected = 'filter-selected'
            }
            return (
              <li className={`font-border mask hand ${selected}`} style={{ backgroundImage: `url(/static/filters/filter-${filter}.jpeg)` }} id={filter} key={filter} onClick={handleChangeFilter}>{filter}</li>
            )
          })
        }
      </ul>
    </Box>
  );
}
