import { makeStyles } from '@material-ui/core';

/*
 * Styles for: Posts, CreatePost, Post, Comments
 */
export default makeStyles((theme) => ({
  showContainer: {
    width: "100%",
    height: "calc(100vh - 40px)",
    margin: 'auto',
    // maxWidth: 500,
    position: 'relative',
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  canvasContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: "hidden",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
  },
  videoContainer: {
    width: "100%",
    margin: 'auto',
    maxHeight: "80vh",
    maxWidth: 700,
  },
  controls: {
    bottom: 0,
    padding: 4,
    position: 'absolute',
    width: '100%',
    color: '#FFF',
    //background: 'linear-gradient(to bottom, rgba(0,0,0,0.40) 0%, rgba(0,0,0,0.75) 100%)',
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "center"
  },
}));