import React, { useEffect, useState, useContext, useCallback } from "react";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import * as PostService from "../../services/posts.service";
import { useTheme } from '@material-ui/core/styles';
import Post from "components/posts/Post";
import TopMenu from "components/stories/TopMenu";
import useInterval from "vendor/useInterval";
import colors_background from "colors_background";
import { CreatePostContext } from "components/posts/CreatePostContext";
// See: https://github.com/ricardo-ch/react-easy-crop
// https://valentinh.github.io/react-easy-crop/
import Cropper from "react-easy-crop";
import Dialog from "@material-ui/core/Dialog";
import CommonStyles from "components/styles/CommonStyles";
import { syncGetCroppedImg, getCroppedImg, getRotatedImage } from "../../vendor/canvasUtils";
import ReactEasyCropStyles from "components/events/ReactEasyCropStyles";
import { CirclePicker } from "react-color";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Cookies from 'vendor/js.cookie.min.mjs'
import {
  Slider,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormControl,
} from "@material-ui/core";
import { Popover } from 'react-tiny-popover'
import StoryStyles from "components/styles/StoryStyles";

/*
 * Editor for these context items:
 *
 * background, croppedAreaPixels, crop, rotation, zoom
 */
export default function EditCrop(props) {
  const reactEasyCropStyles = ReactEasyCropStyles();
  const commonStyles = CommonStyles();

  const storyClasses = StoryStyles();

  let [context, setContext] = useContext(CreatePostContext);

  /* Image cropper. See: https://codesandbox.io/s/y09komm059 */
  const [imageSrc, setImageSrc] = React.useState(null);

  let initialValues = {
    zoomMarks: [ { value: 0.2 }, { value: 0.4 }, { value: 0.6 }, { value: 0.8 }, { value: 1 }, { value: 1.2 }, { value: 1.4 }, { value: 1.6 }, { value: 1.8 }, { value: 2 }, { value: 2.2 }, { value: 2.4 }, { value: 2.6 }, { value: 2.8 }, { value: 3 } ],
    dialogBgStylesShow: false,
    showZoomPopover: false,
    showRotatePopover: false,
  };
  const [values, setValues] = useState(initialValues);

  // ToDo: get this shared function into a service of some sort
  function bgStyles() {
    return `linear-gradient(171deg, ${context.background.colorA} 0%, ${context.background.colorB} 100%)`
  }

  function openDialogBkgColors() {
    setValues((state) => ({
      ...state,
      dialogBgStylesShow: true
    }));
  }

  function closeDialogBkgColors(evt) {
    setValues((state) => ({
      ...state,
      dialogBgStylesShow: false
    }));

    // If they re-click the 'color circle', close it. Don't pass the event down to openDialogBkgColors or it will just re-appear
    if (evt.target.id == 'set-background-color') {
      evt.preventDefault()
    }
  }

  const onCropComplete = useCallback((croppedArea, croppedPx) => {
    window.croppedAreaPixels = croppedPx

    setContext((state) => ({
      ...state,
      croppedAreaPixels: croppedPx 
    }))
  }, []);

  function handleChangedColor(letter, value) {
    let colorKey = 'color' + letter // A or B
    let bkg = context.background
    bkg[colorKey] = value
    setContext((state) => ({
      ...state,
      background: bkg,
    }));

    Cookies.set('background_' + colorKey, value, { expires: 7 })
  }

  function handleChangedZoom(e, zoom) {
    setContext((state) => ({
      ...state,
      zoom: zoom 
    }));
    window.zoom = zoom
  }

  function handleChangedCrop(crop) {
    setContext((state) => ({
      ...state,
      crop: crop 
    }));
    window.crop = crop
  }

  function handleToggleZoom(event) {
    setValues((state) => ({
      ...state,
      showZoomPopover: !values.showZoomPopover
    }))
  }

  function handleCloseZoom() {
    setValues((state) => ({
      ...state,
      showZoomPopover: false
    }))
  }

  function rotate() {
    let rotation = context.rotation
    rotation += 90
    if (rotation >= 360) {
      rotation = 0
    }
    setContext((state) => ({
      ...state,
      rotation: rotation 
    }));
    window.rotation = rotation
  }

  function nextStep() {
    if (window.croppedAreaPixels) {
      setContext((state) => ({
        ...state,
        loading: true
      }));

      // Timeout gives the loading image time to appear
      setTimeout(function() {
        syncGetCroppedImg(context.imageResized, window.croppedAreaPixels, window.rotation, function(croppedCanvas) {
          let stepIdx = context.steps.indexOf(context.step)
console.log("croppedCanvas", croppedCanvas)
          // croppedImage: base64 encoded png
          setContext((state) => ({
            ...state,
            canvasCropped: croppedCanvas,
          }));

          setTimeout(() => {
            // Wait until next render to actually move to the next step
            setContext((state) => ({
              ...state,
              step: context.steps[stepIdx + 1],
              loading: false,
            }))
          }, 50)
        })
      }, 50)
    }
  }

  let colors = ['#FFFFFF', '#000000', '#F47373', '#F88379', '#EC5800', '#ff8a65', '#AA4A44', '#D22B2B', '#770737', '#8B8000', '#E49B0F', '#F5B041', '#D35400', '#697689', '#097969', '#228B22', '#32CD32', '#2CCCE4', '#191970', '#0047AB', '#555555', '#CD7F32', '#6E260E', '#808000', '#ba68c8', '#FF00FF', '#7D3C98', '#800080']
  let heightBottomControls = 55

  let wid = Math.floor($('.reactEasyCrop_Container').outerWidth())
  if (wid && wid != context.containerMaxWidth)
  setContext((state) => ({
    ...state,
    containerMaxWidth: wid
  }))

  let hei = Math.floor($('.reactEasyCrop_Container').outerHeight())
  if (hei && hei != context.containerMaxHeight)
  setContext((state) => ({
    ...state,
    containerMaxHeight: hei
  }))

  return (
    <React.Fragment>
      <TopMenu next={nextStep} prev={props.exitCallback} />

      { /* , width: '100%', height: `calc(100vh - ${heightTopMenu}px)`, position: 'relative' */ }

      <div className={storyClasses.showContainer} style={ { height: `calc(100vh - ${props.heightTopMenu}px)`, background: bgStyles() } }>

        { /* https://github.com/ricardo-ch/react-easy-crop */}
        <Cropper
          image={context.imageResized}
          crop={context.crop}
          rotation={context.rotation}
          zoom={context.zoom}
          minZoom={0.2}
          maxZoom={3}
          showGrid={true}
          aspect={1080/1920}
          restrictPosition={false}
          zoomWithScroll={false}
          zoomSpeed={1}
          objectFit="vertical-cover"
          onCropChange={handleChangedCrop}
          onZoomChange={handleChangedZoom}
          onCropComplete={onCropComplete}
          onMediaLoaded={(mediaSize) => {
            // Adapt zoom based on media size to fit max height/width
            setTimeout(() => {
              let zoomW = ($('.reactEasyCrop_CropArea').width() / mediaSize.width).toFixed(3)
              let zoomH = ($('.reactEasyCrop_CropArea').height() / mediaSize.height).toFixed(3)

              let zoom = Math.min(zoomW, zoomH)
              if (context.zoom == -1) {
                setContext((state) => ({
                  ...state,
                  zoom: zoom
                }))
              }

              // Add the automated zoom into zoomMarks
              if (!values.zoomMarks.includes({ value: zoom })) {
                let arr = values.zoomMarks
                arr.push({ value: parseFloat(zoom) })
                arr.sort((a,b) => a.value - b.value)
                setValues((state) => ({
                  ...state,
                  zoomMarks: arr
                }))
              }
            }, 50)
          }}
        />
      </div>

      {/* Should match logic in PreviewVideo.jsx */}
      {
        values.dialogBgStylesShow && (
          <div id='bkg-color-picker' className={`${storyClasses.controls}`} style={ { background: 'rgb(0,0,0,0.55)', bottom: heightBottomControls } }>
            <ClickAwayListener onClickAway={closeDialogBkgColors}>
              <div style={ { width: '100%', maxWidth: 750 } }>
                <div style={ { paddingBottom: 4 } }>Story Background:</div>
                <ul id='bkg-color-picker-top' className="hs full">
                  {
                    colors.map((color) => (
                      <li key={color} className={`font-border mask hand`} onClick={() => handleChangedColor('A', color)}>
                        <div style={ { height: 16, width: 16, backgroundColor: color, borderRadius: "50%", display: "inline-block", border: (color == context.background.colorA ? 'solid 1px #FFF' : 'none') }}></div>
                      </li>
                    ))
                  }
                </ul>
                <ul id='bkg-color-picker-bottom' className="hs full">
                  {
                    colors.map((color) => (
                      <li key={color} className={`font-border mask hand`} onClick={() => handleChangedColor('B', color)}>
                        <div style={ { height: 16, width: 16, backgroundColor: color, borderRadius: "50%", display: "inline-block", border: (color == context.background.colorB ? 'solid 1px #FFF' : 'none') }}></div>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </ClickAwayListener>
          </div>
        )
      }

      <div className={storyClasses.controls} style={ { bottom: 0, height: heightBottomControls } }>
        <div onClick={openDialogBkgColors} style={ { zIndex: 20000 } } >
          <div id="set-background-color" style={ { borderRadius: '50%', width: 36, height: 36, marginLeft: 16, border: '1px solid', background: bgStyles() } }>
            &nbsp;
          </div>
        </div>

        <Popover
          isOpen={values.showZoomPopover}
          positions={['top', 'right']}
          padding={4}
          reposition={true}
          align='center'
          onClickOutside={handleCloseZoom}
          containerStyle={ { zIndex: 339939 } }
          content={({ position, nudgedLeft, nudgedTop }) => (
            <div id="slider-zoom" style={ { width: 200, height: 40, paddingLeft: 8, paddingRight: 16, borderRadius: 20, background: "linear-gradient(to bottom, rgba(39,39,39,0.40) 0%, rgba(0,0,0,0.75) 100%)" } }>
              <Slider
                value={context.zoom}
                marks={values.zoomMarks}
                color="secondary"
                min={0.2}
                max={3}
                step={null}
                aria-labelledby="Zoom"
                valueLabelDisplay={"off"}
                onChange={handleChangedZoom}
              />
            </div>
          )}
        >
          <div onClick={handleToggleZoom} className="hand" style={{ width: 36, height: 36, marginLeft: 12, background: 'rgb(0,0,0,0.55)', borderRadius: '50%', display: 'flex' }}>
            <img id="set-zoom" src="/stories/zoom.svg" height='22' style={ { margin: 'auto' } } />
          </div>
        </Popover>

        <div onClick={rotate} className="hand" style={{ width: 36, height: 36, marginLeft: 12, background: 'rgb(0,0,0,0.55)', borderRadius: '50%', display: 'flex' }}>
          <img id="set-rotate" src="/stories/rotate.svg" height='22' style={ { margin: 'auto' } } />
        </div>
      </div>
    </React.Fragment>
  );
}