import React, { useState } from "react";
import { Link } from "@material-ui/core";
import { Link as RLink } from "react-router-dom";
import Heart from "components/posts/Heart";
import CommonStyles from "components/styles/CommonStyles";
import LinkedText from "components/LinkedText";
import { Button, TextField } from "@material-ui/core";

export default function Comment(props) {
  //let html = TextTransformationService.textToLinkedURL(props.comment.content)

  // 'full' (postWide) || 'partial' (posts page)

  const classes = CommonStyles();

  const initialValues = {
    mode: 'view',
    comment: props.comment,
  };
  const [values, setValues] = useState(initialValues);

  function callbackLike(val) {
    let comment = values.comment;
    comment.liked = val;
    if (val) {
      comment.likes_num = comment.likes_num + 1;
    } else {
      comment.likes_num = comment.likes_num - 1;
    }

    setValues((state) => ({
      ...state,
      ["comment"]: comment,
    }));
  }

  function editComment() {
    setValues((state) => ({
      ...state,
      mode: 'edit'
    }));
  }

  function deleteComment() {
    if (confirm("Are you sure you want to delete this comment?")) {
      $.ajax({
        url: `/comments/${values.comment.id}?authenticity_token=${encodeURIComponent(
          window.auth_token
        )}`,
        type: "DELETE",
        async: window.rails_env != "test",
        success: function (data) {
          window.toastr.success("Comment deleted!");
          // Hack: should update the parent post
          $(`#comment-${values.comment.id}`).remove()
        },
        error: function (data) {
          window.toastr.error("Error removing comment! Please contact support@kinkkin.com if this keeps happening.");
        },
      });
    }
  }

  function updateComment() {
    $.ajax({
      url: `/comments/${values.comment.id}?authenticity_token=${encodeURIComponent(
        window.auth_token
      )}`,
      type: "PUT",
      async: window.rails_env != "test",
      data: {
        content: $(`#edit-comment-${values.comment.id}`).val()
      },
      success: function (data) {
        window.toastr.success("Comment edited!");
        setValues((state) => ({
          ...state,
          comment: data.comment,
          mode: 'view'
        }));
      },
      error: function (data) {
        window.toastr.success("Error updating comment! Please contact support@kinkkin.com if this keeps happening.");
      },
    });
  }

  // comment.user.id
  return (
    <div>
      {
        values.mode == 'view' && (
          <div className={classes.flexRow} id={`comment-${values.comment.id}`}>
            <div style={{ flexGrow: 1, paddingTop: 2 }}>
              {values.comment.user.username && (
                <span>
                  <Link to={`/${values.comment.user.username}`} component={RLink} color="inherit">
                    <b>{values.comment.user.username}</b>
                  </Link>
                  &nbsp;
                </span>
              )}

              <LinkedText content={values.comment.content} />

              {/*<span className="comment-text" dangerouslySetInnerHTML={{'__html': html }}></span>*/}
            </div>


            {values.comment.user_id == window.current_user.id && (
              <div style={ { fontSize: 12, float: 'right', flexBasis: 70, width: 70, minWidth: 70, paddingTop: 4 } }>
                <a className="hand" onClick={editComment}>edit</a>{" "}&nbsp;
                <a className="hand" onClick={deleteComment}>delete</a>{" "}&nbsp;
              </div>
            )}

            {props.variant == "full" && (
              <div style={{ flexBasis: 40, width: 40, minWidth: 40 }}>
                {values.comment.likes_num > 0 && (
                  <center>{values.comment.likes_num}</center>
                )}
              </div>
            )}

            <div style={{ flexBasis: 20, width: 20, minWidth: 20 }}>
              <Heart
                type="comments"
                size={20}
                id={values.comment.id}
                liked={values.comment.liked}
                likes_num={values.comment.likes_num}
                callbackLike={callbackLike}
              />
            </div>
          </div>
        )
      }

      {
        values.mode == 'edit' && (
          <div id={`comment-${values.comment.id}`}>
            <textarea id={`edit-comment-${values.comment.id}`} style={ { width: '100%' } }>
              {values.comment.content}
            </textarea>
            <Button
              type="submit"
              className="btn-edit-comment"
              variant="contained"
              color="primary"
              onClick={updateComment}
            >
              Save
            </Button>            
          </div>
        )
      }
      {/*<div style={ { 'color': 'rgb(138, 138, 138)', 'fontSize': '10px' }}>
        {props.created_ago}
      </div>*/}
    </div>
  );
}
