import React, { useEffect, useState, useContext, useCallback } from "react";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import * as PostService from "../../services/posts.service";
import { useTheme } from '@material-ui/core/styles';
import Post from "components/posts/Post";
import TopMenu from "components/stories/TopMenu";
import useInterval from "vendor/useInterval";
import { CreatePostContext } from "components/posts/CreatePostContext";
// See: https://github.com/ricardo-ch/react-easy-crop
import Cropper from "react-easy-crop";
import { getCroppedImg, getRotatedImage, createImage } from "../../vendor/canvasUtils";
import ReactEasyCropStyles from "components/events/ReactEasyCropStyles";
import {
  Slider,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormControl,
} from "@material-ui/core";
import FilterButtons from "image_editor/FilterButtons.jsx";
import Filterous2 from "vendor/filterous2";
import cloneCanvas from "image_editor/clone_canvas.js";
import Cookies from 'vendor/js.cookie.min.mjs'
import StoryStyles from "components/styles/StoryStyles";

// ,
//     background: "#333"
// const useStyles = makeStyles((theme) => ({
//   previewContainer: {
//     width: "100%",
//     height: "calc(100vh - 40px)",
//     margin: 'auto',
//     maxWidth: 700,
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center"
//   },
//   videoContainer: {
//     width: "100%",
//     margin: 'auto',
//     maxHeight: "80vh",
//     maxWidth: 700,
//   },
//   controls: {
//     bottom: 0,
//     padding: 4,
//     color: '#FFF',
//     position: 'absolute',
//     width: '100%',
//     background: 'linear-gradient(to bottom, rgba(0,0,0,0.40) 0%, rgba(0,0,0,0.75) 100%)',
//   },
// }));

export default function PreviewVideo(props) {
  const storyClasses = StoryStyles();

  let [context, setContext] = useContext(CreatePostContext);

  // When cropped image is set, save it as the initial imageFiltered
  // useEffect(
  //   function () {
  //     // var imgObj = new Image();
  //     // // Base64 data url
  //     // imgObj.src = context.imageCropped;

  //     let canvasCropped = context.canvasCropped
  //     setContext((state) => ({
  //       ...state,
  //       canvasFiltered: cloneCanvas(canvasCropped)
  //     }));
  //   },
  //   [context.canvasCropped]
  // );


  let initialValues = {
    dialogBgStylesShow: false
  };
  const [values, setValues] = useState(initialValues);

  function nextStep() {
    setContext((state) => ({
      ...state,
      loading: true
    }));

    setTimeout(() => {
      let stepIdx = context.steps.indexOf(context.step)
      setContext((state) => ({
        ...state,
        loading: false,
        step: context.steps[stepIdx + 1]
      }));
    }, 50)
  }

  function prevStep() {
    let stepIdx = context.steps.indexOf(context.step)

    if (stepIdx == 0) { 
      props.exitCallback()      
    } else {
      setContext((state) => ({
        ...state,
        step: context.steps[stepIdx - 1]
      }));
    }
  }

  function bgStyles() {
    return `linear-gradient(171deg, ${context.background.colorA} 0%, ${context.background.colorB} 100%)`
  }

  function openDialogBkgColors() {
    setValues((state) => ({
      ...state,
      dialogBgStylesShow: true
    }));
  }

  function closeDialogBkgColors(evt) {
    setValues((state) => ({
      ...state,
      dialogBgStylesShow: false
    }));

    // If they re-click the 'color circle', close it. Don't pass the event down to openDialogBkgColors or it will just re-appear
    if (evt.target.id == 'set-background-color') {
      evt.preventDefault()
    }
  }

  function handleChangedColor(letter, value) {
    let colorKey = 'color' + letter // A or B
    let bkg = context.background
    bkg[colorKey] = value
    setContext((state) => ({
      ...state,
      background: bkg,
    }));

    Cookies.set('background_' + colorKey, value, { expires: 7 })
  }

  let colors = ['#FFFFFF', '#000000', '#F47373', '#F88379', '#EC5800', '#ff8a65', '#AA4A44', '#D22B2B', '#770737', '#8B8000', '#E49B0F', '#F5B041', '#D35400', '#697689', '#097969', '#228B22', '#32CD32', '#2CCCE4', '#191970', '#0047AB', '#555555', '#CD7F32', '#6E260E', '#808000', '#ba68c8', '#FF00FF', '#7D3C98', '#800080']
  let heightTopMenu = 40 // should match TopMenu.jsx
  let heightBottomControls = 55

  function bgStyles() {
    return `linear-gradient(171deg, ${context.background.colorA} 0%, ${context.background.colorB} 100%)`
  }

  return (
    <React.Fragment>
      <TopMenu next={nextStep} prev={prevStep} exitCallback={props.exitCallback} />

      <div className={storyClasses.showContainer} style={ { height: `calc(100vh - ${props.heightTopMenu}px)`, background: bgStyles() } }>
        {context.upload.mimeType == "image/gif" && (
          <img
            src={context.upload.src}
            style={{ width: "100%", maxWidth: "100%", maxHeight: '100%', margin: 'auto' }}
            draggable="false"
          />
        )}
        {context.upload.mimeType == "video/mp4" && (
          <video
            src={context.upload.src}
            preload="auto"
            style={{ width: "100%", maxWidth: "100%", maxHeight: '100%', margin: 'auto' }}
            draggable="false"
            controls={true}
          />
        )}
        {context.upload.mimeType == "video/quicktime" && (
          <React.Fragment>
            <p>
              <b>Note</b>: iPhone/Quicktime videos may not render properly
              in this preview, but will look correct within a few minutes
              of posting.
            </p>
            <video
              src={context.upload.src}
              preload="auto"
              style={{ width: "100%", maxWidth: "100%", maxHeight: '100%', margin: 'auto' }}
              draggable="false"
              controls={true}
            />
          </React.Fragment>
        )}
      </div>

      {/* Should match logic in EditCrop.jsx */}
      {
        values.dialogBgStylesShow && (
          <div id='bkg-color-picker' className={`${storyClasses.controls}`} style={ { background: 'rgb(0,0,0,0.55)', bottom: heightBottomControls } }>
            {/*<ClickAwayListener onClickAway={closeDialogBkgColors}>*/}
              <div style={ { width: '100%', maxWidth: 750 } }>
                <ul id='bkg-color-picker-top' className="hs full">
                  {
                    colors.map((color) => (
                      <li className={`font-border mask hand`} onClick={() => handleChangedColor('A', color)}>
                        <div style={ { height: 16, width: 16, backgroundColor: color, borderRadius: "50%", display: "inline-block", border: (color == context.background.colorA ? 'solid 1px #FFF' : 'none') }}></div>
                      </li>
                    ))
                  }
                </ul>
                <ul id='bkg-color-picker-bottom' className="hs full">
                  {
                    colors.map((color) => (
                      <li className={`font-border mask hand`} onClick={() => handleChangedColor('B', color)}>
                        <div style={ { height: 16, width: 16, backgroundColor: color, borderRadius: "50%", display: "inline-block", border: (color == context.background.colorB ? 'solid 1px #FFF' : 'none') }}></div>
                      </li>
                    ))
                  }
                </ul>
              </div>
            {/*</ClickAwayListener>*/}
          </div>
        )
      }

      <div className={storyClasses.controls} style={ { bottom: 0, height: 48 } }>
        <div onClick={openDialogBkgColors} style={ { zIndex: 20000 } } >
          <div id="set-background-color" style={ { borderRadius: '50%', width: 36, height: 36, marginLeft: 16, border: '1px solid', background: bgStyles() } }>
            &nbsp;
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}