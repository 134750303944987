import React, { useCallback, useState, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { CreatePostContext } from 'components/posts/CreatePostContext'
import PostStyles from 'components/styles/PostStyles'

export default function FaceMasks(props) {
  const facemasks = [ 'blur_3', 'blur_2', 'blur_1' ]

  const classes = PostStyles();

  const [context, setContext] = useContext(CreatePostContext)

  function save() {
    setContext(state => ({
      ...state,
      ['event']: 'image_editor::mask::save'
    }));
  }

  function del() {
    setContext(state => ({
      ...state,
      ['event']: 'image_editor::mask::delete'
    }));
  }

  // Tell image editor we changed the blur level or its size
  const handleChangeMask = (event) => {
    let val = event.target.id

    setContext(state => ({
      ...state,
      ['currentMask']: val
    }));
  }

  const handleChangeMaskSize = (event) => {
    let val = event.target.value

    setContext(state => ({
      ...state,
      ['currentMaskSize']: val
    }));
  }

  let clickedMask = null
  let size = context.currentMaskSize; // default to whatever's already chosen in the menu
  // if the user has clicked a mask in the image editor, switch to that mask's size
  if (context.selectedUpload && context.selectedUpload.state && context.selectedUpload.state.clickedMask) {
    clickedMask = context.selectedUpload.state.clickedMask
    size = clickedMask.size
  }

  return (
    <div id='menu-masks'>
      {
        facemasks.map(mask => {
          let selected = 'borderNotSelected';
          if (clickedMask && mask == clickedMask.id) {
            selected = 'borderSelected'
          }
          let fn = `${mask}.png`
          let maskName = mask.replace(/_/, ' ')
          return (
            <img className={`${selected} hand`} src={`/static/masks/mask-${fn}`} id={mask} key={mask} onClick={handleChangeMask} width={70} height={70} style={{ marginRight: "8px" }} />   
          )
        })
      }
      {
        clickedMask && 
          <div className={classes.flexCols} style={{ alignContent: 'center' }}>
            <div style={{ 'flexBasis': '100px' }}>
              Blur #{clickedMask.sequence_id} size: 
            </div>
            <div style={{ 'flexBasis': '50px' }}>
              <input id="mask-size-slider" value={size} type="range" min="5" max="90" step="2" data-orientation="horizontal" onChange={handleChangeMaskSize} />
            </div>
            <div style={{ 'flexBasis': '40px' }}>
              &nbsp;&nbsp;<font color='black'><img src='/static/icons/icon-save.svg' onClick={save} width={20} height={20} /></font>
            </div>
            <div style={{ 'flexBasis': '30px' }}>
              <font color='red'><DeleteIcon onClick={del} /></font>
            </div>
          </div>
      }
      {
        !clickedMask &&
          <div>
            Click above to add a blur for faces, tattoos or other private info
          </div>
      }
    </div>
  );
}
