import ImageBlur from 'vendor/ImageBlur'

class Draggable { 
  constructor(id, size) {
    console.log("THISSSS", this.canvas())
    let canvas = this.canvas()
    let natWidth = canvas.width
    let natHei = canvas.height

    let wid = parseInt((size / 100) * natWidth);
    let hei = wid;

    // Centered, size% wid/hei
    this.id = id;
    this.size = parseInt(size)
    this.x = parseInt(natWidth / 2 - (wid / 2));
    this.y = parseInt(natHei / 2 - (hei / 2));
    this.wid = parseInt(wid);
    this.hei = parseInt(hei);
    this.dragging = false;
    this.id = id;
  }

  static clicked(x, y, draggables) {
    let clickedDraggable = null

    for (let num = draggables.length - 1; num >= 0; num--) {
      let draggable = draggables[num]
      if (x >= draggable.x && x <= (draggable.x + draggable.wid) && y >= draggable.y && y <= (draggable.y + draggable.hei)) {
        clickedDraggable = draggable
        break
      }
    }
    return clickedDraggable
  }

  erase(canvasWithoutSelectedDraggable, extraPixels = 20) {
console.log("canvasWithoutSelectedDraggable", canvasWithoutSelectedDraggable)
    // "Erase" old blur by reapplying filtered canvas
    let origCtx = canvasWithoutSelectedDraggable.getContext('2d')
console.log("x", this.x, "y", this.y, "wid", this.wid, "hei", this.hei)
    let oldImg = origCtx.getImageData(this.x - (extraPixels / 2), this.y - (extraPixels / 2), this.wid + extraPixels, this.hei + extraPixels)
    let ctx = $('#canvas-preview')[0].getContext('2d')
    ctx.putImageData(oldImg, this.x - (extraPixels / 2), this.y - (extraPixels / 2))
  }

  resize(val) {
    let orig_x = this.x
    let orig_y = this.y
    let orig_wid = this.wid
    let orig_hei = this.hei

    let natWidth = this.canvas().width
    let wid = parseInt((val / 100) * natWidth);
    let hei = wid;

    this.size = parseInt(val)
    this.x = parseInt(orig_x - (wid - orig_wid) / 2);
    this.y = parseInt(orig_y - (hei - orig_hei) / 2);
    this.wid = wid;
    this.hei = hei;
  }

  markSelected() {
    let destCtx = this.ctx();
    destCtx.strokeStyle = '#FFF';
    if (this.dragging) {
      destCtx.strokeStyle = '#F00';
    }

    //destCtx.shadowColor = '#d53';
    //destCtx.shadowBlur = 20;
    //destCtx.lineJoin = 'bevel';
    destCtx.lineWidth = 1;
    //destCtx.strokeStyle = '#38f';
    console.log(`Mark ${this.x}, ${this.y}, ${this.wid}, ${this.hei}`)
    destCtx.strokeRect(this.x + 0.5, this.y + 0.5, this.wid - 1.5, this.hei - 1.5);

console.error("= mark selected")
  }

  toJSON() {
    return {
      "id": this.id,
      "x": this.x,
      "y": this.y,
      "wid": this.wid,
      "hei": this.hei
    }
  }

  canvas() {
    return $('#canvas-preview')[0]
  }

  ctx() {
    return this.canvas().getContext('2d')
  }
}

class Sticker extends Draggable {
  draw() {
    let destCtx = this.ctx();
    destCtx.drawImage(this.svg, this.x, this.y, this.wid, this.hei);
  }
}

class Blur extends Draggable {
  draw() {
    console.log("THIS ID", this.id)
    let radius = 10
    if (this.id == 'blur_2') { radius = 20 }
    if (this.id == 'blur_3') { radius = 30 }
    ImageBlur(this.canvas(), radius, this.x, this.y, this.wid, this.hei, false)
  }
}

class Text extends Draggable {
  constructor(vals) {
    super()

    let canvas = this.canvas()
    let natWidth = canvas.width
    let natHei = canvas.height

    this.update(vals)

    // Centered, size% wid/hei
    this.x = parseInt(natWidth / 2 - (this.wid / 2));
    this.y = parseInt(natHei / 2 - (this.hei / 2));
    this.dragging = false;
  }

  update(vals) {
    this.font = vals.font;
    this.size = vals.size;
    this.color = vals.color;
    this.category = vals.category;
    this.text = vals.text

    let fontstring = this.size + 'px "' + this.font + '", ' + this.category

    let ctx = this.ctx()
    ctx.font = fontstring

    let txt = ctx.measureText(this.text)

console.log("measureText", txt)
    let wid = txt.actualBoundingBoxRight
    let hei = txt.actualBoundingBoxDescent

    this.wid = parseInt(wid);
    this.hei = parseInt(hei);

    console.log("wid", this.wid)
    console.log("hei", this.hei)
  }

  draw() {
    let canvas = $('#canvas-preview')[0]
    let ctx = canvas.getContext('2d');
    ctx.font = this.size + 'px "' + this.font + '", ' + this.category
    ctx.textBaseline = 'hanging'
    ctx.fillStyle = this.color;
    ctx.fillText(this.text, this.x, this.y);
   }
}

export { Draggable, Sticker, Blur, Text };
