import React, { useState, useEffect } from "react";
import $ from "jquery";
import { Link, Typography } from "@material-ui/core";
import { Link as RLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Button, TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Modal from "@material-ui/core/Modal";
import Popover from "@material-ui/core/Popover";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Loading from "components/Loading";
import CommonStyles from "components/styles/CommonStyles";
import { useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    maxWidth: "96%",
    maxHeight: "96%",
    overflowY: "auto",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
}));

export default function Invites() {
  const classes = useStyles();
  const commonStyles = CommonStyles();
  const theme = useTheme();

  const initialValues = {
    loading: true,
    snackbarMessage: "",
    invites: [],
    current_invite: {},
    phone_number: "",
    email_address: "",
    message: "",
  };
  const [values, setValues] = useState(initialValues);

  function refreshInvites() {
    $.ajax({
      url: `/invites`,
      type: "GET",
      async: window.rails_env != "test",
      success: function (data) {
        setValues((state) => ({
          ...state,
          ["invites"]: data.invites,
          ["loading"]: false,
        }));
      },
      error: function (data) {
        if (data.status == 404) {
          setValues((state) => ({
            ...state,
            ["found"]: false,
            ["loading"]: false,
          }));
        }
      },
      cache: false,
      contentType: false,
      processData: false,
    });
  }

  useEffect(() => {
    refreshInvites();
  }, []);

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    let required = event.target.required;

    setValues((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const [openModalSend, setOpenModalSend] = React.useState(false);

  const openSendInvite = (invite) => {
    setOpenModalSend(true);
    setValues((state) => ({
      ...state,
      ["current_invite"]: invite,
    }));
  };

  const closeSendInvite = () => {
    setOpenModalSend(false);
  };

  function url() {
    return `${window.app_protocol}://${window.app_host}?i=${values.current_invite.invite_code}`;
  }

  function email_address_set() {
    if (values.email_address) {
      return /\S+@\S+\.\S+/.test(values.email_address);
    }
    return false;
  }

  function sendInvite() {
    $.ajax({
      method: "POST",
      async: window.rails_env != "test",
      url: "/invites/send",
      data: {
        type: $("#input-phone-number").is(":disabled") ? "email" : "text",
        invite_code: values.current_invite.invite_code,
        phone_number: values.phone_number,
        email_address: values.email_address,
        message_email: ($("#message-email").html() || "")
          .replace(/<br>/g, "\n")
          .replace(/<\/?span>/g, "\n"),
        message_text: ($("#message-text").html() || "")
          .replace(/<br>/g, "\n")
          .replace(/<\/?span>/g, "\n"),
        authenticity_token: window.auth_token,
      },
    }).done(function (data) {
      if (data.success) {
        snackbarShow("Invite sent!");
        setOpenModalSend(false);

        setValues((state) => ({
          ...state,
          ["phone_number"]: "",
          ["email_address"]: "",
          ["message"]: "",
        }));
      } else {
        snackbarShow("Error sending invite: " + data.error);
      }
      refreshInvites();
    });
  }

  // Privacy popover stuff
  const [privacyPopover, setPrivacyPopover] = React.useState(null);

  const handleClickPrivacy = (event) => {
    setPrivacyPopover(event.currentTarget);
  };

  const closeSendInvitePrivacy = () => {
    setPrivacyPopover(null);
  };

  const privacyOpen = Boolean(privacyPopover);
  const privacyId = privacyOpen ? "simple-popover" : undefined;

  // Snackbars
  function snackbarShow(message) {
    setValues((state) => ({
      ...state,
      ["snackbarMessage"]: message,
    }));
  }

  function closeSnackbar() {
    setValues((state) => ({
      ...state,
      ["snackbarMessage"]: "",
    }));
  }

  return (
    <div style={ { color: theme.palette.text.primary } }>
      <Snackbar
        open={values.snackbarMessage != ""}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" onClose={closeSnackbar} severity="info">
          {values.snackbarMessage}
        </Alert>
      </Snackbar>

      <p>
        <b>Help us grow the site!</b> Invite some friends to join KinkKin by
        email or SMS/MMS. If you need more invites just ask{" "}
        <Link to={"/dennis"} component={RLink} color="inherit">
          dennis
        </Link>
      </p>
      <Popover
        id={privacyId}
        open={privacyOpen}
        anchorEl={privacyPopover}
        onClose={closeSendInvitePrivacy}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ width: "90%", maxWidth: 500 }}
      >
        <Typography style={{ padding: 8 }}>
          We do not store contact data except in an obscured form for the
          purpose of this page, ex: ***-***-**12.
        </Typography>
      </Popover>

      <Modal
        open={openModalSend}
        onClose={closeSendInvite}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modal}>
          <h2 id="simple-modal-title">
            Send Invite: {values.current_invite.invite_code}
          </h2>

          <div>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="input-phone-number"
              label="SMS / MMS: U.S. Phone Number"
              name="phone_number"
              placeholder="###-###-####"
              disabled={email_address_set()}
              inputProps={{ maxLength: 12 }}
              type="tel"
              value={values.phone_number}
              onChange={handleChange}
              autoComplete="off"
              size="small"
              autoFocus
            />

            <center>... or ...</center>

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="input-email"
              label="Email: Email Address"
              name="email_address"
              placeholder="blerg@floo.net"
              value={values.email_address}
              onChange={handleChange}
              autoComplete="off"
              size="small"
            />

            <hr />

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="input-message"
              label="Say Some Words"
              name="message"
              value={values.message}
              onChange={handleChange}
              autoComplete="off"
              multiline={true}
              size="small"
            />
          </div>

          <div>
            {values.email_address && (
              <div>
                <p>From: support@kinkkin.com</p>
                <p>
                  Subject: {window.current_user.username} has invited you to
                  KinkKin
                </p>

                <div id="message-email">
                  {values.message}
                  <br />
                  <br />
                  {url()}
                  <br />
                  <br />
                  Invite Code: {values.current_invite.invite_code}
                  <br />
                  <br />
                </div>
              </div>
            )}
            {!values.email_address && (
              <div>
                <p>From: {window.twilio_from_phone_friendly}</p>

                <div id="message-text">
                  {values.message && (
                    <span>
                      {values.message}
                      <br />
                      <br />
                    </span>
                  )}
                  {window.current_user.username} has invited you to KinkKin:
                  <br />
                  <br />
                  {url()}
                  <br />
                  <br />
                  Invite Code: {values.current_invite.invite_code}
                  <br />
                  <br />
                </div>
              </div>
            )}

            <Button
              id="btn-send"
              fullWidth
              variant="contained"
              color="primary"
              onClick={sendInvite}
            >
              Send Invite
            </Button>
          </div>
        </div>
      </Modal>

      {!values.loading && (
        <TableContainer>
          <Table>
            <TableBody>
              {values.invites.map((invite) => (
                <TableRow hover key={invite.id} style={{ cursor: "pointer" }}>
                  <TableCell
                    style={{ maxWidth: "75px" }}
                    align="left"
                    id={`invite_${invite.id}`}
                  >
                    <span style={{ fontSize: "1.5rem" }}>
                      <b>
                        <code>{invite.invite_code}</code>
                      </b>
                    </span>
                  </TableCell>
                  <TableCell
                    style={{ maxWidth: "75px" }}
                    align="right"
                    align="center"
                    id={`invite_${invite.invite_code}`}
                  >
                    {invite.claimed_on == null && invite.sent_to == null && (
                      <Button
                        type="button"
                        id={`button-send-${invite.id}`}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          openSendInvite(invite);
                        }}
                      >
                        Invite
                      </Button>
                    )}
                    {(invite.claimed_on != null || invite.sent_to != null) && (
                      <Button type="button" variant="contained" disabled>
                        Invite
                      </Button>
                    )}
                  </TableCell>
                  <TableCell
                    style={{ maxWidth: "120px" }}
                    align="right"
                    align="right"
                    id={`invite_${invite.id}`}
                  >
                    {invite.claimed_by == null && (
                      <span>
                        {invite.sent_at == null && <span>-</span>}
                        {invite.sent_at != null && (
                          <span>
                            {invite.sent_to.type == "sms" && (
                              <span>SMS: {invite.sent_to.phone_number}</span>
                            )}
                            {invite.sent_to.type == "email" && (
                              <span>Email: {invite.sent_to.email_address}</span>
                            )}
                          </span>
                        )}
                      </span>
                    )}
                    {invite.claimed_by != null && (
                      <a href={`/${invite.claimed_by_username}`} style={ { color: theme.palette.text.primary } }>
                        {invite.claimed_by_username}
                      </a>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Loading loading={values.loading} />
    </div>
  );
}
