import React, { useEffect, useState, useContext } from "react";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import * as PostService from "../../services/posts.service";
import { useTheme } from '@material-ui/core/styles';
import UserAvatar from "components/UserAvatar";
import StoryView from "components/stories/StoryView";
import CreatePost from "components/stories/CreatePost";
import CommonStyles from "components/styles/CommonStyles";
import { Avatar } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { ThemeContext } from "components/ThemeContext";
import CreateStoryIcon from "components/stories/CreateStoryIcon"

/*
 * Given an array of stories[] and a current_story,
 * show a link to the user's current story and an array of thumbnails for stories[]
 *
 * When a story is clicked, display it in a StoryView
 */
export default function StoryThumbs(props) {
  const commonStyles = CommonStyles();

  const POSTS_PER_PAGE = 20

  let [themeContext, setThemeContext] = useContext(ThemeContext);

  const initialValues = {
    current_story_index: -1,
    current_story: {},
    stories: props.stories,
    current_story: props.current_story,
    current_user_avatar_url: props.current_user_avatar_url,
    hasMore: props.hasMore,
    loaded: props.loaded,
    show_confirmation_message: false
  };
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    setValues((state) => ({
      ...state,
      loaded: props.loaded,
      stories: props.stories,
      hasMore: props.hasMore,
      current_story: props.current_story,
      current_user_avatar_url: props.current_user_avatar_url
    }));
  }, [props.stories, props.loading, props.current_story, props.hasMore, props.current_user_avatar_url]);

  function range(size, startAt = 0) {
      return [...Array(size).keys()].map(i => i + startAt);
  }

  const [openDialogViewStories, setOpenDialogViewStories] = useState(false);

  function showDialogViewStories() {
    window.disable_pull_down_to_refresh = true

    setOpenDialogViewStories(true);
  }

  function closeDialogViewStories() {
    window.disable_pull_down_to_refresh = false

    setValues((state) => ({
      ...state,
      loaded: false
    }));

    props.fetchData(true)
    setOpenDialogViewStories(false);
  }

  function showStory(story_id) {
    let index = -1
    let story = {}
    for (let i = 0; i < values.stories.length; i++) {
      let stor = values.stories[i]
      if (stor.id == story_id) {
        index = i
        story = stor
      }
    }

    if (index == -1) {
      console.error("Story does not exist: " + story_id)
      return
    }

    setValues((state) => ({
      ...state,
      current_story: story,
      current_story_index: index
    }));

    showDialogViewStories()
  }

  function handleAvatarClick() {
    //console.warn("==", values.current_story)
    setValues((state) => ({
      ...state,
      current_story: values.current_story,
      current_story_index: 0
    }));

    showDialogViewStories()
  }

  // Not yet loaded, show 'loading' avatars
  if (!values.loaded) {
    return (
      <div className={`${commonStyles.flexRow} tags-browse`} style={ { paddingBottom: 12, marginTop: 8 } }>
        {
          range(5).map((x) => (
            <div key={`story-avatar-${x}`} style={ { marginRight: 4, position: 'relative' } }>
              <Avatar
                variant="circle"
                src={"/loading-overlay-200.svg"}
                style={{
                  width: 50,
                  height: 50,
                  border: `solid 2px white`
                }}
              />
            </div>
          ))
        }
      </div>
    )
  }

  // We've watched the last post in this story
  // Move on to the next one
  function nextStoryCallback(story_id) {
    let index = -1
    let story = {}
    for (let i = 0; i < values.stories.length; i++) {
      let stor = values.stories[i]
      if (stor.id == story_id) {
        index = i
        story = stor
      }
    }

    if (index == -1) {
      //console.log("Done watching current user's story");
      closeDialogViewStories()
      return
    }

    //console.log("next story", values.stories[index + 1])
    if (index < values.stories.length - 1) {
      setValues((state) => ({
        ...state,
        current_story: values.stories[index + 1],
        current_story_index: index + 1
      }));
    } else {
      closeDialogViewStories()
      // ToDo: pull in the next story
      //window.toastr.info("You're all caught up on stories!")
    }

    $.ajax({
      url: `/stories/seen/${story_id}`,
      type: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      success: function (data) {
        //console.log("seen", story)
      },
      cache: false,
      contentType: false,
      processData: false,
    });
  }

  // function swipeLeftCallback(event) {
  //   nextStoryCallback(values.current_story.id)

  //   setValues((state) => ({
  //     ...state,
  //     current_story: values.stories[values.current_story_index + 1],
  //     current_story_index: values.current_story_index + 1
  //   }));
  // }

  function prevStoryCallback(event) {
    if (values.current_story_index > 0) {
      setValues((state) => ({
        ...state,
        current_story: values.stories[values.current_story_index - 1],
        current_story_index: values.current_story_index - 1
      }));
    }
  }

  // Copied from Posts.jsx / PostFull.jax
  let deletePost = (id) => {
    if (confirm("Are you sure you want to delete this post?")) {
      $.ajax({
        url: `/posts/${id}?authenticity_token=${encodeURIComponent(
          window.auth_token
        )}`,
        type: "DELETE",
        async: window.rails_env != "test",
        success: function (data) {
          window.toastr.success("Post deleted!");
          setTimeout(function () {
            closeDialogViewStories()
          }, 100);
        },
        error: function (data) {
          window.toastr.error(
            data.responseText || data.responseJSON.error || default_error
          );
        },
      });
    }
  };

  function showConfirmationMessage() {
    setValues((state) => ({
      ...state,
      show_confirmation_message: true
    }));
  }

  function imageClass() {
    return (themeContext.type == "dark" ? "no-dark-mode" : "")
  }

  // Loaded: show actual avatars
  return (
    <div style={ { padding: 8 } }>
      <div className={`${commonStyles.flexRow}`} style={ { width: '100%', overflowX: 'auto' } }>
        <div className="hand" style={ { marginRight: 4 } } id='add-story'>
          <CreateStoryIcon
            current_story={props.current_story}
            community={null}
            handleAvatarClick={handleAvatarClick}
          />
        </div>

        {
          values.stories.map((story) => (
            <div
              onClick={() => { showStory(story.id) } }
              className="hand story_avatar story_show"
              id={`story-show-${story.id}`}
              style={ { marginRight: 4 } }
              key={story.id}
            >
              {
                story.posts.length > 0 && (
                  <Avatar
                    variant="circle"
                    src={story.posts[0].user.avatar_url}
                    id={`story-${story.id}`}
                    style={{
                      width: 50,
                      height: 50,
                      border: `solid 2px ${story.posts[0].user.color_background || 'white'}`,
                    }}
                  />   
                )
              }
            </div>
          ))
        }
      </div>

      {
        values.show_confirmation_message && (
          <div>
            <font color="red">Check your email!</font> <b>{window.current_user.email}</b> is not
            confirmed so you are not yet able to post.{" "}
            <a href="/resend-confirmation">Resend confirmation</a>
          </div>
        )
      }

      <Dialog
        id="dialog-view-stories"
        open={openDialogViewStories}
        keepMounted={false}
        onClose={closeDialogViewStories}
        scroll="paper"
        fullScreen={true}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={ { position: 'absolute', right: 0, paddingLeft: 4, paddingRight: 4, paddingTop: 4, margin: 8, background: "rgb(255, 255, 255, 0.5)", zIndex: 150000 } }>
          <img id='button-close-stories' src="/static/icons/icon-close-stories.svg" onClick={closeDialogViewStories} width="16" />
        </div>

        <StoryView
          story={values.current_story}
          callbackDelete={deletePost}
          nextStoryCallback={nextStoryCallback}
          prevStoryCallback={prevStoryCallback}
        />
      </Dialog>

    </div>
  );
}