import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import { Container } from "@material-ui/core";
import { Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import { Link as RLink } from "react-router-dom";
import Loading from "components/Loading";
import CommunityAbbrev from "components/events/CommunityAbbrev";
import { LocationContext } from "components/profile/LocationContext";
import CommonStyles from "components/styles/CommonStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 614,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    marginBottom: 8,
    marginLeft: 'auto',
    marginRight: 'auto',
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
    border: "1px solid",
    borderColor: (theme.palette.type == "dark" ? "#765f00" : "#FFCD00")
  },
}));

export default function Communities(props) {
  const classes = useStyles();
  const commonStyles = CommonStyles();
  const theme = useTheme();

  const initialValues = {
    communities: [],
    my_communities: [],
    include_virtual: true,
    page: 0,
    loading: true,
  };

  const [values, setValues] = useState(initialValues);

  //const [locationContext, setLocationContext] = useState(initialContext);

  let [locationContext, setLocationContext] = useContext(LocationContext);

  useEffect(() => {
    setValues((state) => ({
      ...state,
      ["loading"]: true,
    }));

    if (locationContext.city_id) {
      $.ajax({
        url: `/community.json?city_id=${locationContext.city_id}&distance=${locationContext.distance}&include_virtual=${values.include_virtual}`,
        type: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        success: function (data) {
          setValues((state) => ({
            ...state,
            communities: data.communities,
            my_communities: data.my_communities,
            loading: false,
          }));
        },
        cache: false,
        contentType: false,
        processData: false,
      });
    }
  }, [values.page, values.include_virtual, locationContext.city_id, locationContext.distance]);

  function handleChangeIncludeVirtual(evt) {
    const { name, value } = evt.target;
    setValues((state) => ({
      ...state,
      include_virtual: evt.target.checked,
    }));
  }

  // function performSearch() {
  //   console.log("asdf");
  // }

  function handleClickLocation() {
    setLocationContext((state) => ({
      ...state,
      dialogOpen: true,
    }));
  }

  return (
    <div style={ { paddingBottom: 8 } }>
      <div className={classes.container}>
        <div style={{ paddingBottom: 8, fontSize: "1rem", fontWeight: 400, fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}>
          Within{" "}
          <a onClick={handleClickLocation} className="hand">
            {locationContext.distance}
          </a>
          {" "}miles of{" "}
          <a onClick={handleClickLocation} className="hand">
            {locationContext.city}, {locationContext.region}
          </a>

          <FormControlLabel
            control={
              <Checkbox
                checked={values.include_virtual}
                onChange={handleChangeIncludeVirtual}
                name="include-virtual"
                color="primary"
              />
            }
            label="Include communities with no location?"
          />

          <div style={{ paddingTop: 8, paddingBottom: 8 }}>
            {/* ToDo: only show if user has been on Kin > 1 month, has interacted with events */}
            <Link to={`/community/new`} component={RLink} id="create-community" style={ { fontWeight: 300 } }>
              Create a community
            </Link>
          </div>
        </div>
      </div>

      <div className={classes.container}>
        <div style={{ flexGrow: 1, display: values.loading ? "none" : "block" }}>
          <div style={{ display: values.loading ? "none" : "block" }}>
            {values.my_communities.length > 0 && (
              <div style={{ paddingBottom: 8 }}>
                <b>Communities you run:</b>
                <ul>
                  {values.my_communities.map(function (community, index) {
                    return (
                      <li key={community.id}>
                        <Link to={`/community/${community.id}`} component={RLink} color="inherit">
                          {community.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            <div className={commonStyles.flexCol} style={{ width: "100%", paddingBottom: 24 }}>
              {values.communities.map(function (community, index) {
                return (
                  <CommunityAbbrev community={community} key={community.id}>
                    {community.name}
                  </CommunityAbbrev>
                );
              })}
            </div>
          </div>
          <Loading loading={values.loading} />
        </div>
      </div>

    </div>
  );
}
