import React, { useCallback, useState, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { CreatePostContext } from 'components/posts/CreatePostContext'
import PostStyles from 'components/styles/PostStyles'
import cloneCanvas from "image_editor/clone_canvas.js";
import { Blur } from "image_editor/draggables.js";

export default function FaceBlurs(props) {
  const faceblurs = [ 'blur_3', 'blur_2', 'blur_1' ]

  const classes = PostStyles();

  const [context, setContext] = useContext(CreatePostContext)

  function save() {
    setContext((state) => ({
      ...state,
      clickedBlur: null
    }));

    props.redraw()
  }

  function del() {
    let blurs = context.blurs.filter(
      (draggable) => draggable != context.clickedBlur
    );
    context.clickedBlur.erase(
      window.contextDecorator.canvasWithoutSelectedDraggable
    );
    setContext((state) => ({
      ...state,
      blurs: blurs,
      clickedBlur: null
    }));

    props.redraw()
  }

  /*
   * Blur selected from the menu
   */
  const handleChangeBlur = (event) => {
    console.log("event", event)
    let val = event.target.id

    if (context.clickedBlur) {
      let blur = context.clickedBlur;
      blur.id = val;

      setContext((state) => ({
        ...state,
        currentBlur: val,
        clickedBlur: blur
      }));
    } else {
      // Add new blur
      window.contextDecorator.canvasWithoutSelectedDraggable = cloneCanvas(
        $("#canvas-preview")[0]
      );

      let blurs = context.blurs
      let clickedBlur = new Blur(val, context.currentBlurSize || 15);
      clickedBlur.sequence_id = blurs.length + 1;
      blurs.push(clickedBlur);

      setContext((state) => ({
        ...state,
        blurs: blurs,
        currentBlur: val,
        clickedBlur: clickedBlur
      }));

      clickedBlur.markSelected();
    }

    props.redraw()
  }

  /*
   * Blur size selected from the menu
   */
  const handleChangeBlurSize = (event) => {
    let val = event.target.value

    setContext(state => ({
      ...state,
      currentBlurSize: val
    }));

    if (context.clickedBlur) {
      let blur = context.clickedBlur;

      blur.erase(window.contextDecorator.canvasWithoutSelectedDraggable);
      blur.resize(context.currentBlurSize);
      //blur.draw(sharedState.currentMenu, values.clickedBlur)
    }

    props.redraw()
  }

  // let clickedBlur = null
  // let size = context.currentBlurSize; // default to whatever's already chosen in the menu
  // // if the user has clicked a blur in the image editor, switch to that blur's size
  // if (context.blurs && context.clickedBlur) {
  //   clickedBlur = context.clickedBlur
  //   size = clickedBlur.size
  // }

  return (
    <div id='menu-blurs'>
      {
        faceblurs.map(blur => {
          let selected = 'borderNotSelected';
          if (context.currentBlur && blur == context.currentBlur) {
            selected = 'borderSelected'
          }
          let fn = `${blur}.png`
          let blurName = blur.replace(/_/, ' ')
          return (
            <img className={`${selected} hand`} src={`/static/blurs/blur-${fn}`} id={blur} key={blur} onClick={handleChangeBlur} width={70} height={70} style={{ marginRight: "8px" }} />   
          )
        })
      }
      {
        context.clickedBlur && 
          <div className={classes.flexCols} style={{ alignContent: 'center' }}>
            <div style={{ 'flexBasis': '100px' }}>
              Blur #{context.currentBlur.sequence_id} size: 
            </div>
            <div style={{ 'flexBasis': '50px' }}>
              <input id="blur-size-slider" value={context.clickedBlur?.size} type="range" min="5" max="90" step="2" data-orientation="horizontal" onChange={handleChangeBlurSize} />
            </div>
            <div style={{ 'flexBasis': '40px' }}>
              &nbsp;&nbsp;<font color='black'><img src='/static/icons/icon-save.svg' onClick={save} width={20} height={20} /></font>
            </div>
            <div style={{ 'flexBasis': '30px' }}>
              <font color='red'><DeleteIcon onClick={del} /></font>
            </div>
          </div>
      }
      {
        !context.clickedBlur &&
          <div>
            Click above to add a blur for faces, tattoos or other private info
          </div>
      }
    </div>
  );
}