import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import PostStyles from "components/styles/PostStyles";

export default function CreateComment(props) {
  const classes = PostStyles();

  // Form values state
  const initialValues = {
    text: "",
  };
  const [values, setValues] = useState(initialValues);

  // User has typed a key or selected a dropdown
  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    let required = event.target.required;

    setValues((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const allFieldsValid = () => {
    return values.text != "";
  };

  function post(post_id) {
    let content = values.text;

    setValues((state) => ({
      ...state,
      ["text"]: "",
    }));

    if (!content) {
      return;
    }

    // See: Post.jsx -> posted
    let vals = {
      post_id: post_id,
      content: content,
      authenticity_token: window.auth_token,
    };
    $.ajax({
      method: "POST",
      url: "/comments",
      async: window.rails_env != "test",
      data: vals,
    }).done(function (data) {
      console.log("data", data);
      props.posted(data.comment);
    });
  }

  return (
    <div>
      <div className={classes.flexCols}>
        <div style={{ flexGrow: 1 }}>
          <TextField
            className="input-create-comment"
            id={`${props.source}-input-text-${props.post_id}`}
            name="text"
            placeholder="Join the conversation"
            multiline={false}
            value={values["text"] || ""}
            error={false}
            onChange={handleChange}
            inputProps={{
              "data-lpignore": "true",
              autoComplete: "off",
              style: { marginRight: "12px" },
            }}
            fullWidth
            size="small"
            variant="standard"
            margin="normal"
          />
        </div>
        <div style={{ flexBasis: "70px" }}>
          <Button
            type="submit"
            className="btn-create-comment"
            disabled={!allFieldsValid()}
            variant="contained"
            color="primary"
            onClick={() => post(props.post_id)}
          >
            Post
          </Button>
        </div>
      </div>
    </div>
  );
}
