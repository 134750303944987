import React, { useState, useEffect, useContext } from "react";
import $ from "jquery";
import { makeStyles } from "@material-ui/core";
// import { PostBrowseContext } from "components/posts/PostBrowseContext";
import Cookies from "vendor/js.cookie.min.mjs";

const useStyles = makeStyles((theme) => ({
  defaults: {
    margin: "auto",
    userSelect: "none",
  },
}));

// Show a single asset, expanded to the width of its parent element
export default function AssetWide(props) {
  const classes = useStyles();

  let asset = props.asset;
  let variant = props.variant;

  let height_adjustment_px = 60;
  if (variant == 'story') {
    height_adjustment_px = 104
  }

  let initialValues = {
    error_count: 0,
    video_loaded: false
  };
  const [values, setValues] = useState(initialValues);

  // let [postBrowseContext, setPostBrowseContext] = useContext(PostBrowseContext);

  let error_count = 0;

  /*
   * Setting volume on 1 vid sets it on them all
   */
  //useEffect(() => {

  // Hack!! This is very very inefficient to do on each render
    //console.warn("== SET EVENTS", `.video-asset-${asset.id}-${variant}`)
    let video_selector = `video-asset-${asset.id}-${variant}`
    let video = $('.' + video_selector);

    if (!window[video_selector]) {
      window[video_selector] = {
        canplay: false,
        paused: false
      }
    }

    video.off();

    // Prevents issue where initial load of video sets itself as muted and overrides the cookie
    video.on("canplay", function(event) {
      console.warn("== CANPLAY")
      // Note: this didn't seem to work w/ values.video_loaded -- when the event runs it's always false
      window[video_selector].canplay = true
    })

    video.on("pause", function(event) {
      if (window[video_selector].canplay == true) {
        if (window.changing_stories) {
          // when the story/post changes and a video is playing, a 'pause' event goes out
          // the `changing_stories` flag enables us to not pause the story view
          console.warn("== Clicked: do NOT pause video")
          return
        }
        // Pause the video? Pause the story
        window[video_selector].paused = true
        if (props.assetClick) {
          console.warn("== Clicked: DO pause video")
          props.assetClick(event)
        }
      }
    })

    video.on("play", function(event) {
      // Resume the video? Pause the story
      if (window[video_selector].paused == true) {
        window[video_selector].paused = false
        console.warn("PLAY VID")
        if (props.assetClick) {
          props.assetClick(event)
        }
      }
    })

    video.on("seeked", function(event) {
      console.warn("== SEEK")
      if (props.callbackVideoSeek) {
        props.callbackVideoSeek(video[0].currentTime)
      }
    })

    video.on("volumechange", function (event) {
      if (window[video_selector].canplay == true) {
        console.warn("== VOLCHANGE")
        let vol = $(this).prop("volume");
        let muted = $(this).prop("muted");
        $("video").prop("volume", vol); // change for all videos
        $("video").prop("muted", muted); // change for all videos
        Cookies.set("default-video-volume", vol, { expires: 1 });
        Cookies.set("default-video-mute", muted, { expires: 1 });
      }
    });

    video.on("error", function (err) {
      console.warn("== ERROR")
      if (values.error_count < 6) {
        // Workaround... fail getting the asset 6 times? Stop trying.
        let currentTime = video[0].currentTime;
        refreshVideo(currentTime);

        setValues((state) => ({
          ...state,
          error_count: values.error_count + 1,
        }));
      } else {
        console.error("Uh oh, video not found?");
      }
    });
  //}, []);

  function refreshVideo(seek, callback) {
    $.ajax({
      url: `/assets/${asset.id}/url`,
      type: "GET",
      async: window.rails_env != "test",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      success: function (data) {
        if (callback) {
          callback();
        }

        let video_jq = $(`.video-asset-${asset.id}-${variant}`);
        video_jq.show();

        let vid = video_jq[0];
        vid.src = data.url;
        console.log("data url", data.url);
        vid.load();
        vid.currentTime = seek;
        vid.play();
      },
      error: function (data) {
        if (data.status == 429) {
          window.toastr.error(data.responseJSON.message);
        }
        console.log("data", data);
      },
    });
  }

  function toggleVideo(asset_id) {
    let preview = $(`.video-preview-${asset_id}-${variant}`);
    let video = $(`.video-asset-${asset_id}-${variant}`);
    let video_wrapper = $(`.video-wrapper-${asset_id}-${variant}`);
    let video_overlay = $(`.video-overlay-${asset_id}-${variant}`);
    //video.width(preview.width())

    refreshVideo(0, () => {
      video.height(preview.height());
      preview.hide();
      video_overlay.hide();
      video_wrapper.css("filter", "brightness(100%)");
    });
    // Fetch a fresh video URL from the back-end (permissioned)
  }

  //function showBrowseDialog() {
    // console.log("show browse", props.post_id);
    // setPostBrowseContext((state) => ({
    //   ...state,
    //   dialog_open: true,
    //   cur_post_id: props.post_id,
    // }));
  //}

  // Story or feed view
  let style_override = {
    // width: '100%',
    // maxWidth: 700,
    maxWidth: '100%',
    maxHeight: `calc(100vh - ${height_adjustment_px}px`,
    margin: "auto"
  };

  // Full post page: /p/:post_id
  if (variant == "full") {
    style_override = {
      width: "auto",
      height: "auto",
      maxWidth: "100%",
      maxHeight: "90vh",
    };
  }

  if (asset.category == "image" || asset.category == "gif") {
    return (
      <div style={ { maxHeight: `calc(100vh - ${height_adjustment_px}px`, display: 'flex', alignItems: 'center' } }>
        <img
          id={`asset-wide-${props.asset.id}`}
          className={`${classes.defaults} disable_right_click asset-wide-${props.asset.id}`}
          decoding="async"
          src={props.asset.url}
          style={style_override}
          draggable={false}
        />
      </div>
    );
  }

  if (asset.category == "video") {
    let video_style_override = {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      maxHeight: `calc(100vh - ${height_adjustment_px}px`,
      alignItems: 'center',
      // background: "linear-gradient(171deg, #FF0000 0%, #000 100%)"
    };

    return (
      <div
        className={`video-wrapper video-wrapper-${asset.id}-${variant}`}
        style={Object.assign(video_style_override, style_override)}
      >
        {
          // For StoryView: plays video automatically
          props.autoplay_video && (
            <video
              className={`video-asset-${asset.id}-${variant} disable_right_click asset-wide-${props.asset.id}`}
              controlsList="nodownload"
              preload="true"
              autoPlay={true}
              muted={Cookies.get("default-video-mute") == 'true' ? true : false}
              draggable={false}
              src={asset.url}
              style={$.extend(JSON.parse(JSON.stringify(style_override)), { minWidth: 200 })}
              controls={true}
            />
          )
        }
        {
          // For Feed view: do not autoplay
          !props.autoplay_video && (
            <React.Fragment>
              <img
                className={`video-overlay-${asset.id}-${variant} disable_right_click`}
                onClick={() => {
                  toggleVideo(asset.id);
                }}
                src="/static/icons/icon-video-overlay-button.svg"
                style={{
                  position: "absolute",
                  width: "20%",
                  filter: "brightness(100%)",
                }}
              />
              <img
                onClick={() => {
                  toggleVideo(asset.id);
                }}
                className={`${classes.defaults} video-preview-${asset.id}-${variant} disable_right_click asset-wide-${props.asset.id}`}
                decoding="async"
                src={props.asset.thumbnail_url}
                style={style_override}
                draggable={false}
              />
              <video
                className={`video-asset-${asset.id}-${variant} disable_right_click asset-wide-${props.asset.id}`}
                controlsList="nodownload"
                preload="true"
                autoPlay={true}
                muted={true}
                draggable={false}
                style={$.extend(JSON.parse(JSON.stringify(style_override)), {
                  display: "none",
                })}
                controls={true}
              />
            </React.Fragment>
          )
        }

      </div>
    );
  }
  return <p>Error</p>;
}
