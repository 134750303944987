import React from "react";
import { Link } from "@material-ui/core";
import { Link as RLink } from "react-router-dom";
import UserAvatar from "components/UserAvatar";
import CommonStyles from "components/styles/CommonStyles";
import DTOW from "vendor/distance_of_time_in_words.js";
import { railsUTCtoFriendlyLocalTime } from "services/dates.service.js";

export default function AlertFull(props) {
  const commonStyles = CommonStyles();
  let alert = props.alert;

  return (
    <div
      className={commonStyles.flexCol}
      style={{ width: "100%", padding: 4, marginBottom: 12 }}
    >
      <div
        className={commonStyles.flexRow}
        style={{ width: "100%", alignItems: "flex-start" }}
      >
        <div style={{ flexBasis: 52, width: 52, maxWidth: 52 }}>
          <Link
            to={{ pathname: `/${alert.username}` }}
            component={RLink}
            style={{ position: "relative" }}
            color="inherit"
          >
            <UserAvatar
              username={alert.username}
              url={alert.avatar_url}
              border_color="red"
              size="small"
            />
          </Link>
        </div>
        <div style={{ flexGrow: 1, paddingLeft: 4, width: "100%" }}>
          {alert.other_user_id != window.current_user.id && (
            <span>
              <Link
                to={{ pathname: `/${alert.username}` }}
                component={RLink}
                style={{ position: "relative" }}
                color="inherit"
              >
                <b>{alert.username}</b>
              </Link>{" "}
            </span>
          )}
          {alert.event_type == 0 && (
            <span>
              loved your&nbsp;
              <Link
                to={{ pathname: `/p/${alert.post_id}` }}
                component={RLink}
                style={{ position: "relative" }}
               color="inherit"
              >
                <b>post</b>
              </Link>
              &nbsp;from{" "}
              {DTOW.distance_of_time_in_words(
                new Date(),
                new Date(alert.post_created * 1000),
                false,
                { vague: true }
              )}{" "}
              ago
            </span>
          )}
          {alert.event_type == 1 && (
            <span>
              commented on your&nbsp;
              <Link
                to={{ pathname: `/p/${alert.post_id}` }}
                component={RLink}
                style={{ position: "relative" }}
                color="inherit"
              >
                <b>post</b>
              </Link>
              &nbsp;from{" "}
              {DTOW.distance_of_time_in_words(
                new Date(),
                new Date(alert.post_created * 1000),
                false,
                { vague: true }
              )}{" "}
              ago:
              <div
                style={{
                  paddingLeft: 12,
                  paddingTop: 4,
                  paddingBottom: 4,
                  color: "#444",
                }}
              >
                {alert.data.content}
              </div>
            </span>
          )}
          {alert.event_type == 2 && <span>is now following you!</span>}
          {alert.event_type == 3 && <span>added you as kin!</span>}
          {alert.event_type == 4 && (
            <span>
              Your post from {railsUTCtoFriendlyLocalTime(alert.created_at)} has
              expired.
            </span>
          )}
          {alert.event_type == 6 && (
            <span>
              <Link
                to={{ pathname: `/event/${alert.event_id}` }}
                component={RLink}
                style={{ position: "relative" }}
                color="inherit"
              >
                <b>{alert.data.event_name}</b>
              </Link>{" "}
              <div>{alert.data.starts_in}</div>
              <div>{alert.data.tagline}</div>
            </span>
          )}
          <div style={{ color: "#AAA" }}>
            {DTOW.distance_of_time_in_words(
              new Date(),
              new Date(alert.created_at_int * 1000),
              false,
              { vague: true }
            )}{" "}
            ago
          </div>
        </div>
      </div>
    </div>
  );
}
