import React from "react";
import { CssBaseline } from "@material-ui/core";
import { Link } from "@material-ui/core";
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { makeStyles } from "@material-ui/core/styles";
import CommonStyles from "components/styles/CommonStyles";
import Footer from "components/Footer";
const loginWidth = 300;
const carouselWidth = 324 + 2;
const bothWidth = loginWidth;

const useStyles = makeStyles((theme) => ({
  root: {
    // height: '100vh',
    width: "100%",
  },
  imgFormContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    alignItems: "left",
    flexDirection: "row",
  },
  imgCarouselContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down(bothWidth)]: {
      flexDirection: "column",
    },
    width: bothWidth + 10,
    paddingTop: 24,
    marginLeft: "auto",
    marginRight: "auto",
  },
  demoImagesFull: {
    width: carouselWidth,
    marginLeft: 20,
    paddingRight: 0,
    [theme.breakpoints.down(bothWidth)]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  loginFormContainer: {
    width: loginWidth,
    border: "1px solid #541f91",
    background: "#FFF",
    paddingTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
    [theme.breakpoints.down(bothWidth)]: {
      width: 324,
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: 20,
    },
  },
  logoContainer: { width: '100%' },
  logo: {},
  loginForm: {},
  signupLink: {
    marginTop: 24,
  },
  hideBelow600: {
    display: "flex",
    [theme.breakpoints.down(bothWidth)]: {
      display: "none",
    },
  },
  showAbove600: {
    display: "none",
    [theme.breakpoints.up(bothWidth)]: {
      display: "flex",
    },
  },
}));

/*
return <SimpleSnackbar key={flash[1]} message={flash[1]} />
*/

function DemoImages() {
  // See custom css in
  return (
    <Carousel
      width={324}
      showThumbs={false}
      autoPlay={true}
      interval={5000}
      infiniteLoop={true}
      emulateTouch={true}
      showStatus={false}
    >
      <div>
        <img src="/login_carousel/01.jpg" />
        <p className="legend">Image + Video Sharing</p>
      </div>
      <div>
        <img src="/login_carousel/02.jpg" />
        <p className="legend">Events</p>
      </div>
      <div>
        <img src="/login_carousel/03.jpg" />
        <p className="legend">Messaging</p>
      </div>
      <div>
        <img src="/login_carousel/04.jpg" />
        <p className="legend">Great Privacy + Moderation</p>
      </div>
      <div>
        <img src="/login_carousel/05.jpg" />
        <p className="legend">Content Recommendations</p>
      </div>
    </Carousel>
  );
}

export default function Login(props) {
  const classes = useStyles();
  const commonStyles = CommonStyles();

  return (
    <div>
      <CssBaseline />
      <div className={classes.root}>
        <div className={classes.imgFormContainer}>
          <div className={`${classes.imgCarouselContainer}`}>
            <div className={classes.loginFormContainer}>
              <div className={classes.logoContainer}>
                <center>
                  <img className={classes.logo} src="/logo-heart-kinkykin-light.svg" width={230} />
                  <div className={classes.tagLine}>
                    A new 18+ photo sharing community for kinksters.
                  </div>
                </center>
              </div>

              <div className={classes.loginForm}>
                {(props.flash || []).map((flash) => {
                  return (
                    <div key={flash[0]} className="flash">
                      {flash[1]}
                    </div>
                  );
                })}

                <form
                  className={classes.form}
                  action="/login"
                  method="POST"
                  noValidate
                >
                  <input
                    name="authenticity_token"
                    type="hidden"
                    value={window.auth_token}
                  />
                  <TextField
                    variant="outlined"
                    margin="none"
                    fullWidth
                    id="input-login"
                    label="Username or email"
                    name="user[login]"
                    autoComplete="login"
                    size="small"
                    style={{ marginTop: 20 }}
                    autoFocus
                  />
                  <TextField
                    variant="outlined"
                    margin="none"
                    fullWidth
                    name="user[password]"
                    label="Password"
                    type="password"
                    id="input-password"
                    size="small"
                    style={{ marginTop: 20 }}
                    autoComplete="current-password"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="true"
                        color="primary"
                        name="user[remember_me]"
                      />
                    }
                    label="Remember Me"
                  />

                  <br />
                  <Link
                    href="/password"
                    variant="body2"
                    style={{ color: "#5271ff" }}
                  >
                    Forgot password?
                  </Link>

                  <div style={{ color: "red", marginBottom: 8 }}>
                    {props.error_login}
                  </div>

                  <br />
                  <Button
                    type="submit"
                    id="btn-login"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    style={{ marginTop: 4 }}
                  >
                    Sign In
                  </Button>
                </form>
              </div>

              <div className={classes.signupLink}>
                <center>
                  Don't have an account?&nbsp;
                  <Link
                    id="btn-sign-up"
                    href="/registration/sign_up"
                    variant="body2"
                    style={{ color: "#5271ff", fontSize: "1.1em" }}
                  >
                    Sign Up!
                  </Link>
                </center>
              </div>

              <div id="socials" style={ { fontSize: 12, display: "flex", flexDirection: "column", paddingTop: 16 } }>
                <div style={ { display: "flex", alignItems: "center" } }>
                  <a href="https://www.instagram.com/kinkykin_official">
                    <img src="/instagram.svg" width={24} />
                  </a>
                  {" "}&nbsp;
                  <Link
                    id="link-insta"
                    href="https://www.instagram.com/kinkykin_official"
                    variant="body2"
                    style={{ color: "#5271ff", fontSize: "1.1em" }}
                  >
                    @kinkykin_official
                  </Link>
                </div>
                <div style={ { display: "flex", alignItems: "center" } }>
                  <a href="https://twitter.com/KinkyKinSocial">
                    <img src="/twitter.svg" width={24} />
                  </a>
                  {" "}&nbsp;
                  <Link
                    id="link-twitter"
                    href="https://twitter.com/KinkyKinSocial"
                    variant="body2"
                    style={{ color: "#5271ff", fontSize: "1.1em" }}
                  >
                    @KinkyKinSocial
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div
        style={{
          width: "100%",
          maxWidth: 500,
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: 10,
        }}
      >
        <Footer></Footer>
      </div>
    </div>
  );
}
