import React, { useState, useEffect } from "react";
import $ from "jquery";
import { Box, Container } from "@material-ui/core";
import { Link, Typography } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import { Link as RLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  LocationOnOutlined,
  PeopleOutline,
  CalendarTodayOutlined,
  ThumbUp,
  ThumbDown,
} from "@material-ui/icons";
import ics from "vendor/ics";
import LinkIcon from "@material-ui/icons/Link";
import { FormLabel } from "@material-ui/core";
import { Switch } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import {
  railsUTCtoFriendlyLocalTime,
  railsUTCtoICSDate,
} from "services/dates.service.js";
import { Button, TextField, FormControl } from "@material-ui/core";
import UserAvatar from "components/UserAvatar";
import CommonStyles from "components/styles/CommonStyles";
import DTOW from "vendor/distance_of_time_in_words.js";
import Loading from "components/Loading";

export default function Event(props) {
  const commonStyles = CommonStyles();

  const initialValues = {
    owner: {},
    event: {},
    community: { name: "" },
    my_rsvp: { post_reminders_at: [] },
    group_chat: {},
    rsvps: [],
    pending_rsvps: [],
    rsvpers_by_id: {},
    attending: true,
    organizing: false,
    tab: 0,
    loading: true,
  };
  const [values, setValues] = useState(initialValues);

  let { id } = useParams();

  function fetchEvent() {
    // Dates are stored in UTC. We'll need the user's UTC offset to be able to calculate dates correctly
    const timezone_offset = new Date().getTimezoneOffset();

    $.ajax({
      url: `/events/${id}.json?timezone_offset=${timezone_offset}`,
      type: "GET",
      async: window.rails_env != "test",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      success: function (data) {
        // Go from database version of date (UTC) to localized (CST)
        let dt_start = data.dt_start;
        let dt_end = data.dt_end;
        let dt_start_friendly = "";
        let dt_end_friendly = "";
        if (dt_start) {
          dt_start_friendly = railsUTCtoFriendlyLocalTime(dt_start);
          let arr1 = dt_start_friendly.split("@");
          let date_sta = arr1[0];
          let time_sta = arr1[1];
          if (dt_end) {
            dt_end_friendly = railsUTCtoFriendlyLocalTime(dt_end);
            let arr2 = dt_end_friendly.split("@");
            let date_end = arr2[0];
            let time_end = arr2[1];
            // Same day? Just show the end time
            if (date_sta == date_end) {
              dt_end_friendly = time_end;
            }
          }
        }
        setValues((state) => ({
          ...state,
          ...data,
          dt_start_friendly: dt_start_friendly,
          dt_end_friendly: dt_end_friendly,
          ["loading"]: false,
        }));

        setComposeValues((state) => ({
          ...state,
          users: [data.rsvpers_by_id[data.poster_id]],
        }));
      },
      error: function (data) {
        console.log("== event show ERROR", data);
      },
      cache: false,
      contentType: false,
      processData: false,
    });
  }

  useEffect(() => {
    fetchEvent();
  }, [values.page]);

  const {
    community_id,
    poster_id,
    event_type,
    name,
    tagline,
    description_raw,
    description_cooked,
    cost,
    dress,
    timezone,
    location_name,
    location_address,
    url,
    country_id,
    region_id,
    city_id,
    country,
    region,
    city,
    latitude,
    longitude,
    members_only,
    rsvp_approvals,
    dt_start,
    end_type,
    dt_end,
    created_at,
    updated_at,
    quality,
    status,
    starts_in,
    dt_start_friendly,
    dt_end_friendly,
    end_type_friendly,
    group_chat,
    convo_organizer,
    duration,
    username,
    community,
    rsvps,
    pending_rsvps,
    my_rsvp,
    rsvpers_by_id,
    event_image_url,
  } = values;

  let going = false;
  let watch = false;

  if (my_rsvp) {
    if (my_rsvp.typ == 0) {
      watch = true;
    }
    if (my_rsvp.typ == 1) {
      going = true;
    }
  }

  let not_going = !going && !watch;
  let is_owner = poster_id == window.current_user.id;

  let owner = { name: "" };
  if (poster_id) {
    owner = rsvpers_by_id[poster_id];
  }

  // RSVPs dialog
  const [openDialogRSVPs, setOpenDialogRSVPs] = useState(false);

  function handleRsvpClick() {
    setOpenDialogRSVPs(true);
  }

  function closeDialogRSVPs() {
    setOpenDialogRSVPs(false);
  }

  const [openDialogPendingRSVPs, setOpenDialogPendingRSVPs] = useState(false);

  function handlePendingRsvpClick() {
    setOpenDialogPendingRSVPs(true);
  }

  function closeDialogPendingRSVPs() {
    setOpenDialogPendingRSVPs(false);
  }

  function sendRsvp(typ) {
    if (typ == "not_going" && my_rsvp.status == 1) {
      if (
        !confirm(
          "Are you sure you want to change your RSVP to 'not going'? If you decide to go then your RSVP will need to be re-approved."
        )
      ) {
        return;
      }
    }

    $.ajax({
      url: `/events/${values.id}/rsvp?typ=${typ}`,
      type: "GET",
      async: window.rails_env != "test",
      data: {},
      success: function (data) {
        if (data.success) {
          setValues((state) => ({
            ...state,
            my_rsvp: data.my_rsvp || {},
          }));

          window.toastr.success("You have RSVP'd!");
        } else {
          window.toastr.error(data.error);
        }
      },
    });
  }

  function approveRsvp(rsvp) {
    $.ajax({
      url: `/events/${values.id}/approve?rsvp_id=${rsvp.id}`,
      type: "GET",
      data: {},
      success: function (data) {
        if (data.success) {
          fetchEvent();

          window.toastr.success("You have approved this RSVP!");
        } else {
          window.toastr.error(data.error);
        }
      },
    });
  }

  function rejectRsvp(rsvp) {
    $.ajax({
      url: `/events/${values.id}/reject?rsvp_id=${rsvp.id}`,
      type: "GET",
      async: window.rails_env != "test",
      data: {},
      success: function (data) {
        if (data.success) {
          fetchEvent();

          window.toastr.success("You have rejected this RSVP!");
        } else {
          window.toastr.error(data.error);
        }
      },
    });
  }

  function toTitleCase(str) {
    if (!str) {
      return "";
    }
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  function shortString(s, l, reverse) {
    var stop_chars = [" ", "/", "&"];
    var acceptable_shortness = l * 0.8; // When to start looking for stop characters
    var reverse = typeof reverse != "undefined" ? reverse : false;
    var s = reverse ? s.split("").reverse().join("") : s;
    var short_s = "";

    for (var i = 0; i < l - 1; i++) {
      short_s += s[i];
      if (i >= acceptable_shortness && stop_chars.indexOf(s[i]) >= 0) {
        break;
      }
    }
    if (reverse) {
      return short_s.split("").reverse().join("");
    }
    return short_s;
  }

  function shortUrl(url, l) {
    var l = typeof l != "undefined" ? l : 50;
    var chunk_l = l / 2;
    var url = url.replace("http://", "").replace("https://", "");
    url = url.replace(/^\w+\./, ""); // remove subdomain
    if (url.length <= l) {
      return url;
    }

    var start_chunk = shortString(url, chunk_l, false);
    var end_chunk = shortString(url, chunk_l, true);
    return start_chunk + "…" + end_chunk;
  }

  /*
   * Message the organizer-related stuff
   */

  const [openDialogNewConvo, setOpenDialogNewConvo] = useState(false);

  function showDialogNewConvo() {
    setOpenDialogNewConvo(true);
  }

  function closeDialogNewConvo() {
    setOpenDialogNewConvo(false);
  }

  /*
   * Start a group chat
   */

  const [openDialogNewGroupMessage, setOpenDialogNewGroupMessage] =
    useState(false);

  function showDialogNewGroupMessage() {
    setOpenDialogNewGroupMessage(true);
  }

  function closeDialogNewGroupMessage() {
    setOpenDialogNewGroupMessage(false);
  }

  // Compose stuff
  const [composeValues, setComposeValues] = useState({ draft: "" });

  // User is typing a message
  function handleChangeDraft(evt) {
    const { name, value } = evt.target;
    setComposeValues((state) => ({
      ...state,
      ["draft"]: value,
    }));
  }

  // If enter is pressed, send the message
  function handleKeyDown(evt) {
    const { name, value } = evt.target;

    if (evt.keyCode === 13 && !evt.shiftKey) {
      evt.preventDefault();

      if (sendEnabled()) {
        handleMessageSend();
      }
    }
  }

  function sendEnabled() {
    return composeValues.draft.length > 0 && composeValues.users.length > 0;
  }

  function handleMessageSend() {
    let to_ids = composeValues.users.map((user) => user.object_id);

    let fd = new FormData();
    fd.append("from", window.current_user.object_id);
    fd.append("to", to_ids);
    fd.append("event_id", values.id);
    fd.append("event_group_chat", 0);
    fd.append("message", composeValues.draft);
    fd.append("authenticity_token", window.auth_token);
    $.ajax({
      url: "/api/v1/messaging/conversation/send_message_event_organizer",
      type: "POST",
      async: window.rails_env != "test",
      data: fd,
      success: function (data) {
        props.history.push(`/messages/${data.conversation_object_id}`);
      },
      error: function (data) {
        window.toastr.error("Error sending message");
      },
      cache: false,
      contentType: false,
      processData: false,
    });
  }

  function handleStartGroupChat() {
    let rsvp_ids = [];
    for (let i = 0; i < rsvps.length; i++) {
      rsvp_ids.push(rsvps[i].id);
    }
    let fd = new FormData();
    fd.append("from", window.current_user.object_id);
    fd.append("to", rsvp_ids);
    fd.append("event_id", values.id);
    fd.append("event_group_chat", 1);
    fd.append("message", composeValues.draft);
    fd.append("authenticity_token", window.auth_token);
    $.ajax({
      url: "/api/v1/messaging/conversation/send_message_event_group_chat",
      type: "POST",
      async: window.rails_env != "test",
      data: fd,
      success: function (data) {
        props.history.push(`/messages/${data.conversation_object_id}`);
      },
      error: function (data) {
        window.toastr.error("Error sending message");
      },
      cache: false,
      contentType: false,
      processData: false,
    });
  }

  function saveIcsFile() {
    var cal = ics();
    let location = "";
    if (location_address) {
      location += location_address;
    }
    if (location_name) {
      if (location != "") {
        location += " / ";
      }
      location += location_name;
    }
    let sta = railsUTCtoICSDate(dt_start);
    let en = railsUTCtoICSDate(dt_end);
    let desc = description_raw.replace(/\r?\n|\r/g, "\\n");
    cal.addEvent(name, desc, location, sta, en);
    if (
      confirm(
        "If you're on a work phone or computer, you should probably cancel!"
      )
    ) {
      cal.download();
    }
  }

  const [timeoutSwitchChange, setTimeoutSwitchChange] = useState(null);

  function updateRsvp() {
    $.ajax({
      url: `/events/my_rsvp/${values.my_rsvp.id || -1}?event_id=${
        values.id
      }&authenticity_token=${encodeURIComponent(window.auth_token)}`,
      type: "PUT",
      async: window.rails_env != "test",
      data: values.my_rsvp,
      success: function (data) {
        setValues((state) => ({
          ...state,
          my_rsvp: data.my_rsvp, // because rsvp may have been created
        }));
        window.onbeforeunload = null;
      },
      error: function (data) {
        console.error("data", data);
      },
    });
  }

  function remindSwitchChange(val) {
    let my_rsvp = values.my_rsvp;

    let post_reminders_at = my_rsvp.post_reminders_at;
    if (post_reminders_at.includes(val)) {
      var idx = post_reminders_at.indexOf(val);
      post_reminders_at.splice(idx, 1);
    } else {
      post_reminders_at.push(val);
    }
    my_rsvp.post_reminders_at = post_reminders_at;

    setValues((state) => ({
      ...state,
      my_rsvp: my_rsvp,
    }));

    clearTimeout(timeoutSwitchChange);
    setTimeoutSwitchChange(
      setTimeout(() => {
        updateRsvp();
      }, 1500)
    );
  }
  window.remindSwitchChange = remindSwitchChange;

  return (
    <div
      id="event"
      className={commonStyles.container}
      style={{ paddingTop: "16px" }}
    >
      <div style={{ display: values.loading ? "none" : "block", padding: 8 }}>
        {/* message the organizer dialog */}
        <Dialog
          id="dialog-new-conversation"
          open={openDialogNewConvo}
          keepMounted={false}
          onClose={closeDialogNewConvo}
          scroll="paper"
          classes={{ paper: commonStyles.dialog300h }}
        >
          <div className={commonStyles.paddingLR}>
            <Typography variant="h4">Message Organizer</Typography>

            <p>
              If you're not sure about this event and have questions, this is
              the place. Please keep your message brief, the organizer may have
              dozens of messages to sift through.
            </p>
          </div>

          <Box display="flex" alignItems="center" mb={1}>
            <Box ml={1}>
              <Link to={"/" + owner.username} component={RLink} color="inherit">
                <UserAvatar
                  url={owner.avatar_url}
                  border_color={owner.color_background}
                  size="small"
                />
              </Link>
            </Box>
            <Box
              ml={0.5}
              component="span"
              style={{
                fontWeight: 600,
              }}
            >
              <div>
                <Link to={"/" + owner.username} component={RLink} color="inherit">
                  <span>{owner.username}</span>
                </Link>
              </div>
            </Box>
          </Box>

          <div
            style={{
              position: "absolute",
              bottom: 0,
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ flexGrow: 1, flexShrink: 1 }}>
              <TextField
                id="input-draft-message"
                name="input-draft"
                variant="outlined"
                margin="none"
                size="small"
                onChange={handleChangeDraft}
                onKeyDown={handleKeyDown}
                value={composeValues.draft || ""}
                inputProps={{ autoComplete: "off", maxLength: 1500 }}
                multiline
                rowsMax={10}
                placeholder=".=[ be nice ]=."
                fullWidth={true}
                autoComplete="off"
              />
            </div>
            <div style={{ flexBasis: 32, flexShrink: 1 }}>
              {sendEnabled() && (
                <img
                  id="id-message-send-message"
                  src="/static/icons/icon-send-message.svg"
                  width={32}
                  style={{ cursor: "pointer" }}
                  onClick={handleMessageSend}
                />
              )}
              {!sendEnabled() && (
                <img
                  id="id-message-send-message"
                  src="/static/icons/icon-send-message-disabled.svg"
                  width={32}
                />
              )}
            </div>
          </div>
        </Dialog>

        {/* message the organizer dialog */}
        <Dialog
          id="dialog-new-groupChat"
          open={openDialogNewGroupMessage}
          keepMounted={false}
          onClose={closeDialogNewGroupMessage}
          scroll="paper"
          classes={{ paper: commonStyles.dialog300h }}
        >
          <div className={commonStyles.paddingLR}>
            <Typography variant="h4">Start Group Chat</Typography>

            <p>
              This will start a group chat here on Kin with all users who have
              approved RSVPs. As you approve new RSVPs they will be added to the
              group chat.
            </p>
          </div>

          <div
            style={{
              position: "absolute",
              bottom: 0,
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div style={{ flexGrow: 1, flexShrink: 1 }}>
              <TextField
                id="input-draft-group"
                name="input-draft"
                variant="outlined"
                margin="none"
                size="small"
                onChange={handleChangeDraft}
                onKeyDown={handleKeyDown}
                value={composeValues.draft || ""}
                inputProps={{ autoComplete: "off", maxLength: 1500 }}
                multiline
                rowsMax={10}
                placeholder=".=[ be nice ]=."
                fullWidth={true}
                autoComplete="off"
              />
            </div>
            <div style={{ flexBasis: 32, flexShrink: 1 }}>
              {sendEnabled() && (
                <img
                  id="id-message-send-group"
                  src="/static/icons/icon-send-message.svg"
                  width={32}
                  style={{ cursor: "pointer" }}
                  onClick={handleStartGroupChat}
                />
              )}
              {!sendEnabled() && (
                <img
                  id="id-message-send-group"
                  src="/static/icons/icon-send-message-disabled.svg"
                  width={32}
                />
              )}
            </div>
          </div>
        </Dialog>

        <Typography className={commonStyles.title} color="textSecondary">
          {dt_start_friendly}
        </Typography>
        <Box mb={1}>{starts_in}</Box>

        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h5"
              style={{
                fontWeight: 900,
              }}
            >
              {name}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h6"
              style={{
                fontWeight: 300,
              }}
            >
              {tagline}
            </Typography>
          </Box>

          <div>
            <Link to={"/community/" + community.id} component={RLink} color="inherit">
              <span>{community.name}</span>
            </Link>
            &nbsp;&middot;&nbsp;community
          </div>

          {/* Edit and duplicate event */}
          {is_owner && (
            <Box justifyContent="space-between" alignItems="center" mb={1}>
              <Link id="edit-event" to={`/events/edit/${id}`} component={RLink} color="inherit">
                Edit Event
              </Link>
              &nbsp;&middot;&nbsp;
              <a id="duplicate-event" href={`/events/dupe/${id}`}>
                Duplicate Event
              </a>
            </Box>
          )}

          <Box display="flex" alignItems="center" mb={1}>
            <Box ml={1}>
              <Link to={"/" + owner.username} component={RLink} color="inherit">
                <UserAvatar
                  url={owner.avatar_url}
                  border_color={owner.color_background}
                  size="small"
                />
              </Link>
            </Box>
            <Box
              ml={0.5}
              component="span"
              style={{
                fontWeight: 600,
              }}
            >
              <div>
                <Link to={"/" + owner.username} component={RLink} color="inherit">
                  <span>{owner.username}</span>
                </Link>
                &nbsp;&middot;&nbsp;organizer
              </div>
            </Box>
          </Box>

          <Box display="flex" alignItems="flex-start">
            <CalendarTodayOutlined />
            <Box ml={1} component="span">
              <div>Start: {dt_start_friendly}</div>
              <div>
                End:&nbsp;
                {end_type == "precise" && (
                  <span>
                    {dt_end_friendly} ({duration})
                  </span>
                )}
                {end_type != "precise" && <span>{end_type_friendly}</span>}
              </div>
              {/*<div>
                // https://calendar.google.com/calendar/u/0/r/eventedit?location=Online+event&sprop=name:Chicago+Startup+Tech+Entrepreneurs+MasterMinds+Meetup&sprop=website:https://www.meetup.com/chicago-startup-founders/events/qknjksyccmblc&details=For+full+details,+including+the+address,+and+to+RSVP+see:+https://www.meetup.com/chicago-startup-founders/events/qknjksyccmblc%0A%0AStartup+Entrepreneurs+%E2%80%93+Free+LIVE+MasterMinds+Fundraising+Q%26A+with+Angel+Investor/Serial+Founder/Sta...&text=Free+MasterMinds+Startup+Fundraising+Office+Hours+with+Startup+Expert+Scott+Fox!&dates=20210928T190000Z/20210928T200000Z&sf=true
                <input type="checkbox" />&nbsp;Anonymize 
                <a onClick={saveIcsFile}>Save Calendar File</a>
              </div>*/}
            </Box>
          </Box>
          {event_type == "in_person" && (location_name || location_address) && (
            <Box mt={1} display="flex" alignItems="flex-start">
              <LocationOnOutlined />
              <Box ml={1}>
                <div>
                  <b>{location_name}</b>
                </div>
                {my_rsvp.status == 0 && <div>RSVP for Address / Location</div>}
                {my_rsvp.status == 1 && (
                  <div>
                    {location_address && (
                      <div>
                        <b>{location_address}</b>&nbsp;&middot;{" "}
                        <a
                          href={`https://maps.google.com/?q=${location_address},%20${region},%20${city},%20${country}`}
                        >
                          google&nbsp;maps
                        </a>
                      </div>
                    )}
                  </div>
                )}
                <div>
                  {city}, {region}, {country}
                </div>
              </Box>
            </Box>
          )}

          {event_type == "virtual" && url && (
            <Box mt={1} display="flex" alignItems="flex-start">
              <LinkIcon />
              <Box ml={1}>
                {my_rsvp.status != 1 && (
                  <div>
                    <b>RSVP for Link</b>
                  </div>
                )}
                {my_rsvp.status == 1 && (
                  <div>
                    <a href={url}>{shortUrl(url, 36)}</a>
                  </div>
                )}
              </Box>
            </Box>
          )}

          <Box mt={1} display="flex" alignItems="flex-start">
            <img
              src="/static/icons/messages.svg"
              width={24}
              className={commonStyles.iconMiddle}
            />
            &nbsp;&nbsp;
            <Box>
              {my_rsvp.status == 1 && group_chat.object_id && (
                <span>
                  <Link
                    id="group-chat"
                    to={`/messages/${group_chat.object_id}`}
                    component={RLink}
                    color="inherit"
                  >
                    Group Chat
                  </Link>
                </span>
              )}
              {is_owner && !group_chat.object_id && rsvps.length > 2 && (
                <span>
                  <a id="start-group-chat" onClick={showDialogNewGroupMessage}>
                    Start Group Chat
                  </a>
                </span>
              )}
              {is_owner && !group_chat.object_id && rsvps.length <= 2 && (
                <span>Group Chat (requires 3+ RSVPs)</span>
              )}
              {!is_owner && !convo_organizer && (
                <div>
                  <a id="message-organizer" onClick={showDialogNewConvo}>
                    Message Organizer
                  </a>
                </div>
              )}
              {!is_owner && convo_organizer && (
                <div>
                  <Link
                    id="message-organizer"
                    to={`/messages/${convo_organizer}`}
                    component={RLink}
                    color="inherit"
                  >
                    Message Organizer
                  </Link>
                </div>
              )}
            </Box>
          </Box>

          <Box mt={1} display="flex" alignItems="flex-start">
            <PeopleOutline />
            <Box ml={1} component="span">
              {rsvps.length} RSVP{rsvps.length == 1 ? "" : "s"}
            </Box>
          </Box>

          {cost && (
            <div>
              <img
                src="/static/icons/icon-cost.svg"
                width={24}
                className={commonStyles.iconMiddle}
              />
              &nbsp; &nbsp;{cost}
            </div>
          )}

          {dress && (
            <div>
              <img
                src="/static/icons/icon-dress.svg"
                width={24}
                className={commonStyles.iconMiddle}
              />
              &nbsp; &nbsp;{dress}
            </div>
          )}

          {/*          <div style={ { 'display': (url ? 'block' : 'none') } }>
            <img src="/static/icons/icon-video.svg" width={24} className={commonStyles.iconMiddle} />&nbsp;
            &nbsp;<a href={url}>{url}</a>
          </div>
*/}

          {/* Confirmed RSVPs */}
          <Dialog
            id="dialog-rsvps"
            open={openDialogRSVPs}
            keepMounted={false}
            onClose={closeDialogRSVPs}
            scroll="paper"
            classes={{ paper: commonStyles.dialog90 }}
          >
            <div style={{ marginTop: 12 }}>
              <div style={{ height: 200 }}>
                {rsvps.map(function (rsvp, index) {
                  let user = rsvpers_by_id[rsvp.user_id];
                  return (
                    <div
                      key={user.id}
                      className={commonStyles.flexRowCenter}
                      style={{ paddingBottom: "10px" }}
                    >
                      <div style={{ flexBasis: "70px" }}>
                        <center>
                          <UserAvatar
                            url={user.avatar_url}
                            border_color={user.color_background}
                            size="small"
                          />
                        </center>
                      </div>
                      <div style={{ flexGrow: 1 }}>
                        <Link to={`/${user.username}`} component={RLink} color="inherit">
                          <span>{user.username}</span>
                        </Link>
                        <div>{rsvp.typ == 1 && <span>going</span>}</div>
                      </div>
                      <div style={{ flexBasis: "32px" }}>
                        {is_owner && (
                          <div>
                            {rsvp.user_id == window.current_user.id && (
                              <font color="#ddd">
                                <ThumbDown />
                                &nbsp;
                              </font>
                            )}
                            {rsvp.user_id != window.current_user.id && (
                              <font color="red">
                                <ThumbDown
                                  className="hand rsvp-reject"
                                  onClick={() => rejectRsvp(rsvp)}
                                />
                                &nbsp;
                              </font>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Dialog>

          <Box>
            <center>
              <img
                src={event_image_url}
                style={{ maxWidth: "100%", maxHeight: "60vh" }}
              />
            </center>
          </Box>

          {rsvps && rsvps.length > 0 && (
            <Box mt={1.5}>
              <Typography
                variant="h6"
                style={{
                  fontWeight: 800,
                }}
              >
                RSVPs ({rsvps.length})
              </Typography>
              <Box mt={1.5}>
                <a
                  id="show-rsvps"
                  onClick={(evt) => {
                    handleRsvpClick();
                    evt.preventDefault();
                  }}
                >
                  <AvatarGroup
                    max={5}
                    style={{
                      width: rsvps.length > 4 ? 180 - 10 : rsvps.length * 45,
                      alignSelf: "center",
                    }}
                  >
                    {rsvps.map((rsvp) => {
                      return (
                        <UserAvatar
                          key={rsvp.user_id}
                          url={rsvpers_by_id[rsvp.user_id].avatar_url}
                          border_color={
                            rsvpers_by_id[rsvp.user_id].color_background
                          }
                          size="small"
                        />
                      );
                    })}
                  </AvatarGroup>
                </a>
              </Box>
            </Box>
          )}

          {/* Pending RSVPs */}
          <Dialog
            id="dialog-pending-rsvps"
            open={openDialogPendingRSVPs}
            keepMounted={false}
            onClose={closeDialogPendingRSVPs}
            scroll="paper"
            classes={{ paper: commonStyles.dialog90 }}
          >
            <div style={{ marginTop: 12 }}>
              <div style={{ height: 200 }}>
                {pending_rsvps.map(function (rsvp, index) {
                  let user = rsvpers_by_id[rsvp.user_id];
                  return (
                    <div
                      key={user.id}
                      className={commonStyles.flexRowCenter}
                      style={{ paddingBottom: "10px" }}
                    >
                      <div style={{ flexBasis: "70px" }}>
                        <center>
                          <UserAvatar
                            url={user.avatar_url}
                            border_color={user.color_background}
                            size="small"
                          />
                        </center>
                      </div>
                      <div style={{ flexGrow: 1 }}>
                        <Link to={`/${user.username}`} component={RLink} color="inherit">
                          <span>{user.username}</span>
                        </Link>
                        <div>
                          {rsvp.status == 0 && (
                            <div>{rsvp.typ == 1 && <span>going</span>}</div>
                          )}
                          {rsvp.status == 2 && <span>Declined</span>}
                        </div>
                      </div>
                      {/**/}
                      <div style={{ flexBasis: "56px" }}>
                        {rsvp.status == 2 && (
                          <font color="#ddd">
                            <ThumbDown />
                            &nbsp;
                          </font>
                        )}
                        {rsvp.status == 0 && (
                          <font color="red">
                            <ThumbDown
                              className="hand rsvp-reject"
                              onClick={() => rejectRsvp(rsvp)}
                            />
                            &nbsp;
                          </font>
                        )}
                        <font color="green">
                          <ThumbUp
                            className="hand rsvp-accept"
                            onClick={() => approveRsvp(rsvp)}
                          />
                        </font>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Dialog>

          {pending_rsvps && pending_rsvps.length > 0 && (
            <Box mt={1.5}>
              <Typography
                variant="h6"
                style={{
                  fontWeight: 800,
                }}
              >
                Pending RSVPs ({pending_rsvps.length})
              </Typography>
              <Box mt={1.5}>
                <a
                  id="show-pending-rsvps"
                  onClick={(evt) => {
                    handlePendingRsvpClick();
                    evt.preventDefault();
                  }}
                >
                  <AvatarGroup
                    max={5}
                    style={{
                      width:
                        pending_rsvps.length > 4
                          ? 180 - 10
                          : pending_rsvps.length * 45,
                      alignSelf: "center",
                    }}
                  >
                    {pending_rsvps.map((rsvp) => {
                      return (
                        <UserAvatar
                          key={rsvp.user_id}
                          url={rsvpers_by_id[rsvp.user_id].avatar_url}
                          border_color={
                            rsvpers_by_id[rsvp.user_id].color_background
                          }
                          size="small"
                        />
                      );
                    })}
                  </AvatarGroup>
                </a>
              </Box>
            </Box>
          )}

          {!window.current_user.confirmed_at && (
            <div style={{ marginTop: 8 }}>
              <font color="red">Check your email!</font> Your email address is
              not confirmed so you can't join communities or attend events.&nbsp;
              <a href="/resend-confirmation">Resend confirmation</a>
            </div>
          )}

          {window.current_user.confirmed_at && (
            <div className={commonStyles.flexRow} style={{ marginTop: 12 }}>
              {going && (
                <Button
                  id="event-rsvped-going"
                  style={{
                    width: "100%",
                    margin: 4,
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    sendRsvp("not_going");
                  }}
                >
                  <b>I'm&nbsp;Going</b>
                </Button>
              )}
              {!going && (
                <Button
                  id="event-rsvped-not-going"
                  style={{
                    width: "100%",
                    margin: 4,
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    sendRsvp("going");
                  }}
                >
                  Attend
                </Button>
              )}
            </div>
          )}

          <div>
            {my_rsvp && my_rsvp.status == 0 && going && (
              <p>
                You requested to RSVP to this event{" "}
                {DTOW.distance_of_time_in_words(
                  new Date(),
                  new Date(my_rsvp.created_at),
                  false,
                  { vague: true }
                )}{" "}
                ago.
              </p>
            )}
            {my_rsvp && my_rsvp.status == 1 && going && (
              <div>{!is_owner && <p>Your RSVP has been approved!</p>}</div>
            )}
            {my_rsvp && my_rsvp.status == 2 && going && (
              <p>Sorry, your RSVP has been declined by the organizer</p>
            )}
          </div>

          <FormControl component="div" fullWidth={true} margin={"normal"}>
            <FormLabel component="legend">⭐ Event Reminders</FormLabel>
            <p>
              If you flip these switches, this event will appear in your Stories at the given times.
            </p>

            <table>
              <tbody>
                <tr>
                  <td width={50}>
                    <Switch
                      id="reminder_2_weeks"
                      color="primary"
                      checked={
                        my_rsvp && my_rsvp.post_reminders_at.includes("2_weeks")
                      }
                      onChange={() => remindSwitchChange("2_weeks")}
                    />
                  </td>
                  <td>Two weeks before</td>
                </tr>
                <tr>
                  <td>
                    <Switch
                      id="reminder_1_week"
                      color="primary"
                      checked={
                        my_rsvp && my_rsvp.post_reminders_at.includes("1_week")
                      }
                      onChange={() => remindSwitchChange("1_week")}
                    />
                  </td>
                  <td>One week before</td>
                </tr>
                <tr>
                  <td>
                    <Switch
                      id="reminder_24_hours"
                      color="primary"
                      checked={
                        my_rsvp &&
                        my_rsvp.post_reminders_at.includes("24_hours")
                      }
                      onChange={() => remindSwitchChange("24_hours")}
                    />
                  </td>
                  <td>24 hours before</td>
                </tr>
                {/*<tr>
                  <td>
                    <Switch
                      id='reminder_2_hours'
                      color="primary"
                      checked={ my_rsvp && values.my_rsvp.post_reminders_at.includes('2_hours') }
                      onChange={ () => remindSwitchChange('2_hours') }
                    />
                  </td>
                  <td>2 hours before</td>
                </tr>*/}
                <tr>
                  <td>
                    <Switch
                      id="reminder_start"
                      color="primary"
                      checked={
                        my_rsvp && my_rsvp.post_reminders_at.includes("start")
                      }
                      onChange={() => remindSwitchChange("start")}
                    />
                  </td>
                  <td>Start time</td>
                </tr>
              </tbody>
            </table>
          </FormControl>

          <Box mt={1.5}>
            <Typography
              variant="h6"
              style={{
                fontWeight: 800,
              }}
            >
              About
            </Typography>
            <div
              className="cooked"
              style={{
                background: "rgb(248, 248, 248)",
                padding: 2,
                height: 300,
                overflowY: "auto",
              }}
            >
              <span dangerouslySetInnerHTML={{ __html: description_cooked }} />
            </div>
          </Box>
        </Box>
      </div>
      <Loading loading={values.loading} />
    </div>
  );
}
