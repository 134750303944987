import React, { useState } from "react";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { TextField, Button } from "@material-ui/core";

import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    background: "#FFF",
  },
  container: {
    maxWidth: "614px !important",
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default function Contact(props) {
  const classes = useStyles();

  let initialValues = {
    email: window.current_user.email || "",
    contact_text: "",
    captcha_value: "",
  };
  const [values, setValues] = useState(initialValues);

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    let required = event.target.required;

    setValues((state) => ({
      ...state,
      [name]: value,
    }));
  };

  function onChange(value) {
    setValues((state) => ({
      ...state,
      captcha_value: value,
    }));
  }

  return (
    <div className={`${classes.container} ${classes.paper}`}>
      <Typography variant="h4">Contact Us</Typography>
      <br />
      <table>
        <tbody>
          <tr>
            <td>Coding, tech stuff and bugs:</td>
            <td>
              <b>dennis&#x00040;kinkkin.com</b>
            </td>
          </tr>
          <tr>
            <td>Content and community moderation:&nbsp;&nbsp;</td>
            <td>
              <b>lynn&#x00040;kinkkin.com</b>
            </td>
          </tr>
        </tbody>
      </table>
      <br />

      <Typography variant="h6">
        For everything else, contact support:
      </Typography>

      <form action="/contact" method="POST">
        <input
          name="authenticity_token"
          type="hidden"
          value={window.auth_token}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="input-email"
          label="Email Address"
          name="email"
          value={values.email}
          onChange={handleChange}
          autoComplete="contact_email"
          size="small"
          autoFocus
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          multiline={true}
          rows={5}
          id="input-login"
          label="What's the deal?"
          name="contact_text"
          value={values.contact_text}
          onChange={handleChange}
          size="small"
        />

        <ReCAPTCHA
          sitekey={window.recaptcha_key}
          onChange={onChange}
          style={{ display: "inline-block" }}
        />

        <Button
          id="btn-signup"
          type="submit"
          fullWidth
          disabled={
            values.contact_text == "" ||
            values.email == "" ||
            values.captcha_value == ""
          }
          variant="contained"
          color="primary"
        >
          Contact Us
        </Button>
      </form>

      <p>
        <a className="light-themed" href="/about">About</a> |&nbsp;
        <a className="light-themed" href="/content_guidelines">Content Guidelines</a> |&nbsp;
        <a className="light-themed" href="/privacy">Privacy Policy</a> |&nbsp;
        <a className="light-themed" href="/terms">Terms</a>
      </p>
      <br />
    </div>
  );
}
