import React, { useContext, useEffect } from "react";
import Cookies from "vendor/js.cookie.min.mjs";
import Dialog from "@material-ui/core/Dialog";
import { Select } from "@material-ui/core";
import CommonStyles from "components/styles/CommonStyles";
import Location from "components/profile/location";
import { detectLocation } from "components/events/EventService";
import { LocationContext } from "components/profile/LocationContext";

export default function DialogSetLocation(props) {
  const commonStyles = CommonStyles();

  const [locationContext, setLocationContext] = useContext(LocationContext);

  // Location dialog
  //const [openDialogSetLocation, setOpenDialogSetLocation] = useState(false);

  function closeDialogSetLocation() {
    setLocationContext((state) => ({
      ...state,
      dialogOpen: false,
    }));

    // Closed the modal without choosing a city, restore defaults
    if (!locationContext.city_id) {
      setLocationContext((state) => ({
        ...state,
        ...detectLocation(),
      }));
    }
  }

  function handleDistanceChange(event) {
    let value = event.target.value;

    setLocationContext((state) => ({
      ...state,
      distance: value,
    }));

    Cookies.set("communities_distance", value, { expires: 7 });
  }

  // If user selects a new city, save it to the events_location cookie
  useEffect(() => {
    if (locationContext.city_id) {
      Cookies.set("events_location", JSON.stringify(locationContext), {
        expires: 7,
      });
    }
  }, [locationContext]);

  return (
    <Dialog
      id="dialog-set-events-location"
      open={locationContext.dialogOpen}
      keepMounted={false}
      onClose={closeDialogSetLocation}
      scroll="paper"
      classes={{ paper: commonStyles.dialog500 }}
    >
      <div style={{ marginTop: 12, padding: 12 }}>
        <font size="4">
          Within{" "}
          <Select
            native
            id="select-distance"
            name="distance"
            value={locationContext.distance}
            onChange={handleDistanceChange}
            label="Distance"
            size="small"
            style={{ minWidth: 140, padding: 0 }}
          >
            <option value={5}>5 miles</option>
            <option value={10}>10 miles</option>
            <option value={25}>25 miles</option>
            <option value={50}>50 miles</option>
            <option value={"any"}>any distance</option>
          </Select>{" "}
          of:
        </font>
        <Location />
      </div>
    </Dialog>
  );
}
