import React, { useState } from "react";
import $ from "jquery";
import PostStyles from "components/styles/PostStyles";
import HeartIconTrue from "@material-ui/icons/Favorite";
import HeartIconFalse from "@material-ui/icons/FavoriteBorder";

export default function Heart(props) {
  const classes = PostStyles();

  const initialValues = {
    liked: false,
  };
  const [values, setValues] = useState(initialValues);

  // posts | comments
  let type = props.type || "posts";

  React.useEffect(() => {
    setValues((state) => ({
      ...state,
      ["liked"]: props.liked,
    }));
  }, [props.liked]);

  function like() {
    setValues((state) => ({
      ...state,
      ["liked"]: true,
    }));
    props.callbackLike(true);

    let data = {
      authenticity_token: window.auth_token,
    };
    $.post(`/${type}/${props.id}/like`, data)
      .done(function (data) {
        // no-op
      })
      .fail(function (data) {
        console.log("FAIL");
      });
  }

  function unlike() {
    setValues((state) => ({
      ...state,
      ["liked"]: false,
    }));
    props.callbackLike(false);

    let data = {
      authenticity_token: window.auth_token,
      _method: "delete",
    };

    $.post(`/${type}/${props.id}/unlike`, data)
      .done(function (data) {
        // no-op
      })
      .fail(function (data) {
        console.log("FAIL");
      });
  }

  return (
    <span>
      {values.liked && (
        <a className="unheart" onClick={unlike}>
          <font color="#f935b3">
            <HeartIconTrue style={{ width: props.size }} />
          </font>
        </a>
      )}
      {!values.liked && (
        <a className="heart" onClick={like}>
          <HeartIconFalse style={{ width: props.size }} />
        </a>
      )}
    </span>
  );
}
