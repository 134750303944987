import { Container, Link } from "@material-ui/core";
import Loading from "components/Loading";
import CreatePost from "components/posts/CreatePost";
import Post from "components/posts/Post";
//import { PostBrowseContext } from "components/posts/PostBrowseContext";
import PostBrowseDialog from "components/posts/PostBrowseDialog";
import CommonStyles from "components/styles/CommonStyles";
import PostStyles from "components/styles/PostStyles";
import $ from "jquery";
import React, { useEffect, useState, useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link as RLink } from "react-router-dom";
import { railsUTCtoFriendlyLocalTime } from "services/dates.service.js";
import * as PostService from "../../services/posts.service";
import { useTheme } from '@material-ui/core/styles';

import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Pagination]);

/*
 * Retrieve and display a selection of stories
 */
export default function StoriesFeed(props) {
  const classes = PostStyles();
  const commonStyles = CommonStyles();
  const theme = useTheme();

  // Context stuff should match Explore.jsx and User.jsx
  // let initialPostBrowseContext = {
  //   dialog_open: false,
  //   cur_post_id: -1,
  //   cur_post_index: -1,
  //   post_ids: [],
  // };

  // const [postBrowseContext, setPostBrowseContext] = useState(
  //   initialPostBrowseContext
  // );

  const initialValues = {
    stories: props.stories,
    hasMore: props.hasMore,
    loaded: props.loaded,
  };
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    setValues((state) => ({
      ...state,
      loaded: props.loaded,
      stories: props.stories,
      hasMore: props.hasMore
    }));
  }, [props.stories, props.loaded, props.hasMore]);

  let deletePost = (id) => {
    if (confirm("Are you sure you want to delete this post?")) {
      let posts = values.posts.filter((post) => post.id != id);
      setValues((state) => ({
        ...state,
        posts: posts,
      }));

      $.ajax({
        url: `/posts/${id}?authenticity_token=${encodeURIComponent(
          window.auth_token
        )}`,
        type: "DELETE",
        async: window.rails_env != "test",
        success: function (data) {
          snackbarShow("Post deleted!");
        },
      });
    }
  };

  function refresh() {
    if (!window.disable_pull_down_to_refresh) {
      window.location.reload();
    }
  }

  /*
   * Hack: runs on every render cycle
   * Used to refresh data in case something changed, such as the user's list of followers when 'follow' is clicked on Post.jsx
   */
  // useEffect(() => {
  //   if (window.postsForceRender) {
  //     setValues((state) => ({
  //       ...state,
  //       loaded: false,
  //     }));
  //     fetchData(true);
  //     window.postsForceRender = false;
  //   }
  // });

  // When the list of post_ids changes, notify the lower-level components such as AssetWide
  // useEffect(() => {
  //   let post_ids = [];
  //   for (let i = 0; i < values.stories.length; i++) {
  //     post_ids.push(values.stories[i].id);
  //   }
  //   setPostBrowseContext((state) => ({
  //     ...state,
  //     post_ids: post_ids,
  //   }));
  // }, [values.stories]);

  function focusSearch() {
    $("#header-search").focus();
  }

  // See: https://www.tutorialdocs.com/tutorial/swiper/api-pagination.html
  let swiperPagination = {
    el: ".swiper-pagination",
    clickable: false,
    renderBullet: (index, className) => {
      return '<span class="' + className + '"></span>';
    },
  };

  // Create post and selected stories
  return (
    <div id="stories-feed" fixed="true" className={classes.container} style={ { marginTop: 0 } }>
      {
        values.loaded && (
          // <PostBrowseContext.Provider
          //   value={[postBrowseContext, setPostBrowseContext]}
          // >
            <div style={{ display: values.loaded ? "block" : "none" }}>
              {/*<PostBrowseDialog posts={values.stories} />*/}
              <div id="posts-infinite-scroll">
                <InfiniteScroll
                  dataLength={values.stories.length}
                  next={() => {
                    props.fetchData(false);
                  }}
                  hasMore={values.hasMore}
                  loader={
                    <center>
                      <img src="/static/loading/loading1.svg" />
                    </center>
                  }
                  scrollThreshold={0.8}
                  refreshFunction={refresh}
                  pullDownToRefresh={true}
                  pullDownToRefreshThreshold={80}
                  pullDownToRefreshContent={<span />}
                  releaseToRefreshContent={<span />}
                  endMessage={
                    <p
                      style={{
                        textAlign: "center",
                        display: values.stories.length > 0 ? "block" : "none",
                      }}
                    >
                      No more posts! Try{" "}
                      <Link color="error" to="/explore" component={RLink} color="inherit">
                        <b><font color="red">following</font></b>
                      </Link>{" "}
                      a few more users above or browse some <b>#hashtags</b>.
                    </p>
                  }
                >
                  {values.stories.map((story) => (
                    <div id={`stories-feed-${story.id}`} key={`stories-feed-${story.id}`}>
                      {/* Grey spacer between posts */}
                      {/*[{story.id}]*/}
                      <div style={{ height: 16, background: theme.palette.background.default }} />
                      {
                        story.posts.length == 1 && (
                          <Post
                            key={`story-${story.id}-${story.posts[0].id}-0`}
                            post={story.posts[0]}
                            show_follow={true}
                            callbackDelete={deletePost}
                            story_id={story.id}
                            variant="feed"
                          />
                        )
                      }

                      {
                        story.posts.length > 1 && (
                          <Swiper
                            navigation
                            pagination={swiperPagination}
                            loop={false}
                            spaceBetween={30}
                            slidesPerView={1}
                            speed={200}
                            autoHeight={true}
                            centeredSlides={true}
                            updateOnWindowResize={true}
                            setWrapperSize={false}
                          >
                            {story.posts.map(function (post, index) {
                              return (
                                <SwiperSlide key={index}>
                                  <Post
                                    key={`story-${story.id}-${post.id}-${index}`}
                                    post={post}
                                    show_follow={true}
                                    callbackDelete={deletePost}
                                    story_id={story.id}
                                    variant="feed"
                                  />
                                </SwiperSlide>
                              );
                            })}
                          </Swiper>
                        )
                      }
                    </div>
                  ))}
                </InfiniteScroll>
              </div>
            </div>
          // </PostBrowseContext.Provider>
        )
      }

      <Loading loading={!values.loaded} />

      <div
        style={{ display: values.loaded ? "block" : "none", height: "200px" }}
      />
    </div>
  );
}
