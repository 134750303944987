import React, { useCallback, useState, useEffect } from "react";
import $ from "jquery";
import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import {
  FormControl,
  Button,
  TextField,
  Select,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import { CirclePicker } from "react-color";
import { useDropzone } from "react-dropzone";
import colors_background from "colors_background";
import UserAvatar from "components/UserAvatar";
import Loading from "components/Loading";
import Dob from "components/profile/dob";
import CommonStyles from "components/styles/CommonStyles";
import loadImage from "vendor/javascript_load_image";
import { LocationContext } from "components/profile/LocationContext";
import Location from "components/profile/location";
import { useTheme } from "@material-ui/core";

export default function Posts(props) {
  const commonClasses = CommonStyles();
  const theme = useTheme();

  // shared data with location.jsx
  let initialContext = {
    country_id: "",
    region_id: "",
    city_id: "",
  };
  const [locationContext, setLocationContext] = useState(initialContext);

  const initialValues = {
    username: window.current_user.username,
    avatar_url: null,
    color_background: { hex: "#000" },
    originalFilename: null,
    file: null,
    loading: true,
    dob_mm: 1,
    dob_dd: 1,
    dob_yy: 2002,
    settings_profile: window.current_user.settings,
  };
  const [values, setValues] = useState(initialValues);

  // FE Errors hash: { fieldname: 'front end error' }
  const [errorsFE, setErrorsFE] = useState({});
  // BE Errors hash: { fieldname: 'username is taken' }
  const [errorsBE, setErrorsBE] = useState({});
  // Keep timeout across state so we're able to cancel it
  const [timeoutValidate, setTimeoutValidate] = useState(null);

  /*
   * Get profile
   */
  useEffect(() => {
    $.ajax({
      url: `/profile`,
      type: "GET",
      async: window.rails_env != "test",
      success: function (data) {
        console.log("data", data);
        let dob_arr = data.dob.split("-");
        setValues((state) => ({
          ...state,
          ["avatar_url"]: data.avatar_url,
          ["color_background"]: data.color_background,
          ["website"]: data.website,
          ["bio"]: data.bio,
          ["pronouns_id"]: data.pronouns_id,
          ["email"]: data.email,
          ["dob_yy"]: parseInt(dob_arr[0]),
          ["dob_mm"]: parseInt(dob_arr[1]),
          ["dob_dd"]: parseInt(dob_arr[2]),
          ["loading"]: false,
        }));

        setLocationContext((state) => ({
          ...state,
          ["country_id"]: data.country_id || "",
          ["region_id"]: data.region_id || "",
          ["city_id"]: data.city_id || "",
        }));
      },
      error: function (data) {
        if (data.status == 404) {
          setValues((state) => ({
            ...state,
            ["found"]: false,
            ["loading"]: false,
          }));
        }
      },
      cache: false,
      contentType: false,
      processData: false,
    });
  }, []);

  function handleChange() {
    let name = event.target.name;
    let value = event.target.value;
    let required = event.target.required;

    setValues((state) => ({
      ...state,
      [name]: value,
    }));

    validate(name, value, required);
  }

  const handleChangeLocation = (vals) => {
    console.log("location change", vals);
    /*
    setValues(state => ({
      ...state,
      'country_id': vals.country_id,
      'region_id': vals.region_id,
      'city_id': vals.city_id
    }));
    */
  };

  const validate = (name, value, required) => {
    validateFrontend(name, value, required);
    /*
    if (name == 'username' || name == 'email') {
      initiateBackEndValidation({
        ...values,
        [name]: value,
      })
    }
    */
  };

  // Front-end validations
  const validationsFE = {
    username: {
      regex: /^\w{3,24}$/,
      error:
        "Username can only contain letters, numbers and underscore. It must be 3-24 letters long.",
    },
    email: {
      regex: /\S+@\S+\.\S+/,
      error: "Invalid email address.",
    },
    bio: {
      max: 1000,
    },
    dob_dd: {
      valid_date: true,
      error: "Invalid date",
    },
    dob_mm: {
      valid_date: true,
      error: "Invalid date",
    },
  };

  const validateFrontend = (name, value, required) => {
    let error = "";
    const rules = validationsFE[name];
    if (rules) {
      if (rules.regex && value && !rules.regex.test(value)) {
        error = rules.error;
      }
      if (required && (value == null || !value.trim())) {
        error = "Required";
      }
      if (rules.max && value.length > rules.max) {
        error = `Must be ${rules.max} characters or less`;
      }
      if (rules.valid_date) {
        const days_in_month = {
          1: 31,
          2: 29,
          3: 31,
          4: 30,
          5: 31,
          6: 30,
          7: 31,
          8: 31,
          9: 30,
          10: 31,
          11: 30,
          12: 31,
        };
        if (value > days_in_month[values.dob_mm]) {
          error = rules.error;
        }
      }
    }
    setErrorsFE((state) => ({
      ...state,
      [name]: error,
    }));
  };

  const allFieldsValid = () => {
    let retval = true;
    Object.keys(initialValues).forEach((element) => {
      if (errorsBE[element] || errorsFE[element]) {
        retval = false;
      }
    });
    return retval;
  };

  /*
   * Save profile changes
   */
  const save = () => {
    setValues((state) => ({
      ...state,
      ["loading"]: true,
    }));

    let data = { ...values, ...locationContext };
    data["authenticity_token"] = window.auth_token;
    $.ajax({
      url: `/profile`,
      type: "PUT",
      async: window.rails_env != "test",
      data: data,
      success: function (data) {
        // Partial success
        if (data.errors == "") {
          window.toastr.success("Profile saved!");
          window.location.href = `/${values.username}`;
          //props.history.push()
        } else {
          window.toastr.error("Profile saved but with errors: " + data.errors);

          setValues((state) => ({
            ...state,
            ["loading"]: false,
          }));
        }
      },
      error: function (response) {
        setValues((state) => ({
          ...state,
          ["loading"]: false,
          ["username"]: window.current_user.username, // todo: use initiateBackendValidation like in registration.jsx
        }));

        window.toastr.error(response.responseJSON.error);
      },
    });
  };

  /*
   * Profile image uploaded
   */
  const onDrop = useCallback((acceptedFiles) => {
    let file = acceptedFiles[0];

    const reader = new FileReader();

    setValues((state) => ({
      ...state,
      ["originalFilename"]: file.name,
      ["file"]: file,
    }));

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      const binaryStr = reader.result;

      let image = document.createElement("img");
      image.src = binaryStr;

      loadImage(
        binaryStr,
        function (canvas, data) {
          canvas.toBlob((blob) => {
            let fd = new FormData();
            fd.append("file", blob);
            fd.append("original_filename", values.originalFilename);
            fd.append("authenticity_token", window.auth_token);

            $.ajax({
              url: "/profile/picture",
              type: "POST",
              async: window.rails_env != "test",
              data: fd,
              success: function (urls) {
                setValues((state) => ({
                  ...state,
                  ["avatar_url"]: urls[150],
                }));
                window.current_user.avatar_url = urls[45];

                setValues((state) => ({
                  ...state,
                  ["originalFilename"]: null,
                  ["file"]: null,
                }));
              },
              error: function (data) {
                console.error("error", data);
              },
              cache: false,
              contentType: false,
              processData: false,
            });
          });
        },
        {
          meta: true,
          maxWidth: 640,
          maxHeight: 640,
          canvas: true,
        }
      );
    };

    reader.readAsDataURL(file);
  });

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onDrop,
    multiple: false,
    accept: "image/jpeg, image/png",
  });

  // User flipped a switch
  function switchChange(switch_field, value) {
    let arr = switch_field.split(".");
    let typ = arr[0]; // e.g. alerts
    let fld = arr[1]; // e.g. comments
    let hsh = values[typ];

    if (hsh[fld] != value) {
      hsh[fld] = value;

      setValues((state) => ({
        ...state,
        [typ]: hsh,
      }));
    }
  }

  function handleChangedColor(value) {
    console.log("value", value.hex);
    setValues((state) => ({
      ...state,
      color_background: value.hex,
    }));
  }

  return (
    <div>
      <div style={{ display: !values.loading ? "block" : "none" }}>
        <div className={commonClasses.flexRowCenter}>
          <div style={{ flexBasis: "100px" }}>
            <center>
              <UserAvatar
                url={values.avatar_url}
                border_color={values.color_background}
                size="medium"
              />
            </center>
          </div>
          <div style={{}}>
            <Typography variant="h5" style={ { color: theme.palette.text.primary } }>{values.username}</Typography>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Typography>
                <a style={{ color: theme.palette.text.primary }}>Change Profile Photo</a>
              </Typography>
            </div>
          </div>
        </div>
        <Box mt={1}>
          <TextField
            id="input-username"
            name="username"
            label="Username"
            value={values.username || ""}
            error={!!errorsFE["username"] || !!errorsBE["username"]}
            onChange={handleChange}
            inputProps={{
              autoComplete: "off",
              "data-valid":
                values["username"] &&
                !errorsFE["username"] &&
                !errorsBE["username"],
            }}
            required
            size="small"
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <FormHelperText
            error={!!errorsFE["username"] || !!errorsBE["username"]}
          >
            {errorsFE["username"] || errorsBE["username"]}
          </FormHelperText>
        </Box>

        <Box mt={2}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="select-pronouns">Pronouns</InputLabel>
            <Select
              native
              id="select-pronouns"
              inputProps={{ id: "select-pronouns" }}
              name="pronouns_id"
              label="Pronouns"
              value={values.pronouns_id}
              onChange={handleChange}
              style={{ minWidth: 140 }}
            >
              <option value={0}>she / her</option>
              <option value={1}>he / him</option>
              <option value={2}>they / them</option>
              <option value={3}>ze / zir</option>
              <option value={4}>xe / xem</option>
              <option value={5}>sie / hir</option>
              <option value={6}>n / a</option>
            </Select>
          </FormControl>
        </Box>

        <Box mt={1}>
          <TextField
            id="input-website"
            name="website"
            label="Web Site URL"
            placeholder="https://blerg.io"
            value={values.website || ""}
            error={!!errorsFE["website"]}
            onChange={handleChange}
            inputProps={{
              autoComplete: "off",
              "data-valid": values["website"] && !errorsFE["username"],
            }}
            size="small"
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </Box>
        <FormHelperText error={!!errorsFE["website"]}>
          {errorsFE["website"]}
        </FormHelperText>

        <Box mt={1}>
          <TextField
            id="input-bio"
            name="bio"
            label="Bio"
            value={values.bio || ""}
            error={!!errorsFE["bio"]}
            onChange={handleChange}
            inputProps={{
              autoComplete: "off",
              "data-valid": !errorsFE["bio"],
              maxLength: 1000,
            }}
            rows={5}
            multiline
            size="small"
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </Box>
        <FormHelperText error={!!errorsFE["bio"]}>
          {errorsFE["bio"]}
        </FormHelperText>

        <Box mt={1}>
          <InputLabel id="colors-label">Pick a color:</InputLabel>
          <br />
          <div
            style={{
              background: values.color_background,
              width: "100%",
              height: "24px",
              marginTop: 0,
              marginBottom: 8,
              color: "#FFF",
            }}
          ></div>
          <CirclePicker
            color={values.color_background}
            colors={colors_background}
            onChangeComplete={handleChangedColor}
            triangle="hide"
            width={"100%"}
          />
        </Box>

        <br />
        <br />
        <InputLabel id="colors-label">
          Location for Events + Communities:
        </InputLabel>
        <smalltext>Your location is never displayed to other users</smalltext>

        {/* Location.jsx */}
        <LocationContext.Provider value={[locationContext, setLocationContext]}>
          <Location />
        </LocationContext.Provider>

        {/*
        ToDo: What information should show on the user page?

        <center>
          <br />
          <br />
          <Typography>
            <img src="/static/icons/icon-incognito.svg" style={ { height: 30, verticalAlign: "middle" }} /> What info should show on my user page for non-kin?
          </Typography>
        </center>

        <TableContainer width="100%">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align='right' className={commonClasses.tableCell}>
                  Kin:
                </TableCell>
                <TableCell className={commonClasses.tableCell}>
                  <Switch
                    checked={values.settings_profile.likes}
                    onChange={(event, value) => switchChange('alerts.likes', value)}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </TableCell>
                <TableCell align='right' className={commonClasses.tableCell}>
                  Location:
                </TableCell>
                <TableCell className={commonClasses.tableCell}>
                  <Switch
                    checked={values.settings_profile.comments}
                    onChange={(event, value) => switchChange('alerts.comments', value)}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='right' className={commonClasses.tableCell}>
                  Following:
                </TableCell>
                <TableCell className={commonClasses.tableCell}>
                  <Switch
                    checked={values.settings_profile.new_followers}
                    onChange={(event, value) => switchChange('alerts.new_followers', value)}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </TableCell>
                <TableCell align='right' className={commonClasses.tableCell}>
                  Pronouns:
                </TableCell>
                <TableCell className={commonClasses.tableCell}>
                  <Switch
                    checked={values.settings_profile.add_kin}
                    onChange={(event, value) => switchChange('alerts.add_kin', value)}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='right' className={commonClasses.tableCell}>
                  Followers:
                </TableCell>
                <TableCell className={commonClasses.tableCell}>
                  <Switch
                    checked={values.settings_profile.new_events}
                    onChange={(event, value) => switchChange('alerts.new_events', value)}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </TableCell>
                <TableCell align='right' className={commonClasses.tableCell}>
                  
                </TableCell>
                <TableCell className={commonClasses.tableCell}>

                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        */}

        <p>
          <b><span style={ { color: theme.palette.text.primary } }>
            Private information (will never appear in your profile):
            </span>
          </b>
        </p>

        <TextField
          id="input-email"
          name="email"
          label="Email Address"
          value={values["email"] || ""}
          error={!!errorsFE["email"]}
          onChange={handleChange}
          inputProps={{ autoComplete: "off" }}
          disabled={true}
          required
          size="small"
          fullWidth
          variant="outlined"
          margin="normal"
        />
        <FormHelperText error={!!errorsFE["email"] || !!errorsBE["email"]}>
          {errorsFE["email"] || errorsBE["email"]}
        </FormHelperText>

        <p>
          <b><span style={ { color: theme.palette.text.primary } }>Date of Birth:</span></b>
        </p>
        <Dob
          dob_dd={values.dob_dd}
          dob_mm={values.dob_mm}
          dob_yy={values.dob_yy}
          handleChange={handleChange}
        />
        <FormHelperText error={!!errorsFE["dob_mm"] || !!errorsFE["dob_dd"]}>
          {errorsFE["dob_mm"] || errorsFE["dob_dd"]}
        </FormHelperText>

        <Box pt={1} pb={1}>
          <Button
            type="button"
            id="btn-save"
            disabled={!allFieldsValid()}
            variant="contained"
            color="primary"
            onClick={save}
          >
            Save
          </Button>
        </Box>
      </div>
      <Loading loading={values.loading} />
    </div>
  );
}
