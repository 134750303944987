import React, { useState, useEffect, useCallback } from "react";
import $ from "jquery";
import { useParams } from "react-router-dom";
import { Box, Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
// See: https://github.com/ricardo-ch/react-easy-crop
import Cropper from "react-easy-crop";
import { getCroppedImg, getRotatedImage } from "../../vendor/canvasUtils";
import ReactEasyCropStyles from "components/events/ReactEasyCropStyles";
import DatePicker from "react-datepicker";
import {
  railsUTCtoLocalTime,
  jsLocalTimeToUTC,
} from "services/dates.service.js";
// Markdown editor
import ReactMde from "react-mde";
import * as Showdown from "showdown";
// Form crap
import {
  Slider,
  Button,
  TextField,
  Select,
  FormHelperText,
  FormControl,
} from "@material-ui/core";
import { FormLabel } from "@material-ui/core";
import Loading from "components/Loading";
import useInterval from "vendor/useInterval";
import { LocationContext } from "components/profile/LocationContext";
import Location from "components/profile/location";
import CommonStyles from "components/styles/CommonStyles";
import { detectLocation } from "components/events/EventService";

const converter = new Showdown.Converter({
  tables: false,
  tasklists: false,
  simpleLineBreaks: true,
  ghMentions: true,
  ghMentionsLink: "https://kinkykin.com/{u}",
  openLinksInNewWindow: true,
  backslashEscapesHTMLTags: true,
  emoji: true,
  simplifiedAutoLink: true,
  strikethrough: false,
  tasklists: true,
});

export default function EventEdit(props) {
  const classes = CommonStyles();
  const reactEasyCropStyles = ReactEasyCropStyles();

  let { community_id, event_id } = useParams();
  if (!community_id) {
    community_id = -1;
  }
  let mode = props.mode;

  // Default to user's location, then their detected location if not set
  let initialContext = {};
  let locationHsh = detectLocation(window.current_user);
  initialContext.country_id = locationHsh.country_id;
  initialContext.region_id = locationHsh.region_id;
  initialContext.city_id = locationHsh.city_id;

  // For sharing location data w/ <Location />
  const [locationContext, setLocationContext] = useState(initialContext);

  // Convert browser's local timezone version of the date to an ISO date
  // and return it as a string that Rails understands as a date
  const timezone_offset = new Date().getTimezoneOffset() * 1000 * 60;

  // let dt_start = new Date()
  // dt_start.setHours(dt_start.getHours() + 2)
  // dt_start.setMinutes(0)

  // let dt_end = new Date()
  // dt_end.setHours(dt_end.getHours() + 4)
  // dt_end.setMinutes(0)

  const initialValues = {
    community_id: community_id,
    id: event_id || "",
    community: { id: -1, name: "" },
    event_name: "",
    tagline: "",
    description_raw: "",
    members_only: false,
    cost: "",
    dress: "",
    image: null,
    dt_start: null, //jsLocalTimeToUTC(dt_start),
    end_type: "precise",
    dt_end: null, //jsLocalTimeToUTC(dt_end),
    timezone: null,
    event_type: "virtual",
    url: "",
    location_name: "",
    location_address: "",
    post_reminders_at: [],
    post_reminders_sent: [],
    country_id: window.current_user.country_id,
    region_id: window.current_user.region_id,
    city_id: window.current_user.city_id,
  };
  const [values, setValues] = useState(initialValues);
  const [selectedTab, setSelectedTab] = React.useState("write");

  const mdeCommands = [
    ["header", "bold", "italic"],
    ["link", "quote"],
    ["unordered-list", "ordered-list", "checked-list"],
  ];

  // Get the 'draft' version of this community, if it exists
  useEffect(() => {
    setValues((state) => ({
      ...state,
      ["loading"]: true,
    }));

    if (mode == "create") {
      $.ajax({
        url: `/events/draft`,
        type: "GET",
        async: window.rails_env != "test",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        success: function (data) {
          if (data.success) {
            let event = data.event;

            let dt_start = event.dt_start;
            let dt_end = event.dt_end;
            delete event.dt_start;
            delete event.dt_end;

            setValues((state) => ({
              ...state,
              ...event,
              dt_start: dt_start ? railsUTCtoLocalTime(dt_start + "Z") : null,
              dt_end: dt_end ? railsUTCtoLocalTime(dt_end + "Z") : null,
              event_name: event.name,
              loading: false,
            }));

            setLocationContext((state) => ({
              ...state,
              country_id: event.country_id,
              region_id: event.region_id,
              city_id: event.city_id,
            }));
          } else {
            setValues((state) => ({
              ...state,
              loading: false,
            }));
          }
        },
        cache: false,
        contentType: false,
        processData: false,
      });
    } else if (mode == "edit") {
      $.ajax({
        url: `/events/${event_id}.json`,
        type: "GET",
        async: window.rails_env != "test",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        success: function (data) {
          let event = data;

          let dt_start = data.dt_start;
          let dt_end = data.dt_end;
          delete data.dt_start;
          delete data.dt_end;

          //console.log("parsed date", dt_start, railsUTCtoLocalTime(dt_start + "Z"))

          setValues((state) => ({
            ...state,
            ...data,
            dt_start: dt_start ? railsUTCtoLocalTime(dt_start + "Z") : null,
            dt_end: dt_end ? railsUTCtoLocalTime(dt_end + "Z") : null,
            loading: false,
          }));

          setLocationContext((state) => ({
            ...state,
            country_id: event.country_id,
            region_id: event.region_id,
            city_id: event.city_id,
          }));
        },
        cache: false,
        contentType: false,
        processData: false,
      });
    }
  }, [values.page]);

  /*
   * Hack: onBlur doesn't work on ReactMDE. Use jquery instead
   */
  useEffect(() => {
    $("#input-description-raw").blur(handleDescBlur);
  }, []);

  function getPayload() {
    let data = {
      authenticity_token: window.auth_token,
      community_id: values.community_id,
      event_name: values.event_name,
      description_raw: values.description_raw,
      tagline: values.tagline,
      members_only: values.members_only,
      cost: values.cost,
      dress: values.dress,
      dt_start: values.dt_start, // todo: fix
      end_type: values.end_type,
      dt_end: values.dt_end,
      event_type: values.event_type,
      url: values.url,
      location_name: values.location_name,
      location_address: values.location_address,
      country_id: locationContext.country_id,
      region_id: locationContext.region_id,
      city_id: locationContext.city_id,
      post_reminders_at: values.post_reminders_at,
    };
    return data;
  }

  // Save current draft of the community every 5s if both name and desc have a value
  useInterval(
    () => {
      if (
        mode == "create" &&
        !window.stop_saving_drafts &&
        values.loading == false &&
        values.event_name != "" &&
        values.description_raw != ""
      ) {
        let payload = getPayload();
        if (JSON.stringify(payload) != window.last_payload) {
          window.last_payload = JSON.stringify(payload);
          $.ajax({
            url: `/events/draft`,
            type: "POST",
            async: window.rails_env != "test",
            data: payload,
            success: function (data) {
              setValues((state) => ({
                ...state,
                id: data.id,
              }));
              //console.log("Event draft saved")
            },
            error: function (data) {
              console.error("Error saving community draft", data);
            },
          });
        }
      }
    },
    window.rails_env == "test" ? 2000 : 4000
  );

  // Which fields have been visited then left: { fieldname: true (or false0) }
  const [blurred, setBlurred] = useState({});

  // 'country_id', 'region_id', 'city_id'
  const requiredInputs = [
    "event_name",
    "description_raw",
    "tagline",
    "dt_start",
  ];

  // FE Errors hash: { fieldname: 'front end error' }
  const [errorsFE, setErrorsFE] = useState({});

  // Front-end validations
  const validationsFE = {
    event_name: {
      regex: /^.{5,60}$/,
      error: "Name must be 5-60 characters",
    },
    description_raw: {
      regex: /^.{50,1000}$/,
      error: "Description must be 50-1000 characters",
    },
    tagline: {
      regex: /^.{5,80}$/, // < 80 characters
      error: "Tagline must be 5-80 characters",
    },
  };

  const validateFrontend = (name, value, required) => {
    let error = "";
    const rules = validationsFE[name];
    if (rules) {
      if (rules.regex && value && !rules.regex.test(value)) {
        error = rules.error;
      }
      if (required && !value.trim()) {
        error = "Required";
      }
    }
    setErrorsFE((state) => ({
      ...state,
      [name]: error,
    }));
  };

  // User has typed a key or selected a dropdown
  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    let required = event.target.required;

    setValues((state) => ({
      ...state,
      [name]: value,
    }));

    // Don't validate on keystroke until the user has completed a first attempt
    if (blurred[name]) {
      validate(name, value, required);
    }
  };

  function handleDescBlur() {
    let val = $("#input-description-raw").val();
    val = val.replace(/\r?\n|\r/gm, "");
    let fake_event = {
      target: {
        name: "description_raw",
        value: val,
        required: true,
      },
    };
    handleBlur(fake_event);
  }

  const descChange = (val) => {
    setValues((state) => ({
      ...state,
      ["description_raw"]: val,
    }));
  };

  // User has moved focus out of a field
  const handleBlur = (event) => {
    let name = event.target.name;
    let value = event.target.value.trim();
    let required = event.target.required;

    //validateFrontend(name, value, required)
    if (!blurred[name]) {
      validate(name, value, required);
    }

    setBlurred((state) => ({
      ...state,
      [name]: true,
    }));
  };

  const validate = (name, value, required) => {
    validateFrontend(name, value, required);
  };

  const allFieldsValid = () => {
    let retval = true;
    requiredInputs.forEach((element) => {
      if (
        values[element] == null ||
        values[element] == "" ||
        errorsFE[element]
      ) {
        retval = false;
      }
    });
    if (values.end_type == "precise" && !values.dt_end) {
      retval = false;
    }
    return retval;
  };

  function handleChangeMembersOnly(val) {
    setValues((state) => ({
      ...state,
      members_only: val,
    }));
  }

  function post() {
    setValues((state) => ({
      ...state,
      loading: true,
    }));
  }

  // Image crap
  function sendPhotoStart() {
    $("#input-file-image").click();
  }

  /* Image cropper. See: https://codesandbox.io/s/y09komm059 */
  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    //console.log("croppedArea, croppedAreaPixels", croppedArea, croppedAreaPixels)
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      // apply rotation if needed
      //const orientation = await getOrientation(file)
      //const rotation = ORIENTATION_TO_ANGLE[orientation]
      if (false && rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
      }

      setImageSrc(imageDataUrl);
    }
  };

  const finalizeAndSubmit = useCallback(
    async (dt_start, dt_end) => {
      try {
        setValues((state) => ({
          ...state,
          loading: true,
        }));
        window.stop_saving_drafts = true;

        // Process new image if any
        if (imageSrc) {
          const croppedImage = await getCroppedImg(
            imageSrc,
            croppedAreaPixels,
            rotation
          );
          //setCroppedImage(croppedImage)

          let blob = await fetch(croppedImage).then((r) => r.blob());

          let file = new File([blob], "img.jpg", {
            type: "image/jpeg",
            lastModified: new Date().getTime(),
          });
          let container = new DataTransfer();
          container.items.add(file);

          var hidden_elem = document.getElementById("input-file-cropped");
          hidden_elem.files = container.files;
        }

        // This converts the selected date from CST to UTC
        $("#dt_start").val(jsLocalTimeToUTC(dt_start));
        $("#dt_end").val(jsLocalTimeToUTC(dt_end));

        $("#form-save").submit();
      } catch (e) {
        console.error(e);
      }
    },
    [imageSrc, croppedAreaPixels, rotation]
  );

  function setStartDate(date) {
    // date is in the user's current time zone
    setValues((state) => ({
      ...state,
      dt_start: date,
    }));
  }

  function setEndDate(date) {
    setValues((state) => ({
      ...state,
      dt_end: date,
    }));
  }

  return (
    <div id="create_event" className={classes.container} style={ { paddingLeft: 8, paddingRight: 8 } }>
      <div style={{ display: values.loading ? "none" : "block" }}>
        <form
          id="form-save"
          action="/events"
          method="POST"
          noValidate
          encType="multipart/form-data"
        >
          <input
            type="hidden"
            name="authenticity_token"
            value={window.auth_token}
          />
          <input type="hidden" name="id" value={values.id} />
          <input
            type="hidden"
            name="community_id"
            value={values.community_id}
          />
          <Typography variant="h4">
            {mode == "create" ? "Create New Event" : "Edit Your Event"}
          </Typography>

          <p>
            Events are either in-person gatherings or virtual/video/chat events.
          </p>

          <p>
            As the organizer, you will be able to accept or reject RSVPs from
            users. Only users with accepted RSVPs can see the location
            information (in-person events) or URL (virtual).
          </p>

          <p>
            <b>Note</b>: any user can send you a message about this event, even
            if your consent settings would normally not allow them to. These
            messages will go in the 'Events' inbox in messaging.
          </p>

          <p>
            This event will be created in the <b>{values.community.name}</b>{" "}
            community
          </p>

          <p>
            <font color="red">* Required</font>
          </p>

          <FormControl component="div" fullWidth={true} margin={"normal"}>
            <FormLabel component="legend">
              Event Name<font color="red">*</font>:
            </FormLabel>
            <TextField
              id="input-event-name"
              name="event_name"
              type="text"
              value={values.event_name || ""}
              error={!!errorsFE["event_name"]}
              onBlur={handleBlur}
              onChange={handleChange}
              required
              size="small"
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <FormHelperText error={!!errorsFE["event_name"]}>
              {errorsFE["event_name"]}
            </FormHelperText>
          </FormControl>

          <FormControl component="div" fullWidth={true} margin={"normal"}>
            <FormLabel component="legend">
              Event Tagline<font color="red">*</font>:
            </FormLabel>

            <TextField
              id="input-tagline"
              name="tagline"
              type="text"
              placeholder="Our first outdoor munch of the summer!"
              value={values["tagline"] || ""}
              error={!!errorsFE["tagline"]}
              onBlur={handleBlur}
              onChange={handleChange}
              required={true}
              size="small"
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <FormHelperText>A short (5-80 character) tagline.</FormHelperText>
            <FormHelperText error={!!errorsFE["tagline"]}>
              {errorsFE["tagline"]}
            </FormHelperText>
          </FormControl>

          {/*
          <FormControl component="div" fullWidth={true} margin={'normal'}>
            <FormLabel component="legend">Who can see / RSVP to this event?</FormLabel>
            <div>
              <Radio
                checked={values.members_only === false}
                onChange={() => { handleChangeMembersOnly(false) } }
                value={false}
                name="radio-members-only"
              /> Any Kin User
            </div>
            <div>
              <Radio
                checked={values.members_only === true}
                onChange={() => { handleChangeMembersOnly(true) } }
                value={true}
                name="radio-members-only"
              /> Community Members Only
            </div>
          </FormControl>
          */}

          <FormControl component="div" fullWidth={true} margin={"normal"}>
            <FormLabel component="legend">
              Event Image (highly recommended):
            </FormLabel>
            <br />
            <div>
              <img
                id="id-attach-image"
                src="/static/icons/icon-attach-image-message.svg"
                width={32}
                style={{ cursor: "pointer", verticalAlign: "middle" }}
                onClick={sendPhotoStart}
              />
              {values.event_image_url && <span>&nbsp;Change Image</span>}
              {!values.event_image_url && <span>&nbsp;Set an image</span>}
            </div>
            <FormHelperText>
              Should be a 16:9 resolution. Most phones are 16:9 in portrait mode.
            </FormHelperText>

            <div>
              <input
                id="input-file-cropped"
                name="input-file-cropped"
                style={{ display: "none" }}
                type="file"
                accept=".jpg, .jpeg, .png"
              />
              {imageSrc && (
                <React.Fragment>
                  <div className={reactEasyCropStyles.cropContainer}>
                    <Cropper
                      image={imageSrc}
                      crop={crop}
                      rotation={rotation}
                      zoom={zoom}
                      aspect={9 / 16}
                      zoomWithScroll={false}
                      onCropChange={setCrop}
                      onRotationChange={setRotation}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  </div>
                  <div className={reactEasyCropStyles.controls}>
                    <div className={reactEasyCropStyles.sliderContainer}>
                      <Typography
                        variant="overline"
                        classes={{ root: reactEasyCropStyles.sliderLabel }}
                      >
                        Zoom
                      </Typography>
                      <Slider
                        value={zoom}
                        aspect={3 / 2}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        classes={{ root: reactEasyCropStyles.slider }}
                        onChange={(e, zoom) => setZoom(zoom)}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {values.event_image_url && (
                <img
                  src={values.event_image_url}
                  style={{ maxWidth: "100%" }}
                />
              )}
            </div>
          </FormControl>

          {/* https://github.com/andrerpena/react-mde */}

          <FormControl component="div" fullWidth={true} margin={"normal"}>
            <FormLabel component="legend">
              Long Description<font color="red">*</font>:
            </FormLabel>

            <ul>
              <li>Who should go to this event?</li>
              <li>What will happen at this event?</li>
              <li>Where is it?</li>
            </ul>

            <ReactMde
              value={values.description_raw}
              onChange={descChange}
              selectedTab={selectedTab}
              onTabChange={setSelectedTab}
              toolbarCommands={mdeCommands}
              childProps={{
                textArea: {
                  name: "description_raw",
                  id: "input-description-raw",
                },
              }}
              generateMarkdownPreview={(markdown) =>
                Promise.resolve(converter.makeHtml(markdown))
              }
            />
            <FormHelperText>
              Please describe your event in detail.
            </FormHelperText>
            <FormHelperText error={!!errorsFE["description_raw"]}>
              {errorsFE["description_raw"]}
            </FormHelperText>
          </FormControl>

          <FormControl component="div" fullWidth={true} margin={"normal"}>
            <FormLabel component="legend">Cost:</FormLabel>

            <TextField
              id="input-cost"
              name="cost"
              placeholder="$10 at the door"
              type="text"
              value={values["cost"] || ""}
              error={!!errorsFE["cost"]}
              onBlur={handleBlur}
              onChange={handleChange}
              size="small"
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <FormHelperText error={!!errorsFE["cost"]}>
              {errorsFE["cost"]}
            </FormHelperText>
          </FormControl>

          <FormControl component="div" fullWidth={true} margin={"normal"}>
            <FormLabel component="legend">Dress Code:</FormLabel>

            <TextField
              id="input-dress"
              name="dress"
              placeholder="Vanilla to the door then whatever you want inside"
              type="text"
              value={values["dress"] || ""}
              error={!!errorsFE["dress"]}
              onBlur={handleBlur}
              onChange={handleChange}
              size="small"
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <FormHelperText error={!!errorsFE["dress"]}>
              {errorsFE["dress"]}
            </FormHelperText>
          </FormControl>

          <FormControl component="div" fullWidth={true} margin={"normal"}>
            <FormLabel component="legend">Start Time:</FormLabel>
            <br />

            <input id="dt_start" name="dt_start" type="hidden" />
            <DatePicker
              id="input-dt_start"
              selected={values.dt_start}
              onChange={(date) => setStartDate(date)}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
              timeIntervals={30}
              style={ { background: "#000" } }
            />
            <FormHelperText>
              Times are in the{" "}
              {Intl.DateTimeFormat().resolvedOptions().timeZone} time zone.
            </FormHelperText>
          </FormControl>

          <FormControl component="div" fullWidth={true} margin={"normal"}>
            <FormLabel component="legend">End Time:</FormLabel>
            <br />
            <Select
              native
              autoWidth={true}
              id="select-end-type"
              style={{ width: 200 }}
              name="end_type"
              labelId="type-label"
              value={values.end_type}
              onChange={handleChange}
            >
              <option value={"precise"}>Exact Time</option>
              <option value={"2_3_hours"}>2 or 3 hours</option>
              <option value={"party_dies"}>Till The Party Dies Down</option>
              <option value={"sunset"}>Sunset</option>
              <option value={"late"}>Late</option>
              <option value={"very_late"}>Very Late</option>
            </Select>

            <input id="dt_end" name="dt_end" type="hidden" />
            <div>
              <br />
              <DatePicker
                id="input-dt_end"
                selected={values.dt_end}
                onChange={(date) => setEndDate(date)}
                disabled={values.end_type != "precise"}
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
                timeIntervals={30}
              />
            </div>
          </FormControl>

          <Box mt={2}>
            <FormControl component="div" fullWidth={true} margin={"normal"}>
              <FormLabel component="legend">Event Type:</FormLabel>
              <br />
              <Select
                native
                id="select-event_type"
                name="event_type"
                value={values.event_type}
                label="In-Person or Virtual?"
                onChange={handleChange}
              >
                <option value={"virtual"}>Virtual Event</option>
                <option value={"in_person"}>In-Person Event</option>
              </Select>
            </FormControl>
          </Box>

          <div
            style={{
              display: values.event_type == "in_person" ? "block" : "none",
            }}
          >
            <br />
            <LocationContext.Provider
              value={[locationContext, setLocationContext]}
            >
              <Location />
            </LocationContext.Provider>

            <FormControl component="div" fullWidth={true} margin={"normal"}>
              <FormLabel component="legend">Location Name:</FormLabel>
              <TextField
                id="input-location_name"
                name="location_name"
                placeholder="e.g. Leather Rose Dungeon"
                type="text"
                value={values["location_name"] || ""}
                error={!!errorsFE["location_name"]}
                onBlur={handleBlur}
                onChange={handleChange}
                required
                size="small"
                fullWidth
                variant="outlined"
                margin="normal"
              />
              <FormHelperText error={!!errorsFE["location"]}>
                {errorsFE["location"]}
              </FormHelperText>
            </FormControl>

            <FormControl component="div" fullWidth={true} margin={"normal"}>
              <FormLabel component="legend">
                Exact Address / Location:
              </FormLabel>

              <p>Only attendees you approve can see the location</p>

              <TextField
                id="input-location_address"
                name="location_address"
                placeholder="6969 Kink Ave"
                type="text"
                value={values["location_address"] || ""}
                error={!!errorsFE["location_address"]}
                onBlur={handleBlur}
                onChange={handleChange}
                size="small"
                fullWidth
                variant="outlined"
                margin="none"
              />
              <FormHelperText>
                You can optionally send the address privately to everyone who
                RSVPs
              </FormHelperText>
              <FormHelperText error={!!errorsFE["location_address"]}>
                {errorsFE["location_address"]}
              </FormHelperText>
            </FormControl>
          </div>

          <div
            style={{
              display: values.event_type == "virtual" ? "block" : "none",
            }}
          >
            <FormControl component="div" fullWidth={true} margin={"normal"}>
              <FormLabel component="legend">URL / Link:</FormLabel>

              <p>Only attendees you approve can see the link</p>

              <TextField
                id="input-url"
                name="url"
                placeholder="https://web.zoom.us/j/1234?pwd=qwertyuiop"
                type="text"
                value={values.url}
                error={!!errorsFE["url"]}
                onBlur={handleBlur}
                onChange={handleChange}
                required
                size="small"
                fullWidth
                variant="outlined"
                margin="none"
              />
              <FormHelperText error={!!errorsFE["url"]}>
                {errorsFE["url"]}
              </FormHelperText>
            </FormControl>
          </div>

          <br />
          <br />
          <Button
            id="btn-save"
            fullWidth
            onClick={() => {
              finalizeAndSubmit(values.dt_start, values.dt_end);
            }}
            disabled={!allFieldsValid()}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {mode == "create" ? "Create Event" : "Save Changes"}
          </Button>
          <br />
          <br />
          <br />
        </form>
        {/* Must be outside the form so it doesn't get POSTed */}
        <input
          id="input-file-image"
          name="input-file-image"
          style={{ display: "none" }}
          type="file"
          onChange={onFileChange}
          accept=".jpg, .jpeg, .png"
        />
      </div>
      <Loading loading={values.loading} />
    </div>
  );
}
