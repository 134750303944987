function follow(user_id) {
  let data = {
    authenticity_token: window.auth_token,
  };
  $.post(`/users/follow/${user_id}`, data)
    .done(function (data) {
      console.log("Data", data);
    })
    .fail(function (data) {
      console.log("FAIL");
    });
}

function unfollow(user_id) {
  let data = {
    authenticity_token: window.auth_token,
    _method: "delete",
  };
  $.post(`/users/follow/${user_id}`, data)
    .done(function (data) {
      console.log("Data", data);
    })
    .fail(function (data) {
      console.log("FAIL");
    });
}

function add_kin(user_id) {
  let data = {
    authenticity_token: window.auth_token,
  };
  $.post(`/users/kin/${user_id}`, data)
    .done(function (data) {
      console.log("Data", data);
    })
    .fail(function (data) {
      console.log("FAIL");
    });
}

function unkin(user_id) {
  let data = {
    authenticity_token: window.auth_token,
    _method: "delete",
  };
  $.post(`/users/kin/${user_id}`, data)
    .done(function (data) {
      console.log("Data", data);
    })
    .fail(function (data) {
      console.log("FAIL");
    });
}

function block(user_id, callback) {
  let data = {
    authenticity_token: window.auth_token,
  };
  $.post(`/users/block/${user_id}`, data)
    .done(function (data) {
      if (callback) {
        callback()
      }
      console.log("Data", data);
    })
    .fail(function (data) {
      console.log("FAIL");
    });
}

function unblock(user_id, callback) {
  let data = {
    authenticity_token: window.auth_token,
    _method: "delete",
  };
  $.post(`/users/unblock/${user_id}`, data)
    .done(function (data) {
      if (callback) {
        callback()
      }
      console.log("Data", data);
    })
    .fail(function (data) {
      console.log("FAIL");
    });
}

export default {
  follow: follow,
  unfollow: unfollow,
  add_kin: add_kin,
  unkin: unkin,
  block: block,
  unblock: unblock
};
