import React, { useState, useContext, useEffect } from "react";
import { useParams, Link as RLink } from "react-router-dom";
import { Link } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import Cookies from "vendor/js.cookie.min.mjs";
import PageDescription from "components/PageDescription";
import StoriesFeed from "components/stories/StoriesFeed";
import Connections from "components/home/Connections";
import PostStyles from "components/styles/PostStyles";
import SearchUsers from "components/SearchUsers";
import TabPanel from "components/TabPanel";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Avatar } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import platform from "components/pwa/platform";
import PWAPrompt from "react-ios-pwa-prompt";
import CommonStyles from "components/styles/CommonStyles";
import useInterval from "vendor/useInterval";
import Trending from "components/explore/Trending";
import { ThemeContext } from "components/ThemeContext";
import StoryThumbs from "components/stories/StoryThumbs";
import * as PostService from "../../services/posts.service";


export default function Home(props) {
  const classes = PostStyles();
  const commonStyles = CommonStyles();
  const theme = useTheme();

  // must match posts_controller.rb#STORIES_PER_PAGE
  const STORIES_PER_PAGE = 20;

  let { tag } = useParams();

  let defaultTab = (window.current_user?.stats?.following < 3 ? 'trending' : 'home')
  const [tab, setTab] = React.useState(Cookies.get("lastMenuHome") || defaultTab);

  let history = props.history;

  let [themeContext, setThemeContext] = useContext(ThemeContext);

  const initialValues = {
    stories: [],
    tag_spotlight_image_url: "",
    related_tags: [],
    following_tag: false,
    tag: {},
    hasMore: true,
    loaded: false,
  };
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    fetchData(true);
  }, [tag]);

  let fetchData = (clear) => {
    console.warn("== FETCH")
    let last_id = "";
    if (!clear) {
      last_id = values.last_id;
    }

    //let url = `/stories.json?post_tab=${props.tab}`
    let url = `/stories/tag/${tag}.json?clear=${clear ? 'true' : 'false'}`
    if (last_id) {
      url += `&last_id=${last_id}`
    }
    if (props.tab == "community") {
      url += `&community_id=${props.community_id}`
    }
    $.ajax({
      url: url,
      type: "GET",
      async: window.rails_env != "test",
      success: function (data) {
        console.warn("DATA", data)
        console.warn("DATA STORIES", data.stories)
        let last_id = "";
        for (let i = 0; i < data.stories.length; i++) {
          let id = data.stories[i].id
          if (!last_id || id < last_id) {
            last_id = id
          }
        }
        // if (data.stories.length > 0) {
        //   last_id = data.stories[data.stories.length - 1].id;
        // }

        setValues((state) => ({
          ...state,
          stories: clear ? data.stories : values.stories.concat(data.stories),
          tag_spotlight_image_url: data.tag_spotlight_image_url,
          related_tags: data.related_tags,
          following_tag: data.following_tag,
          tag: data.tag,
          last_id: last_id,
          hasMore: data.stories.length == STORIES_PER_PAGE,
          loaded: true,
        }));
        PostService.disableRightClick();
      },
      cache: false,
      contentType: false,
      processData: false,
    });
  };

  return (
    <div style={ { color: themeContext.type == 'dark' ? '#FFF' : '#000' } }>
      {values.tag &&
        values.tag.tag && ( // if tag.tag is set, we have data from the backend
          <div
            className={commonStyles.flexRow}
            style={{ display: "inline-flex", width: "100%", marginTop: 12 }}
          >
            <div
              style={{
                flexBasis: 170,
                flexShrink: 0,
                flexGrow: 0,
                paddingLeft: 10,
              }}
            >
              <Avatar
                variant="circle"
                src={
                  values.tag_spotlight_image_url || "/default-profile.svg"
                }
                style={{
                  border: `solid 2px ${props.border_color || "white"}`,
                  width: 150,
                  height: 150,
                }}
              />
            </div>

            <div style={{ flexGrow: 0.05 }}></div>

            <div
              style={{ flexGrow: 0.95, alignItems: "left", minWidth: 0 }}
            >
              <div style={{ marginTop: 4, fontSize: "1.5em" }}>
                #{values.tag.tag}
              </div>

              {/*<div style={{ paddingTop: 0 }}>
                {!values.following_tag && (
                  <a
                    className="hand"
                    id="follow"
                    onClick={() => followTag(values.tag.id)}
                  >
                    <b>
                      <font color="red">follow</font>
                    </b>
                  </a>
                )}
                {values.following_tag && (
                  <span>
                    {values.following_tags.length <= 3 && (
                      <a
                        className="hand"
                        id="unfollow-text-disabled"
                        onClick={() =>
                          alert("You must follow 3+ tags to unfollow")
                        }
                      >
                        <b>
                          <font color="#DDD">unfollow</font>
                        </b>
                      </a>
                    )}
                    {values.following_tags.length > 3 && (
                      <a
                        className="hand"
                        id="unfollow-text-enabled"
                        onClick={() => unfollowTag(values.tag.id)}
                      >
                        <b>
                          <font color="red">unfollow</font>
                        </b>
                      </a>
                    )}
                  </span>
                )}
              </div>*/}

              {values.related_tags && (
                <div
                  className="tags-browse"
                  style={{
                    marginTop: 14,
                    marginBottom: 10,
                    paddingBottom: 4,
                    width: "100%",
                  }}
                >
                  {values.related_tags.map((tag, index) => (
                    <span key={`related-${tag}`} className="pill">
                      <Link
                        key={`word${index}`}
                        to={`/stories/tag/${tag
                          .replace(/^#+/, "")
                          .replace(/[!?.,]$/, "")
                          .toLowerCase()}`}
                        component={RLink}
                        color="inherit"
                      >
                        #{tag}
                      </Link>
                      {" "}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}

      <StoriesFeed key={`tag-${tag}`} tab="tag" history={history} stories={values.stories} hasMore={values.hasMore} loaded={values.loaded} fetchData={fetchData} />
    </div>
  );
}
