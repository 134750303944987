import React, { useState } from "react";

export default function Thumbnails(props) {
  const initialValues = {
    selectedIdx: 0,
  };

  const [values, setValues] = useState(initialValues);

  const uploadChosen = (evt) => {
    let id = evt.target.id;
    id = id.match(/select-(\d+)/);
    id = parseInt(id[1]);
    props.callback(id);

    setValues({ selectedIdx: id });
    //selectCanvas(id)
  };

  let showIfMoreThan = props.showIfMoreThan;
  if (showIfMoreThan == null || showIfMoreThan == undefined) {
    showIfMoreThan = 1;
  }

  return (
    <div>
      {props.uploads && props.uploads.length > showIfMoreThan && (
        <div>
          {props.uploads.map(function (upload, index) {
            return (
              <img
                className={
                  index == values.selectedIdx
                    ? "borderSelected"
                    : "borderNotSelected"
                }
                id={`select-${index}`}
                onClick={uploadChosen}
                key={index}
                src={upload.thumbnail}
                height={60}
                style={{ marginRight: 8 }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}
