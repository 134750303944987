import React, { useState, useEffect } from "react";
import $ from "jquery";
import { Box, Grid, Container, Button, TextField } from "@material-ui/core";
import { Link, Typography } from "@material-ui/core";
import { Link as RLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import UserAvatar from "components/UserAvatar";
import UserList from "components/UserList";
import Flag from "components/Flag";
import Loading from "components/Loading";
import PostStyles from "components/styles/PostStyles";
import CommonStyles from "components/styles/CommonStyles";
import utils from "components/posts/utils";
import AssetLinked from "components/posts/AssetLinked";
import EventAbbrev from "components/events/EventAbbrev";
import { railsUTCtoFriendlyLocalTime } from "services/dates.service.js";
import TabPanel from "components/TabPanel";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useHistory } from "react-router-dom";
import Can from "can_permission";
import PostBrowseDialog from "components/posts/PostBrowseDialog";
//import { PostBrowseContext } from "components/posts/PostBrowseContext";
import { useParams, useRouteMatch } from "react-router-dom";
import goBack from "go_back";
import StoriesFeed from "components/stories/StoriesFeed";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: 8,
    marginTop: 8,
  },
  username: {
    fontSize: "1.25rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: "0em",
  },
  paper: {
    marginTop: theme.spacing(3),
  },
  dialogFullScreen: {
    width: "85%",
    margin: "auto",
  },
  container: {
    maxWidth: "940px",
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 0,
    paddingRight: 0,
  },
  image_wide: {
    objectFit: "contain",
    maxWidth: "100%",
    height: "100%",
  },
  title: {
    fontSize: 14,
    textTransform: "uppercase",
  },
  card: {
    minWidth: 275,
    marginBottom: 8,
  },
  url: {
    color: theme.palette.text.primary
  },
  desktop_only: {
    display: "none",
    [theme.breakpoints.up(500)]: {
      display: "block",
    },
  },
  mobile_only: {
    display: "none",
    [theme.breakpoints.down(500)]: {
      display: "block",
    },
  },
}));

export default function User(props) {
  const classes = useStyles();
  const postClasses = PostStyles();
  const commonStyles = CommonStyles();

  let { path, url } = useRouteMatch();

  let { username } = useParams();
  let isMe = username == window.current_user.username;

  const initialValues = {
    stories: [],
    user: {
      id: -1,
      stats: {},
      my_followers: [],
      my_following: [],
      kin: [],
      settings: {
        perm_send_images: 0,
        perm_comment_posts: 0,
        perm_send_messages: 0,
      },
    },
    discourse_user: {},
    discourse_posts: [],
    discourse_topics: {},
    events: [],
    tab: 0,
    follower: false,
    following: false,
    kin_to_them: false,
    kin_from_them: false,
    blocking: false,
    blocked_by: false,
    has_forum_posts: true,
    has_events: true,
    avatar_url: null,
    avatar_asset_id: null,
    color_background: null,
    loading: true,
    found: true,
    page: 0,
  };
  const [values, setValues] = useState(initialValues);

  // // Context stuff should match Posts.jsx and Explore.jsx
  // let initialPostBrowseContext = {
  //   dialog_open: false,
  //   cur_post_id: -1,
  //   cur_post_index: -1,
  //   post_ids: [],
  // };

  // const [postBrowseContext, setPostBrowseContext] = useState(
  //   initialPostBrowseContext
  // );

  function selectTab(evt, val) {
    setValues((state) => ({
      ...state,
      tab: val,
    }));
  }

  function fetchUser(clear) {
    let lastIdFeed = "";
    if (!clear) {
      lastIdFeed = values.lastIdFeed;
    }

    let url = `/users/${username}.json?clear=${clear ? 'true' : 'false'}`
    if (lastIdFeed) {
      url += `&last_id=${lastIdFeed}`
    }

    $.ajax({
      url: url,
      type: "GET",
      async: window.rails_env != "test",
      success: function (data) {
        let tab = 0;
        if (data.events.length > 0) {
          tab = 2;
        }
        if (data.discourse_posts.length > 0) {
          tab = 1;
        }
        if (data.stories && data.stories.length > 0) {
          tab = 0;
        }

        // Set dt_start_friendly
        let events = data.events;
        for (let i = 0; i < events.length; i++) {
          events[i].dt_start_friendly = railsUTCtoFriendlyLocalTime(
            events[i].dt_start
          );
        }

        // Prepare stories.posts[]. <StoriesFeed expects each post to have a 'user' element
        // ToDo: this is not ideal due to duplication of data. Should be at story or top-level
        let stories = data.stories || [];
        for (let i = 0; i < stories.length; i++) {
          let story = stories[i]
          for (let j = 0; j < story.posts.length; j++) {
            let post = story.posts[j]
            post.user = data.user
          }
        }

        let lastIdFeed = "";
        for (let i = 0; i < stories.length; i++) {
          let id = stories[i].id
          if (!lastIdFeed || id < lastIdFeed) {
            lastIdFeed = id
          }
        }

        setValues((state) => ({
          ...state,
          stories: clear ? data.stories : values.stories.concat(data.stories),
          user: data.user,
          discourse_user: data.discourse_user,
          discourse_posts: data.discourse_posts,
          discourse_topics: data.discourse_topics,
          events: events,
          following: data.following,
          follower: data.follower,
          kin_to_them: data.kin_to_them,
          kin_from_them: data.kin_from_them,
          blocking: data.blocking,
          blocked_by: data.blocked_by,
          avatar_url: data.avatar_url,
          avatar_asset_id: data.avatar_asset_id,
          hasMore: stories.length > 0,
          lastIdFeed: lastIdFeed,
          found: true,
          loading: false,
          tab: tab,
        }));
      },
      error: function (data) {
        if (data.status == 404 || data.status == 401) {
          setValues((state) => ({
            ...state,
            ["found"]: false,
            ["loading"]: false,
          }));
        }
      },
      cache: false,
      contentType: false,
      processData: false,
    });    
  }

  useEffect(() => {
    fetchUser()
  }, [values.page, username]);

  // Clean up modals
  useEffect(() => {
    closeDialogProfilePic();
    closeDialogMyFollowers();
    closeDialogMyFollowing();
    closeDialogMyKin();
    // Should match: home_controller.rb#catch_all
    $("#title").text(username + " • KinkyKin");
    return function cleanup() {
      $("#title").text($("#title_orig").text());
    };
  }, [username]);

  // Read-more effect, jquery hack. See also: clickedReadMore
  useEffect(() => {
    if ($(".expandable-text").outerHeight() < 200) {
      // should match user.css
      $(".read-more").hide();
    }
  }, [values.user]);

  // // When the list of post_ids changes, notify the lower-level components such as AssetWide
  // useEffect(() => {
  //   let post_ids = [];
  //   for (let i = 0; i < values.posts.length; i++) {
  //     post_ids.push(values.posts[i].id);
  //   }
  //   setPostBrowseContext((state) => ({
  //     ...state,
  //     ["post_ids"]: post_ids,
  //   }));
  // }, [values.posts]);

  function follow() {
    utils.follow(values.user.id);
    setValues((state) => ({
      ...state,
      ["following"]: true,
    }));
  }

  function unfollow() {
    utils.unfollow(values.user.id);
    setValues((state) => ({
      ...state,
      ["following"]: false,
    }));
  }

  function add_kin() {
    utils.add_kin(values.user.id);
    setValues((state) => ({
      ...state,
      ["kin_to_them"]: true,
    }));
  }

  function unkin() {
    utils.unkin(values.user.id);
    setValues((state) => ({
      ...state,
      ["kin_to_them"]: false,
    }));
  }

  function block() {
    utils.block(values.user.id, () => {
      window.toastr.success("User blocked!");
      window.location.reload()
    });
  }

  function unblock() {
    utils.unblock(values.user.id, () => {
      window.toastr.success("User unblocked!");
      window.location.reload()
    });
  }

  function chunk(arr, len) {
    var chunks = [],
      i = 0,
      n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
  }

  // Profile pic dialog
  const [openDialogProfilePic, setOpenDialogProfilePic] = useState(false);

  function showDialogProfilePic() {
    setOpenDialogProfilePic(true);
  }

  function closeDialogProfilePic() {
    setOpenDialogProfilePic(false);
  }

  // My Followers dialog
  const [openDialogMyFollowers, setOpenDialogMyFollowers] = useState(false);

  function showDialogMyFollowers() {
    $.ajax({
      url: `/users/${values.user.id}/get_followers`,
      type: "GET",
      async: window.rails_env != "test",
      success: function (data) {
        let user = values.user;
        user.my_followers = data;

        setValues((state) => ({
          ...state,
          ["user"]: user,
        }));
      },
      cache: false,
      contentType: false,
    });
    setOpenDialogMyFollowers(true);
  }

  function closeDialogMyFollowers() {
    setOpenDialogMyFollowers(false);
  }

  // My Following dialog
  const [openDialogMyFollowing, setOpenDialogMyFollowing] = useState(false);

  function showDialogMyFollowing() {
    $.ajax({
      url: `/users/${values.user.id}/get_following`,
      type: "GET",
      async: window.rails_env != "test",
      success: function (data) {
        let user = values.user;
        user.my_following = data;

        setValues((state) => ({
          ...state,
          ["user"]: user,
        }));
      },
      cache: false,
      contentType: false,
    });
    setOpenDialogMyFollowing(true);
  }

  function closeDialogMyFollowing() {
    setOpenDialogMyFollowing(false);
  }

  // My Kin dialog
  const [openDialogMyKin, setOpenDialogMyKin] = useState(false);

  function showDialogMyKin() {
    $.ajax({
      url: `/users/${values.user.id}/get_kin`,
      type: "GET",
      async: window.rails_env != "test",
      success: function (data) {
        let user = values.user;
        user.my_kin = data;

        setValues((state) => ({
          ...state,
          ["user"]: user,
        }));
      },
      cache: false,
      contentType: false,
    });
    setOpenDialogMyKin(true);
  }

  function closeDialogMyKin() {
    setOpenDialogMyKin(false);
  }

  // Block dialog
  const [openDialogBlockUser, setOpenDialogBlockUser] = useState(false);

  function closeDialogBlockUser() {
    setOpenDialogBlockUser(false);
  }

  let permission_data = {
    following: values.following,
    follower: values.follower,
    kin_to_them: values.kin_to_them,
    kin_from_them: values.kin_from_them,
    blocking: values.blocking,
    blocked_by: values.blocked_by
  };
  let permission_data_reversed = {
    following: values.follower,
    follower: values.following,
    kin_to_them: values.kin_from_them,
    kin_from_them: values.kin_to_them,
  };

  function canMessageEachOther() {
    return (
      Can("send_messages", values.user.settings, permission_data) &&
      Can("send_messages", current_user.settings, permission_data_reversed)
    );
  }

  /* Message the user dialog stuff */
  const [openDialogNewConvo, setOpenDialogNewConvo] = useState(false);

  function showDialogNewConvo() {
    setOpenDialogNewConvo(true);
  }

  function closeDialogNewConvo() {
    setOpenDialogNewConvo(false);
  }
  // Compose stuff
  const [composeValues, setComposeValues] = useState({ draft: "" });

  // User is typing a message
  function handleChangeDraft(evt) {
    const { name, value } = evt.target;
    setComposeValues((state) => ({
      ...state,
      ["draft"]: value,
    }));
  }

  // If enter is pressed, send the message
  function handleKeyDown(evt) {
    const { name, value } = evt.target;

    if (evt.keyCode === 13 && !evt.shiftKey) {
      evt.preventDefault();

      if (sendEnabled()) {
        handleMessageSend();
      }
    }
  }

  function sendEnabled() {
    return composeValues.draft.length > 0;
  }

  function handleMessageSend() {
    let fd = new FormData();
    fd.append("to", values.user.object_id);
    fd.append("message", composeValues.draft);
    fd.append("authenticity_token", window.auth_token);
    $.ajax({
      url: "/api/v1/messaging/conversation/send_message_social",
      type: "POST",
      async: window.rails_env != "test",
      data: fd,
      success: function (data) {
        history.push(`/messages/${data.conversation_object_id}`);
      },
      error: function (data) {
        console.error(data);
        window.toastr.error("Error sending message");
      },
      cache: false,
      contentType: false,
      processData: false,
    });
  }

  let history = useHistory();

  function clickedReadMore() {
    let readMore = $(".read-more");

    let expandableText = $(".expandable-text");
    expandableText.animate({
      "max-height": 9999,
      duration: 700,
    });

    readMore.fadeOut();

    return false;
  }

  return (
    <div className={classes.container}>
      <div
        className={`${postClasses.container} ${postClasses.postContainer}`}
        style={{ display: !values.loading && !values.found ? "block" : "none" }}
      >
        <center>
          <img src="/404.svg" />

          <p>
            <a onClick={goBack}>Go Back</a>
          </p>
        </center>
      </div>
      <div
        style={{ display: !values.loading && values.found ? "block" : "none" }}
      >
        <Box className={classes.gridBox}>
          <Grid container direction="row" spacing={1} justify="center">
            <Grid item xs={4}>
              <Box
                pl={1}
                pb={1}
                pt={1}
                align="center"
                className={classes.desktop_only}
              >
                <div
                  className={values.avatar_url ? "hand" : ""}
                  onClick={values.avatar_url ? showDialogProfilePic : null}
                >
                  <UserAvatar
                    url={values.avatar_url}
                    border_color={values.user.color_background}
                    size="large"
                  />
                </div>
              </Box>
              <Box
                pl={1}
                pb={1}
                pt={1}
                align="center"
                className={classes.mobile_only}
              >
                <div onClick={values.avatar_url ? showDialogProfilePic : null}>
                  <UserAvatar
                    url={values.avatar_url}
                    border_color={values.user.color_background}
                    size="medium"
                  />
                </div>
              </Box>
            </Grid>
            <Grid item xs={8} pt={5}>
              <Box pt={2} pl={1}>
                <div
                  style={{ display: "inline", height: 32 }}
                  className={classes.username}
                >
                  {username} &nbsp;
                  <div style={{ float: "right" }}>
                    <Flag type="User" id={values.user.id} />
                  </div>
                </div>

                <Box mb={1} mt={1}>
                  {isMe && (
                    <Link to={`/settings`} component={RLink} color="inherit">
                      <Button
                        variant="outlined"
                        size="small"
                        pl={3}
                        variant="contained"
                      >
                        Edit Profile
                      </Button>
                    </Link>
                  )}

                  {
                    !isMe && values.blocking && (
                      <div style={{ display: "inline" }}>
                        <Button
                          id="button-unblock-modal"
                          className={classes.button}
                          onClick={unblock}
                          variant="outlined"
                          size="small"
                          pl={3}
                          variant="contained"
                        >
                          Unblock
                        </Button>
                      </div>
                    )
                  }

                  {
                    /* Another user */
                    !isMe && !values.blocking && (
                      <div style={{ display: "inline" }}>
                        {/* message this user dialog */}
                        <Dialog
                          id="dialog-new-conversation"
                          open={openDialogNewConvo}
                          keepMounted={false}
                          onClose={closeDialogNewConvo}
                          scroll="paper"
                          classes={{ paper: commonStyles.dialog300h }}
                        >
                          <div className={commonStyles.paddingLR}>
                            <Typography variant="h4">Send Message</Typography>

                            <p>
                              This user consents for you to message them. Be
                              sure not to betray their trust!
                            </p>
                          </div>

                          <Box display="flex" alignItems="center" mb={1}>
                            <Box ml={1}>
                              <Link
                                to={"/" + values.user.username}
                                component={RLink}
                                color="inherit"
                              >
                                <UserAvatar
                                  url={values.avatar_url}
                                  border_color={values.user.color_background}
                                  size="small"
                                />
                              </Link>
                            </Box>
                            <Box
                              ml={0.5}
                              component="span"
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              <div>
                                <Link
                                  to={"/" + values.user.username}
                                  component={RLink}
                                  color="inherit"
                                >
                                  <span>{values.user.username}</span>
                                </Link>
                              </div>
                            </Box>
                          </Box>

                          <div
                            style={{
                              position: "absolute",
                              bottom: 0,
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                            }}
                          >
                            <div style={{ flexGrow: 1, flexShrink: 1 }}>
                              <TextField
                                id="input-draft-message"
                                name="input-draft"
                                variant="outlined"
                                margin="none"
                                size="small"
                                onChange={handleChangeDraft}
                                onKeyDown={handleKeyDown}
                                value={composeValues.draft || ""}
                                inputProps={{
                                  autoComplete: "off",
                                  maxLength: 1500,
                                }}
                                multiline
                                rowsMax={10}
                                placeholder=".=[ be nice ]=."
                                fullWidth={true}
                                autoComplete="off"
                              />
                            </div>
                            <div style={{ flexBasis: 32, flexShrink: 1 }}>
                              {sendEnabled() && (
                                <img
                                  id="id-message-send-message"
                                  src="/static/icons/icon-send-message.svg"
                                  width={32}
                                  style={{ cursor: "pointer" }}
                                  onClick={handleMessageSend}
                                />
                              )}
                              {!sendEnabled() && (
                                <img
                                  id="id-message-send-message"
                                  src="/static/icons/icon-send-message-disabled.svg"
                                  width={32}
                                />
                              )}
                            </div>
                          </div>
                        </Dialog>

                        <Dialog
                          id="dialog-block-user"
                          open={openDialogBlockUser}
                          keepMounted={false}
                          onClose={closeDialogBlockUser}
                          scroll="paper"
                          classes={{ paper: commonStyles.dialog90 }}
                          aria-labelledby="simple-modal-title"
                          aria-describedby="simple-modal-description"
                        >
                          <div style={{ marginTop: 12, padding: 12 }}>
                            If you block this user:
                            <ul>
                              <li> Neither user will be able to see the other user's profile.</li>
                              <li> Neither profile will be able to send the other private messages.</li>
                              <li> Private messages between you two will be archived.</li>
                              <li> All comments and loves <b>{values.user.username}</b> made on your posts will be removed (and vice versa).</li>
                              <li> All pictures and videos in which you have each other tagged will be removed.</li>
                            </ul>

                            <Button
                              id="button-block-go"
                              fullWidth
                              variant="contained"
                              color="primary"
                              onClick={block}
                            >
                              Block
                            </Button>
                          </div>
                        </Dialog>

                        {canMessageEachOther() && values.convo_object_id && (
                          <Link
                            to={`/messages/${values.convo_object_id}`}
                            component={RLink}
                            color="inherit"
                          >
                            <Button
                              id="button-message"
                              className={classes.button}
                              variant="outlined"
                              size="small"
                              variant="contained"
                            >
                              Message
                            </Button>
                          </Link>
                        )}
                        {canMessageEachOther() && !values.convo_object_id && (
                          <Button
                            onClick={showDialogNewConvo}
                            id="button-message"
                            className={classes.button}
                            variant="outlined"
                            size="small"
                            variant="contained"
                          >
                            Message
                          </Button>
                        )}
                        {!canMessageEachOther() && (
                          <Button
                            id="button-message"
                            disabled
                            className={classes.button}
                            variant="outlined"
                            size="small"
                            variant="contained"
                          >
                            Message
                          </Button>
                        )}
                        {Can(
                          "follow",
                          values.user.settings,
                          permission_data
                        ) && (
                          <div style={{ display: "inline" }}>
                            <Button
                              id="button-follow"
                              className={classes.button}
                              onClick={follow}
                              variant="outlined"
                              size="small"
                              pl={3}
                              variant="contained"
                            >
                              Follow
                            </Button>
                          </div>
                        )}
                        {
                          /* Following but not kin */
                          Can(
                            "unfollow",
                            values.user.settings,
                            permission_data
                          ) && (
                            <div style={{ display: "inline" }}>
                              <Button
                                id="button-unfollow"
                                className={classes.button}
                                onClick={unfollow}
                                variant="outlined"
                                size="small"
                                pl={1}
                                variant="contained"
                              >
                                Unfollow
                              </Button>
                            </div>
                          )
                        }
                        {
                          /* Both following each other but not kin */
                          Can(
                            "add_kin",
                            values.user.settings,
                            permission_data
                          ) && (
                            <div style={{ display: "inline" }}>
                              <Button
                                id="button-add-kin"
                                className={classes.button}
                                onClick={add_kin}
                                variant="outlined"
                                size="small"
                                pl={3}
                                variant="contained"
                              >
                                + Kin
                              </Button>
                            </div>
                          )
                        }
                        {Can(
                          "unkin",
                          values.user.settings,
                          permission_data
                        ) && (
                          <div style={{ display: "inline" }}>
                            <Button
                              id="button-unkin"
                              className={classes.button}
                              onClick={unkin}
                              variant="outlined"
                              size="small"
                              pl={3}
                              variant="contained"
                            >
                              Unkin
                            </Button>
                          </div>
                        )}
                        {
                          !isMe && !values.blocking && (
                            <div style={{ display: "inline" }}>
                              <Button
                                id="button-block-modal"
                                className={classes.button}
                                onClick={() => setOpenDialogBlockUser(true)}
                                variant="outlined"
                                size="small"
                                pl={3}
                                variant="contained"
                              >
                                Block
                              </Button>
                            </div>
                          )
                        }

                        {
                          /* Another user */
                          !isMe && (
                            <div style={{ display: "inline" }}>
                              {!values.kin_to_them && values.kin_from_them && (
                                <p style={{ fontSize: ".8em" }}>
                                  {username} has requested to add you as 'kin',
                                  the highest trust level
                                </p>
                              )}
                              {values.kin_to_them && !values.kin_from_them && (
                                <p style={{ fontSize: ".8em" }}>
                                  You have requested to add {username} as 'kin',
                                  the highest trust level
                                </p>
                              )}
                            </div>
                          )
                        }
                      </div>
                    )
                  }
                </Box>

                <div>
                  {values.user.pronouns != "n / a" && (
                    <div>
                      <span id="pronouns">
                        {values.user.pronouns && values.user.pronouns}{" "}
                        &nbsp;&nbsp;{" "}
                      </span>
                      <br />
                    </div>
                  )}
                  {values.user.country && (
                    <span id="location">
                      {values.user.city && (
                        <span>
                          {values.user.city + (values.user.region ? ", " : "")}
                        </span>
                      )}
                      {values.user.region && (
                        <span>
                          {values.user.region +
                            (values.user.country &&
                            values.user.country != "United States"
                              ? ", "
                              : "")}
                        </span>
                      )}
                      {values.user.country &&
                        values.user.country != "United States" && (
                          <span>{values.user.country}</span>
                        )}
                    </span>
                  )}
                </div>

                <div>
                  <span id="website">
                    {values.user.website && (
                      <a href={values.user.website} className={classes.url}>{values.user.website}</a>
                    )}
                  </span>
                </div>

                {
                  !values.blocking && (
                    <div>
                      <span>
                        <b>{values.user.stats.posts}</b>&nbsp;post
                        {values.user.stats.posts != 1 ? "s" : ""} &nbsp;
                      </span>

                      <div>
                        <span>
                          <a className="hand" onClick={showDialogMyKin}>
                            <b>{values.user.stats.kin}</b>&nbsp;kin &nbsp;
                          </a>
                        </span>
                        <span>
                          <a className="hand" onClick={showDialogMyFollowing}>
                            <b>{values.user.stats.following}</b>&nbsp;following
                            &nbsp;
                          </a>
                        </span>
                        <span>
                          <a className="hand" onClick={showDialogMyFollowers}>
                            <b>{values.user.stats.followers}</b>&nbsp;follower
                            {values.user.stats.followers != 1 ? "s" : ""} &nbsp;
                          </a>
                        </span>
                      </div>

                      <span id="member-since">
                        since&nbsp;{values.user.created_at}
                      </span>
                    </div>
                  )
                }
              </Box>
            </Grid>
          </Grid>

          {values.user.bio && (
            <div className={`expandable-text`}>
              <span
                dangerouslySetInnerHTML={{
                  __html: values.user.bio.replace(/(?:\r\n|\r|\n)/g, "<br />"),
                }}
              />
              <p
                className="read-more hand"
                onClick={() => {
                  clickedReadMore("desktop");
                }}
              >
                Read More
              </p>
            </div>
          )}

          {
            !isMe && values.blocking && (
              <center>
                <p><b><font color="red">You are blocking this user.</font></b></p>
              </center>
            )
          }

          {(values.stories.length > 0 ||
            values.events.length > 0) && (
            <div>
              <br />
              <AppBar position="static" color="transparent" elevation={2}>
                <Tabs
                  classes={{
                    root: commonStyles.tabsRoot,
                    scroller: commonStyles.tabsScroller,
                  }}
                  variant="fullWidth"
                  value={values.tab}
                  onChange={selectTab}
                  indicatorColor="primary"
                  textColor="primary"
                  scrollButtons="auto"
                >
                  <Tab
                    label="Stories"
                    id="index-0"
                    style={{
                      display: values.stories.length > 0 ? "block" : "none",
                    }}
                  />
                  {/* <Tab label="Groups" id="index-1" style={ { 'display': (values.discourse_posts.length > 0 ? 'block' : 'none') } } /> */}
                  <Tab
                    label="Events"
                    id="index-2"
                    style={{
                      display: values.stories.length > 0 ? "block" : "none",
                    }}
                  />
                </Tabs>
              </AppBar>

              <TabPanel value={values.tab} index={0}>
                {/*<PostBrowseContext.Provider
                  value={[postBrowseContext, setPostBrowseContext]}
                >
                  <PostBrowseDialog posts={values.posts} />*/}
                  {/* ToDo: InfiniteScroll this B */}
                  {/* Posts */}
                  <StoriesFeed
                    key={`user-${values.user.id}`}
                    tab="tag" history={history}
                    stories={values.stories}
                    hasMore={values.hasMore}
                    loaded={!values.loaded}
                    fetchData={fetchUser}
                  />
                {/*</PostBrowseContext.Provider>*/}
              </TabPanel>

              {/* Groups
                <TabPanel value={values.tab} index={1}>
                  <br />

                  <Typography variant="h6" component="h6">
                    Groups Trust Level: <b>{values.discourse_user.trust_level_string}</b>
                  </Typography>
                  <br />

                  <div>
                    {
                      values.discourse_posts.map((discourse_post, idx_row) => (
                        <Card key={discourse_post.id} variant="outlined" className={classes.card}>
                          <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                              { dayjs(discourse_post.last_version_at).format('MMM D, h:mm A') }
                            </Typography>
                            <Typography variant="h6" component="h6">
                              <RLink to={`/t/slug/${discourse_post.topic_id}#pn${discourse_post.post_number}`}>{discourse_post.raw}</RLink>
                            </Typography>
                            <p>
                              In topic: {(values.discourse_topics[discourse_post.topic_id] || {}).title}
                            </p>
                          </CardContent>
                        </Card>
                      ))
                    }
                  </div>
                  <br />
                  <br />
                  <br />
                </TabPanel>*/}

              <TabPanel value={values.tab} index={1}>
                <div
                  style={{
                    width: "100%",
                    maxWidth: 600,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  {values.events.map(function (event, index) {
                    let event_jsx = (
                      <EventAbbrev event={event} key={event.id} />
                    );
                    return event_jsx;
                  })}
                </div>
              </TabPanel>
            </div>
          )}
          {values.stories.length == 0 &&
            values.events.length == 0 && !values.blocking && (
              <div style={{ padding: 12 }}>
                <center>
                  {
                    window.current_user?.username &&
                      <Typography>Nothing to see here...yet</Typography>
                  }
                  {
                    !window.current_user?.username &&
                      <Typography>You are not signed in. Please <a href="/">sign in</a> or <a href="/registration/sign_up">sign up</a> to see this user.</Typography>
                  }
                </center>
              </div>
            )}
        </Box>

        <Dialog
          id="dialog-profile-pic"
          open={openDialogProfilePic}
          keepMounted={false}
          onClose={closeDialogProfilePic}
          classes={{ paper: classes.dialogFullScreen }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <img
            src={`/assets/${values.avatar_asset_id}/url?size=640&redirect=true`}
            className={classes.image_wide}
          />
        </Dialog>

        <Dialog
          id="dialog-my-followers"
          open={openDialogMyFollowers}
          keepMounted={false}
          onClose={closeDialogMyFollowers}
          scroll="paper"
          classes={{ paper: commonStyles.dialog90 }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={{ marginTop: 12 }}>
            <UserList
              show_follow={false}
              users={values.user.my_followers || []}
            />
          </div>
        </Dialog>

        <Dialog
          id="dialog-my-following"
          open={openDialogMyFollowing}
          keepMounted={false}
          onClose={closeDialogMyFollowing}
          scroll="paper"
          classes={{ paper: commonStyles.dialog90 }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={{ marginTop: 12 }}>
            <UserList
              show_follow={false}
              users={values.user.my_following || []}
            />
          </div>
        </Dialog>

        <Dialog
          id="dialog-my-kin"
          open={openDialogMyKin}
          keepMounted={false}
          onClose={closeDialogMyKin}
          scroll="paper"
          classes={{ paper: commonStyles.dialog90 }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={{ marginTop: 12 }}>
            <UserList show_follow={false} users={values.user.my_kin || []} />
          </div>
        </Dialog>
      </div>
      <Loading loading={values.loading} />
    </div>
  );
}
