import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  flexRow: {
    'display': 'flex',
    'flexDirection': 'row' /* left to right */
  },
  flexCol: {
    'display': 'flex',
    'flexDirection': 'column' /* top to bottom */
  },
  flexRowCenter: {
    'display': 'flex',
    'alignItems': 'center',
    'flexDirection': 'row' /* left to right */
  },
  flexColCenter: {
    'display': 'flex',
    'alignItems': 'center',
    'flexDirection': 'column' /* top to bottom */
  },
  iconMiddle: {
    verticalAlign: 'middle'
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2)
  },
  container: {
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: "614px !important",
    marginTop: 16,
    marginBottom: 16,
    paddingTop: 12,
    paddingBottom: 12
  },
  containerNoPadding: {
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: "614px !important",
    marginTop: 0,
    marginBottom: 16,
    padding: 0
  },
  containerNarrow: {
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
    maxWidth: "514px",
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 8,
    marginBottom: 8,
    paddingTop: 8,
    paddingLeft: 0,
    paddingRight: 0
  },
  dialog90: {
    width: '90%',
    height: '90%',
    margin: 'auto',
    maxWidth: 320
  },
  dialog500: {
    width: '90%',
    height: '90%',
    margin: 'auto',
    maxHeight: 500,
    maxWidth: 500
  },
  dialog300h: {
    width: '90%',
    height: '90%',
    margin: 'auto',
    maxHeight: 500,
    maxWidth: 500
  },
  dialog90Max100Minus64: {
    width: '90%',
    height: '90%',
    margin: 'auto',
    maxWidth: "calc(100% - 64px)"
  },
  tableCell: {
    padding: 1
  },
  paddingLR: {
    paddingLeft: 8,
    paddingRight: 8
  },
  tabsRoot: { // for centered + scrollable tabs. See: https://codesandbox.io/s/material-ui-centered-scrollable-tabs-ud26w
    justifyContent: "center"
  },
  tabsScroller: {
    flexGrow: "0"
  },
  noMarginTop: {
    marginTop: 0
  },
  desktopOnlyBlockSm: { // 600
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  mobileOnlyBlockSm:{
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  desktopOnlyBlock: { // 960
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  mobileOnlyBlock:{
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  desktopOnlyFlex: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  mobileOnlyFlex:{
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  pill: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    border: '1px solid',
    borderColor: theme.palette.text.disabled,
    padding: "6px 10px",
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    marginRight: 13,
    marginLeft: 0,
    marginBottom: 0,
    borderRadius: 8
  }
}));