import React, { useEffect, useState, useContext, useCallback, useReducer } from "react";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import * as PostService from "../../services/posts.service";
import { useTheme } from '@material-ui/core/styles';
import Post from "components/posts/Post";
import EditTexts from "components/stories/EditTexts";
import EditBlurs from "components/stories/EditBlurs";
import EditStickers from "components/stories/EditStickers";
import useInterval from "vendor/useInterval";
import { CreatePostContext } from "components/posts/CreatePostContext";
// See: https://github.com/ricardo-ch/react-easy-crop
import Cropper from "react-easy-crop";
import { getCroppedImg, getRotatedImage } from "../../vendor/canvasUtils";
import {
  Slider,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormControl,
} from "@material-ui/core";
import FilterButtons from "image_editor/FilterButtons.jsx";
import TopMenu from "components/stories/TopMenu";
import Decorator from "components/stories/Decorator";
// See: https://github.com/alexkatz/react-tiny-popover
import { Popover } from 'react-tiny-popover'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Filterous2 from "vendor/filterous2";
import cloneCanvas from "image_editor/clone_canvas.js";
import StoryStyles from "components/styles/StoryStyles";

import { Draggable, Sticker, Blur, Text } from "image_editor/draggables.js";
import { imageXTouch, imageYTouch } from "image_editor/x_y_fix.jsx";

import { flushSync } from 'react-dom';

export default function EditDecoration(props) {
  const storyClasses = StoryStyles();

  let [context, setContext] = useContext(CreatePostContext);

  let initialValues = {
    dialogFilterShow: true,
    dialogTextsShow: false,
    dialogBlursShow: false,
    dialogStickersShow: false
  };
  const [values, setValues] = useState(initialValues);

  // Hack: forces context / state update, this lets us avoid setTimeouts
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(
    function () {
      window.contextDecorator = {
        touchX: null,
        touchY: null,
        canvasFiltered: null,
        canvasWithoutSelectedDraggable: null
      }

      // Start by drawing canvasCropped (from EditCrop.jsx), apply filters and show any draggables if they exist in context
      applyFilterAndDrawDraggables(context.currentFilter, context.canvasCropped)
    },
    []
  );

  function nextStep() {
    setContext((state) => ({
      ...state,
      loading: true
    }));

    setTimeout(function() {
      finalize(1080, 1080).then((canvas_resized) => {
        let stepIdx = context.steps.indexOf(context.step)
        setContext((state) => ({
          ...state,
          canvasFinal: canvas_resized,
          loading: false,
          step: context.steps[stepIdx + 1]
        }));
      });
    }, 50)
  }

  function prevStep() {
    let stepIdx = context.steps.indexOf(context.step)
    setContext((state) => ({
      ...state,
      step: context.steps[stepIdx - 1]
    }));
  }

  function openMenu(menu) {
    setValues((state) => ({
      ...state,
      dialogFilterShow: menu == 'filters',
      dialogTextsShow: menu == 'texts',
      dialogBlursShow: menu == 'blurs',
      dialogStickersShow: menu == 'stickers'
    }));

    setContext(state => ({
      ...state,
      currentMenu: menu,
    }));
  }

  function bgStyles() {
    return `linear-gradient(171deg, ${context.background.colorA} 0%, ${context.background.colorB} 100%)`
  }

  /************************************************************************
   * 
   * Filters-related functionality
   * 
   ************************************************************************/
  const filters = [ 'normal', 'clarendon', 'gingham', 'moon', 'lark', 'reyes', 'juno', 'slumber', 'crema', 'ludwig', 'aden', 'perpetua', 'amaro', 'mayfair', 'rise', 'hudson', 'valencia', 'xpro2', 'sierra', 'willow', 'lofi', 'inkwell', 'hefe', 'nashville', 'stinson', 'vesper', 'earlybird', 'brannan', 'sutro', 'toaster', 'walden', '1977', 'kelvin', 'maven', 'ginza', 'skyline', 'dogpatch', 'brooklyn', 'helena', 'ashby', 'charmes' ]

  const handleChangeFilter = (event) => {
    let val = event.target.id

    setContext(state => ({
      ...state,
      currentFilter: val
    }));

    let options = {
      scale: 1,
      format: "png",
    };

    applyFilterAndDrawDraggables(val)
  }

  function applyFilterAndDrawDraggables(val, canvasOrig) {
    // Apply filter to canvasCropped from the last step
    let filteredImage = Filterous2.importImage(null, cloneCanvas(context.canvasCropped || canvasOrig));
    filteredImage.applyInstaFilter(val);
    let filteredCanvas = filteredImage.canvas

    let canvas = document.getElementById('canvas-preview')

    if (canvas) {
      var contextPreview = canvas.getContext('2d');
      canvas.width = filteredCanvas.width;
      canvas.height = filteredCanvas.height;
      //context.translate(0.5, 0.5);
      contextPreview.drawImage(filteredCanvas, 0, 0);
    }

    window.contextDecorator.canvasFiltered = filteredCanvas
    window.contextDecorator.canvasWithoutSelectedDraggable = filteredCanvas

    // Each time we update the filter, we must then re-draw all draggables according to their state on `context`
    drawAllDraggablesAfterContextUpdate()
  }

  /************************************************************************
   * 
   * Text/blur/stickers-related functionality
   * 
   ************************************************************************/

  function drawAllDraggablesAfterContextUpdate() {
    forceUpdate()
    drawAllDraggables();
  }

  function drawAllDraggables(finalize) {
    console.warn("clickedText", context.clickedText)
    // console.warn("stickers", context.stickers)
    // console.warn("blurs", context.blurs)
    // console.warn("texts", context.dragTexts)
    drawDraggables(context.blurs, true);
    drawDraggables(context.stickers, true);
    drawDraggables(context.dragTexts, true);

    if (!finalize) {
      if (context.currentMenu == "blurs" && context.clickedBlur) {
        context.clickedBlur.markSelected();
      }
      if (context.currentMenu == "stickers" && context.clickedSticker) {
        context.clickedSticker.markSelected();
      }
      if (context.currentMenu == "texts" && context.clickedText) {
        context.clickedText.markSelected();
      }
    }
  }

  function drawDraggables(draggables, erase) {
    for (let i = 0; i < draggables.length; i++) {
      let draggable = draggables[i];

      if (erase) {
        draggable.erase(window.contextDecorator.canvasWithoutSelectedDraggable);
      }
      draggable.draw(null, null, false);
    }
  }

  // Called by parent CreatePost.jsx object
  function finalize(maxWidth, maxHeight) {
    if (!maxWidth || !maxHeight) {
      throw "maxWidth and maxHeight not specified";
    }

    return new Promise((resolve, reject) => {
      let numImages = 0;

      let upload = context.upload;
      // for all editable images
      if (upload.mimeType == "image/jpeg" || upload.mimeType == "image/png") {
        applyAllEdits(true);

        //resolve($("#canvas-preview")[0])
        // // Resize to fit e.g. scale down
        let finalized_canvas = $("#canvas-preview")[0];
        let canvas_resized = document.createElement("canvas");
        canvas_resized.width = finalized_canvas.width;
        canvas_resized.height = finalized_canvas.height;
        let context_resized = canvas_resized.getContext("2d");
        context_resized.drawImage(finalized_canvas, 0, 0, finalized_canvas.width, finalized_canvas.height);

        resolve(canvas_resized)
      }
    });
  };

  function applyAllEdits(final) {
    drawCanvasToDOM(window.contextDecorator.canvasFiltered);
    drawAllDraggables(final);
  }

  const onMouseDown = function (reactEvt) {
    let evt = reactEvt.nativeEvent
    console.log("onmousedown", reactEvt)

    if (evt.target.id != "canvas-preview") {
      return false;
    }

    let x = 0;
    let y = 0;
    x = imageXTouch(evt);
    y = imageYTouch(evt);

    // Because touchup doesn't include x/y
    window.contextDecorator.touchX = x
    window.contextDecorator.touchY = y

    let draggables = [];
    let clicked = null;

console.log("context.currentMenu", context.currentMenu)

    if (context.currentMenu == "blurs") { // blur
      let clickedBlur = Draggable.clicked(x, y, context.blurs);
console.log("clickedBlur", clickedBlur)
      if (clickedBlur) {
        clickedBlur.dragging = true;
        clickedBlur.diffX = x - clickedBlur.x; // # pixels to the right of the blur's left edge
        clickedBlur.diffY = y - clickedBlur.y; // # pixels to the bottom of the blur's top edge

        // Take a 'snapshot' of the canvas with the selected blur removed
        // Store it in: canvasWithoutSelectedDraggable
        // For use when moving the object: it erases the background correctly re: overlap
        let blurs = context.blurs.filter(
          (draggable) => draggable != clickedBlur
        );
        takeSnapshot(context.dragTexts, context.stickers, blurs);

        blurs.push(clickedBlur)

        // Clicked Blur is now at the end of the array, so it's "on top" of the draw order

        setContext((state) => ({
          ...state,
          clickedBlur: clickedBlur,
          currentBlurSize: clickedBlur.size,
          blurs: blurs
        }));
      } else {
        // Tell the menu we clicked a blur (or deslected it)
        setContext((state) => ({
          ...state,
          clickedBlur: clickedBlur
        }));
      }

      drawAllDraggablesAfterContextUpdate()

      if (context.clickedSticker) {
        context.clickedSticker.markSelected();
      }
    }

    if (context.currentMenu == "stickers") {
      let clickedSticker = Draggable.clicked(x, y, context.stickers);

      if (clickedSticker) {
        clickedSticker.dragging = true;
        clickedSticker.diffX = x - clickedSticker.x;
        clickedSticker.diffY = y - clickedSticker.y;

        let stickers = context.stickers.filter(
          (draggable) => draggable != clickedSticker
        );
        takeSnapshot(context.dragTexts, stickers, context.blurs);

        stickers.push(clickedSticker);

        setContext((state) => ({
          ...state,
          currentStickerSize: clickedSticker.size,
          clickedSticker: clickedSticker,
          stickers: stickers
        }));
      } else {
        setContext((state) => ({
          ...state,
          clickedSticker: null
        }));
      }

      drawAllDraggablesAfterContextUpdate()

      if (context.clickedSticker) {
        context.clickedSticker.markSelected();
      }

      //drawAllDraggablesAfterContextUpdate()
    }

    if (context.currentMenu == "texts") {
      let clickedText = Draggable.clicked(x, y, context.dragTexts);

      if (clickedText) {
        // Take the clicked text out of the image
        let textS = context.dragTexts.filter(
          (draggable) => draggable != clickedText
        );

console.log("001", "textS.length", textS.length)

        clickedText.dragging = true;
        clickedText.diffX = x - clickedText.x;
        clickedText.diffY = y - clickedText.y;

        takeSnapshot(textS, context.stickers, context.blurs);

        textS.push(clickedText)

        setContext((state) => ({
          ...state,
          clickedText: clickedText,
          textS: textS
        }));
      } else {
        setContext((state) => ({
          ...state,
          clickedText: null
        }));
      }

      drawAllDraggablesAfterContextUpdate()
    }

    evt.preventDefault();
  };

  /*****************
   * onMouseMove
   *****************/

  let prevMoveX = 0;
  let prevMoveY = 0;
  const onMouseMove = function (reactEvt) {
    let evt = reactEvt.nativeEvent
    //console.log("move context", evt)

    if (evt.target.id != "canvas-preview") {
      console.error("oops")
      return false;
    }

    let x = 0;
    let y = 0;
    if (
      context.currentMenu == "blurs" ||
      context.currentMenu == "stickers" ||
      context.currentMenu == "texts"
    ) {
      x = imageXTouch(evt);
      y = imageYTouch(evt);
//console.log("001", x, y)
      if (Math.abs(prevMoveX - x) < 12 && Math.abs(prevMoveY - y) < 12) {
        // If small move, don't redraw
        return;
      }

      prevMoveX = x;
      prevMoveY = y;
//console.log("002", prevMoveX, prevMoveY)

      window.contextDecorator.touchX = x
      window.contextDecorator.touchY = y
      // setContext((state) => ({
      //   ...state,
      //   touchX: x,
      //   touchY: y,
      // }));
    }

    if (
      context.currentMenu == "stickers" &&
      context.clickedSticker &&
      context.clickedSticker.dragging
    ) {
      // "Erase" old blur by reapplying filtered canvas
      context.clickedSticker.erase(
        window.contextDecorator.canvasWithoutSelectedDraggable
      );

      let diffX = context.clickedSticker.diffX;
      let diffY = context.clickedSticker.diffY;

      context.clickedSticker.x = x - diffX;
      context.clickedSticker.y = y - diffY;

      context.clickedSticker.draw();
      context.clickedSticker.markSelected();

      //draw()
    }

    if (
      context.currentMenu == "blurs" &&
      context.clickedBlur &&
      context.clickedBlur.dragging
    ) {
      // "Erase" old blur by reapplying filtered canvas
      context.clickedBlur.erase(
        window.contextDecorator.canvasWithoutSelectedDraggable
      );

      let diffX = context.clickedBlur.diffX;
      let diffY = context.clickedBlur.diffY;
      context.clickedBlur.x = x - diffX;
      context.clickedBlur.y = y - diffY;

      context.clickedBlur.draw();
      context.clickedBlur.markSelected();

      //draw()
    }

//console.log("003", context.clickedText)

    if (
      context.currentMenu == "texts" &&
      context.clickedText &&
      context.clickedText.dragging
    ) {
      // "Erase" old text by reapplying filtered canvas
      context.clickedText.erase(
        window.contextDecorator.canvasWithoutSelectedDraggable, 100
      );
//console.log("004", context.canvasWithoutSelectedDraggable)

      let diffX = context.clickedText.diffX;
      let diffY = context.clickedText.diffY;
//console.log("005", diffX, diffY)

      context.clickedText.x = x - diffX;
      context.clickedText.y = y - diffY;

//console.log("006", diffX, diffY)
      context.clickedText.draw();
      context.clickedText.markSelected();

      //draw()
    }

    evt.preventDefault();
  };

  /*****************
   * onMouseUp
   *****************/

  const onMouseUp = function (reactEvt) {
    let evt = reactEvt.nativeEvent
    console.log("onMouseUp", evt)
    if (evt.target.id != "canvas-preview") {
      return false;
    }

    let x = 0;
    let y = 0;
    if (
      context.currentMenu == "blurs" ||
      context.currentMenu == "stickers" ||
      context.currentMenu == "texts"
    ) {
      if (evt.touches) {
        x = window.contextDecorator.touchX;
        y = window.contextDecorator.touchY;
      } else {
        x = imageXTouch(evt);
        y = imageYTouch(evt);
      }

      if (context.currentMenu == "blurs") {
        if (context.clickedBlur && context.clickedBlur.dragging) {
          let clickedBlur = context.clickedBlur
          clickedBlur.dragging = false
          setContext((state) => ({
            ...state,
            clickedBlur: clickedBlur
          }));

          drawAllDraggablesAfterContextUpdate()
        }
      }

      if (context.currentMenu == "stickers") {
        if (
          context.clickedSticker &&
          context.clickedSticker.dragging
        ) {
          let clickedSticker = context.clickedSticker
          clickedSticker.dragging = false
          setContext((state) => ({
            ...state,
            clickedSticker: clickedSticker
          }));

          drawAllDraggablesAfterContextUpdate()
        }
      }

      if (context.currentMenu == "texts") {
        if (context.clickedText && context.clickedText.dragging) {

          let clickedText = context.clickedText
          clickedText.dragging = false

          setContext((state) => ({
            ...state,
            clickedText: clickedText
          }));

          // Redraw original canvas on destination
          let destCanvas = $("#canvas-preview")[0];
          let destCtx = destCanvas.getContext("2d");
          destCtx.drawImage(window.contextDecorator.canvasWithoutSelectedDraggable, 0, 0);

          drawAllDraggablesAfterContextUpdate()
        }

        // Fixes a weirdness on mobile where a mouseup sends focus back to the input, showing the keyboard
        $('#text-content').blur()
      }
    }
    evt.preventDefault();
  };

  function takeSnapshot(textS, stickers, blurs) {
    console.warn("=+= takeSnapshot canvasFiltered", textS)
    drawCanvasToDOM(window.contextDecorator.canvasFiltered);
    //drawAllDraggables(true);
    drawDraggables(textS, true);
    drawDraggables(stickers, true);
    drawDraggables(blurs, true);

    let tmpCanvas = cloneCanvas(
      $("#canvas-preview")[0]
    )
console.log("== snapshot stickers", stickers)
    window.contextDecorator.canvasWithoutSelectedDraggable = tmpCanvas

    drawCanvasToDOM(window.contextDecorator.canvasFiltered);
    //}, 50)
  }

  function drawCanvasToDOM(cv) {
    // if (!cv) {
    //   return
    // }

    // Destination is a <canvas> element on the page
    let destCanvas = $("#canvas-preview")[0];
    destCanvas.width = cv.width;
    destCanvas.height = cv.height;
    let destCtx = destCanvas.getContext("2d");

    // Redraw original canvas on destination
    destCtx.drawImage(cv, 0, 0);
  }



  let heightBottomControls = 55
  let shadow = "-1px 1px 0 #777, 1px 1px 0 #777, 1px -1px 0 #777, -1px -1px 0 #777"

  return (
    <React.Fragment>
      <TopMenu next={nextStep} prev={prevStep} exitCallback={props.exitCallback} />

      {
        values.dialogTextsShow && (
          <div
            id='menu-texts'
            style={ 
              {
                position: 'absolute',
                color: '#FFF',
                background: 'rgb(0,0,0,0.7)',
                width: '100%',
                left: 0,
                /* top: 0, Up top instead of the bottom of the page bc the react font picker only goes down */
                bottom: 60,
                height: 120,
                padding: 8,
                zIndex: 20000
              } }
          >
            <EditTexts redraw={drawAllDraggablesAfterContextUpdate} />
          </div>
        )
      }

      {
        values.dialogStickersShow && (
          <div style={
            {
              position: 'absolute',
              height: 110,
              color: '#FFF',
              background: 'rgb(0,0,0,0.7)',
              width: '100%',
              left: 0,
              bottom: 4 + 55,
              zIndex: 20000
            } } >
            <div id='menu-stickers'>
              <EditStickers redraw={drawAllDraggablesAfterContextUpdate} />
            </div>
          </div>
        )
      }

      {
        values.dialogBlursShow && (
          <div style={
            {
              position: 'absolute',
              height: 110,
              color: '#FFF',
              background: 'rgb(0,0,0,0.7)',
              width: '100%',
              left: 0,
              bottom: 4 + 55,
              zIndex: 20000
            } } >
            <div id='menu-blurs'>
              <EditBlurs redraw={drawAllDraggablesAfterContextUpdate} />
            </div>
          </div>
        )
      }

        { /* Decorator.jsx handles the logic of drawing all of the texts/stickers/blurs */ }
        {/*<Decorator />*/}

      <div className={storyClasses.showContainer} style={ { height: `calc(100vh - ${props.heightTopMenu}px)`, background: bgStyles() } }>
        { /* { width: context.containerMaxWidth, height: context.containerMaxHeight } */ }
        <div className={storyClasses.canvasContainer}> {/*, display: 'flex'*/}
          <canvas
            id="canvas-preview"
            style={{ maxWidth: "100%", maxHeight: '100%', margin: 'auto' }}
            draggable="false"
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseMove={onMouseMove}
            onTouchStart={onMouseDown}
            onTouchEnd={onMouseUp}
            onTouchMove={onMouseMove}
          />
        </div>

      </div>


      {
        values.dialogFilterShow && (
          <div style={ { position: 'absolute', height: `calc(100vh - ${props.heightTopMenu}px)`, right: 12, top: 40, paddingTop: 4, overflowY: 'auto' } } >
            <div id='menu-filters'>
              {
                filters.map(filter => {
                  let selected = '';
                  if (filter == context.currentFilter) {
                    selected = 'filter-selected'
                  }
                  return (
                    <div key={filter}>
                      <div style={ { position: 'absolute', width: '100%', textShadow: shadow, color: (filter == context.currentFilter ? 'yellow' : '#FFF'), fontWeight: (filter == context.currentFilter ? 900 : 700), fontSize: '.85em' }}>
                        <center>
                          {filter}
                        </center>
                      </div>
                      <img width={70} height={70} className={`hand ${selected}`} src={`/static/filters/filter-${filter}.jpeg`} id={filter} key={filter} onClick={handleChangeFilter} />
                    </div>                          
                  )
                })
              }
            </div>
          </div>
        )
      }

      <div className={storyClasses.controls} style={ { bottom: 0, height: heightBottomControls } }>
        <div className="hand" onClick={() => { openMenu('filters') }} style={{ width: 36, height: 36, marginLeft: 12, background: 'rgb(0,0,0,0.55)', borderRadius: '50%', display: 'flex' }}>
          {
            values.dialogFilterShow && (
              <img id="toggle-filters-img" src="/stories/filters-active.svg" height='22' style={ { margin: 'auto' } } />
            )
          }
          {
            !values.dialogFilterShow && (
              <img id="toggle-filters-img" src="/stories/filters-select.svg" height='22' style={ { margin: 'auto' } } />
            )
          }
        </div>

        <div className="hand" onClick={() => { openMenu('texts') }} style={{ width: 36, height: 36, marginLeft: 12, background: 'rgb(0,0,0,0.55)', borderRadius: '50%', display: 'flex' }}>
          {
            values.dialogTextsShow && (
              <img id="toggle-texts-img" src="/stories/texts-active.svg" height='22' style={ { margin: 'auto' } } />
            )
          }
          {
            !values.dialogTextsShow && (
              <img id="toggle-texts-img" src="/stories/texts-select.svg" height='22' style={ { margin: 'auto' } } />
            )
          }
        </div>

        <div className="hand" onClick={() => { openMenu('stickers') }} style={{ width: 36, height: 36, marginLeft: 12, background: 'rgb(0,0,0,0.55)', borderRadius: '50%', display: 'flex' }}>
          {
            values.dialogStickersShow && (
              <img id="toggle-stickers-img" src="/stories/stickers-active.svg" height='22' style={ { margin: 'auto' } } />
            )
          }
          {
            !values.dialogStickersShow && (
              <img id="toggle-stickers-img" src="/stories/stickers-select.svg" height='22' style={ { margin: 'auto' } } />
            )
          }
        </div>

        <div className="hand" onClick={() => { openMenu('blurs') }} style={{ width: 36, height: 36, marginLeft: 12, background: 'rgb(0,0,0,0.55)', borderRadius: '50%', display: 'flex' }}>
          {
            values.dialogBlursShow && (
              <img id="toggle-blurs-img" src="/stories/blurs-active.svg" height='22' style={ { margin: 'auto' } } />
            )
          }
          {
            !values.dialogBlursShow && (
              <img id="toggle-blurs-img" src="/stories/blurs-select.svg" height='22' style={ { margin: 'auto' } } />
            )
          }
        </div>
      </div>

    </React.Fragment>
  );
}