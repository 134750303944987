import React, { useState, useContext } from "react";
import { Box } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { LocationContext } from "components/profile/LocationContext";
import { useTheme } from "@material-ui/core";

export default function Location() {
  const theme = useTheme();

  const [regionData, setRegionData] = useState(window.region_data);

  let [locationContext, setLocationContext] = useContext(LocationContext);

  //console.log("initialValues", initialValues)
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  // Form values state
  //const [values, setValues] = useState({});

  /*
     1. Parent sets the initial values in locationContext after retrieving profile
     2. Load regions/cities, when done set the values
   */
  React.useEffect(
    (val1, val2) => {
      if (locationContext.country_id) {
        refreshRegions(locationContext.country_id);
      }
      if (locationContext.region_id) {
        refreshCities(locationContext.region_id);
      }
    },
    [locationContext]
  );

  // update regionData state with the regions for country_id
  function refreshRegions(country_id) {
    //console.log("== refresh regions", country_id)
    if (!regionData["regions"][country_id]) {
      $.ajax({
        method: "GET",
        async: window.rails_env != "test",
        url: `/country-data?country_id=${country_id}`,
      }).done(function (data) {
        regionData["regions"][country_id] = data;
        setRegionData(regionData);
        forceUpdate();
      });
    }
  }

  // update regionData state with the cities for region_id
  function refreshCities(region_id, callback) {
    //console.log("== refresh cities", region_id)
    if (!regionData["cities"][region_id]) {
      $.ajax({
        method: "GET",
        async: window.rails_env != "test",
        url: `/country-data?region_id=${region_id}`,
      }).done(function (data) {
        regionData["cities"][region_id] = data;
        setRegionData(regionData);
        if (callback) {
          callback();
        }
        forceUpdate();
      });
    }
  }

  const handleChangeCountry = (event) => {
    console.log("event", event);
    let value = event.target.value;

    let text = $("#select-country").find(":selected").text();
    setLocationContext((state) => ({
      ...state,
      ["country_id"]: value,
      ["country"]: text,
      ["region_id"]: "",
      ["region"]: "",
      ["city_id"]: "",
      ["city"]: "",
    }));

    refreshRegions(value);
  };

  const handleChangeRegion = (event) => {
    let value = event.target.value;

    let text = $("#select-region").find(":selected").text();
    setLocationContext((state) => ({
      ...state,
      ["region_id"]: value,
      ["region"]: text,
      ["city_id"]: "",
      ["city"]: "",
    }));

    refreshCities(value);
  };

  let handleChangeCity = (event) => {
    let target = event.target;
    let value = target.value;
    let text = $("#select-city").find(":selected").text();
    setLocationContext((state) => ({
      ...state,
      ["city_id"]: value,
      ["city"]: text,
    }));
  };

  function getHash() {
    return {
      country: locationContext.country_id,
      region: locationContext.region_id,
      city: locationContext.city_id,
      country: locationContext.country,
      region: locationContext.region,
      city: locationContext.city,
    };
  }

  return (
    <div>
      <Box>
        {" "}
        {/*  mt={4} */}
        <FormControl variant="outlined" fullWidth={true}>
          <b id="country"><span style={ { color: theme.palette.text.primary } }>Country:</span></b>
          <Select
            native
            fullWidth
            id="select-country"
            name="country_id"
            value={locationContext.country_id}
            onChange={handleChangeCountry}
          >
            <option value={""} key={""}></option>
            {regionData["countries"].map((country) => {
              return (
                <option value={country.id} key={country.id}>
                  {country.name}
                </option>
              );
            })}
          </Select>
        </FormControl>
      </Box>

      <Box mt={2}>
        <FormControl variant="outlined" fullWidth={true}>
          <b id="region"><span style={ { color: theme.palette.text.primary } }>State / Province / Region:</span></b>
          <Select
            native
            fullWidth
            id="select-region"
            name="region_id"
            value={locationContext.region_id}
            onChange={handleChangeRegion}
          >
            <option value={""} key={""}></option>
            {(regionData["regions"][locationContext.country_id] || []).map(
              (region) => {
                return (
                  <option value={region.id} key={region.id}>
                    {region.name}
                  </option>
                );
              }
            )}
          </Select>
        </FormControl>
      </Box>

      <Box mt={2}>
        <FormControl variant="outlined" fullWidth={true}>
          <b id="city"><span style={ { color: theme.palette.text.primary } }>Nearest City:</span></b>
          <Select
            native
            fullWidth
            id="select-city"
            name="city_id"
            value={locationContext.city_id}
            onChange={handleChangeCity}
          >
            <option value={""} key={""}></option>
            {(regionData["cities"][locationContext.region_id] || []).map(
              (city) => {
                return (
                  <option value={city.id} key={city.id}>
                    {city.name}
                  </option>
                );
              }
            )}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
}
