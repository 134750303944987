import React from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CommonStyles from "components/styles/CommonStyles";

const useStyles = makeStyles({
  root: {
    maxWidth: 271,
  },
  media: {
    height: 180,
  },
  gradient: {
    top: 0,
    height: "100%",
    width: "100%",
    position: "absolute",
    backgroundImage: "transparent",
    backgroundImage:
      "-webkit-linear-gradient(rgba(15,20,31,0) 0,rgba(15,20,31,.1) 20%,rgba(15,20,31,.8) 100%)",
    backgroundImage:
      "linear-gradient(rgba(15,20,31,0) 0,rgba(15,20,31,.1) 20%,rgba(15,20,31,.8) 100%)",
  },
});

// The 'thumbnail' version of the community's data, for displaying in lists
export default function CommunityAbbrev(props) {
  const commonClasses = CommonStyles();
  const classes = useStyles();

  let community = props.community;

  let history = useHistory();

  function handleClickCard() {
    history.push("/community/" + community.id);
  }

  return (
    <div
      onClick={handleClickCard}
      className="hand"
      style={{
        width: "100%",
        marginBottom: 8,
        position: "relative",
        color: "white",
      }}
    >
      <img
        src={community.image_url || "/default_community_640.png"}
        style={{ maxWidth: "100%", maxHeight: "66%" }}
      />

      <div className={classes.gradient} />
      <div style={{ position: "absolute", bottom: 4, left: 8 }}>
        <h3 style={{ marginTop: 2, marginBottom: 2 }}>{community.name}</h3>
        <h4 style={{ marginTop: 2, marginBottom: 2 }}>{community.tagline}</h4>
        <h5 style={{ marginTop: 2, marginBottom: 2 }}>
          {community.has_location ? `${community.city}, ${community.region}` : "Virtual"}
        </h5>
        <h5 style={{ marginTop: 2, marginBottom: 2 }}>
          {community.num_members} members
          {
            community.num_events > 0 &&
              <span>
              {" "}| {community.num_events} events
              </span>
          }
          {
            community.num_posts > 0 &&
              <span>
              {" "}| {community.num_posts} posts
              </span>
          }
        </h5>
      </div>
    </div>
  );
}
