import React, { useState, useEffect, useRef, useContext } from "react";
import $ from "jquery";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useParams, Link as RLink } from "react-router-dom";
import TabPanel from "components/TabPanel";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Switch from "@material-ui/core/Switch";
import { TextField, Button } from "@material-ui/core";
import CommonStyles from "components/styles/CommonStyles";
import IOSSlider from "components/IOSSlider";
import Profile from "components/settings/Profile";
//import Invites from "components/settings/Invites";
import NewUser from "components/onboarding/NewUser";
import { ThemeContext } from "components/ThemeContext";
import { useTheme } from "@material-ui/core";
import Content from "components/settings/Content";

import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    maxWidth: "614px !important",
    paddingLeft: 0,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
}));

export default function Settings(props) {
  const classes = useStyles();
  const commonStyles = CommonStyles();
  const theme = useTheme();

  let urlParams = useParams();
  let tabNum = 0;
  if (urlParams.tab == "filters") {
    tabNum = 1;
  }
  if (urlParams.tab == "consent") {
    tabNum = 2;
  }
  if (urlParams.tab == "security") {
    tabNum = 3;
  }

  const [tab, setTab] = React.useState(tabNum);

  let [themeContext, setThemeContext] = useContext(ThemeContext);

  const handleChangeTab = (event, newTab) => {
    setTab(newTab);
  };

  const handleCallToRouter = (value) => {
    props.history.push(value);
  };

  let value = 1;
  const marks = [
    {
      value: 0,
      label: "Kin",
      caption: `Only those you add as 'kin'`,
    },
    {
      value: 1,
      label: "Following",
      caption: `Kin and people you are following`,
    },
    {
      value: 2,
      label: "Followers",
      caption: `Kin, those you're following and your followers`,
    },
    {
      value: 3,
      label: "Everyone",
      caption: `Absolutely anyone on the site!`,
    },
  ];

  const initialValues = window.current_user.settings;
  const [values, setValues] = useState(initialValues);
  const [timeoutSave, setTimeoutSave] = useState(null);

  const initialValuesPw = {
    old_password: "",
    new_password: "",
    new_password_confirmation: "",
  };
  const [valuesPw, setValuesPw] = useState(initialValuesPw);

  const MS_UNTIL_SAVE = 2000;

  function sliderChange(slider_field, value) {
    if (values[slider_field] != value) {
      setValues((state) => ({
        ...state,
        [slider_field]: parseInt(value),
      }));
    }
  }

  // User flipped a switch
  function switchChange(switch_field, value) {
    let arr = switch_field.split(".");
    let typ = arr[0]; // e.g. alerts
    let fld = arr[1]; // e.g. comments
    let hsh = values[typ];

    if (hsh[fld] != value) {
      hsh[fld] = value;

      setValues((state) => ({
        ...state,
        [typ]: hsh,
      }));
    }
  }

  const handleChangePw = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    let required = event.target.required;

    setValuesPw((state) => ({
      ...state,
      [name]: value,
    }));
  };

  function save() {
    let settings = {
      perm_send_messages: parseInt(values.perm_send_messages),
      perm_send_images: parseInt(values.perm_send_images),
      alerts: values.alerts,
    };

    // Should match: user_controller.rb#save_settings, user.rb#DEFAULT_PREFS
    $.ajax({
      url: "/users/save_settings",
      type: "POST",
      async: window.rails_env != "test",
      data: { settings: settings, authenticity_token: window.auth_token },
      success: function (data) {
        window.onbeforeunload = null;
      },
    });
  }

  const didMountRef = useRef(false);

  // Save if we haven't yet
  useEffect(() => {
    if (didMountRef.current) {
      clearTimeout(timeoutSave);
      setTimeoutSave(
        setTimeout(() => {
          save();
        }, MS_UNTIL_SAVE)
      );
      window.onbeforeunload = function () {
        return true;
      };
    } else {
      didMountRef.current = true;
    }
  }, [values]);

  // Save if we haven't yet
  useEffect(() => {
    return function cleanup() {
      if (timeoutSave) {
        clearTimeout(timeoutSave);
        save();
      }
    };
  }, []);

  function deleteAccount(evt) {
    if (confirm("Are you absolutely sure? There's no going back!")) {
      alert(
        "OK! Here goes. If you have a lot of posts this may take several minutes -- please don't hit the button more than once."
      );
      return true;
    }
    evt.preventDefault();
    return false;
  }

  function imageClass() {
    return (themeContext.type == "dark" ? "no-dark-mode" : "")
  }

  //console.log("comment props", props)
  return (
    <div className={classes.root}>
      <AppBar position="static" color="inherit" elevation={1}>
        <Tabs
          classes={{
            root: commonStyles.tabsRoot,
            scroller: commonStyles.tabsScroller,
          }}
          variant="fullWidth"
          value={tab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
        >
          <Tab component={RLink} label="Profile" to="/settings/profile" />
          <Tab component={RLink} label="Filters" to="/settings/filters" />
          <Tab component={RLink} label="Consent" to="/settings/consent" />
          <Tab component={RLink} label="Security" to="/settings/security" />
        </Tabs>
      </AppBar>
      <div className={classes.container} style={ { color: theme.palette.text.primary } }>
        <TabPanel value={tab} index={0} style={{ padding: 12 }}>
          <Profile history={props.history} />
        </TabPanel>
        <TabPanel value={tab} index={1} style={{ paddingLeft: 12, paddingRight: 12 }}>
          <Content />
        </TabPanel>
        <TabPanel value={tab} index={2} style={{ padding: 12 }}>
          <center>
            <Typography color="textPrimary">
              <img
                className={ imageClass() }
                src="/static/icons/messages.svg"
                style={{ height: "24px", "vertical-align": "middle" }}
              />
              &nbsp; Who do I consent to send me private messages?
            </Typography>
            <p style={{ fontSize: ".8em" }}>
              {marks[values.perm_send_messages].caption}
            </p>
            <IOSSlider
              value={values.perm_send_messages}
              onChange={(event, value) =>
                sliderChange("perm_send_messages", value)
              }
              min={0}
              max={3}
              step={1}
              aria-labelledby="discrete-slider-custom"
              valueLabelDisplay="off"
              marks={marks}
              style={{ marginLeft: 12, marginRight: 24, width: "80%" }}
            />
          </center>

          <center>
            <br />
            <br />
            <Typography color="textPrimary">
              <img
                className={ imageClass() }
                src="/static/icons/camera.svg"
                style={{ height: "24px", "vertical-align": "middle" }}
              />
              &nbsp; Who can PM me images and videos?
            </Typography>
            <p style={{ fontSize: ".8em" }}>
              {marks[values.perm_send_images].caption}
            </p>
            <IOSSlider
              value={values.perm_send_images}
              onChange={(event, value) =>
                sliderChange("perm_send_images", value)
              }
              min={0}
              max={3}
              step={1}
              aria-labelledby="discrete-slider-custom"
              valueLabelDisplay="off"
              marks={marks}
              style={{ marginLeft: 12, marginRight: 24, width: "80%" }}
            />
          </center>

          <center>
            <br />
            <br />
            <Typography color="textPrimary">
              <img
                className={ imageClass() }
                src="/static/icons/alerts.svg"
                style={{ height: "24px", "vertical-align": "middle" }}
              />
              &nbsp; Which notifications are OK?
            </Typography>
            <br />
          </center>

          <TableContainer width="100%">
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell align="right" className={commonStyles.tableCell}>
                    Messages:
                  </TableCell>
                  <TableCell className={commonStyles.tableCell}>
                    <Switch
                      checked={values.alerts.messages}
                      onChange={(event, value) =>
                        switchChange("alerts.messages", value)
                      }
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </TableCell>
                  <TableCell align="right" className={commonStyles.tableCell}>
                    Group Messages:
                  </TableCell>
                  <TableCell className={commonStyles.tableCell}>
                    <Switch
                      checked={values.alerts.group_messages}
                      onChange={(event, value) =>
                        switchChange("alerts.group_messages", value)
                      }
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" className={commonStyles.tableCell}>
                    Hearts:
                  </TableCell>
                  <TableCell className={commonStyles.tableCell}>
                    <Switch
                      checked={values.alerts.likes}
                      onChange={(event, value) =>
                        switchChange("alerts.likes", value)
                      }
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </TableCell>
                  <TableCell align="right" className={commonStyles.tableCell}>
                    Comments:
                  </TableCell>
                  <TableCell className={commonStyles.tableCell}>
                    <Switch
                      checked={values.alerts.comments}
                      onChange={(event, value) =>
                        switchChange("alerts.comments", value)
                      }
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" className={commonStyles.tableCell}>
                    New Followers:
                  </TableCell>
                  <TableCell className={commonStyles.tableCell}>
                    <Switch
                      checked={values.alerts.new_followers}
                      onChange={(event, value) =>
                        switchChange("alerts.new_followers", value)
                      }
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </TableCell>
                  <TableCell align="right" className={commonStyles.tableCell}>
                    Kin Requests:
                  </TableCell>
                  <TableCell className={commonStyles.tableCell}>
                    <Switch
                      checked={values.alerts.add_kin}
                      onChange={(event, value) =>
                        switchChange("alerts.add_kin", value)
                      }
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" className={commonStyles.tableCell}>
                    New Events:
                  </TableCell>
                  <TableCell className={commonStyles.tableCell}>
                    <Switch
                      checked={values.alerts.new_events}
                      onChange={(event, value) =>
                        switchChange("alerts.new_events", value)
                      }
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </TableCell>
                  <TableCell align="right" className={commonStyles.tableCell}>
                    Event Reminders:
                  </TableCell>
                  <TableCell className={commonStyles.tableCell}>
                    <Switch
                      checked={values.alerts.event_reminders}
                      onChange={(event, value) =>
                        switchChange("alerts.event_reminders", value)
                      }
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </TableCell>
                  <TableCell align="right" className={commonStyles.tableCell}>
                    &nbsp;
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel
          value={tab}
          index={3}
          style={{ paddingLeft: 12, paddingRight: 12 }}
        >
          <p>
            <b>Change Password:</b>
          </p>

          <form action="/passwords" method="POST">
            <input
              name="authenticity_token"
              type="hidden"
              value={window.auth_token}
            />
            <input type="hidden" name="_method" value="PUT" />

            <TextField
              variant="outlined"
              margin="normal"
              required
              style={{ width: "50%" }}
              id="input-password-old"
              label="Old Password"
              name="old_password"
              type="password"
              value={valuesPw.old_password}
              onChange={handleChangePw}
              autoComplete="password"
              size="small"
            />
            <br />
            <TextField
              variant="outlined"
              margin="normal"
              required
              style={{ width: "50%" }}
              id="input-password-new"
              label="New Password"
              name="new_password"
              type="password"
              value={valuesPw.new_password}
              onChange={handleChangePw}
              autoComplete="off"
              size="small"
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              style={{ width: "50%" }}
              id="input-password-new"
              label="New Password (again)"
              name="new_password_confirmation"
              type="password"
              value={valuesPw.new_password_confirmation}
              onChange={handleChangePw}
              autoComplete="off"
              size="small"
            />

            <Button
              id="btn-change-password"
              type="submit"
              disabled={
                valuesPw.old_password == "" ||
                valuesPw.new_password == "" ||
                valuesPw.new_password_confirmation == "" ||
                valuesPw.new_password != valuesPw.new_password_confirmation
              }
              variant="contained"
              color="primary"
            >
              Change Password
            </Button>
          </form>

          <br />
          <p>
            <b>Delete Account:</b>
          </p>

          <form action="/delete_account" method="POST" onSubmit={deleteAccount}>
            <input
              name="authenticity_token"
              type="hidden"
              value={window.auth_token}
            />
            <input type="hidden" name="_method" value="PUT" />

            <p>
              If you've decided that KinkyKin is not for you, click the scary
              red button to delete your account.
            </p>

            <p>
              All of your posts, messages and other data will be permanently
              deleted without possibility of recovery.
            </p>

            <Button
              id="btn-delete-account"
              type="submit"
              variant="contained"
              color="secondary"
            >
              Delete Account
            </Button>
            <br />
            <br />
            <br />
          </form>
        </TabPanel>
      </div>
    </div>
  );
}
