import React, { useState, useEffect, useContext } from "react";
import $ from "jquery";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CommonStyles from "components/styles/CommonStyles";
import { ThemeContext } from "components/ThemeContext";

export default function Settings(props) {
  const commonStyles = CommonStyles();

  let [themeContext, setThemeContext] = useContext(ThemeContext);

  let initialValues = {
    step: 0,
    flaggable_id: props.id,
    flaggable_type: props.type,
    description: "",
    flag_type: null,
    selected_flag_type: {},
  };
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    setValues((state) => ({
      ...state,
      flaggable_id: props.id,
    }));
  }, [props.id]);

  // Flag dialog
  const [openDialogFlag, setOpenDialogFlag] = useState(false);

  function handleMemberClick() {
    setOpenDialogFlag(true);
  }

  function closeDialogFlag() {
    setOpenDialogFlag(false);
  }

  let flag_types = window.flag_types;

  function setFlagType(flag_type) {
    setValues((state) => ({
      ...state,
      step: 1,
      flag_type: flag_type.num,
      selected_flag_type: flag_type,
    }));
  }

  function setStep(num) {
    setValues((state) => ({
      ...state,
      step: num,
    }));
  }

  const handleChangeDescription = (event) => {
    let value = event.target.value;

    setValues((state) => ({
      ...state,
      description: value,
    }));
  };

  function submit() {
    // ajax
    setValues((state) => ({
      ...state,
      step: 0,
    }));

    let data = values;
    data.authenticity_token = window.auth_token;

    $.ajax({
      url: "/flag",
      type: "POST",
      async: window.rails_env != "test",
      data: data,
      success: function (data) {
        if (data.success) {
          //console.log("Success!!", data);
        } else {
          window.toastr.error(data.error);
        }
      },
      error: function (data) {
        window.toastr.error(data.error);
      },
    });

    closeDialogFlag();
    window.toastr.success(
      "Your flag has been sent to moderators. Thank you for helping keep Kin safe!"
    );
  }

  function cancel() {
    closeDialogFlag();
  }

  function imageClass() {
    return (themeContext.type == "dark" ? "no-dark-mode" : "")
  }

  return (
    <span>
      <Dialog
        id="dialog-flag"
        open={openDialogFlag}
        keepMounted={false}
        onClose={closeDialogFlag}
        scroll="paper"
        classes={{ paper: commonStyles.dialog90 }}
      >
        <div
          style={{
            marginTop: 4,
            marginBottom: 8,
            marginLeft: 8,
            marginRight: 8,
          }}
        >
          <h3>Flag {values.flaggable_type}</h3>

          {values.step == 0 && (
            <div>
              Which of our community guidelines does this{" "}
              {values.flaggable_type.toLowerCase()} violate?{" "}
              <a
                target="_new"
                href="/content_guidelines"
              >
                <u><font color="#5271ff">Content Guidelines</font></u>
              </a>
              {flag_types.map((flag_type) => (
                <div key={flag_type.name}>
                  <h4>
                    <a
                      className="flag-type hand"
                      onClick={() => setFlagType(flag_type)}
                    >
                      <u><font color="#5271ff">
                        {flag_type.name}
                      </font></u>
                    </a>
                  </h4>
                  {flag_type.description}
                </div>
              ))}
            </div>
          )}

          {values.step == 1 && (
            <div>
              <div className={commonStyles.flexRow}>
                <div style={{ flexGrow: 1 }}>
                  Flagging for: <b>{values.selected_flag_type.name}</b>
                </div>
                <div style={{ flexBasis: "32px" }}>
                  <a onClick={() => setStep(0)}>Go&nbsp;Back</a>
                </div>
              </div>
              <div>
                <h4>Additional Context:</h4>

                <p>
                  If there is additional information about this{" "}
                  {values.flaggable_type.toLowerCase()} that you think Kin's
                  moderators should know before making a decision, please enter
                  it below.
                </p>

                <TextField
                  id="input-description"
                  multiline={true}
                  rows={10}
                  value={values.description}
                  error={false}
                  onChange={handleChangeDescription}
                  inputProps={{
                    autoComplete: "off",
                  }}
                  fullWidth
                  size="small"
                  variant="outlined"
                  margin="normal"
                />

                <Button
                  id="btn-submit-flag"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={submit}
                >
                  Flag {values.flaggable_type.toLowerCase()}
                </Button>

                <br />
                <br />
                <center>
                  <a onClick={cancel}>
                    <font color="red">Cancel</font>
                  </a>
                </center>
              </div>
            </div>
          )}
        </div>
      </Dialog>

      <img
        id={`flag-${props.type}-${props.id}`}
        onClick={handleMemberClick}
        className={`hand ${imageClass()}`}
        src="/static/icons/flag.svg"
        width={24}
        height={24}
      />
    </span>
  );
}
