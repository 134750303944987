import React, { useState, useContext, useEffect } from "react";
import { Link as RLink } from "react-router-dom";
import { Link } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import Cookies from "vendor/js.cookie.min.mjs";
import PageDescription from "components/PageDescription";
import StoriesFeed from "components/stories/StoriesFeed";
import Connections from "components/home/Connections";
import PostStyles from "components/styles/PostStyles";
import SearchUsers from "components/SearchUsers";
import TabPanel from "components/TabPanel";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Switch from "@material-ui/core/Switch";
import platform from "components/pwa/platform";
import PWAPrompt from "react-ios-pwa-prompt";
import CommonStyles from "components/styles/CommonStyles";
import useInterval from "vendor/useInterval";
import Trending from "components/explore/Trending";
import * as PostService from "../../services/posts.service";
import { ThemeContext } from "components/ThemeContext";
import StoryThumbs from "components/stories/StoryThumbs";
import Content from "components/settings/Content";
import { railsUTCtoFriendlyLocalTime } from "services/dates.service.js";

import TabList from '@material-ui/lab/TabList';

export default function Home(props) {
  const classes = PostStyles();
  const commonStyles = CommonStyles();
  const theme = useTheme();

  // must match posts_controller.rb#STORIES_PER_PAGE
  const STORIES_PER_PAGE = 20;

  let history = props.history;

  let [themeContext, setThemeContext] = useContext(ThemeContext);

  let defaultVariant = 'like'
  if (window.current_user.stats.following == 0) {
    defaultVariant = 'random'
  }

  /* Home State */
  const initialValues = {
    feedVariant: Cookies.get("feedVariant") || defaultVariant,
    settingsMenuOpen: (Cookies.get("settingsMenuOpen") ? Cookies.get("settingsMenuOpen") == 'true' : true),
    storiesFollowing: [],
    hasMoreFollowing: true,
    loadedFollowing: false,
    lastIdFollowing: "",
    showPwaDialog: false,
    // showSearchDialog: false,
    showSettingsDialog: false,
    storiesFeed: [],
    hasMoreFeed: true,
    loadedFeed: false,
    lastIdFeed: "",
    lastQualityFeed: "",
    current_user_avatar_url: "",
    current_user_story: {}
  };
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    fetchDataFeed(true);
    fetchDataFollowing(true);
  }, []);

  /* PWA Stuff */
  let initialPwaValues = {
    showInstallPwa: false,
    showIosPrompt: false,
    platform: platform.getPlatform(),
    isMobile: platform.isMobile(),
    iOS: platform.iOS(),
  };

  const [pwaValues, setPwaValues] = useState(initialPwaValues);

  useInterval(() => {
    // See:
    // https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent
    if (window.deferredPrompt && !pwaValues.showInstallPwa) {
      setPwaValues((state) => ({
        ...state,
        showInstallPwa: true,
      }));
    }
  }, 150);

  function installAppMobileSafari() {
    setPwaValues((state) => ({
      ...state,
      showIosPrompt: true,
    }));
  }

  function installAppChromium() {
    // Show the prompt
    window.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    window.deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the A2HS prompt");
      } else {
        console.log("User dismissed the A2HS prompt");
      }
      window.deferredPrompt = null;
      window.location.reload();
    });
  }

  function allowNotifications() {
    Notification.requestPermission(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        console.log("Permission to receive notifications has been granted");
      }
      window.location.reload();
    });
  }

  let urlReenableNotifs =
    "https://www.google.com/search?q=unblock+notifications";
  let urlDisableNotifs =
    "https://www.google.com/search?q=disable+notifications";
  if (pwaValues.isMobile) {
    urlReenableNotifs += "+mobile";
    urlDisableNotifs += "+mobile";
  }
  if (pwaValues.platform.isChromium) {
    urlReenableNotifs += "+chrome";
    urlDisableNotifs += "+chrome";
  } else if (pwaValues.platform.isFireFox) {
    urlReenableNotifs += "+firefox";
    urlDisableNotifs += "+firefox";
  } else if (pwaValues.platform.isOpera) {
    urlReenableNotifs += "+opera";
    urlDisableNotifs += "+opera";
  } else if (pwaValues.platform.isEdge) {
    urlReenableNotifs += "+edge";
    urlDisableNotifs += "+edge";
  } else if (pwaValues.platform.isMobileSafari) {
    urlReenableNotifs += "+safari";
    urlDisableNotifs += "+safari";
  } else if (pwaValues.platform.isSamsung) {
    urlReenableNotifs += "+samsung+internet";
    urlDisableNotifs += "+samsung+internet";
  }
  /* /PWA Stuff */

  function toggleTheme(event, value) {
    let type = (value == true ? "dark" : "light")
    setThemeContext((state) => ({
      ...state,
      type: type
    }));

    Cookies.set("lastTheme", type, { expires: 7 });
  }

  // ToDo: we get an error for this... needed still?
  // useEffect(() => {
  //   // Hasn't yet followed 3 users
  //   if (window.current_user?.stats?.following < 3) {
  //     const interval = setInterval(() => {
  //       // Keep trying every 2 seconds until following is 3 then stop
  //       if (window.current_user?.stats?.following == 3) {
  //         // Force a re-render
  //         setThemeContext((state) => ({
  //           ...state
  //         }));
  //         clearInterval(interval)
  //       }
  //     }, 2000);
  //   }
  //   return () => {
  //     if (interval) {
  //       clearInterval(interval)
  //     }
  //   };
  // }, []);   

  function openPwaDialog() {
    if (values.showPwaDialog) {
      closeDialogs()
      return
    }

    setValues((state) => ({
      ...state,
      showPwaDialog: true,
      // showSearchDialog: false,
      showSettingsDialog: false
    }));
  }

  function closeDialogs() {
    setValues((state) => ({
      ...state,
      showPwaDialog: false,
      // showSearchDialog: false,
      showSettingsDialog: false
    }));
  } 

  // function openSearchDialog() {
  //   if (values.showSearchDialog) {
  //     closeDialogs()
  //     return
  //   }

  //   setValues((state) => ({
  //     ...state,
  //     showPwaDialog: false,
  //     showSearchDialog: true,
  //     showSettingsDialog: false
  //   }));
  // }

  function openSettingsDialog() {
    if (values.showSettingsDialog) {
      closeDialogs()
      return
    }

    setValues((state) => ({
      ...state,
      showPwaDialog: false,
      // showSearchDialog: false,
      showSettingsDialog: true
    }));
  }
  /* /PWA Stuff */

  let fetchDataFeed = (clear, variantOverride) => {
    let lastIdFeed = "";
    if (!clear) {
      lastIdFeed = values.lastIdFeed;
    }

    let lastQualityFeed = "";
    if (!clear) {
      lastQualityFeed = values.lastQualityFeed;
    }

    if (clear) {
      setValues((state) => ({
        ...state,
        loadedFeed: false
      }));
    }

    let variant = variantOverride || values.feedVariant

    //let url = `/stories.json?post_tab=${props.tab}`
    let url = `/stories/feed.json?post_variant=${variant}&clear=${clear ? 'true' : 'false'}`
    if (lastIdFeed) {
      url += `&last_id=${lastIdFeed}`
    }
    if (lastQualityFeed) {
      url += `&last_quality=${lastQualityFeed}`
    }
    if (props.tab == "community") {
      url += `&community_id=${props.community_id}`
    }
    $.ajax({
      url: url,
      type: "GET",
      async: window.rails_env != "test",
      success: function (data) {
        let lastIdFeed = "";
        for (let i = 0; i < data.stories.length; i++) {
          let id = data.stories[i].id
          if (!lastIdFeed || id < lastIdFeed) {
            lastIdFeed = id
          }
        }

        let lastQualityFeed = "";
        for (let i = 0; i < data.stories.length; i++) {
          let quality_avg = data.stories[i].quality_avg
          if (!lastQualityFeed || quality_avg < lastQualityFeed) {
            lastQualityFeed = quality_avg
          }
        }

        setValues((state) => ({
          ...state,
          storiesFeed: clear ? data.stories : values.storiesFeed.concat(data.stories),
          lastIdFeed: lastIdFeed,
          lastQualityFeed: lastQualityFeed,
          hasMoreFeed: data.stories.length > 0,
          loadedFeed: true,
        }));
        PostService.disableRightClick();
      },
      cache: false,
      contentType: false,
      processData: false,
    });
  };

  let fetchDataFollowing = (clear, post_category) => {
    // let last_id = "";
    // if (!clear) {
    //   last_id = values.last_id;
    // }

    let url = `/stories/feed.json?post_variant=following&i=24`
    $.ajax({
      url: url,
      type: "GET",
      async: window.rails_env != "test",
      success: function (data) {
        //console.warn("DATA FOLLOWING", data)
        // let last_id = "";
        // if (data.posts.length > 0) {
        //   last_id = data.posts[data.posts.length - 1].id;
        // }

        // Set dt_start_friendly for 'event' posts
        //console.warn("== TODO: event posts")
        let stories = data.stories
        for (let i = 0; i < stories.length; i++) {
          let story = stories[i]
          let posts = story.posts;
          for (let j = 0; j < posts.length; j++) {
            let post = posts[j];
            let event = post.event;
            if (event) {
              event.dt_start_friendly = railsUTCtoFriendlyLocalTime(
                event.dt_start
              );
            }
          }
        }

          //last_id: last_id,

        setValues((state) => ({
          ...state,
          storiesFollowing: data.stories,
          current_user_story: data.current_user_story,
          current_user_avatar_url: data.current_user_avatar_url,
          hasMoreFollowing: false, // data.posts_count_orig == STORIES_PER_PAGE,
          loadedFollowing: true,
        }));
        PostService.disableRightClick();
      },
      cache: false,
      contentType: false,
      processData: false,
    });
  };

  function selectFeedVariant(event, newTab) {
    let val = newTab

    Cookies.set("feedVariant", val, { expires: 7 });

    setValues((state) => ({
      ...state,
      loadedFeed: false,
      feedVariant: val
    }))

    setTimeout(() => {
      fetchDataFeed(true, val)
    }, 50)
  }

  function imageClass() {
    return (themeContext.type == "dark" ? "no-dark-mode" : "")
  }

  function toggleSettingsMenuOpen() {
    // Shows again every 3 days
    Cookies.set("settingsMenuOpen", !values.settingsMenuOpen, { expires: 3 });

    setValues((state) => ({
      ...state,
      settingsMenuOpen: !values.settingsMenuOpen
    }));
  }

  return (
    <div>

      <div
        className={`${classes.container} ${classes.postContainer} noselect`}
        id="stories-container"
        style={{ paddingTop: 0 }}
      >
        {
          window.current_user.stats.following == 0 && (
            <div style={ { padding: 8 } }>
              Welcome to KinkyKin! Please <font color="#5271ff">follow</font> 3 or more kinksters below to see their stories.
            </div>
          )
        }
        {
          // Code path for create story:
          // StoryThumbs -> CreateStoryIcon -> CreatePost -> EditCrop
          window.current_user.stats.following > 0 && (
            <StoryThumbs
              stories={values.storiesFollowing}
              current_story={values.current_user_story}
              current_user_avatar_url={values.current_user_avatar_url}
              hasMore={values.hasMoreFollowing}
              loaded={values.loadedFollowing}
              fetchData={fetchDataFollowing}
            />
          )
        }
      </div>

{/* PWA Debugging Stuff */}
{/*
        [{window.navigator.userAgent}]
        [{ JSON.stringify(pwaValues.platform, null, '\t') }]
        [iOS: {JSON.stringify(pwaValues.isMobileSafari)}, isStandalone: {JSON.stringify(pwaValues.isStandalone)}, isMobile: {JSON.stringify(pwaValues.isMobile)}]
*/}

      <div
        className={`${classes.container} ${classes.postContainer} noselect`}
        style={{
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 4,
          paddingBottom: 4,
          marginTop: 16,
          minHeight: 22
        }}
      >
        <div style={{ float: 'right' } }>
          {
            values.settingsMenuOpen && (
              <img className="hand" src="/static/icons/close-red.svg" onClick={toggleSettingsMenuOpen} height={24} />
            )
          }
          {
            !values.settingsMenuOpen && (
              <img className={`hand ${imageClass()}`} src="/static/icons/icon-phone.svg" onClick={toggleSettingsMenuOpen} height={24} />
            )
          }
        </div>

        <div className={commonStyles.flexRowCenter} style={ { paddingBottom: 4 } }>
          <Switch
            edge="start"
            size="small"
            id='switch-dark-mode'
            checked={themeContext.type == "dark"}
            onChange={toggleTheme}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
          &nbsp;Dark Mode
        </div>

        {
          values.settingsMenuOpen && (
            <div>
              <p>KinkyKin has a Progessive Web App which you can configure below.</p>

              {
                // Group 1: Install kin as an app
                // Only for non-firefox Chromium browsers
                // Skip if we're already in the PWA
                !pwaValues.platform.isStandalone && (
                  <div>
                    {
                      // Case 1: mobile android, webview android, opera android, samsung internet
                      // see: https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent#browser_compatibility
                      pwaValues.platform.isChromium &&
                        !pwaValues.platform.isMobileFireFox && (
                          <div>
                            {!pwaValues.showInstallPwa && (
                              <div style={{ color: "#777", paddingBottom: 8 }}>
                                <b>Install Kin</b> as an app on your{" "}
                                {pwaValues.isMobile ? "Android" : "Chrome"} home
                                screen.{" "}
                              </div>
                            )}
                            {pwaValues.showInstallPwa && (
                              <div style={{ paddingBottom: 8 }}>
                                <a onClick={installAppChromium}>Install Kin</a> as
                                an app on your{" "}
                                {pwaValues.isMobile ? "Android" : "Chrome"} home
                                screen.{" "}
                              </div>
                            )}
                          </div>
                        )
                    }
                    {
                      // Case 2: ios
                      // safari does not allow 'add to home screen', so we have to show a 'guidance'
                      // see: https://react-ios-pwa-prompt.vercel.app/
                      pwaValues.platform.isMobileSafari && (
                        <div style={{ paddingBottom: 8 }}>
                          <a onClick={installAppMobileSafari}>Install Kin</a> to
                          your home screen for app-like features.
                          {pwaValues.showIosPrompt && (
                            <PWAPrompt
                              debug={true}
                              copyClosePrompt="Close"
                              copyTitle="Add Kin to Home Screen"
                              copyBody="Kin has app-like functionality. Add it to your home screen to make it easy to launch and to go full-screen."
                              onClose={() => {
                                window.location.reload();
                              }}
                            />
                          )}
                        </div>
                      )
                    }
                  </div>
                )
              }

              {/*
               * Group 2: fancy notifications
               */}
              {"Notification" in window &&
                !pwaValues.platform.isSafari &&
                !pwaValues.platform.isMobileFireFox && (
                  <div>
                    {Notification.permission == "granted" && (
                      <div style={{ paddingBottom: 8 }}>
                        Fancy notifications are enabled &middot;{" "}
                        <Link to="/settings/consent" component={RLink} color="inherit">
                          Alert Settings
                        </Link>{" "}
                        {/*&middot;{" "}
                          How to <a href={urlDisableNotifs}>disable notifications</a>.*/}
                      </div>
                    )}
                    {Notification.permission == "denied" && (
                      <div style={{ paddingBottom: 8 }}>
                        Fancy notifications are blocked. If you change your mind,
                        you may{" "}
                        <a href={urlReenableNotifs}>re-enable notifications</a>
                      </div>
                    )}
                    {(!Notification.permission ||
                      Notification.permission == "default") && (
                      <div style={{ paddingBottom: 8 }}>
                        Would you like to allow fancy browser notifications?{" "}
                        <a onClick={allowNotifications}>Allow Notifications</a>
                      </div>
                    )}
                  </div>
                )}

              {/*
               * Group 3: hey, try a different browser
               */}
              {
                <div>
                  {!pwaValues.platform.isMobile &&
                    !pwaValues.platform.isFireFox &&
                    !pwaValues.platform.isChromium && (
                      <div style={{ paddingBottom: 8 }}>
                        Kin has app-like features for{" "}
                        <a href="https://www.google.com/chrome/">Chrome</a> and{" "}
                        <a href="https://www.mozilla.org/en-US/firefox/new/">
                          Firefox
                        </a>
                        .
                      </div>
                    )}
                  {pwaValues.platform.isMobile && (
                    <div>
                      {pwaValues.platform.isIOS && !pwaValues.platform.isSafari && (
                        <div style={{ paddingBottom: 8 }}>
                          Kin has app-like features if you're using{" "}
                          <a href="https://support.apple.com/downloads/safari">
                            Safari
                          </a>
                          .
                        </div>
                      )}
                      {pwaValues.platform.isAndroid &&
                        !pwaValues.platform.isChromium && (
                          <div style={{ paddingBottom: 8 }}>
                            Kin has app-like features if you use{" "}
                            <a href="https://www.google.com/chrome/">Chrome</a>.
                          </div>
                        )}
                    </div>
                  )}
                </div>
              }
              {/* /PWA Stuff */}
            </div>
          )
        }

        {/*<div style={ { display: (values.showSearchDialog ? 'block' : 'none') }}>
          <img
            id="close-app-options"
            src="/static/icons/close-red.svg"
            onClick={closeDialogs}
            className="hand"
            width={20}
            height={20}
            style={{
              float: 'right'
            }}
          />

          <div
            className={`noselect`}
            style={{
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 8,
              paddingBottom: 0,
            }}
          >
            <div style={{ fontWeight: "bold", paddingTop: 4 }}>
              Username search:
            </div>
            <SearchUsers
              history={props.history}
              fullWidth={true}
              color="primary"
            />
          </div>
        </div>*/}
      </div>

      {
        // Show submenu only for moderators
        window.current_user.moderator_level > 0 && (
          <div
            className={`${classes.container} ${classes.postContainer} noselect`}
            style={{
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <p>
              <font color="red">
                <a href="/flagz" style={ { color: theme.palette.text.primary } }>
                  There are <b>{window.pending_flag_num}</b> pending cases
                </a>
              </font>
            </p>
          </div>
        )
      }

      <div
        className={`${classes.container} ${classes.postContainer} noselect`}
        style={{
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 8,
          paddingBottom: 8,
          marginTop: 16,
          minHeight: 22
        }}
      >
        <Tabs
          variant="fullWidth"
          value={values.feedVariant}
          onChange={selectFeedVariant}
          aria-label="nav tabs example"
        >
          <Tab id='feed-following' value='following' label="Following" />
          <Tab id='feed-like' value='like' label="For You" />
          <Tab id='feed-trending' value='trending' label="Trending" />
          <Tab id='feed-random' value='random' label="Random" />
        </Tabs>
      </div>

      <StoriesFeed
        stories={values.storiesFeed}
        hasMore={values.hasMoreFeed}
        loaded={values.loadedFeed}
        fetchData={fetchDataFeed}
      />
    </div>
  );
}
