import anchorme from 'vendor/anchorme.min';

// Also replaces newlines with BRs
// >3 BRs become 1
export function textToLinkedURL(text) {
  let txt = text
              .replace(/#(\w+)/g,'<a href="stories/tag/$1">#$1</a>')
              .replace(/@(\w+)/g,'<a href="/$1">@$1</a>') // ToDo: should verify username exists
              .replace(/(?:\r\n|\r|\n)/g, '<br>')
              .replace(/(<br>){3,}/g, '<br><br>')
              .replace(/(<br>)*$/g, '')
  return anchorme(txt)
}

export function newlinesToBr(text) {
  let txt = text
              .replace(/(?:\r\n|\r|\n)/g, '<br>')
              .replace(/(<br>){3,}/g, '<br><br>')
              .replace(/(<br>)*$/g, '')
}
