import React, { useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import Filterous2 from "vendor/filterous2";
import { Draggable, Sticker, Blur, Text } from "image_editor/draggables.js";
import cloneCanvas from "image_editor/clone_canvas.js";
import { imageXTouch, imageYTouch } from "image_editor/x_y_fix.jsx";
import { CreatePostContext } from "components/posts/CreatePostContext";

const useStyles = makeStyles((theme) => ({
  hasMargin: {
    marginLeft: 24,
    marginRight: 24,
  },
  uploaded_image_select: {
    marginRight: 8,
  },
}));

export default React.memo(function ImageEditor(props) {
  let [context, setContext] = useContext(CreatePostContext);

  //console.log("image editor RENDER props", props)
  const classes = useStyles();

  // Form values state
  const initialValues = {
    canvasOriginal: null,
    canvasFiltered: null,
    canvasWithoutSelectedDraggable: null,
    blurs: [],
    clickedBlur: null,
    currentBlurSize: 15,
    stickers: [],
    clickedSticker: null,
    currentStickerSize: 10,
    texts: [],
    clickedText: null,
  };

  let stateSelected = {};

  /*
   * Update name/val pair in context.selectedUpload.state
   */
  function setContextParent(name, val) {
    let ul = context.selectedUpload;
    ul.state[name] = val;

    setContext((state) => ({
      ...state,
      ["selectedUpload"]: ul,
    }));
  }

  /*
   * Handler for events originating in FaceBlurs.jsx
   */
  useEffect(
    function () {
      stateSelected = context.selectedUpload.state;

      if (context.event) {
        //console.log("ie event: " + context.event, stateSelected)
        switch (context.event) {
          case "image_editor::blur::save":
            stateSelected.clickedBlur = null;
            drawAllDraggables();
            break;
          case "image_editor::blur::delete":
            stateSelected.blurs = stateSelected.blurs.filter(
              (draggable) => draggable != stateSelected.clickedBlur
            );
            stateSelected.clickedBlur.erase(
              stateSelected.canvasWithoutSelectedDraggable
            );
            stateSelected.clickedBlur = null;
            drawAllDraggables();
            break;
          case "image_editor::sticker::chosen":
            stickerChosen(context.currentSticker);
            break;
          case "image_editor::sticker::resize":
            stickerSizeChosen(context.currentStickerSize);
            break;
          case "image_editor::sticker::save":
            stateSelected.clickedSticker = null;
            drawAllDraggables();
            break;
          case "image_editor::sticker::delete":
            stateSelected.stickers = stateSelected.stickers.filter(
              (draggable) => draggable != stateSelected.clickedSticker
            );
            stateSelected.clickedSticker.erase(
              stateSelected.canvasWithoutSelectedDraggable
            );
            stateSelected.clickedSticker = null;
            drawAllDraggables();
            break;
          case "image_editor::text::changed":
            textValuesChanged();
            break;
        }
        setContext((state) => ({
          ...state,
          ["event"]: null,
        }));
      }
    },
    [context.event]
  );

  /*
   * Set stateSelected on every context change
   */
  useEffect(function () {
    stateSelected = context.selectedUpload.state;
  });

  function set_default_state_for_upload(upload) {
    let state = JSON.parse(JSON.stringify(initialValues));

    state.canvasOriginal = cloneCanvas(upload.canvasUploaded);
    state.canvasFiltered = null;
    state.canvasWithoutSelectedDraggable = null;
    // No filter will yet have been selected
    state.canvasFiltered = cloneCanvas(state.canvasOriginal);

    upload.state = state;
  }

  /*
   * User uploaded or selected a new image
   */
  useEffect(
    function () {
      let ul = context.selectedUpload;

      // ToDo: save old state before switching to new

      if (!["image/jpeg", "image/png"].includes(ul.mimeType)) {
        console.error("Not an editable image: ", ul);
        return;
      }

      // Initialize state if it DNE
      if (!context.selectedUpload.state) {
        set_default_state_for_upload(ul);

        let state = ul.state;
        stateSelected = state;

        setContext((state) => ({
          ...state,
          ["selectedUpload"]: ul,
        }));

        // Start with processed image on a canvas (aka resized to 1080x1080 and EXIF fixed)
        drawCanvasToDOM(state.canvasOriginal);

        // See: https://www.rgraph.net/canvas/howto-antialias.html
        //let destCtx = $('#canvas-preview')[0].getContext('2d');
        //destCtx.translate(0.5, 0.5);
      } else {
        stateSelected = ul.state;
      }

      applyAllEdits(false);

      setCanvasEvents();
    },
    [context.selectedUpload]
  );

  function setCanvasEvents() {
    // Remove old events, add new ones
    let canvas = $("#canvas-preview");

    canvas.off();

    canvas.on("touchstart", onMouseDown);
    canvas.on("touchend", onMouseUp);
    canvas.on("touchmove", onMouseMove);

    canvas.on("mousemove", onMouseMove);
    canvas.on("mousedown", onMouseDown);
    canvas.on("mouseup", onMouseUp);
  }

  /*
   * User selected a different menu
   */
  useEffect(
    function () {
      stateSelected = context.selectedUpload.state;
      setCanvasEvents();
    },
    [context.currentMenu]
  );

  useEffect(
    function () {
      stateSelected = context.selectedUpload.state;
      applyAllEdits();
    },
    [context.currentFilter]
  );

  /*
   * Blur selected from the menu
   */
  useEffect(
    function () {
      stateSelected = context.selectedUpload.state;
      if (stateSelected.clickedBlur) {
        // Change selected blur
        let blur = stateSelected.clickedBlur;
        blur.id = context.currentBlur;
        setContextParent("clickedBlur", blur); // Tell the menus we changed the blur
      } else {
        // Add new blur
        if (context.currentBlur) {
          stateSelected.canvasWithoutSelectedDraggable = cloneCanvas(
            $("#canvas-preview")[0]
          );

          let new_blur = new Blur(context.currentBlur, context.currentBlurSize);
          new_blur.sequence_id = stateSelected.blurs.length + 1;
          stateSelected.blurs.push(new_blur);
          stateSelected.clickedBlur = new_blur;
          setContextParent("clickedBlur", new_blur); // Tell the menus we added a blur
        }
      }
      drawAllDraggables();
    },
    [context.currentBlur]
  );

  /*
   * Blur size selected from the menu
   */
  useEffect(
    function () {
      stateSelected = context.selectedUpload.state;
      stateSelected.currentBlurSize = parseInt(context.currentBlurSize);

      if (stateSelected.clickedBlur) {
        let blur = stateSelected.clickedBlur;

        blur.erase(stateSelected.canvasWithoutSelectedDraggable);
        blur.resize(context.currentBlurSize);
        //blur.draw(sharedState.currentMenu, values.clickedBlur)
      }
      drawAllDraggables();
    },
    [context.currentBlurSize]
  );

  /*
   * Sticker chosen from the UI
   */
  let stickerChosen = (sticker_id) => {
    if (stateSelected.clickedSticker) {
      stateSelected.clickedSticker.erase(
        stateSelected.canvasWithoutSelectedDraggable
      );

      // Change sticker
      stateSelected.clickedSticker.id = sticker_id;
    } else {
      stateSelected.canvasWithoutSelectedDraggable = cloneCanvas(
        $("#canvas-preview")[0]
      );

      // Add sticker
      let new_sticker = new Sticker(
        sticker_id,
        stateSelected.currentStickerSize
      );
      new_sticker.sequence_id = stateSelected.stickers.length + 1;

      stateSelected.stickers.push(new_sticker);
      stateSelected.clickedSticker = new_sticker;
    }
    drawAllDraggables();
  };

  let stickerSizeChosen = (val) => {
    stateSelected.currentStickerSize = val;

    if (stateSelected.clickedSticker) {
      let sticker = stateSelected.clickedSticker;

      sticker.erase(stateSelected.canvasWithoutSelectedDraggable);
      sticker.resize(val);
    }
    drawAllDraggables();
  };

  let textValuesChanged = () => {
    let vals = {
      text: context.text,
      color: context.color,
      font: context.font,
      size: context.size,
    };
    if (stateSelected.clickedText) {
      stateSelected.clickedText.erase(
        stateSelected.canvasWithoutSelectedDraggable
      );

      if (context.text) {
        stateSelected.clickedText.update(vals);
      } else {
        // Delete it
        stateSelected.texts = stateSelected.texts.filter(
          (draggable) => draggable != stateSelected.clickedText
        );
        stateSelected.clickedText.erase(
          stateSelected.canvasWithoutSelectedDraggable
        );
        stateSelected.clickedText = null;
        drawAllDraggables();
      }
    } else {
      //{font: "Anton", size: "30em", color: "#000", category: "sans-serif", text: "asefasefeesseef", …}
      if (vals.text) {
        stateSelected.canvasWithoutSelectedDraggable = cloneCanvas(
          $("#canvas-preview")[0]
        );

        // Add text
        let new_text = new Text(vals);

        stateSelected.texts.push(new_text);
        stateSelected.clickedText = new_text;
      }
    }
    drawAllDraggables();
  };

  /*
   * Display-related functions
   */

  // Called by parent CreatePost.jsx object
  window.finalize = (maxWidth, maxHeight) => {
    //console.log('maxWidth', maxWidth, maxHeight)
    let blobs = [];

    if (!maxWidth || !maxHeight) {
      throw "maxWidth and maxHeight not specified";
    }

    return new Promise((resolve, reject) => {
      let numImages = 0;

      function checkReadAllFiles() {
        if (blobs.length == numImages) {
          resolve(blobs);
        }
      }

      for (let i = 0; i < context.uploads.length; i++) {
        let upload = context.uploads[i];
        if (upload.mimeType == "image/jpeg" || upload.mimeType == "image/png") {
          numImages = numImages + 1;
        }
      }

      for (let i = 0; i < context.uploads.length; i++) {
        let upload = context.uploads[i];
        // for all editable images
        if (upload.mimeType == "image/jpeg" || upload.mimeType == "image/png") {
          if (upload.state) {
            stateSelected = upload.state;
          } else {
            // User never looked at this image in the editor so its state is blank. Fill it.
            set_default_state_for_upload(upload);
            stateSelected = upload.state;
          }
          console.log(i, upload, "stateSelected", stateSelected);
          applyAllEdits(true);

          // Resize to fit e.g. scale down
          let finalized_canvas = $("#canvas-preview")[0];
          let canvas_resized = document.createElement("canvas");

          // get finalized canvas as an image
          let img = new Image();
          img.id = "pic";
          img.onload = function () {
            // resize image to fit within maxHeight and maxWidth
            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }
            } else {
              if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }
            }
            canvas_resized.width = width;
            canvas_resized.height = height;
            let context_resized = canvas_resized.getContext("2d");
            context_resized.drawImage(img, 0, 0, width, height);

            // add resized image to the blobs array
            // then return the blogs array to the caller via resolve if possible
            canvas_resized.toBlob((blob) => {
              blobs.push(blob);
              checkReadAllFiles();
            });
          };
          img.src = finalized_canvas.toDataURL();
        }
      }
    });
  };

  function applyAllEdits(final) {
    // Apply edits
    //console.log("stateSelected", stateSelected)
    drawCanvasToDOM(stateSelected.canvasOriginal);
    applyFilter();
    drawAllDraggables(final);
  }

  function drawCanvasToDOM(cv) {
    // Destination is a <canvas> element on the page
    let destCanvas = $("#canvas-preview")[0];
    destCanvas.width = cv.width;
    destCanvas.height = cv.height;
    let destCtx = destCanvas.getContext("2d");

    // Redraw original canvas on destination
    destCtx.drawImage(cv, 0, 0);
  }

  function applyFilter() {
    let destCanvas = $("#canvas-preview")[0];

    let options = {
      scale: 1,
      format: "png",
    };

    if (context.currentFilter != "normal") {
      let filteredImage = Filterous2.importImage(null, destCanvas, options);
      filteredImage.applyInstaFilter(context.currentFilter);
    }

    stateSelected.canvasFiltered = cloneCanvas(destCanvas);
  }

  function drawDraggables(draggables, erase) {
    for (let i = 0; i < draggables.length; i++) {
      let draggable = draggables[i];

      if (erase) {
        draggable.erase(stateSelected.canvasFiltered);
      }
      draggable.draw(null, null, false);
    }
  }

  // Finalize removes the bounding boxes from draggables
  function drawAllDraggables(finalize) {
    drawDraggables(stateSelected.blurs, true);
    drawDraggables(stateSelected.stickers, true);
    drawDraggables(stateSelected.texts, true);

    if (!finalize) {
      if (context.currentMenu == "blurs" && stateSelected.clickedBlur) {
        stateSelected.clickedBlur.markSelected();
      }
      if (context.currentMenu == "stickers" && stateSelected.clickedSticker) {
        stateSelected.clickedSticker.markSelected();
      }
      if (context.currentMenu == "texts" && stateSelected.clickedText) {
        stateSelected.clickedText.markSelected();
      }
    }
  }

  let touchX = 0;
  let touchY = 0;

  function takeSnapshot() {
    drawCanvasToDOM(stateSelected.canvasFiltered);
    drawAllDraggables(true);
    stateSelected.canvasWithoutSelectedDraggable = cloneCanvas(
      $("#canvas-preview")[0]
    );
    drawCanvasToDOM(stateSelected.canvasFiltered);
  }

  const onMouseDown = function (evt, type) {
    if (evt.target.id != "canvas-preview") {
      return false;
    }

    let x = 0;
    let y = 0;
    x = imageXTouch(evt, type);
    y = imageYTouch(evt, type);

    // Because touchup doesn't include x/y
    touchX = x;
    touchY = y;

    let draggables = [];
    let clicked = null;
    if (context.currentMenu == "blurs") {
      let clickedBlur = Draggable.clicked(x, y, stateSelected.blurs);
      stateSelected.clickedBlur = clickedBlur;

      if (clickedBlur) {
        clickedBlur.dragging = true;
        clickedBlur.diffX = x - clickedBlur.x; // # pixels to the right of the blur's left edge
        clickedBlur.diffY = y - clickedBlur.y; // # pixels to the bottom of the blur's top edge

        // Take a 'snapshot' of the canvas with the selected blur removed
        // Store it in: canvasWithoutSelectedDraggable
        // For use when moving the object: it erases the background correctly re: overlap
        stateSelected.blurs = stateSelected.blurs.filter(
          (draggable) => draggable != clickedBlur
        );
        takeSnapshot();

        setContextParent("currentBlurSize", clickedBlur.size);

        // Clicked Blur is now at the end of the array, so it's "on top" of the draw order
        stateSelected.blurs.push(clickedBlur);
      }

      // Tell the menu we clicked a blur (or deslected it)
      setContextParent("clickedBlur", clickedBlur);

      drawAllDraggables();
    }

    if (context.currentMenu == "stickers") {
      let clickedSticker = Draggable.clicked(x, y, stateSelected.stickers);
      stateSelected.clickedSticker = clickedSticker;

      if (clickedSticker) {
        clickedSticker.dragging = true;
        clickedSticker.diffX = x - clickedSticker.x;
        clickedSticker.diffY = y - clickedSticker.y;

        stateSelected.stickers = stateSelected.stickers.filter(
          (draggable) => draggable != clickedSticker
        );
        takeSnapshot();
        setContextParent("currentStickerSize", clickedSticker.size);

        stateSelected.stickers.push(clickedSticker);
      }

      // Tell the menu we clicked a sticker (or deslected it)
      setContextParent("clickedSticker", clickedSticker);

      drawAllDraggables();
    }

    if (context.currentMenu == "texts") {
      let clickedText = Draggable.clicked(x, y, stateSelected.texts);
      stateSelected.clickedText = clickedText;

      if (clickedText) {
        clickedText.dragging = true;
        clickedText.diffX = x - clickedText.x;
        clickedText.diffY = y - clickedText.y;

        stateSelected.texts = stateSelected.texts.filter(
          (draggable) => draggable != clickedText
        );
        takeSnapshot();

        stateSelected.texts.push(clickedText);
      }
      drawAllDraggables();
    }

    evt.preventDefault();
  };

  /*****************
   * onMouseMove
   *****************/

  let prevMoveX = 0;
  let prevMoveY = 0;
  const onMouseMove = function (evt, type) {
    if (evt.target.id != "canvas-preview") {
      return false;
    }

    let x = 0;
    let y = 0;
    if (
      context.currentMenu == "blurs" ||
      context.currentMenu == "stickers" ||
      context.currentMenu == "texts"
    ) {
      x = imageXTouch(evt, type);
      y = imageYTouch(evt, type);

      if (Math.abs(prevMoveX - x) < 12 && Math.abs(prevMoveY - y) < 12) {
        // If small move, don't redraw
        return;
      }

      prevMoveX = x;
      prevMoveY = y;

      touchX = x;
      touchY = y;
    }

    if (
      context.currentMenu == "blurs" &&
      stateSelected.clickedBlur &&
      stateSelected.clickedBlur.dragging
    ) {
      // "Erase" old blur by reapplying filtered canvas
      stateSelected.clickedBlur.erase(
        stateSelected.canvasWithoutSelectedDraggable
      );

      let diffX = stateSelected.clickedBlur.diffX;
      let diffY = stateSelected.clickedBlur.diffY;
      stateSelected.clickedBlur.x = x - diffX;
      stateSelected.clickedBlur.y = y - diffY;

      stateSelected.clickedBlur.draw();
      stateSelected.clickedBlur.markSelected();

      //draw()
    }

    if (
      context.currentMenu == "stickers" &&
      stateSelected.clickedSticker &&
      stateSelected.clickedSticker.dragging
    ) {
      // "Erase" old blur by reapplying filtered canvas
      stateSelected.clickedSticker.erase(
        stateSelected.canvasWithoutSelectedDraggable
      );

      let diffX = stateSelected.clickedSticker.diffX;
      let diffY = stateSelected.clickedSticker.diffY;

      stateSelected.clickedSticker.x = x - diffX;
      stateSelected.clickedSticker.y = y - diffY;

      stateSelected.clickedSticker.draw();
      stateSelected.clickedSticker.markSelected();

      //draw()
    }

    if (
      context.currentMenu == "texts" &&
      stateSelected.clickedText &&
      stateSelected.clickedText.dragging
    ) {
      // "Erase" old blur by reapplying filtered canvas
      stateSelected.clickedText.erase(
        stateSelected.canvasWithoutSelectedDraggable
      );

      let diffX = stateSelected.clickedText.diffX;
      let diffY = stateSelected.clickedText.diffY;

      stateSelected.clickedText.x = x - diffX;
      stateSelected.clickedText.y = y - diffY;

      stateSelected.clickedText.draw();
      stateSelected.clickedText.markSelected();

      //draw()
    }

    evt.preventDefault();
  };

  /*****************
   * onMouseUp
   *****************/

  const onMouseUp = function (evt, type) {
    if (evt.target.id != "canvas-preview") {
      return false;
    }

    let x = 0;
    let y = 0;
    if (
      context.currentMenu == "blurs" ||
      context.currentMenu == "stickers" ||
      context.currentMenu == "texts"
    ) {
      if (evt.touches) {
        x = touchX;
        y = touchY;
      } else {
        x = imageXTouch(evt, type);
        y = imageYTouch(evt, type);
      }

      if (context.currentMenu == "blurs") {
        if (stateSelected.clickedBlur && stateSelected.clickedBlur.dragging) {
          stateSelected.clickedBlur.dragging = false;

          drawAllDraggables();
        }
      }

      if (context.currentMenu == "stickers") {
        if (
          stateSelected.clickedSticker &&
          stateSelected.clickedSticker.dragging
        ) {
          stateSelected.clickedSticker.dragging = false;

          drawAllDraggables();
        }
      }

      if (context.currentMenu == "texts") {
        if (stateSelected.clickedText && stateSelected.clickedText.dragging) {
          stateSelected.clickedText.dragging = false;

          drawAllDraggables();
        }
      }
    }
    evt.preventDefault();
  };

  return <div></div>;
});
