import React, { useState, useEffect, useCallback } from "react";
import $ from "jquery";
import { Link as RLink, useParams } from "react-router-dom";
import { Container } from "@material-ui/core";
import { Link, Typography } from "@material-ui/core";
import {
  Slider,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormControl,
} from "@material-ui/core";
import { FormLabel } from "@material-ui/core";
// See: https://github.com/ricardo-ch/react-easy-crop
import Cropper from "react-easy-crop";
import { getCroppedImg, getRotatedImage } from "../../vendor/canvasUtils";
import ReactEasyCropStyles from "components/events/ReactEasyCropStyles";
import UserAvatar from "components/UserAvatar";
// Markdown editor
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import Loading from "components/Loading";
import useInterval from "vendor/useInterval";
import { LocationContext } from "components/profile/LocationContext";
import Location from "components/profile/location";
import CommonStyles from "components/styles/CommonStyles";
import { detectLocation } from "components/events/EventService";

const converter = new Showdown.Converter({
  tables: false,
  tasklists: false,
  simpleLineBreaks: true,
  ghMentions: true,
  ghMentionsLink: "https://kinkykin.com/{u}",
  openLinksInNewWindow: true,
  backslashEscapesHTMLTags: true,
  emoji: true,
  simplifiedAutoLink: true,
  strikethrough: false,
  tasklists: true,
});

// Handles both the create and edit cases
export default function CommunityEdit(props) {
  const classes = CommonStyles();
  const reactEasyCropStyles = ReactEasyCropStyles();

  let { id } = useParams();

  let mode = props.mode;

  // Default to user's location, then their detected location if not set
  let initialContext = {};
  let locationHsh = detectLocation(window.current_user);
  initialContext.country_id = locationHsh.country_id;
  initialContext.region_id = locationHsh.region_id;
  initialContext.city_id = locationHsh.city_id;
  initialContext.has_location = true

  // For sharing location data w/ <Location />
  const [locationContext, setLocationContext] = useState(initialContext);

  const initialValues = {
    id: id || -1,
    community_name: "",
    description_raw: "",
    tagline: "",
    tags: "",
    uploadedImage: {},
    url: null,
    searchable: true,
    private_pictures: false,
    private_events: false,
    admin_ids: [],
    members: [],
    members_by_id: {},
    member_approvals_join: false,
    member_approvals_events: false,
    member_approvals_posts: false,
    has_location: false,
    searchPostQuery: "",
    tagAutoComplete: [],
    tagRelated: [],
    tagTimeout: null,
  };
  const [values, setValues] = useState(initialValues);

  const [selectedTab, setSelectedTab] = React.useState("write");

  const mdeCommands = [
    ["header", "bold", "italic"],
    ["link", "quote"],
    ["unordered-list", "ordered-list", "checked-list"],
  ];

  const descChange = (val) => {
    setValues((state) => ({
      ...state,
      ["description_raw"]: val,
    }));
  };

  useEffect(() => {
    setValues((state) => ({
      ...state,
      ["loading"]: true,
    }));

    if (mode == "create") {
      // Get the 'draft' version of this community, if it exists
      $.ajax({
        url: `/community/draft`,
        type: "GET",
        async: window.rails_env != "test",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        success: function (data) {
          if (data.success) {
            let comm = data.community;

            setValues((state) => ({
              ...state,
              id: comm.id,
              community_name: comm.name,
              description_raw: comm.description_raw,
              tagline: comm.tagline,
              tags: comm.tags,
              searchable: comm.searchable,
              private_pictures: comm.private_pictures,
              private_events: comm.private_events,
              member_approvals_join: comm.member_approvals_join,
              member_approvals_events: comm.member_approvals_events,
              member_approvals_posts: comm.member_approvals_posts,
              tags: comm.tags,
              url: comm.url,
              loading: false,
            }));

            setLocationContext((state) => ({
              ...state,
              country_id: comm.country_id,
              region_id: comm.region_id,
              city_id: comm.city_id,
            }));
          } else {
            setValues((state) => ({
              ...state,
              loading: false,
            }));
          }
        },
        cache: false,
        contentType: false,
        processData: false,
      });
    } else if (mode == "edit") {
      // We're editing, retrieve the community by id
      $.ajax({
        url: `/community/${id}.json`,
        type: "GET",
        async: window.rails_env != "test",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        success: function (data) {
          let comm = data;
          data.community_name = data.name;

          setValues((state) => ({
            ...state,
            ...data,
            loading: false,
          }));

          setLocationContext((state) => ({
            ...state,
            country_id: comm.country_id,
            region_id: comm.region_id,
            city_id: comm.city_id,
          }));
        },
        cache: false,
        contentType: false,
        processData: false,
      });
    }
  }, [values.page]);

  /*
   * Hack: onBlur doesn't work on ReactMDE. Use jquery instead
   */
  useEffect(() => {
    $("#input-description-raw").blur(handleDescBlur);
  }, []);

  // Save current draft of the community every 5s if both name and desc have a value
  useInterval(
    () => {
      if (
        mode == "create" &&
        !window.stop_saving_drafts &&
        values.community_name != "" &&
        values.description_raw != ""
      ) {
        let payload = {
          authenticity_token: window.auth_token,
          community_name: values.community_name,
          description_raw: values.description_raw,
          tagline: values.tagline,
          tags: values.tags,
          searchable: values.searchable,
          country_id: locationContext.country_id,
          region_id: locationContext.region_id,
          city_id: locationContext.city_id,
          url: values.url,
        };
        if (JSON.stringify(payload) != window.last_payload) {
          window.last_payload = JSON.stringify(payload);
          $.ajax({
            url: `/community/draft`,
            type: "POST",
            async: window.rails_env != "test",
            data: payload,
            success: function (data) {
              if (data.success) {
                if (data.id) {
                  setValues((state) => ({
                    ...state,
                    id: data.id,
                  }));
                }
              }
            },
            error: function (data) {
              console.error("Error saving community draft", data);
            },
          });
        }
      }
    },
    window.rails_env == "test" ? 2000 : 4000
  );

  // Which fields have been visited then left: { fieldname: true (or false0) }
  const [blurred, setBlurred] = useState({});

  const requiredInputs = ["community_name", "description_raw", "tagline"];

  // FE Errors hash: { fieldname: 'front end error' }
  const [errorsFE, setErrorsFE] = useState({});

  // Front-end validations
  const validationsFE = {
    community_name: {
      regex: /^.{5,60}$/, // < 80 characters
      error: "Name must be 5-60 characters",
    },
    description_raw: {
      regex: /^.{50,1000}$/,
      error: "Description must be 50-1000 characters",
    },
    tagline: {
      regex: /^.{5,80}$/, // < 80 characters
      error: "Tagline must be 5-80 characters",
    },
  };

  const validateFrontend = (name, value, required) => {
    let error = "";
    const rules = validationsFE[name];
    if (rules) {
      if (rules.regex && value && !rules.regex.test(value)) {
        error = rules.error;
      }
      if (required && !value.trim()) {
        error = "Required";
      }
    }
    setErrorsFE((state) => ({
      ...state,
      [name]: error,
    }));
  };

  // User has typed a key or selected a dropdown
  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    let required = event.target.required;

    setValues((state) => ({
      ...state,
      [name]: value,
    }));

    // Don't validate on keystroke until the user has completed a first attempt
    if (blurred[name]) {
      validate(name, value, required);
    }
  };

  // User has moved focus out of a field
  const handleBlur = (event) => {
    let name = event.target.name;
    let value = event.target.value.trim();
    let required = event.target.required;

    //validateFrontend(name, value, required)
    if (!blurred[name]) {
      validate(name, value, required);
    }

    setBlurred((state) => ({
      ...state,
      [name]: true,
    }));
  };

  const validate = (name, value, required) => {
    validateFrontend(name, value, required);
  };

  const allFieldsValid = () => {
    let retval = true;
    requiredInputs.forEach((element) => {
      if (
        values[element] == null ||
        values[element] == "" ||
        errorsFE[element]
      ) {
        retval = false;
      }
    });
    if (
      (locationContext.country_id == "" ||
      locationContext.region_id == "" ||
      locationContext.city_id == "") && !locationContext.has_location
    ) {
      retval = false;
    }
    return retval;
  };

  function post() {
    setValues((state) => ({
      ...state,
      loading: true,
    }));
  }

  /* Tag input stuff */

  // onChange
  const handleChangeText = (event) => {
    let value = event.target.value;
    let start_index = event.target.selectionStart;
    let end_index = event.target.selectionEnd;

    // Update the textarea
    setValues((state) => ({
      ...state,
      ["tags"]: value,
      ["prevStartIndex"]: start_index,
      ["prevEndIndex"]: end_index,
    }));

    // Find a hashtag if one is currently being typed or has been clicked
    if (end_index == start_index) {
      let curTag = "";
      // "go left" until '#'
      for (let i = start_index - 1; i >= 0; i--) {
        let chr = value[i];
        if (chr == "#") {
          // If we go back and get a hashtag, it's on. Select it
          // See: https://stackoverflow.com/questions/61598927/get-the-full-word-that-cursor-is-touching-or-within-javascript
          let begin = i + 1; // account for the #
          let next_space_index = value.indexOf(" ", end_index);
          let end = next_space_index < 0 ? value.length : next_space_index;
          let between_spaces = value.substring(begin, end);
          curTag = between_spaces;
        } else if (chr.match(/[a-zA-Z0-9_!\.,;:\?]/)) {
          // hashtag-valid character or allowed post-hashtag punctuations, continue
          continue;
        } else {
          // We (1) the beginning of value
          // or (2) hit a space or invalid punct or something
          // So: not a hashtag
          break;
        }
      }

      // User is typing a tag, auto-suggest
      if (curTag.length > 2) {
        clearTimeout(values.tagTimeout);
        setValues((state) => ({
          ...state,
          ["tagTimeout"]: setTimeout(function () {
            searchTagAjax(curTag);
          }, 200),
        }));
      } else {
        // Just typing or short hashtag, do not suggest
        tagSuggestClose();
      }
    }
  };

  // User has clicked within the textarea
  const handleClickText = (event) => {
    if (event.target.selectionStart) {
      // todo: why is this sometimes null?
      handleChangeText(event);
    }
  };

  const handleFocusText = (event) => {
    handleChangeText(event);
  };

  function handleDescBlur() {
    let val = $("#input-description-raw").val();
    val = val.replace(/\r?\n|\r/gm, "");
    let fake_event = {
      target: {
        name: "description_raw",
        value: val,
        required: true,
      },
    };
    handleBlur(fake_event);
  }

  const handleBlurText = (event) => {
    setValues((state) => ({
      ...state,
      tagAutoComplete: [],
      tagRelated: [],
    }));
  };

  function searchTagAjax(value) {
    $.ajax({
      url: "/search/tags?q=" + encodeURIComponent(value),
      type: "GET",
      async: window.rails_env != "test",
      success: function (data) {
        setValues((state) => ({
          ...state,
          tagAutoComplete: data.autocomplete,
          tagRelated: data.related,
        }));
      },
      error: function (data) {
        if (data.status == 404) {
          console.error("tag search err", data);
        }
      },
      cache: false,
      contentType: false,
    });
  }

  function tagSuggestClose() {
    setValues((state) => ({
      ...state,
      tagAutoComplete: [],
      tagRelated: [],
    }));
  }

  function handleClickTagAutoComplete(tag) {
    let start_index = values.prevStartIndex;
    let end_index = values.prevEndIndex;
    let value = values.tags;

    for (let i = start_index - 1; i >= 0; i--) {
      let chr = value[i];
      if (chr == "#") {
        // If we go back and get a hashtag, it's on. Select it
        // See: https://stackoverflow.com/questions/61598927/get-the-full-word-that-cursor-is-touching-or-within-javascript
        const next_space_index = value.indexOf(" ", end_index);
        const begin = i + 1; // account for the #
        const end = next_space_index < 0 ? value.length : next_space_index;

        let val_left = value.substring(0, begin) + tag.tag;
        let new_cursor_pos = val_left.length;
        value = val_left + value.substring(end, value.length);
        setValues((state) => ({
          ...state,
          ["tags"]: value,
        }));

        setTimeout(function () {
          // Set cursor to new_cursor_pos
          $("#input-tags")[0].setSelectionRange(new_cursor_pos, new_cursor_pos);
          // Now when we focus, handleChangeText has the correct cursor pos to use
          $("#input-tags").focus();
        }, 1);
      } else if (chr.match(/[a-zA-Z0-9_!\.,;:\?]/)) {
        // hashtag-valid character or allowed post-hashtag punctuations, continue
        continue;
      } else {
        // We hit a space or invalid punct or something, not a hashtag
        break;
      }
    }
  }

  function handleClickTagRelated(tag) {
    let start_index = values.prevStartIndex;
    let end_index = values.prevEndIndex;
    let value = values.tags;

    for (let i = start_index - 1; i >= 0; i--) {
      let chr = value[i];
      if (chr == "#") {
        // If we go back and get a hashtag, it's on. Select it
        // See: https://stackoverflow.com/questions/61598927/get-the-full-word-that-cursor-is-touching-or-within-javascript
        const next_space_index = value.indexOf(" ", end_index);
        const begin = i + 1; // account for the #
        const end = next_space_index < 0 ? value.length : next_space_index;

        let val_left = value.substring(0, end) + " #" + tag.tag;
        let new_cursor_pos = val_left.length;
        value = val_left + value.substring(end, value.length);
        setValues((state) => ({
          ...state,
          ["tags"]: value,
        }));

        setTimeout(function () {
          $("#input-tags")[0].setSelectionRange(new_cursor_pos, new_cursor_pos);
          $("#input-tags").focus();
        }, 1);
      } else if (chr.match(/[a-zA-Z0-9_!\.,;:\?]/)) {
        // hashtag-valid character or allowed post-hashtag punctuations, continue
        continue;
      } else {
        // We hit a space or invalid punct or something, not a hashtag
        break;
      }
    }
  }

  function sendPhotoStart() {
    $("#input-file-image").click();
  }

  /* Image cropper. See: https://codesandbox.io/s/y09komm059 */
  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      // apply rotation if needed
      //const orientation = await getOrientation(file)
      //const rotation = ORIENTATION_TO_ANGLE[orientation]
      if (false && rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
      }

      setImageSrc(imageDataUrl);
    }
  };

  // This is the form submit
  // Does some JS processing then a regular old form POST submit
  const showCroppedImage = useCallback(async () => {
    try {
      setValues((state) => ({
        ...state,
        loading: true,
      }));
      window.stop_saving_drafts = true;

      if (imageSrc) {
        const croppedImage = await getCroppedImg(
          imageSrc,
          croppedAreaPixels,
          rotation
        );
        //setCroppedImage(croppedImage)

        let blob = await fetch(croppedImage).then((r) => r.blob());

        let file = new File([blob], "img.jpg", {
          type: "image/jpeg",
          lastModified: new Date().getTime(),
        });
        let container = new DataTransfer();
        container.items.add(file);

        var hidden_elem = document.getElementById("input-file-cropped");
        hidden_elem.files = container.files;
      }

      $("#btn-save").attr("disabled", "disabled");
      $("#form-save").submit();
    } catch (e) {
      console.error(e);
    } finally {
      setValues((state) => ({
        ...state,
        loading: false,
      }));
    }
  }, [imageSrc, croppedAreaPixels, rotation]);

  function handleChangeConsentCheckbox(evt, val) {
    let id = evt.target.id;
    let arr = id.split(/checkbox-/);
    id = arr[1];
    //console.log("id", id, "val", val, arr);

    setValues((state) => ({
      ...state,
      [id]: !values[id],
    }));
  }

  function toggleAdmin(user) {
    let admin_ids = values.admin_ids;
    let index = admin_ids.indexOf(user.id);
    if (index > -1) {
      // already an admin
      admin_ids.splice(index, 1);
    } else {
      admin_ids.push(user.id);
    }

    setValues((state) => ({
      ...state,
      admin_ids: admin_ids,
    }));
  }

  return (
    <div id="create_event" className={classes.container} style={ { paddingLeft: 8, paddingRight: 8 } }>
      <div style={{ display: values.loading ? "none" : "block" }}>
        <form
          id="form-save"
          action="/community"
          method="POST"
          noValidate
          encType="multipart/form-data"
        >
          <input
            type="hidden"
            name="authenticity_token"
            value={window.auth_token}
          />
          <input type="hidden" name="id" value={values.id} />
          <Typography variant="h4">
            {mode == "create" ? "Create a Community" : "Edit Your Community"}
          </Typography>

          <p>
            Communities are groups of Kin users who share pictures and organize
            events.
          </p>

          <p>
            As the community owner, you maintain the list of users who can approve/reject memberships, picture posts, event posts, etc etc.
          </p>

          <p>
            <font color="red">* Required</font>
          </p>

          <FormControl component="div" fullWidth={true} margin={"normal"}>
            <FormLabel component="legend">
              Community Name<font color="red">*</font>:
            </FormLabel>
            <TextField
              id="input-community-name"
              name="community_name"
              type="text"
              value={values.community_name || ""}
              error={!!errorsFE.community_name}
              onBlur={handleBlur}
              onChange={handleChange}
              required
              size="small"
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <FormHelperText error={!!errorsFE["community_name"]}>
              {errorsFE["community_name"]}
            </FormHelperText>
          </FormControl>

          <FormControl component="div" fullWidth={true} margin={"normal"}>
            <FormLabel component="legend">
              Description<font color="red">*</font>:
            </FormLabel>
            <ul>
              <li>What's the purpose of this community?</li>
              <li>Who should join?</li>
              <li>What kind of pictures should members post?</li>
              <li>What will you do at your events?</li>
            </ul>

            <ReactMde
              value={values.description_raw}
              onChange={descChange}
              selectedTab={selectedTab}
              onTabChange={setSelectedTab}
              toolbarCommands={mdeCommands}
              childProps={{
                textArea: {
                  name: "description_raw",
                  id: "input-description-raw",
                },
              }}
              generateMarkdownPreview={(markdown) =>
                Promise.resolve(converter.makeHtml(markdown))
              }
            />
            <FormHelperText error={!!errorsFE["description_raw"]}>
              {errorsFE["description_raw"]}
            </FormHelperText>
          </FormControl>

          <FormControl component="div" fullWidth={true} margin={"normal"}>
            <FormLabel component="legend">
              Tagline<font color="red">*</font>:
            </FormLabel>
            <p>For example: "Events at the Chicago Rose dungeon!"</p>

            <TextField
              id="input-tagline"
              name="tagline"
              type="text"
              value={values["tagline"] || ""}
              error={!!errorsFE["tagline"]}
              onBlur={handleBlur}
              onChange={handleChange}
              required
              size="small"
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <FormHelperText error={!!errorsFE["tagline"]}>
              {errorsFE["tagline"]}
            </FormHelperText>
          </FormControl>

          <FormControl component="div" fullWidth={true} margin={"normal"}>
            <FormLabel component="legend">Hashtags:</FormLabel>

            <p>Choose some #hashtags to make your group discoverable:</p>
            <p>#Chicago #ClubFEM #munch</p>

            <TextField
              id="input-tags"
              name="tags"
              multiline={true}
              value={values.tags || ""}
              error={false}
              onChange={handleChangeText}
              onClick={handleClickText}
              onFocus={handleFocusText}
              onBlur={handleBlurText}
              inputProps={{
                autoComplete: "off",
              }}
              fullWidth
              size="small"
              variant="outlined"
              margin="normal"
            />
            {(values.tagAutoComplete.length > 0 ||
              values.tagRelated.length > 0) && (
              <div
                id="tag-autocomplete"
                style={{
                  zIndex: 9999,
                  position: "absolute",
                  width: "100%",
                  maxWidth: 200,
                  background: "#FFF",
                  border: "1px solid",
                }}
              >
                {values.tagAutoComplete.length > 0 && (
                  <div id="tag-autocomplete-suggestions">
                    {values.tagAutoComplete.map((tag) => (
                      <div key={tag.tag}>
                        &nbsp;
                        <a
                          className="tag-autocomplete-link"
                          onMouseDown={() => {
                            handleClickTagAutoComplete(tag);
                          }}
                        >
                          {`#${tag.tag} (${tag.posts_num})`}
                        </a>
                      </div>
                    ))}
                  </div>
                )}
                {values.tagRelated.length > 0 && (
                  <div id="tag-autocomplete-related">
                    &nbsp;<b>Related:</b>
                    {values.tagRelated.map((tag) => (
                      <div key={tag.tag}>
                        &nbsp;
                        <a
                          className="tag-autocomplete-link hand"
                          onMouseDown={() => {
                            handleClickTagRelated(tag);
                          }}
                        >
                          {`#${tag.tag} (${tag.num_posts_related})`}
                        </a>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </FormControl>

          <FormControl component="div" fullWidth={true} margin={"normal"}>
            <FormLabel component="legend">
              Location<font color="red">*</font>:
            </FormLabel>

            <p>Make your event discoverable by location</p>

            <FormControlLabel
              control={
                <Checkbox
                  id="checkbox-has_location"
                  checked={!values.has_location}
                  onChange={handleChangeConsentCheckbox}
                  name="include-virtual"
                  color="primary"
                />
              }
              label="Internet only / no physical location"
            />

            <LocationContext.Provider
              value={[locationContext, setLocationContext]}
            >
              <Location />
            </LocationContext.Provider>
          </FormControl>

          <FormControl component="div" fullWidth={true} margin={"normal"}>
            <FormLabel component="legend">
              Link<font color="red">*</font>:
            </FormLabel>

            <p>Your Community's URL/Link can be one of these:</p>
            <ul>
              <li>Official Web Site</li>
              <li>Discord Server</li>
              <li>Telegram Group</li>
              <li>FetLife Group</li>
            </ul>

            <TextField
              id="input-url"
              name="url"
              label="URL / Link"
              type="text"
              value={values["url"] || ""}
              error={!!errorsFE["url"]}
              onBlur={handleBlur}
              onChange={handleChange}
              size="small"
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <FormHelperText error={!!errorsFE["url"]}>
              {errorsFE["url"]}
            </FormHelperText>
          </FormControl>

          <FormControl component="div" fullWidth={true} margin={"normal"}>
            <FormLabel component="legend">Image:</FormLabel>

            <p>A community image is optional but highly recommended</p>

            <div>
              <img
                id="id-attach-image"
                src="/static/icons/icon-attach-image-message.svg"
                width={32}
                style={{ cursor: "pointer", verticalAlign: "middle" }}
                onClick={sendPhotoStart}
              />
              {values.community_image_url && <span>&nbsp;Change Image</span>}
              {!values.community_image_url && <span>&nbsp;Set an image</span>}
            </div>
            <FormHelperText>Should be a 4:3 resolution</FormHelperText>

            <input
              id="input-file-cropped"
              name="input-file-cropped"
              style={{ display: "none" }}
              type="file"
              accept=".jpg, .jpeg, .png"
            />
            {imageSrc && (
              <React.Fragment>
                <div className={reactEasyCropStyles.cropContainer}>
                  <Cropper
                    image={imageSrc}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={4 / 3}
                    zoomWithScroll={false}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
                <div className={reactEasyCropStyles.controls}>
                  <div className={reactEasyCropStyles.sliderContainer}>
                    <Typography
                      variant="overline"
                      classes={{ root: reactEasyCropStyles.sliderLabel }}
                    >
                      Zoom
                    </Typography>
                    <Slider
                      value={zoom}
                      aspect={3 / 2}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      classes={{ root: reactEasyCropStyles.slider }}
                      onChange={(e, zoom) => setZoom(zoom)}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
            <img
              src={values.community_image_url}
              style={{ maxWidth: "100%" }}
            />
          </FormControl>

          <FormControl component="div" fullWidth={true} margin={"normal"}>
            <FormLabel component="legend">Consent:</FormLabel>

            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    id="checkbox-searchable"
                    checked={values.searchable}
                    value={values.searchable}
                    onChange={handleChangeConsentCheckbox}
                    name="searchable"
                    color="primary"
                  />
                }
                label="Searchable - users can find this on the Find Communities page"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    id="checkbox-private_pictures"
                    checked={values.private_pictures}
                    value={values.private_pictures}
                    onChange={handleChangeConsentCheckbox}
                    name="private_pictures"
                    color="primary"
                  />
                }
                label="Private pictures - don't show pictures on the public community page"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    id="checkbox-private_events"
                    checked={values.private_events}
                    value={values.private_events}
                    onChange={handleChangeConsentCheckbox}
                    name="private_events"
                    color="primary"
                  />
                }
                label="Private events - don't show events  on the public community page or in Explore"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    id="checkbox-member_approvals_join"
                    checked={values.member_approvals_join}
                    value={values.member_approvals_join}
                    onChange={handleChangeConsentCheckbox}
                    name="member_approvals_join"
                    color="primary"
                  />
                }
                label="Require approval to join this community?"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    id="checkbox-member_approvals_events"
                    checked={values.member_approvals_events}
                    value={values.member_approvals_events}
                    onChange={handleChangeConsentCheckbox}
                    name="member_approvals_events"
                    color="primary"
                  />
                }
                label="Require approval for members to post events?"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    id="checkbox-member_approvals_posts"
                    checked={values.member_approvals_posts}
                    value={values.member_approvals_posts}
                    onChange={handleChangeConsentCheckbox}
                    name="member_approvals_posts"
                    color="primary"
                  />
                }
                label="Require approval for members to post pictures/videos?"
              />
            </div>

            <div>
              <p>
                <b>
                  Which members do you authorize to make the above approvals?
                </b>
              </p>

              <div style={{ maxHeight: 300, overflowY: "auto" }}>
                <input
                  type="hidden"
                  name="admin_ids"
                  value={JSON.stringify(values.admin_ids)}
                />

                <table>
                  <tbody>
                    {values.members.map(function (member, index) {
                      let user = values.members_by_id[member.user_id];
                      let is_owner = user.id == values.owner_id;
                      let is_admin = values.admin_ids.indexOf(user.id) > -1;
                      return (
                        <tr key={user.id}>
                          <td>
                            <input
                              type="checkbox"
                              onChange={() => toggleAdmin(user)}
                              checked={is_owner || is_admin}
                              disabled={is_owner}
                            />
                          </td>
                          <td>
                            <UserAvatar
                              url={user.avatar_url}
                              border_color={user.color_background}
                              size="small"
                            />
                          </td>
                          <td>
                            <Link to={`/${user.username}`} component={RLink} color="inherit">
                              <span>{user.username}</span>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </FormControl>

          <br />
          <Button
            id="btn-save"
            fullWidth
            onClick={showCroppedImage}
            disabled={!allFieldsValid() || values.loading}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {mode == "create" ? "Create Community" : "Save Changes"}
          </Button>
          <br />
          <br />
          <br />
        </form>
        {/* Must be outside the form so it doesn't get POSTed */}
        <input
          id="input-file-image"
          name="input-file-image"
          style={{ display: "none" }}
          type="file"
          onChange={onFileChange}
          accept=".jpg, .jpeg, .png"
        />
      </div>
      <Loading loading={values.loading} />
    </div>
  );
}
