import React, { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";

import {
  useLastLocation,
  LastLocationProvider,
} from "react-router-last-location";

import { Box } from "@material-ui/core";
import { CssBaseline, makeStyles } from "@material-ui/core";
import Cookies from "vendor/js.cookie.min.mjs";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SimpleSnackbar from "components/SimpleSnackbar";
import Header from "components/Header";
import HeaderLoggedOut from "components/HeaderLoggedOut";
import NewUser from "components/onboarding/NewUser";
import EditPost from "components/stories/EditPost";
//import Explore from "components/explore/Explore";
import Kink from "components/explore/Kink";
import User from "components/posts/User";
import PostFull from "components/posts/PostFull";
import Home from "components/home/Home";
import About from "components/About";
import ComingSoon from "components/ComingSoon";
import ContentGuidelines from "components/ContentGuidelines";
import Terms from "components/Terms";
import Privacy from "components/Privacy";
import Contact from "components/Contact";
import Settings from "components/Settings";
import Login from "components/Login";
import Categories from "components/groups/Categories";
import Category from "components/groups/Category";
import CreateCategory from "components/groups/CreateCategory";
import Topic from "components/groups/Topic";
import CreateTopic from "components/groups/CreateTopic";
import Alerts from "components/alerts/Alerts";
import Events from "components/events/Events";
import Event from "components/events/Event";
import Search from "components/Search";
import EventEdit from "components/events/EventEdit";
import CommunityEdit from "components/events/CommunityEdit";
import Community from "components/events/Community";
import Messages from "components/messages/Messages";
import Conversation from "components/messages/Conversation";
import CreateConversation from "components/messages/CreateConversation";
import ForgotPassword from "components/ForgotPassword";
import ResetPassword from "components/ResetPassword";
import { PusherProvider } from "@harelpls/use-pusher";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { ThemeContext } from "components/ThemeContext";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

let RoutesWithModal = withRouter((props) => {
  const { match, location, history, flash } = props;
  const [open, setOpen] = React.useState(false);

  const previousLocation = useLastLocation();

  const isModal =
    location.state &&
    location.state.modal &&
    previousLocation &&
    previousLocation !== location;

  useEffect(() => {
    if (isModal) {
      setOpen(true);
    }
  }, [location]);

  /*
  const handleOpen = (evt) => {
    console.log("open evt", evt.target)
  };

  const handleClose = () => {
  };

  const onBackdropClick = () => {
    console.log("== backdrop click")
  }

  const onClose = () => {
    //console.log("== onClose click")
    history.goBack();
  }
  */

  return (
    <div>
      {window.current_user.username && <Header history={history} />}
      {!window.current_user.username && <HeaderLoggedOut />}

      {(props.flash || []).map((flash) => {
        return <SimpleSnackbar key={flash[1]} message={flash[1]} />;
      })}
      <Switch location={isModal ? previousLocation : location}>
        <Route exact path="/p/:post_id">
          <PostFull show_follow={true} />
        </Route>
        <Route exact path="/p/edit/:post_id">
          <EditPost />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        {/*<Route path="/newuser">
          <NewUser />
        </Route>*/}
        <Route path="/coming_soon">
          <ComingSoon />
        </Route>
        <Route path="/content_guidelines">
          <ContentGuidelines />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/settings/:tab">
          <Settings history={history} />
        </Route>
        <Route path="/settings">
          <Settings history={history} />
        </Route>
        <Route path="/password/edit">
          <ResetPassword flash={props.flash} />
        </Route>
        <Route path="/password">
          <ForgotPassword flash={props.flash} />
        </Route>
        <Route path="/confirmation">
          <Login />
        </Route>
        <Route path="/messages/new">
          <CreateConversation />
        </Route>
        {/*        <Route path="/messages/push_notifications">
          <PushNotifications />
        </Route>*/}
        <Route path="/messages/:conversation_id">
          <Conversation />
        </Route>
        <Route path="/messages">
          <Messages history={history} />
        </Route>
        <Route path="/alerts">
          <Alerts />
        </Route>

        <Route exact path="/community/new">
          <CommunityEdit mode={"create"} />
        </Route>
        <Route exact path="/community/edit/:id">
          <CommunityEdit mode={"edit"} />
        </Route>
        <Route exact path="/community/:id">
          <Community />
        </Route>

        <Route exact path="/events/new/:community_id">
          <EventEdit mode={"create"} />
        </Route>
        <Route exact path="/events/edit/:event_id">
          <EventEdit mode={"edit"} />
        </Route>
        <Route path="/events/:subpage">
          <Events history={history} />
        </Route>
        <Route exact path="/event/:id">
          <Event history={history} />
        </Route>
        <Route path="/events">
          <Events history={history} />
        </Route>

{/*        <Route path="/groups">
          <Categories />
        </Route>
        <Route path="/g/:category_id/new_topic">
          <CreateTopic />
        </Route>
        <Route path="/g/:category_id/new_category">
          <CreateCategory />
        </Route>
        <Route path="/g/:parent_category_slug/:category_slug">
          <Category />
        </Route>
        <Route path="/g/:category_slug">
          <Category />
        </Route>
        <Route path="/t/:topic_slug/:topic_id">
          <Topic />
        </Route>*/}
        <Route path="/stories/tag/:tag">
          <Kink
            history={props.history}
          />
        </Route>
        <Route path="/search">
          <Search history={history} />
        </Route>
        {/*<Route path="/explore/:subpage">
          <Explore history={history} />
        </Route>
        <Route path="/explore">
          <Explore history={history} />
        </Route>*/}
        {(!window.current_user?.content_prefs_set) && (
          <Route exact path="/">
            <NewUser />
          </Route>
        )}
        {(window.current_user?.content_prefs_set) && (
            <Route exact path="/">
              <Home history={history} />
            </Route>
          )}
        <Route path="/:username">
          <User />
        </Route>
      </Switch>
      {/* isModal
        ? <Route exact path="/p/:post_id">
            <Dialog
              open={open}
              keepMounted={false}
              onClose={onClose}
              fullWidth={true}
              maxWidth="lg"
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              Dialog
            </Dialog>
          </Route>
        : null
      */}
    </div>
  );
});

function App(props) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  let defaultMode = 'light'

  if (Cookies.get("lastTheme")) {
    defaultMode = Cookies.get("lastTheme") || defaultMode
  }

  const pusher_config = window.pusher_config;
  pusher_config["clientKey"] = window.poxa_app_key;
  pusher_config["cluster"] = "cluster1";

  let initialThemeContext = {
    type: defaultMode
  };

  // About, contact, login etc etc
  if (window.rails_controller == "home" && window.rails_action != "home" && window.rails_action != "catch_all") {
    initialThemeContext.type = "light"
  }

  const [themeContext, setThemeContext] = useState(initialThemeContext);

  /*
    #ab5fff - light purple
    #7d24e0 - med purple
    #541f91 - dark purple
    #f935b3 - med pink
    #5271ff - med blue
  */
  const myTheme = createMuiTheme({
    palette: {
      type: themeContext.type, // light or dark
      background: {
        paper: (themeContext.type == 'dark' ? '#000' : '#FFF')
      },
      primary: {
        light: '#ab5fff',
        main: '#5271ff',
        dark: '#541f91'
      },
      secondary: {
        light: '#5271ff',
        main: '#7d24e0',
        dark: '#541f91'
      },
      info: {
        light: '#5271ff',
        main: '#7d24e0',
        dark: '#541f91'
      },
    },
  });

  useEffect(() => {
    if (themeContext.type == 'dark') {
      $('html').css('background', '#000')
    } else {
      $('html').css('background', '#FFF')
    }
  }, [themeContext.type]);

  return (
    <Router>
      <CssBaseline />
      <PusherProvider {...pusher_config}>
        <LastLocationProvider>
          <ThemeProvider theme={myTheme}>
            <ThemeContext.Provider value={[themeContext, setThemeContext]} >
              <div style={ { background: myTheme.palette.background.default } }>
                <RoutesWithModal flash={props.flash} />
              </div>
             </ThemeContext.Provider>
          </ThemeProvider>
        </LastLocationProvider>
      </PusherProvider>
    </Router>
  );
}

export default App;
