import React, { useState, useEffect } from "react";
import $ from "jquery";
import { Box, Container } from "@material-ui/core";
import { Link, Typography } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import { Link as RLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import UserAvatar from "components/UserAvatar";
import {
  LocationOnOutlined,
  PeopleOutline,
  PersonOutline,
  CalendarTodayOutlined,
  ThumbUp,
  ThumbDown,
} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import TabPanel from "components/TabPanel";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import EventAbbrev from "components/events/EventAbbrev";
import { Button } from "@material-ui/core";
import CommonStyles from "components/styles/CommonStyles";
import DTOW from "vendor/distance_of_time_in_words.js";
import Loading from "components/Loading";
import { PostBrowseContext } from "components/posts/PostBrowseContext";
import Posts from "components/posts/Posts";
import CreatePost from "components/posts/CreatePost";
import { useTheme } from '@material-ui/core/styles';
import StoriesFeed from "components/stories/StoriesFeed";
import * as PostService from "../../services/posts.service";
import CreateStoryIcon from "components/stories/CreateStoryIcon"

export default function Community(props) {
  const commonStyles = CommonStyles();
  const theme = useTheme();

  const initialValues = {
    community: {
      members_by_id: {},
      members: [],
      pending_members: [],
      declined_members: [],
      temp_banned_members: [],
      perm_banned_members: [],
      events_upcoming: [],
      events_ended: [],
    },
    stories: [],
    current_community_story: null,
    hasMore: true,
    loaded: false,
    lastId: "",
    attending: true,
    organizing: false,
    loading: true,
  };
  const [values, setValues] = useState(initialValues);

  const [tab, setTab] = React.useState("posts");

  let { id } = useParams();

  function fetchCommunity() {
    $.ajax({
      url: `/community/${id}.json`,
      type: "GET",
      async: window.rails_env != "test",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      success: function (data) {
        //console.log("DATA", data)
        setValues((state) => ({
          ...state,
          community: data,
          loading: false,
        }));
      },
      cache: false,
      contentType: false,
      processData: false,
    });
  }

  useEffect(() => {
    setValues((state) => ({
      ...state,
      loading: true,
    }));

    fetchCommunity();
    fetchDataStories(true)
  }, []);

  let fetchDataStories = (clear) => {
    let lastId = "";
    if (!clear) {
      lastId = values.lastId;
    }

    if (clear) {
      setValues((state) => ({
        ...state,
        loaded: false
      }));
    }

    //let url = `/stories.json?post_tab=${props.tab}`
    let url = `/stories/community/${id}.json?clear=${clear ? 'true' : 'false'}`
    if (lastId) {
      url += `&last_id=${lastId}`
    }
    $.ajax({
      url: url,
      type: "GET",
      async: window.rails_env != "test",
      success: function (data) {
        let lastId = "";
        for (let i = 0; i < data.stories.length; i++) {
          let id = data.stories[i].id
          if (!lastId || id < lastId) {
            lastId = id
          }
        }

        setValues((state) => ({
          ...state,
          stories: clear ? data.stories : values.stories.concat(data.stories),
          current_community_story: data.current_community_story,
          lastId: lastId,
          hasMore: data.stories.length > 0,
          loaded: true,
        }));
        PostService.disableRightClick();
      },
      cache: false,
      contentType: false,
      processData: false,
    });
  };

  const {
    owner_id,
    name,
    tagline,
    city,
    region,
    country,
    has_location,
    member_approvals_join,
    member_approvals_events,
    member_approvals_posts,
    community_image_url,
    description_cooked,
    gallery,
    admin_ids,
    members_by_id,
    created_at,
    your_membership,
    members,
    pending_members,
    declined_members,
    temp_banned_members,
    perm_banned_members,
    events_upcoming,
    events_ended,
    num_posts,
    private_pictures,
    private_events,
  } = values.community;

  let is_owner = owner_id == window.current_user.id;
  let is_member = members_by_id[window.current_user.id] ? true : false;

  let owner = {};
  if (values.community.owner_id) {
    owner = members_by_id[values.community.owner_id];
  }

  // Members dialog
  const [openDialogMembers, setOpenDialogMembers] = useState(false);

  function handleMemberClick() {
    setOpenDialogMembers(true);
  }

  function closeDialogMembers() {
    setOpenDialogMembers(false);
  }

  // Pending members dialog
  const [openDialogPendingMembers, setOpenDialogPendingMembers] =
    useState(false);

  function handlePendingMemberClick() {
    setOpenDialogPendingMembers(true);
  }

  function closeDialogPendingMembers() {
    setOpenDialogPendingMembers(false);
  }

  function joinGroup() {
    $.ajax({
      url: `/community/${values.community.id}/join`,
      type: "GET",
      async: window.rails_env != "test",
      data: {},
      success: function (data) {
        if (data.success) {
          let community = values.community;
          community.your_membership = data.membership;

          setValues((state) => ({
            ...state,
            community: community,
          }));

          window.toastr.success("You have applied to join this community!");
        } else {
          window.toastr.error(data.error);
        }
      },
    });
  }

  function leaveCommunity() {
    if (confirm("Are you sure?")) {
      $.ajax({
        url: `/community/${values.community.id}/leave`,
        type: "GET",
        async: window.rails_env != "test",
        data: {},
        success: function (data) {
          if (data.success) {
            let community = values.community;
            community.your_membership = null;

            setValues((state) => ({
              ...state,
              community: community,
            }));

            window.toastr.success("You have left this community!");
          } else {
            window.toastr.error(data.error);
          }
        },
      });
    }
  }

  function acceptMember(member_id) {
    $.ajax({
      url: `/community/${id}/accept.json?member_id=${member_id}`,
      type: "GET",
      async: window.rails_env != "test",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      success: function (data) {
        if (data.success) {
          fetchCommunity();
        } else {
          window.toastr.error(data.error);
        }

        window.toastr.success("You have approved this membership!");
      },
      cache: false,
      contentType: false,
      processData: false,
    });
  }

  function rejectMember(member_id) {
    $.ajax({
      url: `/community/${id}/reject.json?member_id=${member_id}`,
      type: "GET",
      async: window.rails_env != "test",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      success: function (data) {
        if (data.success) {
          fetchCommunity();

          window.toastr.info("You have declined this membership!");
        } else {
          window.toastr.error(data.error);
        }
      },
      cache: false,
      contentType: false,
      processData: false,
    });
  }

  function handleClickTab(clickedTab) {
    setTab(clickedTab);
  }

  let initialPostBrowseContext = {
    dialog_open: false,
    cur_post_id: -1,
    cur_post_index: -1,
    post_ids: [],
  };

  const [postBrowseContext, setPostBrowseContext] = useState(
    initialPostBrowseContext
  );

  return (
    <div
      id="event"
      className={commonStyles.container}
      style={ { paddingTop: 0 } }
    >
      <div style={{ display: values.loading ? "none" : "block" }}>
        <Box>
          <img
            src={community_image_url}
            style={{ width: "100%", maxWidth: "100%" }}
          />
        </Box>
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pl={.5}
            pr={.5}
          >
            <Typography
              variant="h5"
              style={{
                fontWeight: 900,
              }}
            >
              {name}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pl={.5}
            pr={.5}
          >
            <Typography
              variant="h6"
              style={{
                fontWeight: 300,
              }}
            >
              {tagline}
            </Typography>
          </Box>
          {is_owner && (
            <Box pl={.5} pr={.5} pt={.5}>
              <Link
                to={`/community/edit/${id}`}
                component={RLink}
                id="edit-community"
              >
                Edit Community
              </Link>
            </Box>
          )}
          <Box pl={.5} pr={.5} pt={.5}>
            {has_location && (
              <Box display="flex" alignItems="center">
                <LocationOnOutlined />
                <Box ml={1} component="span">
                  <span>
                    {city}, {region}, {country}
                  </span>
                </Box>
              </Box>
            )}
            <Box mt={1} display="flex" alignItems="center">
              <PeopleOutline />
              <Box ml={1} component="span">
                {members.length} member{members.length == 1 ? "" : "s"}
                {/*&middot; public group*/}
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <PersonOutline />
              {/*<Box ml={1}>
                <Link to={'/' + owner.username} component={RLink}>
                  <UserAvatar url={owner.avatar_url} border_color={owner.color_background} size="small" />
                </Link>
              </Box>*/}
              <Box ml={1} component="span">
                Organized by{" "}
                <Link to={"/" + owner.username} component={RLink}>
                  <span>{owner.username}</span>
                </Link>
              </Box>
              <span>
                &nbsp;&middot;&nbsp;
                <Link to={"/" + owner.username} component={RLink}>
                  <span>message</span>
                </Link>
              </span>
            </Box>
            <Box display="flex" alignItems="center">
              <CalendarTodayOutlined />
              <Box ml={1} component="span">
                Created{" "}
                {DTOW.distance_of_time_in_words(
                  new Date(),
                  new Date(created_at),
                  false,
                  { vague: true }
                )}{" "}
                ago
              </Box>
            </Box>
            <Box mt={2} pl={.5} pr={.5}>
              <b>Consent</b>&nbsp;(set by the community organizer):
              <table>
                <tbody>
                  <tr><td>Who can see posts?&nbsp;</td><td><b>{(values.community.private_pictures ? "Community members only" : "Anyone") }</b></td></tr>
                  <tr><td>Who can join?&nbsp;</td><td><b>{(values.community.member_approvals_join ? "Any user an admin approves" : "Anyone") }</b></td></tr>
                  <tr><td>Who can post?&nbsp;</td><td><b>{(values.community.member_approvals_posts ? "Members, requires admin approval" : "Members, no approval needed") }</b></td></tr>
                </tbody>
              </table>
            </Box>
            <Box mt={2} pl={.5} pr={.5} display="flex" justifyContent="center">
              {window.current_user.confirmed_at && your_membership && (
                <div style={{ width: "100%" }}>
                  {your_membership.status == 0 && (
                    <span>
                      You have applied for membership to this community! If the
                      organizer consents, you will be alerted to new events in
                      this group and will be able to see private events.
                    </span>
                  )}
                  {your_membership.status == 1 && (
                    <Button
                      id="community-leave"
                      style={{
                        width: "100%",
                      }}
                      variant="contained"
                      color="default"
                      onClick={leaveCommunity}
                    >
                      Leave Community
                    </Button>
                  )}
                  {your_membership.status == 2 && (
                    <span>
                      Your membership has been declined by the organizer.
                    </span>
                  )}
                  {your_membership.status == 3 && (
                    <span>
                      You have been temporarily banned from this community!
                    </span>
                  )}
                  {your_membership.status == 4 && (
                    <span>
                      You have been permanently banned from this community!
                    </span>
                  )}
                </div>
              )}
              {window.current_user.confirmed_at && !your_membership && (
                <Button
                  id="community-join"
                  style={{
                    width: "100%",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={joinGroup}
                >
                  Join Community
                </Button>
              )}
              {!window.current_user.confirmed_at && (
                <div style={{ marginTop: 8 }}>
                  <font color="red">Check your email!</font> Your email address
                  is not confirmed so you can't join communities or attend events.&nbsp;
                  <a href="/resend-confirmation">Resend confirmation</a>
                </div>
              )}
            </Box>
          </Box>

          {/* Pending members dialog */}
          <Dialog
            id="dialog-members"
            open={openDialogMembers}
            keepMounted={false}
            onClose={closeDialogMembers}
            scroll="paper"
            classes={{ paper: commonStyles.dialog90 }}
          >
            <div style={{ marginTop: 12 }}>
              <div style={{ height: 200 }}>
                {members.map(function (member, index) {
                  let user = members_by_id[member.user_id];
                  return (
                    <div
                      key={user.id}
                      className={commonStyles.flexRowCenter}
                      style={{ paddingBottom: "10px" }}
                    >
                      <div style={{ flexBasis: "70px" }}>
                        <center>
                          <UserAvatar
                            url={user.avatar_url}
                            border_color={user.color_background}
                            size="small"
                          />
                        </center>
                      </div>
                      <div style={{ flexGrow: 1 }}>
                        <Link to={`/${user.username}`} component={RLink}>
                          <span>{user.username}</span>
                          {admin_ids.indexOf(user.id) > -1 &&
                            owner_id != user.id && <span> (admin)</span>}
                          {owner_id == user.id && <span> (owner)</span>}
                        </Link>
                      </div>
                      <div style={{ flexBasis: "32px" }}>
                        {member.user_id == window.current_user.id && (
                          <font color="#ddd">
                            <ThumbDown />
                            &nbsp;
                          </font>
                        )}
                        {member.user_id != window.current_user.id && (
                          <font color="red">
                            <ThumbDown
                              className="hand member-reject"
                              onClick={() => rejectMember(member.id)}
                            />
                            &nbsp;
                          </font>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Dialog>

          {members && members.length > 0 && (
            <Box mt={1.5} pl={.5} pr={.5}>
              <Typography
                variant="h6"
                style={{
                  fontWeight: 800,
                }}
              >
                Members ({members.length})
              </Typography>
              <Box mt={1.5}>
                <a
                  id="show-members"
                  onClick={(evt) => {
                    handleMemberClick();
                    evt.preventDefault();
                  }}
                >
                  <AvatarGroup
                    max={5}
                    style={{
                      width:
                        members.length > 4 ? 180 - 10 : members.length * 45,
                      alignSelf: "center",
                    }}
                  >
                    {members.map((member) => {
                      return (
                        <UserAvatar
                          key={member.user_id}
                          url={members_by_id[member.user_id].avatar_url}
                          border_color={
                            members_by_id[member.user_id].color_background
                          }
                          size="small"
                        />
                      );
                    })}
                  </AvatarGroup>
                </a>
              </Box>
            </Box>
          )}
          {is_owner && (
            <div>
              {/* Pending members dialog */}
              <Dialog
                id="dialog-pending-members"
                open={openDialogPendingMembers}
                keepMounted={false}
                onClose={closeDialogPendingMembers}
                scroll="paper"
                classes={{ paper: commonStyles.dialog90 }}
              >
                <div style={{ marginTop: 12 }}>
                  <div style={{ height: 200 }}>
                    {pending_members.map(function (member, index) {
                      let user = members_by_id[member.user_id];
                      return (
                        <div
                          key={user.id}
                          className={commonStyles.flexRowCenter}
                          style={{ paddingBottom: "10px" }}
                        >
                          <div style={{ flexBasis: "70px" }}>
                            <center>
                              <UserAvatar
                                url={user.avatar_url}
                                border_color={user.color_background}
                                size="small"
                              />
                            </center>
                          </div>
                          <div style={{ flexGrow: 1 }}>
                            <Link to={`/${user.username}`} component={RLink}>
                              <span>{user.username}</span>
                            </Link>
                          </div>
                          <div style={{ flexBasis: "60px" }}>
                            {member.status == 2 && (
                              <font color="#ddd">
                                <ThumbDown />
                                &nbsp;
                              </font>
                            )}
                            {member.status == 0 && (
                              <font color="red">
                                <ThumbDown
                                  className="hand member-reject"
                                  onClick={() => rejectMember(member.id)}
                                />
                                &nbsp;
                              </font>
                            )}
                            <font color="green">
                              <ThumbUp
                                className="hand member-accept"
                                onClick={() => acceptMember(member.id)}
                              />
                            </font>
                            <br />
                            {member.status == 2 && <span>Declined</span>}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Dialog>

              <Box mt={1.5} pl={.5} pr={.5}>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: 800,
                  }}
                >
                  Membership Requests ({pending_members.length})
                </Typography>
                <Box mt={1.5}>
                  {/* todo: modal
                    <p>
                      <a onClick={ (evt) => { handleGroupImageClick(); evt.preventDefault() } }>
                        Declined + Banned ({ declined_members.length + temp_banned_members.length + perm_banned_members.length })
                      </a>
                    </p> */}
                  <a
                    id="show-pending-members"
                    onClick={(evt) => {
                      handlePendingMemberClick();
                      evt.preventDefault();
                    }}
                  >
                    <AvatarGroup max={5}>
                      {pending_members.map((member) => (
                        <UserAvatar
                          key={member.user_id}
                          url={members_by_id[member.user_id].avatar_url}
                          border_color={
                            members_by_id[member.user_id].color_background
                          }
                          size="small"
                        />
                      ))}
                    </AvatarGroup>
                  </a>
                  {!pending_members ||
                    (pending_members.length == 0 && (
                      <span>No pending memberships</span>
                    ))}
                </Box>
              </Box>
              <Box mt={1.5}></Box>
            </div>
          )}

          <AppBar position="static" color="inherit" elevation={1}>
            <Tabs
              classes={{
                root: commonStyles.tabsRoot,
                scroller: commonStyles.tabsScroller,
              }}
              variant="fullWidth"
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="auto"
            >
              <Tab
                onClick={() => {
                  handleClickTab("posts");
                }}
                label={`Posts (${num_posts})`}
                value={"posts"}
                id="index-0"
              />
              <Tab
                onClick={() => {
                  handleClickTab("events");
                }}
                label={`Events (${
                  (events_upcoming || []).length + (events_ended || []).length
                })`}
                value={"events"}
                id="index-1"
              />
              <Tab
                onClick={() => {
                  handleClickTab("about");
                }}
                label={`About`}
                value={"about"}
                id="index-2"
              />
            </Tabs>
          </AppBar>

          <TabPanel id={`tabpanel-posts`} value={tab} index="posts" style={{}}>
            <div style={{ minHeight: 500 }}>
              {(!private_pictures || (private_pictures && is_member)) && (
                <div style={ { padding: 8 } }>
                  <p>Post to <b>{values.community.name}</b>:</p>

                  <div onClick={ () => { } } className="hand" key='add-story' style={ { width: 0 } }>
                    <CreateStoryIcon
                      current_user_story={null}
                      current_community_story={values.current_community_story}
                      community={values.community}
                    />
                  </div>
                </div>
              )}
              {(!private_pictures || (private_pictures && is_member)) && (
                <StoriesFeed
                  stories={values.stories}
                  hasMore={values.hasMore}
                  loaded={values.loaded}
                  fetchData={fetchDataStories}
                />
              )}
              {private_pictures && !is_member && (
                <center>
                  <b>
                    This community has chosen to keep its posts private for
                    non-members
                  </b>
                </center>
              )}
            </div>
          </TabPanel>

          <TabPanel
            id={`tabpanel-events`}
            value={tab}
            index="events"
            style={{}}
          >
            <div style={{ minHeight: 500, paddingLeft: 8, paddingRight: 8 }}>
              {(!private_events || (private_events && is_member)) && (
                <Box mt={1.5}>
                  <span style={{ float: 'right', paddingTop: 6 } }>
                    <Link
                      to={`/events/new/${id}`}
                      component={RLink}
                      id="create-event"
                      color="inherit"
                    >
                      <font color="#5271ff">Create New Event</font>
                    </Link>
                  </span>

                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Upcoming Events:
                  </Typography>
                  <br />

                  {(events_upcoming || []).map(function (event, index) {
                    return (
                      <EventAbbrev event={event} key={event.id}>
                        {event.name}
                      </EventAbbrev>
                    );
                  })}
                  {!events_upcoming ||
                    (events_upcoming.length == 0 && <p>No upcoming events</p>)}

                  <br />
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: 800,
                    }}
                  >
                    Past Events:
                  </Typography>
                  <br />

                  {(events_ended || []).map(function (event, index) {
                    return (
                      <EventAbbrev event={event} key={event.id}>
                        {event.name}
                      </EventAbbrev>
                    );
                  })}
                  {!events_ended ||
                    (events_ended.length == 0 && <p>No past events</p>)}
                </Box>
              )}
              {private_events && !is_member && (
                <center>
                  <b>
                    This community has chosen to keep its events private for
                    non-members
                  </b>
                </center>
              )}
            </div>
          </TabPanel>
          <TabPanel id={`tabpanel-about`} value={tab} index="about" style={{}}>
            <div style={{ minHeight: 500, paddingLeft: 8, paddingRight: 8 }}>
              <Typography
                variant="h6"
                style={{
                  fontWeight: 800,
                }}
              >
                About us
              </Typography>
              <div
                className="cooked"
                style={{ background: theme.palette.background.default, padding: 2 }}
              >
                <span
                  dangerouslySetInnerHTML={{ __html: description_cooked }}
                />
              </div>
            </div>
          </TabPanel>
        </Box>
      </div>
      <Loading loading={values.loading} />
    </div>
  );
}
