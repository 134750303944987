import React, { useState } from "react";
import { Container } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";
import Loading from "components/Loading";
import CommonStyles from "components/styles/CommonStyles";

export default function CreateConversation() {
  const classes = CommonStyles();

  let { conversation_id } = useParams();

  const initialValues = {
    recipients: [],
    loading: false,
  };
  const [values, setValues] = useState(initialValues);

  return (
    <div id="messages" className={classes.container}>
      <div style={{ display: values.loading ? "none" : "block" }}>
        <Typography variant="h5">Send Message</Typography>
      </div>
      <Loading loading={values.loading} />
    </div>
  );
}
