import React from "react";
import { makeStyles } from "@material-ui/core";
import { Avatar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  extra_small: {
    width: 30,
    height: 30,
  },
  small: {
    width: 45,
    height: 45,
  },
  medium: {
    width: 78,
    height: 78,
  },
  large: {
    width: 150,
    height: 150,
  },
  extralarge: {
    width: 200,
    height: 200,
  },
}));

export default function UserAvatar(props) {
  const classes = useStyles();

  return (
    <Avatar
      variant="circle"
      title={props.username}
      src={props.url || "/default-profile.svg"}
      className={classes[props.size] || classes.small}
      style={{
        border: `solid 2px ${props.border_color || "white"}`,
      }}
    />
  );
}
