import React, { useEffect } from "react";
import $ from "jquery";
import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core";

export default function Loading(props) {
  const theme = useTheme();

  function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  let val = getRandomInt(12);

  useEffect(() => {
    // Pre-load the loading images so they display asap
    for (let i = 1; i <= 12; i++) {
      let img = new Image();
      img.id = `loading-image-${i}`;
      img.src = `/static/loading/loading${i}.svg`;
      if ($(`#loading-image-${i}`).length == 0) {
        $(img).css("display", "none");
        $("body").append(img);
      }
    }
  }, []);

  let img = `/static/loading/loading${val + 1}.svg`;
  return (
    <div>
      {props.loading && (
        <div
          style={{
            display: props.loading ? "flex" : "none",
            height: "300px",
            width: "100%",
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!props.load_error && (
            <img
              src={img}
              style={{
                width: props.width || "200px",
                height: props.height || "200px",
              }}
            />
          )}
          {props.load_error && (
            <Box ml={3} mr={3}>
              <font size={2} color="red">
                {props.load_error}
              </font>
            </Box>
          )}
        </div>
      )}
    </div>
  );
}
