import React, { useState, useEffect, useContext } from "react";
import $ from "jquery";
import DTOW from "vendor/distance_of_time_in_words.js";
import { Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import * as TextTransformationService from "../../services/text_transformation.service";
import IconButton from "@material-ui/core/IconButton";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import UserList from "components/UserList";
import { Link, Typography } from "@material-ui/core";
import { Link as RLink } from "react-router-dom";
import { useChannel, useEvent } from "@harelpls/use-pusher";
import UserAvatar from "components/UserAvatar";
import goBack from "go_back";
import Loading from "components/Loading";
import CommonStyles from "components/styles/CommonStyles";
import ImageEditor from "components/ImageEditor";
import Thumbnails from "components/posts/Thumbnails";
import Menu from "components/posts/Menu";
import { CreatePostContext } from "components/posts/CreatePostContext";
import * as PostService from "../../services/posts.service";
import Can from "can_permission";
import ArrowBack from "@material-ui/icons/ArrowBack";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { ThemeContext } from "components/ThemeContext";

export default function Conversation() {
  const classes = CommonStyles();

  let [themeContext, setThemeContext] = useContext(ThemeContext);

  // Must match messaging_service.rb#MESSAGES_PER_PAGE
  let MESSAGES_PER_PAGE = 20;

  let { conversation_id } = useParams();

  /*
   * 1. Shared menu state
   * 2. Shared upload state
   * 3. selectedUpload.state == shared image editor state
   */
  const [context, setContext] = useState(PostService.initialContext());

  const initialValues = {
    current_user: {},
    other_user: {},
    other_users: [],
    messages: [],
    assets_by_id: [],
    users: {},
    user_list: [],
    can_send_message: false,
    can_send_image: false,
    draft: "",
    initialLoad: true,
    event_group_chat: 0,
    event: {},
    last_id: "",
    hasMore: true,
    loading: true,
  };
  const [values, setValues] = useState(initialValues);

  const [seconds, setSeconds] = useState(null);

  // Update the 'X minutes ago' labels beneath messages by updating an unused seconds method
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(Date.now());
    }, 1000 * 3);
    return () => clearInterval(interval);
  }, []);

  let callbackResize = null;

  /*
   * Load initial page of conversation
   */
  useEffect(() => {
    setValues((state) => ({
      ...state,
      ["loading"]: true,
    }));

    fetchData();

    // Window resized or orientation changed: resize #messages
    function resize() {
      let ht = `${window.innerHeight - 88}px`;
      $("#messages").height(ht);
    }

    resize();

    $(window).on("resize", () => {
      if (callbackResize) {
        clearTimeout(callbackResize);
      }
      callbackResize = setTimeout(() => {
        resize();
      }, 150);
    });
  }, []);

  function fetchData() {
    $.ajax({
      url: `/api/v1/messaging/conversation/${conversation_id}?last_id=${values.last_id}`,
      type: "GET",
      async: window.rails_env != "test",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      success: function (data) {
        let last_id = "";
        if (data.messages.length > 0) {
          last_id = data.messages[data.messages.length - 1].id;
        }

        // Useful array of the other users in this conversation
        let other_users = [];
        for (let i = 0; i < data.conversation.user_ids.length; i++) {
          let user_id = data.conversation.user_ids[i];
          if (user_id != window.current_user.id) {
            other_users.push(data.users_by_id[user_id]);
          }
        }
        let other_user = other_users[0];

        let user_list = [];
        data.user_list_alpha.forEach((user_id) => {
          user_list.push(data.users_by_id[user_id]);
        });
        // console.log("data.messages.length == MESSAGES_PER_PAGE", data.messages.length == MESSAGES_PER_PAGE)
        setValues((state) => ({
          ...state,
          conversation: data.conversation,
          messages: values.messages.concat(data.messages),
          assets_by_id: Object.assign(values.assets_by_id, data.assets_by_id),
          users_by_id: data.users_by_id,
          user_list: user_list,
          event: data.event,
          last_id: last_id,
          hasMore: data.messages.length == MESSAGES_PER_PAGE,
          initialLoad: false,
          loading: false,
        }));

        let can_send_message = false;
        let can_send_image = false;
        if (data.event) {
          // normal consent rules are bypassed for event chats
          can_send_message = true;
          can_send_image = false;
        } else {
          // check consent for other user
          // todo: for group chats check consent for all users
          can_send_message = Can(
            "send_messages",
            other_user.settings,
            other_user.relation
          );
          can_send_image = Can(
            "send_images",
            other_user.settings,
            other_user.relation
          );
        }

        // conversation returned is null:
        if (other_users.length > 0) {
          setValues((state) => ({
            ...state,
            other_users: other_users,
            other_user: other_user, // todo: stop using for group chat
            can_send_message: can_send_message,
            can_send_image: can_send_image,
          }));
        }
      },
    });
  }

  // User is typing a message
  function handleChangeDraft(evt) {
    const { name, value } = evt.target;
    setValues((state) => ({
      ...state,
      ["draft"]: value,
    }));
  }

  // If enter is pressed, send the message
  function handleKeyDown(evt) {
    const { name, value } = evt.target;

    if (evt.keyCode === 13 && !evt.shiftKey) {
      evt.preventDefault();

      if (sendEnabled()) {
        handleMessageSend();
      }
    }
  }

  /*
   * Send message and add it to values.messages
   */
  function handleMessageSend() {
    let draft = values.draft;

    let fd = new FormData();

    if (context.upload) {
      // Show loading screen if sending images
      setValues((state) => ({
        ...state,
        ["loading"]: true,
      }));

      // Image uploads and text
      setShowUploads(true);

      // wait for render
      setTimeout(function () {
        //window.finalize(640, 640 * 2).then((blobs) => {
          setShowUploads(false);

          console.log("uploads", context.uploads, "submitMessageForm", submitMessageForm)
          PostService.populateFormUploads(
            fd,
            context.upload,
            submitMessageForm
          );
        //});
      }, 1);
    } else {
      // Just text
      submitMessageForm();
    }

    function submitMessageForm() {
      fd.append("conversation_id", conversation_id);
      fd.append("message", draft);
      fd.append("authenticity_token", window.auth_token);

      $.ajax({
        url: "/api/v1/messaging/conversation",
        type: "POST",
        async: window.rails_env != "test",
        data: fd,
        success: function (data) {
          setValues((state) => ({
            ...state,
            ["draft"]: "",
            ["loading"]: false,
          }));
          setContext((state) => ({
            ...state,
            upload: null,
          }));

          handleMessageReceived(data);
        },
        error: function (data) {
          setValues((state) => ({
            ...state,
            ["loading"]: false,
          }));

          console.error("Error creating new message", data);
          callbackError(data);
          throw "Error creating new message";
        },
        cache: false,
        contentType: false,
        processData: false,
      });
    }
  }

  let alerts_channel = useChannel("private-messages-" + window.current_user.id);

  // Recipient just got an incoming message sent via the above
  useEvent(alerts_channel, "message_received", function (data) {
    handleMessageReceived(data);

    // ajax mark as seen
    $.ajax({
      url: `/api/v1/messaging/seen/${data.message.id}`,
      type: "PUT",
      async: window.rails_env != "test",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      success: function (data) {
        // no-op
      },
    });
  });

  /*
   * An incoming message was found, either from:
   * 1: this user sent a message and got an AJAX response
   * 2: another user sent a message so this user get a websockets response
   */
  function handleMessageReceived(data) {
    let messages = values.messages;
    messages.unshift(data.message);

    let assets_by_id = { ...values.assets_by_id, ...data.assets }; // merge the arrays

    setValues((state) => ({
      ...state,
      ["messages"]: messages,
      ["assets_by_id"]: assets_by_id,
    }));
  }

  /*
   * Modal crap
   */
  const [showUploads, setShowUploads] = React.useState(false);

  const onClose = () => {
    setShowUploads(false);
  };

  /*
   * Upload editor stuff
   */
  function sendPhotoStart() {
    $("#input-file").click();
  }

  function filesUploaded() {
    let fileList = $("#input-file")[0].files;
    let arrFiles = Array.from(fileList);
    console.warn("filelist", fileList)

    //filesUploaded(arrFiles)
    PostService.onDrop(arrFiles).then((upload) => {
      setContext((state) => ({
        ...state,
        upload: upload,
      }));
      console.log("uploads[0]", upload)
      setShowUploads(true);
      // Show the edit at least once so window.finalize is set
    });
  }

  // Selected a thumbnail from the <Thumbnail />
  function selectUpload(idx) {
    setShowUploads(true);
    // setContext((state) => ({
    //   ...state,
    //   ["selectedUpload"]: context.uploads[idx],
    // }));
  }

  function save() {
    setShowUploads(false);
  }

  function save_and_send() {
    handleMessageSend();
  }

  function del_upload() {
    if (confirm("Are you sure you want to remove this image?")) {
      setContext((state) => ({
        ...state,
        upload: null,
      }));
      setShowUploads(false);
    }
  }

  function sendEnabled() {
    return (
      (values.draft.length > 0 || context.upload) &&
      values.can_send_message &&
      !values.loading
    );
  }

  // My Followers dialog
  const [openDialogGroupUsers, setOpenDialogGroupUsers] = useState(false);

  function handleGroupImageClick() {
    setOpenDialogGroupUsers(true);
  }

  function closeDialogGroupUsers() {
    setOpenDialogGroupUsers(false);
  }

  //let permission_data = { following: true, follower: true, kin_to_them: true, kin_from_them: true }
  const commonStyles = CommonStyles();

  /* Hamburger menu crap */

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let blocked_user_ids = {}
  if (current_user && current_user.user_block_ids) {
    for (let i = 0; i < current_user.user_block_ids.length; i++) {
      let uid = current_user.user_block_ids[i]
      blocked_user_ids[uid] = true
    }
  }

  function unblockMsg(id) {
    $('#message-' + id).show()
    $('#unblocked-' + id).hide()
  }

  function imageClass() {
    return (themeContext.type == "dark" ? "no-dark-mode" : "")
  }

  // height: 100vh - 72 for header, -16px for margin-top for container, -32px for texting area
  return (
    <div
      id="messages"
      className={classes.containerNoPadding}
      style={{ height: "calc(100vh - 72px - 16px)", overflow: "none" }}
    >
      {/* Pic/video upload editor */}
      <input
        id="input-file"
        type="file"
        style={{ display: "none" }}
        multiple={false}
        accept=".jpg, .jpeg, .png"
        onChange={filesUploaded}
      />

      <Dialog
        id="dialog-group-message-users"
        open={openDialogGroupUsers}
        keepMounted={false}
        onClose={closeDialogGroupUsers}
        scroll="paper"
        classes={{ paper: commonStyles.dialog90 }}
      >
        <div style={{ marginTop: 12 }}>
          <UserList show_follow={false} users={values.user_list || []} />
        </div>
      </Dialog>

      {/* Top area: back, otherusername, flag */}
      <div
        style={{ height: "100%", display: !values.loading ? "flex" : "none" }}
        className={classes.flexCol}
      >
        <AppBar position="static" color="transparent">
          <Toolbar variant="dense" disableGutters={true}>
            <IconButton onClick={goBack} color="inherit">
              <ArrowBack />
            </IconButton>
            <div
              className="ellipsis"
              style={{
                flexGrow: 1,
                flexShrink: 1,
                height: 65,
                paddingTop: 10,
                alignItems: "center",
              }}
            >
              {values.other_users.length == 1 && (
                <center>
                  <Typography variant="h4">
                    <Link
                      to={`/${values.other_user.username}`}
                      component={RLink}
                    >
                      <span>{values.other_user.username}</span>
                    </Link>
                  </Typography>
                </center>
              )}
              {values.other_users.length > 1 && (
                <center>
                  <a
                    onClick={(evt) => {
                      handleGroupImageClick();
                      evt.preventDefault();
                    }}
                  >
                    <AvatarGroup
                      max={4}
                      style={{
                        width:
                          values.conversation.user_ids.length > 3
                            ? 180 - 10
                            : values.conversation.user_ids.length * 45,
                        alignSelf: "center",
                      }}
                    >
                      {values.conversation.user_ids.map((user_id) => (
                        <UserAvatar
                          key={user_id}
                          url={values.users_by_id[user_id].avatar_url}
                          border_color={
                            values.users_by_id[user_id].color_background
                          }
                          size="small"
                        />
                      ))}
                    </AvatarGroup>
                  </a>
                </center>
              )}
            </div>
            {/*            <IconButton
              onClick={handleMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <MuiMenu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={() => { alert("Reporting should be available by August. 😓") }}>Flag</MenuItem>
            </MuiMenu>*/}
          </Toolbar>

          {values.event?.id && (
            <Toolbar variant="dense" disableGutters={true}>
              <div
                className="ellipsis"
                style={{
                  flexGrow: 1,
                  height: 40,
                  paddingTop: 4,
                  alignItems: "center",
                }}
              >
                <center>
                  <span>
                    <Link to={`/event/${values.event.id}`} component={RLink}>
                      <span>{values.event.name}</span>
                    </Link>
                    <div>{values.event.starts_in}</div>
                  </span>
                </center>
              </div>
            </Toolbar>
          )}
        </AppBar>
        {/* Messages area */}

        {(!showUploads || !context.upload) && (
          <div
            id="scrollableMessages"
            style={{
              flexGrow: 1,
              overflowY: "auto",
              flexDirection:
                values.messages.length == 0 ? "column" : "column-reverse",
            }}
            className={`${classes.flexCol} chat-container`}
          >
            <InfiniteScroll
              dataLength={values.messages.length}
              scrollableTarget="scrollableMessages"
              next={fetchData}
              hasMore={values.hasMore}
              style={{ display: "flex", flexDirection: "column-reverse" }}
              inverse={true}
              loader={
                <center>
                  <img src="/static/loading/loading1.svg" />
                </center>
              }
              scrollThreshold={0.8}
              endMessage={
                <p
                  style={{
                    textAlign: "center",
                    display: values.messages.length > 0 ? "block" : "none",
                  }}
                ></p>
              }
            >
              {values.messages.length > 0 &&
                values.messages.map((msg) => (
                  <div
                    key={msg.id}
                    style={{
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 2,
                      paddingBottom: 4,
                    }}
                  >
                    <div
                      className={
                        `${classes.flexRow} ` +
                        (msg.from_user_id != window.current_user.id
                          ? "item-left"
                          : "item-right justify-end")
                      }
                    >
                      {msg.from_user_id != window.current_user.id && (
                        <UserAvatar
                          url={values.users_by_id[msg.from_user_id]?.avatar_url}
                          size="small"
                          border_color={
                            values.users_by_id[msg.from_user_id]
                              ?.color_background
                          }
                        />
                      )}
                      <div
                        className={"flex ml-1"}
                        style={{ flexDirection: "column" }}
                      >
                        {msg.from_user_id != window.current_user.id &&
                          values.conversation.user_ids.length > 2 && (
                            <div>
                              <Link
                                to={`/${
                                  values.users_by_id[msg.from_user_id].username
                                }`}
                                component={RLink}
                                color="inherit"
                              >
                                <span>
                                  {
                                    values.users_by_id[msg.from_user_id]
                                      .username
                                  }
                                </span>
                              </Link>
                            </div>
                          )}
                        <div
                          style={{
                            background:
                              values.users_by_id[msg.from_user_id]
                                ?.color_background,
                          }}
                          className={
                            "ion-badge text-left leading-tight ion-color ion-color-dark ios hydrated"
                          }
                        >
                          {
                            blocked_user_ids[msg.from_user_id] && (
                              <div id={`unblocked-${msg.id}`} onClick={ () => unblockMsg(msg.id) } className="hand blocked-message">... (user blocked) ...</div>
                            )
                          }
                          {/* Actual message text: */}
                          <div
                            id={`message-${msg.id}`}
                            style={ { 'display': (blocked_user_ids[msg.from_user_id] ? 'none' : 'block') } }
                            dangerouslySetInnerHTML={{
                              __html: TextTransformationService.textToLinkedURL(
                                msg.message
                              ),
                            }}
                          />
                          {msg.asset_ids > 0 && <br />}
                          {msg.asset_ids.map((asset_id) => (
                            <img
                              key={asset_id}
                              src={values.assets_by_id[asset_id.toString()]?.url}
                              style={{ width: "100%", maxWidth: "400px" }}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        paddingLeft: 48,
                        fontSize: ".8em",
                        color: "#aaa",
                      }}
                      className={
                        `${classes.flexRow} ` +
                        (msg.from_user_id != window.current_user.id
                          ? "item-left"
                          : "item-right justify-end")
                      }
                    >
                      {DTOW.distance_of_time_in_words(
                        new Date(),
                        new Date(msg.created_at * 1000),
                        false,
                        { vague: true }
                      )}{" "}
                      ago
                    </div>
                  </div>
                ))}
            </InfiniteScroll>
            {values.messages.length == 0 && (
              <div>
                <center>
                  <Typography variant="h6">{`- No Messages -`}</Typography>
                  <br />
                </center>
              </div>
            )}
          </div>
        )}

        {showUploads && context.upload && (
          <div
            style={{
              flexGrow: 1,
              overflowY: "auto",
              marginLeft: "auto",
              marginRight: "auto",
              width: "100%",
              maxWidth: "100%",
              height: "95%",
              marginTop: "1.0%",
            }}
          >
            {context.upload && (
              <div style={{ display: "block" }}>
                <div className={classes.hasMargin}>
                  <center>
                    <h4>Upload preview:</h4>

                    {(context.upload.mimeType == "image/jpeg" ||
                      context.upload.mimeType == "image/png") && (
                      <img
                        id="preview-image-upload"
                        src={context.upload.fileBase64}
                        style={{ maxWidth: '100%', maxHeight: 400 }}
                      />
                    )}
                    {context.upload.mimeType == "image/gif" && (
                      <img
                        id="preview-gif-upload"
                        src={context.upload.fileBase64}
                        style={{ maxWidth: '100%', maxHeight: 400 }}
                      />
                    )}
                    {context.upload.mimeType == "video/mp4" && (
                      <video
                        id="preview-video-upload"
                        src={context.upload.fileBase64}
                        preload="auto"
                        style={{ width: "100%" }}
                        type="video/mp4"
                        controls={true}
                      />
                    )}
                    {context.upload.mimeType == "video/quicktime" && (
                      <video
                        id="preview-quicktime-upload"
                        src={context.upload.fileBase64}
                        preload="auto"
                        style={{ width: "100%" }}
                        type="video/quicktime"
                        controls={true}
                      />
                    )}
                  </center>
                </div>
              </div>
            )}
          </div>
        )}

        {/* Send Message area */}
        <div
          style={{ flexBasis: 50, flexShrink: 0 }}
          className={classes.flexRow}
        >
          <div style={{ flexGrow: 1 }}>
            {/*{context.upload && (
              <Thumbnails
                uploads={[context.upload]}
                callback={selectUpload}
                showIfMoreThan={0}
              />
            )}*/}

            <TextField
              id="input-draft"
              name="input-draft"
              variant="outlined"
              margin="none"
              size="small"
              onChange={handleChangeDraft}
              onKeyDown={handleKeyDown}
              value={values.draft || ""}
              inputProps={{ autoComplete: "off", maxLength: 1500 }}
              multiline
              rowsMax={10}
              placeholder=".=[ be nice ]=."
              fullWidth={true}
              autoComplete="off"
            />
          </div>
          <div
            style={{ flexBasis: 70, alignItems: "center" }}
            className={classes.flexRow}
          >
            {sendEnabled() && (
              <img
                id="id-message-send"
                className={`hand ${imageClass()}`}
                src="/static/icons/icon-send-message.svg"
                width={32}
                style={{ cursor: "pointer" }}
                onClick={handleMessageSend}
              />
            )}
            {!sendEnabled() && (
              <img
                className={`hand ${imageClass()}`}
                src="/static/icons/icon-send-message-disabled.svg"
                width={32}
              />
            )}
            {values.can_send_image && !values.event && (
              <img
                id="id-attach-image"
                className={`hand ${imageClass()}`}
                src="/static/icons/icon-attach-image-message.svg"
                width={32}
                style={{ cursor: "pointer" }}
                onClick={sendPhotoStart}
              />
            )}
            {(!values.can_send_image || values.event || values.loading) && (
              <img
                className={`hand ${imageClass()}`}
                src="/static/icons/icon-attach-image-message-disabled.svg"
                width={32}
              />
            )}
          </div>
        </div>
      </div>
      <Loading loading={values.loading} />
    </div>
  );
}
