import { makeStyles } from '@material-ui/core';

/*
 * Styles for: Posts, CreatePost, Post, Comments
 */
export default makeStyles((theme) => ({
  container: {
    maxWidth: "614px !important",
    marginTop: theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
      background: theme.palette.background.paper
    }
  },
  postContainer: {
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
    [theme.breakpoints.up(470)]: {
      border: '1px solid',
      borderColor: (theme.palette.type == "dark" ? "#5271ff" : "#7d24e0"), // theme.palette.text.disabled,
      borderRadius: '2px'
    }
  },
  postCreateContainer: { /* No padding above Create Post */
    paddingTop: 0
  },
  flexCols: {
    'display': 'flex',
    'alignItems': 'center',
    'flexDirection': 'row'
  },
  flexRows: {
    'display': 'flex',
    'flexDirection': 'column'
  },
  heading: { /* Create Post */
    backgroundColor: theme.palette.background.paper,
    paddingLeft: 12,
    marginBottom: theme.spacing(1)
  },
  hasMargin: {
    marginLeft: 12,
    marginRight: 12
  },
  hasMargin4: {
    marginLeft: 4,
    marginRight: 4
  },
  hasPadding: {
    paddingLeft: 12,
    paddingRight: 12
  },
  userSearch: {
    marginRight: 0,
    marginLeft: 0,
    display: 'block',
    [theme.breakpoints.up(470)]: {
      display: 'none'
    },
  },

}));