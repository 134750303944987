/***********

POSTS edit post

***********/

import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Button, TextField } from "@material-ui/core";
import VideoIcon from "@material-ui/icons/Videocam";
import PhotoIcon from "@material-ui/icons/PhotoCamera";
import UserAvatar from "components/UserAvatar";
import PostStyles from "components/styles/PostStyles";
import ImageEditor from "components/ImageEditor";
import Thumbnails from "components/posts/Thumbnails";
import Menu from "components/posts/Menu";
import Loading from "components/Loading";
import { CreatePostContext } from "components/posts/CreatePostContext";
import * as PostService from "../../services/posts.service";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

export default function PostEdit(props) {
  const classes = PostStyles();

  let { post_id } = useParams();

  const initialValues = {
    loading: true,
  };
  const [values, setValues] = useState(initialValues);

  /*
   * 1. Shared menu state
   * 2. Shared upload state
   * 3. selectedUpload.state == shared image editor state
   */
  const initialContext = PostService.initialContext();

  const [context, setContext] = useState(initialContext);

  let all_mime_types = PostService.allMimeTypes();

  /*
   * Get post and turn it into uploads
   */
  useEffect(() => {
    $.ajax({
      url: `/posts/${post_id}.json`,
      type: "GET",
      async: window.rails_env != "test",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      success: function (data) {
        let files_read = 0;
        let assets = data.assets;
        let num_files = assets.length;

        let uploads = [];

        function checkReadAllFiles() {
          files_read += 1;

          if (files_read == num_files) {
            if (!context.selectedUpload) {
              setContext((state) => ({
                ...state,
                ["selectedUpload"]: uploads[0],
              }));
            }
            setContext((state) => ({
              ...state,
              ["uploads"]: uploads,
            }));
            setValues((state) => ({
              ...state,
              ["loading"]: false,
            }));
          }
        }

        /*
         * Download assets from cloudfront, convert them into 'uploads'
         */
        for (let i = 0; i < assets.length; i++) {
          let asset = assets[i];

          if (asset.category == "image") {
            let img = new Image();
            img.onload = function () {
              let canvas = document.createElement("canvas");
              canvas.width = asset.width;
              canvas.height = asset.height;
              let context = canvas.getContext("2d");
              context.drawImage(img, 0, 0);

              let upload = {
                originalFilename: "glerf." + asset.ext,
                file: img,
                assetId: asset.id,
                canvasUploaded: canvas,
                thumbnail: canvas.toDataURL(),
                mimeType: asset.content_type,
              };
              uploads.push(upload);
              checkReadAllFiles();
            };
            img.crossOrigin = "Anonymous";
            img.src = asset.url;
          }
          if (asset.category == "gif") {
            let upload = {
              originalFilename: "glerf." + asset.ext,
              file: asset.url,
              assetId: asset.id,
              mimeType: asset.content_type,
              thumbnail: asset.thumbnail_url,
            };
            uploads.push(upload);
            checkReadAllFiles();
          }
          if (asset.category == "video") {
            let upload = {
              originalFilename: "glerf." + asset.ext,
              file: asset.url,
              assetId: asset.id,
              mimeType: asset.content_type,
              thumbnail: asset.thumbnail_url,
            };
            uploads.push(upload);
            checkReadAllFiles();
          }
        }
        console.log("== DATA", data);
        setContext((state) => ({
          ...state,
          ["postText"]: data.content,
          ["permission"]: data.perms,
          ["assets"]: data.assets,
        }));
      },
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDrop,
    multiple: true,
    accept: all_mime_types.join(", "),
  });

  // File dropped onto dropzone
  function onDrop(acceptedFiles) {
    PostService.onDrop(acceptedFiles)
      .then((uploads) => {
        if (!context.selectedUpload) {
          setContext((state) => ({
            ...state,
            ["selectedUpload"]: uploads[0],
          }));
        }

        setContext((state) => ({
          ...state,
          ["uploads"]: context.uploads.concat(uploads),
        }));
      })
      .catch((error) => {
        console.error("Post error: ", error);
      });
  }

  // User has typed a key or selected a dropdown
  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    let required = event.target.required;

    setContext((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const allFieldsValid = () => {
    return context.uploads.length > 0;
  };

  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  const post = () => {
    setValues((state) => ({
      ...state,
      ["loading"]: true,
    }));

    let foundImage = false;

    for (let i = 0; i < context.uploads.length; i++) {
      let upload = context.uploads[i];
      upload.origPosition = i;

      if (upload.mimeType == "image/jpeg" || upload.mimeType == "image/png") {
        //if (!foundImage) {
        foundImage = true;
        // Shows editor and sets default state so window.finalize works below
        selectUpload(i);
        //}
        //break;
      }
    }

    if (foundImage) {
      setTimeout(function () {
        window.finalize(1080, 1080 * 2).then(buildForm); // extracts blobs from the preview-canvas
      }, 1);
    } else {
      buildForm([]);
    }

    function buildForm(blobs) {
      let fd = new FormData();
      let imageNum = 0;

      for (let i = 0; i < context.uploads.length; i++) {
        let upload = context.uploads[i];

        if (upload.mimeType == "image/jpeg" || upload.mimeType == "image/png") {
          fd.append("files[]", blobs[imageNum]);
          imageNum += 1;
        } else {
          fd.append("files[]", DataURIToBlob(upload.file));
        }

        fd.append("asset_ids[]", upload.assetId || -1);
      }
      fd.append("text", context.postText);
      fd.append("perms", context.permission);
      fd.append(
        "time",
        $("input[type='radio'][name='expiration']:checked").val() || "year"
      );
      fd.append("timeHours", context.timeHours || 24 * 7 * 30 * 12);
      //fd.append('original_filename', values.originalFilename)
      fd.append("authenticity_token", window.auth_token);

      $.ajax({
        url: `/posts/${post_id}`,
        type: "PUT",
        async: window.rails_env != "test",
        data: fd,
        success: function (data) {
          window.location.href = `/p/${post_id}`;
        },
        cache: false,
        contentType: false,
        processData: false,
      });
    }
  };

  function selectUpload(idx) {
    setContext((state) => ({
      ...state,
      ["selectedUpload"]: context.uploads[idx],
    }));
  }

  function removeSelected() {
    let selected = context.selectedUpload;

    let newUploads = [];
    for (let i = 0; i < context.uploads.length; i++) {
      if (context.uploads[i] != context.selectedUpload) {
        newUploads.push(context.uploads[i]);
      }
    }

    setContext((state) => ({
      ...state,
      uploads: newUploads,
      selectedUpload: newUploads[0], // Hack, should select the one after or before the removed upload
    }));
  }

  let history = useHistory();

  return (
    <div
      id="posts"
      className={classes.container}
    >
      <div style={{ display: !values.loading ? "block" : "none" }}>
        <div className={(classes.hasMargin, classes.heading)}>
          <Typography variant="h6" gutterBottom>
            Edit Post
          </Typography>
        </div>
        <div className={classes.flexCols}>
          <div style={{ flexBasis: "70px" }}>
            <center>
              <UserAvatar url={props.avatar_url} size="small" />
            </center>
          </div>
          <div style={{ flexGrow: 1, marginRight: "12px" }}>
            <TextField
              id="input-text"
              name="postText"
              label="Words 'n #tags"
              multiline={true}
              value={context.postText || ""}
              error={false}
              onChange={handleChange}
              inputProps={{
                autoComplete: "off",
              }}
              fullWidth
              size="small"
              variant="outlined"
              margin="normal"
            />
          </div>
        </div>

        <div
          id="fileinfo"
          className={`dropzone-previews mb-3 ${classes.hasMargin}`}
          style={{ marginBottom: "4px" }}
        >
          <div {...getRootProps()} className="upload-photos-icon dz-clickable">
            <input {...getInputProps()} />
            {isDragActive ? (
              <div className="icons">
                <PhotoIcon style={{ color: "#FF0000" }} />
                <p>or</p>
                <VideoIcon style={{ color: "#FF0000" }} />
              </div>
            ) : (
              <div className="icons">
                <PhotoIcon />
                <p>or</p>
                <VideoIcon />
              </div>
            )}
          </div>
        </div>

        {context.uploads && context.uploads.length > 0 && (
          <div style={{ display: values.loading ? "none" : "block" }}>
            <div className={classes.hasMargin}>
              <CreatePostContext.Provider value={[context, setContext]}>
                <Thumbnails uploads={context.uploads} callback={selectUpload} />
                {context.uploads.length > 1 && (
                  <div>
                    <a onClick={removeSelected}>
                      Remove selected picture/video from the post
                    </a>
                  </div>
                )}
                <Menu
                  menus={[
                    "permissions",
                    "time",
                    "filters",
                    "masks",
                    "stickers",
                    "texts",
                  ]}
                />
                {(context.selectedUpload.mimeType == "image/jpeg" ||
                  context.selectedUpload.mimeType == "image/png") && (
                  <div>
                    <ImageEditor />
                    <canvas
                      id="canvas-preview"
                      width="0"
                      height="0"
                      style={{ width: "100%" }}
                      draggable="false"
                    />
                  </div>
                )}
                {context.selectedUpload.mimeType == "image/gif" && (
                  <img
                    src={context.selectedUpload.file}
                    style={{ objectFit: "cover" }}
                  />
                )}
                {context.selectedUpload.mimeType == "video/mp4" && (
                  <video
                    src={context.selectedUpload.file}
                    preload="none"
                    style={{ objectFit: "cover" }}
                    type="video/mp4"
                    controls={true}
                  />
                )}
                {context.selectedUpload.mimeType == "video/quicktime" && (
                  <video
                    src={context.selectedUpload.file}
                    preload="none"
                    style={{ objectFit: "cover" }}
                    type="video/mov"
                    controls={true}
                  />
                )}
              </CreatePostContext.Provider>
            </div>
          </div>
        )}

        <Box className={classes.hasMargin} pb={2}>
          <Button
            type="submit"
            id="btn-create-post"
            disabled={!allFieldsValid()}
            variant="contained"
            color="primary"
            onClick={post}
          >
            Post
          </Button>
        </Box>
      </div>
      <Loading loading={values.loading} />
    </div>
  );
}
