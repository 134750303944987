import React, { useState, useEffect, useContext } from "react";
import PostStyles from "components/styles/PostStyles";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import Dialog from "@material-ui/core/Dialog";
import Cookies from "vendor/js.cookie.min.mjs";
import FaceMasks from "image_editor/FaceMasks.jsx";
import FilterButtons from "image_editor/FilterButtons.jsx";
import Stickers from "image_editor/Stickers.jsx";
import TextEditor from "image_editor/TextEditor.jsx";
import { CreatePostContext } from "components/posts/CreatePostContext";
import marksTime from "components/posts/marksTime";
import IOSSlider from "components/IOSSlider";
import CommonStyles from "components/styles/CommonStyles";

export default function Menu(props) {
  const commonStyles = CommonStyles();

  const [context, setContext] = useContext(CreatePostContext);

  //if (window.rails_env == 'test') { console.error("+ Menu render") }

  const classes = PostStyles();

  const menus = props.menus;

  const handleChangeMenu = (event) => {
    let val = event.target.id;

    setContext((state) => ({
      ...state,
      ["currentMenu"]: val,
    }));
  };

  let tab = props.tab;

  let showEditorStuff = ["image/jpeg", "image/png"].includes(
    context.selectedUpload.mimeType
  );

  const marksPermission = [
    {
      value: 0,
      label: "Kin Only",
      caption: `Only those you add as 'kin'`,
    },
    {
      value: 1,
      label: "Kin + Followers",
      caption: `Kin and anyone who follows you`,
    },
    {
      value: 2,
      label: "Everyone",
      caption: `Kin + followers + any users who see your post in ${
        tab == "events" ? "My Communities" : "Explore"
      }`,
    },
  ];

  const marksCommentPermission = [
    {
      value: -1,
      label: "Comments Disabled",
      caption: `Comments will not be allowed for this post`,
    },
    {
      value: 0,
      label: "Kin Only",
      caption: `Only those you add as 'kin'`,
    },
    {
      value: 1,
      label: "Kin + Followers",
      caption: `Kin and anyone who follows you`,
    },
    {
      value: 2,
      label: "Everyone",
      caption: `Kin + followers + any users who see your post in ${
        tab == "events" ? "My Communities" : "Explore"
      }`,
    },
  ];

  function marksPermissionFromInt(num) {
    for (let i = 0; i < marksPermission.length; i++) {
      if (marksPermission[i].value == num) {
        return marksPermission[i];
      }
    }
    return {};
  }

  function marksTimeCaptionFromInt(num) {
    for (let i = 0; i < marksTime.length; i++) {
      if (marksTime[i].hours == num) {
        return marksTime[i].caption;
      }
    }
    return "10 Years";
  }

  function marksCommentPermissionFromInt(num) {
    for (let i = 0; i < marksCommentPermission.length; i++) {
      if (marksCommentPermission[i].value == num) {
        return marksCommentPermission[i];
      }
    }
    return {};
  }

  function sliderChange(slider_field, value) {
    if (context[slider_field] != value) {
      setContext((state) => ({
        ...state,
        [slider_field]: parseInt(value),
      }));

      if (slider_field == "time") {
        let hours = marksTime[value].hours;
        setContext((state) => ({
          ...state,
          ["timeHours"]: hours,
        }));
      }

      Cookies.set(`default-${slider_field}`, value, { expires: 1 });
    }
  }

  // Set default for time slider
  useEffect(() => {
    if (context.time == -1) {
      // See: posts.service, if no cookie then -1
      // In this case, set to max expiration
      context.time = marksTime.length - 1;
      setContext((state) => ({
        ...state,
        ["time"]: context.time,
      }));
    }

    let hours = marksTime[context.time].hours;
    setContext((state) => ({
      ...state,
      ["timeHours"]: hours,
    }));

    if (!menus.includes(context.currentMenu)) {
      setContext((state) => ({
        ...state,
        ["currentMenu"]: menus[0],
      }));
    }
  }, []);

  let timeMaxHours = marksTime[marksTime.length - 1].value;

  // Security + Privacy menu
  const [openDialogSecurity, setOpenDialogSecurity] = useState(false);

  function handleSecurityClick() {
    setOpenDialogSecurity(true);
  }

  function closeDialogSecurity() {
    setOpenDialogSecurity(false);
  }

  return (
    <div id="menu" className={`${classes.hasMargin} ${classes.flexRows}`}>
      <Dialog
        id="dialog-security"
        open={openDialogSecurity}
        keepMounted={false}
        onClose={closeDialogSecurity}
        scroll="paper"
        classes={{ paper: commonStyles.dialog500 }}
      >
        <div
          style={{
            marginTop: 12,
            paddingLeft: 12,
            paddingRight: 12,
            paddingTop: 8,
          }}
        >
          <div>
            <FormControl component="fieldset" fullWidth={true}>
              <FormLabel component="legend">Who may see this post?</FormLabel>
              <br />

              <Select
                native
                autoWidth={true}
                id="select-permission"
                style={{ width: 200 }}
                name="permission"
                labelId="type-label"
                variant="standard"
                value={context.permission}
                onChange={(event) =>
                  sliderChange("permission", event.target.value)
                }
              >
                <option value={0}>Kin Only</option>
                <option value={1}>Kin + Followers</option>
                <option value={2}>Everyone</option>
              </Select>

              <p>
                {(marksPermissionFromInt(context.permission) || {}).caption}
              </p>

              {
                context.permission == 2 && (
                  <small><b>Note:</b> Add #hashtags if you'd like this post to appear on Trending / Explore<br /></small>
                )
              }

            </FormControl>
          </div>

          <div style={{ height: 136, marginTop: 8 }}>
            <FormControl component="fieldset" fullWidth={true}>
              <FormLabel component="legend">
                Who may comment on this post?
              </FormLabel>
              <br />

              <Select
                native
                autoWidth={true}
                id="select-comment-permission"
                style={{ width: 200 }}
                name="comment-permission"
                labelId="type-label"
                variant="standard"
                value={context.comment_permission}
                onChange={(event) =>
                  sliderChange("comment_permission", event.target.value)
                }
              >
                <option value={-1}>Comments Disabled</option>
                <option value={0}>Kin Only</option>
                <option value={1}>Kin + Followers</option>
                <option value={2}>Everyone</option>
              </Select>

              <p>
                {
                  (
                    marksCommentPermissionFromInt(context.comment_permission) ||
                    {}
                  ).caption
                }
              </p>
            </FormControl>
          </div>

          <FormControl
            component="fieldset"
            fullWidth={true}
            style={{ height: 136 }}
          >
            <FormLabel component="legend">
              How long should this post exist?
            </FormLabel>
            <br />
            <br />
            <IOSSlider
              value={context.time}
              onChange={(event, value) => sliderChange("time", value)}
              min={0}
              max={timeMaxHours}
              step={null}
              aria-labelledby="discrete-slider-custom"
              valueLabelDisplay="on"
              valueLabelFormat={(marksTime[context.time] || {}).caption}
              marks={marksTime}
              style={{ marginLeft: 12, marginRight: 24, width: "90%" }}
            />
          </FormControl>
        </div>
      </Dialog>

      <div style={{ flexBasis: "36px" }}>
        {menus.map((menu) => {
          let selected = "";
          if (menu == context.currentMenu) {
            selected = "menu-selected";
          }
          let menufn = `menu-${menu}.svg`;
          if (menu == "masks") {
            menufn = `menu-${menu}.png`;
          }
          if (menu != "permissions" && menu != "time" && !showEditorStuff) {
            return <span key={menu} />;
          }
          return (
            <img
              className={`menu-icon ${selected} hand`}
              src={`/static/menus/${menufn}`}
              id={menu}
              key={menu}
              onClick={handleChangeMenu}
            />
          );
        })}
      </div>
      <div style={{ flexBasis: "150px" }}>
        {/* Messaging-related menus */}
        <div
          className="menu-ui"
          style={{
            display: context.currentMenu == "messaging" ? "block" : "none",
          }}
        >
          <FormControl component="fieldset" fullWidth={true}>
            <h3>Uploaded Image: Edit</h3>
            <div>
              <font color="black">
                <img
                  src="/static/icons/icon-save.svg"
                  onClick={props.save}
                  width={20}
                  height={20}
                />
              </font>
              Save
            </div>
            <div>
              <font color="black">
                <img
                  src="/static/icons/icon-send-message.svg"
                  onClick={props.save_and_send}
                  width={20}
                  height={20}
                />
              </font>
              Save and Send Message
            </div>
            <div>
              <font color="black">
                <img
                  src="/static/icons/icon-delete.svg"
                  onClick={props.del}
                  width={20}
                  height={20}
                />
              </font>
              Remove Image
            </div>
          </FormControl>
        </div>

        {/* Post-related menus */}
        <div
          className="menu-ui"
          style={{
            display: context.currentMenu == "permissions" ? "block" : "none",
          }}
        >
          <FormControl component="fieldset" fullWidth={true}>
            <FormLabel component="legend">Security and Privacy</FormLabel>
            <br />

            <table>
              <tbody>
                {
                  !props.community_id && (
                    <tr>
                      <td width="120">Visible to:</td>
                      <td>
                        <a
                          className="show-dialog-security"
                          onClick={handleSecurityClick}
                        >
                          {marksPermissionFromInt(context.permission).label}
                        </a>
                      </td>
                    </tr>
                  )
                }
                {
                  !props.community_id && (
                    <tr>
                      <td>May comment:</td>
                      <td>
                        <a
                          className="show-dialog-security"
                          onClick={handleSecurityClick}
                        >
                          {
                            marksCommentPermissionFromInt(context.comment_permission)
                              .label
                          }
                        </a>
                      </td>
                    </tr>
                  )
                }
                <tr>
                  <td>Expires:</td>
                  <td>
                    <a
                      className="show-dialog-security"
                      onClick={handleSecurityClick}
                    >
                      {marksTimeCaptionFromInt(context.timeHours)}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </FormControl>
        </div>
        {showEditorStuff && (
          <div>
            <div
              className="menu-ui"
              style={{
                display: context.currentMenu == "filters" ? "block" : "none",
              }}
            >
              <FilterButtons />
            </div>
            <div
              className="menu-ui"
              style={{
                display: context.currentMenu == "masks" ? "block" : "none",
              }}
            >
              <FaceMasks />
            </div>
            <div
              className="menu-ui"
              style={{
                display: context.currentMenu == "stickers" ? "block" : "none",
              }}
            >
              <Stickers />
            </div>
            <div
              className="menu-ui"
              style={{
                display: context.currentMenu == "texts" ? "block" : "none",
              }}
            >
              <TextEditor />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
