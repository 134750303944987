export function exploreInitialContext(tag, query, subpage) {
  return {
    tag: tag,
    query: query,
    subpage: subpage,
    posts: [],
    tags: [],
    related_tags: [],
    tag_info: {},
    no_following: null,
    loading: true,
    found: true,
    last_id: "",
    last_quality: "",
    hasMore: true,
    timeoutSearchPostQuery: null,
    searchPostQuery: tag ? "#" + tag : query,
    tagAutoComplete: [],
    tagRelated: [],
    tagTimeout: null,
    following_tag: false,
    following_tag_names: [],
    interval: "year",
    lastKinkUrl: "",
  };
}

// Also replaces newlines with BRs
// >3 BRs become 1
export function fetchExploreData(
  setContext,
  context,
  tab,
  clear,
  clearTags,
  tagOverride
) {
  let last_id = context.last_id;
  let last_quality = context.last_quality;
  let posts = context.posts;

  const POSTS_PER_PAGE = 36;

  if (clear) {
    // null out the current post list first
    setContext((state) => ({
      ...state,
      searchPostQuery: context.query,
    }));
    last_id = "";
    last_quality = "";
    posts = [];
  }

  let url = `/explore.json?interval=${context.interval}&last_id=${last_id}&last_quality=${last_quality}&subpage=${tab}`;
  if (tagOverride || context.tag) {
    url = `/explore.json?interval=${
      context.interval
    }&last_id=${last_id}&subpage=tag&tag=${tagOverride || context.tag}`;
  }
  if (context.query) {
    url = `/explore.json?interval=${context.interval}&last_id=${last_id}&subpage=query&query=${context.query}`;
  }
  $.ajax({
    url: url,
    type: "GET",
    async: window.rails_env != "test",
    success: function (data) {
      let last_id = "";
      let last_quality = "";
      if (data.posts.length > 0) {
        last_id = data.posts[data.posts.length - 1].id;
        last_quality = data.posts[data.posts.length - 1].quality;
      }

      /*
       * If you're at explore/kinks (e.g. not exploring a tag or search results)
       * The back-end will return the posts for a 'default' tag. We need to set
       * it here so the front-end knows to highlight this default tag
       * See: explore_controller.rb#default_tag
       */
      /*if (subpage == 'kinks' && !context.tag) {
        setContext(state => ({
          ...state,
          tag: data.default_tag
        }))
      }*/

      setContext((state) => ({
        ...state,
        posts: posts.concat(data.posts),
        following_tag: data.following_tag,
        no_following: data.no_following,
        tags: clearTags ? data.tags : context.tags, // We don't want to clear tags when we are just clicking one
        related_tags: data.related_tags,
        following_tag_names: data.following_tag_names,
        tag_spotlight_image_url: data.tag_spotlight_image_url,
        last_id: last_id,
        last_quality: last_quality,
        tag_info: data.tag_info,
        found: true,
        hasMore: data.posts.length == POSTS_PER_PAGE,
        loading: false,
      }));
    },
    error: function (data) {
      if (data.status == 404) {
        setContext((state) => ({
          ...state,
          ["found"]: false,
          ["loading"]: false,
        }));
      }
    },
    cache: false,
    contentType: false,
    processData: false,
  });
}
