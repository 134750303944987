// See: permission_service.rb

const DATA_REQUIRED = {
  "send_messages": ['follower', 'following', 'kin_to_them', 'kin_from_them'],
  "follow": ['follower', 'following', 'kin_to_them', 'kin_from_them'],
  "unfollow": ['follower', 'following', 'kin_to_them', 'kin_from_them'],
  "add_kin": ['follower', 'following', 'kin_to_them', 'kin_from_them'],
  "unkin": ['follower', 'following', 'kin_to_them', 'kin_from_them'],
}

function required_data_avail(perm_name, data) {
  let data_required = DATA_REQUIRED[perm_name]

  if (data == null) return false

  if (!data_required) {
    throw `Data requirement not set for ${perm_name}`
  }
  
  for (let i = 0; i < data_required.length; i++) {
    let datum = data_required[i]
    if (data[datum] == null || data[datum] == undefined) {
      console.warn(`Data missing: ${datum}. Val: ${data[datum]}`)
      return false
    }
  }
  return true
}

// Should match permission_service.rb
export default function Can(perm_name, resource, data = {}) {
  let can = false

  /*
  if (!required_data_avail(perm_name, data)) {
    console.warn(`Required data missing for ${perm_name}:`, DATA_REQUIRED[perm_name], " got: ", data)
    return false
  }
  */

  let kin = (data.kin_to_them && data.kin_from_them)

  if (!resource) {
    console.error(`Resouce not specified for ${perm_name}: `, resource, data)
    return false
  }

  if (!data) {
    console.error(`Data not specified for ${perm_name}: `, resource, data)
    return false
  }

  function checkSliderPerm(perm_name) {
    let other_user_settings = resource
    switch(other_user_settings[perm_name]) {
      case 3:
        return true;
        break;
      case 2:
        return kin || data.follower || data.following
      case 1:
        return kin || data.follower
        break;
      case 0:
        return kin
        break;
      default:
        throw `Invalid permission for ${perm_name}: ${other_user_settings['perm_send_messages']}`
    }
  }

  if (perm_name == 'send_messages') {
    return checkSliderPerm('perm_send_messages')
  }

  if (perm_name == 'send_images') {
    return checkSliderPerm('perm_send_messages') && checkSliderPerm('perm_send_images')
  }

  if (perm_name == 'follow') {
    return !data.following
  }

  if (perm_name == 'unfollow') {
    return data.following
  }

  if (perm_name == 'add_kin') {
    return !data.kin_to_them
  }

  if (perm_name == 'unkin') {
    return data.kin_to_them
  }

  throw `Permission logic not set! ${perm_name}`
}