import React from "react";
import { Link } from "@material-ui/core";
import { Link as RLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import UserAvatar from "components/UserAvatar";

const useStyles = makeStyles((theme) => ({
  flexCols: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
}));

export default function UserList(props) {
  const classes = useStyles();
  return (
    <div>
      {props.users.length == 0 && (
        <center>
          <div>{props.no_users_text || "[ no users ]"}</div>
        </center>
      )}
      {props.users.length > 0 && (
        <div style={{ height: 200 }}>
          {props.users.map(function (user, index) {
            return (
              <div
                key={user.id}
                className={classes.flexCols}
                style={{ paddingBottom: "10px" }}
              >
                <div style={{ flexBasis: "70px" }}>
                  <center>
                    <UserAvatar
                      url={user.avatar_url}
                      border_color={user.color_background}
                      size="small"
                    />
                  </center>
                </div>
                <div style={{ flexGrow: 1 }}>
                  <Link to={`/${user.username}`} component={RLink} color="inherit">
                    <span>{user.username}</span>
                  </Link>
                </div>
                {props.show_follow && (
                  <div style={{ flexBasis: "70px" }}>
                    <span>
                      {user.id != window.current_user.id && !user.following && (
                        <a
                          id="follow"
                          className="hand"
                          onClick={() => follow_liker(index, user.id)}
                        >
                          <b>
                            <font color="red">follow</font>
                          </b>
                        </a>
                      )}
                      {user.id != window.current_user.id && user.following && (
                        <a
                          id="unfollow"
                          className="hand"
                          onClick={() => unfollow_liker(index, user.id)}
                        >
                          <b>
                            <font color="red">unfollow</font>
                          </b>
                        </a>
                      )}
                    </span>
                  </div>
                )}
                {props.show_delete && (
                  <div style={{ flexBasis: "20px" }}>
                    <span>
                      {user.id != window.current_user.id && !user.following && (
                        <a
                          id="delete"
                          onClick={() => props.callback_delete(user.id)}
                        >
                          <b>
                            <font color="red">X</font>
                          </b>
                        </a>
                      )}
                    </span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
