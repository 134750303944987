import React from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  mm: {
    minWidth: "70px",
  },
  dd: {
    minWidth: "70px",
  },
  yy: {
    minWidth: "80px",
  },
}));

export default function Dob(props) {
  const classes = useStyles();
  const year_latest = new Date().getFullYear() - 13;

  let dob_months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let dob_years = Array.from(
    new Array(81),
    (val, index) => year_latest - index
  );
  let dob_days = Array.from(new Array(31), (val, index) => index + 1);

  function monthToVal(mon) {
    if (mon == "") {
      return "";
    }
    return dob_months.indexOf(mon) + 1;
  }

  return (
    <Box>
      <Grid container direction="row" spacing={2}>
        <Grid item>
          <FormControl>
            <InputLabel htmlFor="select-dob-mm">Month</InputLabel>
            <Select
              native
              id="select-dob-mm"
              name="dob_mm"
              value={props.dob_mm}
              onChange={props.handleChange}
              className={classes.mm}
            >
              {props.allow_null && <option value={""} key={""} />}
              {dob_months.map((mon) => {
                return (
                  <option value={monthToVal(mon)} key={mon}>
                    {mon}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item ml={2}>
          <FormControl>
            <InputLabel htmlFor="select-dob-dd">Day</InputLabel>
            <Select
              native
              id="select-dob-dd"
              name="dob_dd"
              value={props.dob_dd}
              onChange={props.handleChange}
              className={classes.dd}
            >
              {props.allow_null && <option value={""} key={""} />}
              {dob_days.map((day, index) => {
                return (
                  <option value={day} key={day}>
                    {day}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item ml={2}>
          <FormControl>
            <InputLabel htmlFor="select-dob-yy">Year</InputLabel>
            <Select
              native
              id="select-dob-yy"
              name="dob_yy"
              value={props.dob_yy}
              onChange={props.handleChange}
              className={classes.yy}
            >
              {props.allow_null && <option value={""} key={""} />}
              {dob_years.map((year, index) => {
                return (
                  <option value={year} key={year}>
                    {year}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
