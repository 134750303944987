import React, { useEffect, useState, useContext } from "react";
import { useSwipeable } from "react-swipeable";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import * as PostService from "../../services/posts.service";
import { useTheme } from '@material-ui/core/styles';
import Post from "components/posts/Post";
import useInterval from "vendor/useInterval";

/*
 * Holds state for an individual Story and each of its child Posts
 *
 * Displays posts in order until it reaches the end of the last, then calls props.nextStoryCallback
 */
export default function StoryView(props) {
  let default_ms_increment = 100

  const initialValues = {
    current_post: {},
    current_post_index: 0,
    story: {},
    posts: [],
    ms_cur: 0,
    ms_speed: 7000,
    ms_increment: default_ms_increment
  };
  const [values, setValues] = useState(initialValues);

  let swipeConfig = {
    delta: (window.innerWidth / 3 > 75 ? 75 : window.innerWidth / 3),
    trackMouse: true
  }
  const swipeHandlers = useSwipeable({
    onSwipedLeft: (eventData) => { nextPostOrStory() },
    onSwipedRight: (eventData) => { prevPostOrStory() },
    onSwipeStart: () => { console.warn("window.changing_stories TRUE"); window.changing_stories = true },
    onSwipeEnd: () => { console.warn("window.changing_stories FALSE"); window.changing_stories = false },
    ...swipeConfig,
  });

  let posts = []
  let ms_start = 0
  let start_post_index = 0

  // Story was just initially set or changed
  if (props.story.id != values.story.id) {
    // Prepare post timeline -- set post.ms_duration, ms_timeline_start and ms_timeline_end
    for (let i = 0; i < props.story.posts.length; i++) {
      let post = props.story.posts[i]
      post.ms_duration = values.ms_speed

      if (post.asset.category == "video") {
        post.ms_duration = (post.asset.duration || 10) * 1000
      }

      post.ms_timeline_start = 0
      post.ms_timeline_end = post.ms_duration

      if (i > 0) {
        post.ms_timeline_start = posts[i - 1].ms_timeline_end + 1
        post.ms_timeline_end += posts[i - 1].ms_timeline_end
      }

      // User has seen this post? Skip to the next
      if (post.user_ids_seen.indexOf(window.current_user.id) > -1) {
        if (i < props.story.posts.length - 1) {
          ms_start = post.ms_timeline_end + 1
          start_post_index = i + 1
        } else {
          console.error("Seen all posts already!")
        }
      }
      if (post.background) {
        post.background_gradient = `linear-gradient(180deg, ${post.background.colorA} 0%, ${post.background.colorB} 100%)`
      } else {
        let colors = ["#ffb6bb", "#ffe691", "#95d5ee", '#EF767A', '#B8F2E6', '#AED9E0', '#966B9D', '#90FFDC', '#8DE4FF', '#E3C0D3', '#94E8B4', '#F6AE2D', '#DFFDFF']
        let color1 = colors[Math.floor(Math.random() * colors.length)];
        let color2 = colors[Math.floor(Math.random() * colors.length)];
        post.background_gradient = `linear-gradient(180deg, ${color1} 0%, ${color2} 100%)`
      }

      posts.push(post)
    }

    setValues((state) => ({
      ...state,
      story: props.story,
      posts: posts,
      ms_cur: ms_start,
      current_post: posts[start_post_index],
      current_post_index: start_post_index,
    }));

    //fadeOutItems()
  }

  useInterval(
    () => {
      setValues((state) => ({
        ...state,
        ms_cur: values.ms_cur + values.ms_increment
      }));

      // We've watched to the end of the current_post
      //console.warn("values.ms_cur", values.ms_cur, "values.current_post.ms_timeline_end", values.current_post.ms_timeline_end)
      if (values.ms_cur + values.ms_increment > values.current_post.ms_timeline_end) {
        window.changing_stories = true
        console.warn("window.changing_stories TRUE", window.changing_stories)
        //setTimeout(() => {
          nextPostOrStory(values.current_post.id)
          setTimeout(() => {
            window.changing_stories = false
            console.warn("window.changing_stories false", window.changing_stories)
          }, 500) // hack -- why do we need to disable play/pause for 1s?
        //}, 1)
      }
    },
    default_ms_increment
  );

  function scrollStoryTop() {
    $('#dialog-view-stories .MuiDialog-paper').scrollTop(0)
  }

  function seenPost(post_id) {
    $.ajax({
      url: `/posts/seen/${post_id}`,
      type: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      success: function (data) {
        console.log("seen post:", post_id)
      },
      cache: false,
      contentType: false,
      processData: false,
    });    
  }

  function unseenPost(post_id) {
    $.ajax({
      url: `/posts/unseen/${post_id}`,
      type: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      success: function (data) {
        console.log("unseen post:", post_id)
      },
      cache: false,
      contentType: false,
      processData: false,
    });    
  }

  let bkg = `linear-gradient(171deg, #FF0000 0%, #000000 100%)`

  // User swiped for the next post/story
  // function onSwipeLeft(event) {
  //   console.warn("== LEFT")
  //   window.changing_stories = true
  //   setTimeout(() => {
  //     seenPost(values.current_post.id)

  //     props.nextStoryCallback(props.story.id)

  //     setValues((state) => ({
  //       ...state,
  //       ms_cur: 0
  //     }));
  //     scrollStoryTop()

  //     setTimeout(() => {
  //       window.changing_stories = false
  //       console.warn("window.changing_stories false", window.changing_stories)
  //     }, 500) // hack -- why do we need to disable play/pause for 1s?
  //   }, 1)
  // }

  function nextPostOrStory() {
    let post_id = values.current_post.id

    seenPost(post_id)

    let index = -1
    let post = {}
    for (let i = 0; i < values.posts.length; i++) {
      let pos = values.posts[i]
      if (pos.id == post_id) {
        index = i
        post = pos
      }
    }

    // More posts to view
    if (index < values.posts.length - 1) {
      console.log("seen post, go to next", index)
      setValues((state) => ({
        ...state,
        current_post: values.posts[index + 1],
        current_post_index: index + 1,
        ms_cur: values.posts[index].ms_timeline_end
      }));
      scrollStoryTop()
    } else {
      console.log("next story")
      // Next story!
      window.changing_stories = true
      if (props.nextStoryCallback) {
        props.nextStoryCallback(props.story.id)

        setValues((state) => ({
          ...state,
          ms_cur: 0
        }));

        scrollStoryTop()

        setTimeout(() => {
          window.changing_stories = false
          console.warn("window.changing_stories false", window.changing_stories)
        }, 500) // hack -- why do we need to disable play/pause for 1s?
      } else {
        console.error(" no callback")
      }
    }
  }

  // User swiped right for the prev post/story
  // function onSwipeRight(event) {
  //   console.warn("== RIGHT")
  //   window.changing_stories = true
  //   setTimeout(() => {
  //     props.prevStoryCallback(props.story.id)

  //     setValues((state) => ({
  //       ...state,
  //       ms_cur: 0
  //     }));
  //     scrollStoryTop()

  //     setTimeout(() => {
  //       window.changing_stories = false
  //       console.warn("window.changing_stories false", window.changing_stories)
  //     }, 500) // hack -- why do we need to disable play/pause for 1s?
  //   }, 1)
  // }

  function prevPostOrStory() {
    let post_id = values.current_post.id

    let index = -1
    let post = {}
    for (let i = 0; i < values.posts.length; i++) {
      let pos = values.posts[i]
      if (pos.id == post_id) {
        index = i
        post = pos
      }
    }

    // More posts to view
    if (index > 0) {
      setValues((state) => ({
        ...state,
        current_post: values.posts[index - 1],
        current_post_index: index - 1,
        ms_cur: values.posts[index - 1].ms_timeline_end - values.posts[index - 1].ms_duration
      }));

      unseenPost(values.posts[index - 1].id)

      scrollStoryTop()
    } else {
      // Prev story!
      window.changing_stories = true
      if (props.prevStoryCallback) {
        setValues((state) => ({
          ...state,
          ms_cur: 0
        }));

        props.prevStoryCallback(props.story.id)
        scrollStoryTop()

        setTimeout(() => {
          window.changing_stories = false
          console.warn("window.changing_stories false", window.changing_stories)
        }, 500) // hack -- why do we need to disable play/pause for 1s?
      } else {
        console.error(" no callback")
      }
    }    
  }

  function handleVideoSeek(seconds) {
    setValues((state) => ({
      ...state,
      ms_cur: seconds * 1000
    }));
  }

  function pauseStory() {
    setValues((state) => ({
      ...state,
      ms_increment: 0
    }));

    $('#story-icon-pause').fadeIn(250)
    setTimeout(() => {
      $(`#story-icon-pause`).fadeOut(250)
    }, 750)
  }

  function handleStoryClick() {
    console.warn("CLICKED", values.ms_increment)
    if (values.ms_increment > 0) {
      pauseStory()
    } else {
      setValues((state) => ({
        ...state,
        ms_increment: default_ms_increment
      }));

      $('#story-icon-play').fadeIn(250)
      setTimeout(() => {
        $(`#story-icon-play`).fadeOut(250)
      }, 750)

      //$(`.story-header-${props.story.id}, .story-interaction-${props.story.id}`).fadeOut(500)
    }

    //$(`.story-header-${props.story.id}, .story-interaction-${props.story.id}`).fadeIn(250)

  }

  function fadeOutItems() {
    setTimeout(() => {
      $(`.story-header-${props.story.id}, .story-interaction-${props.story.id}`).fadeOut(750)
    }, 1500)
  }

  return (
    <React.Fragment>
      {/* Post/story progress bar at the top of the screen */}
      {
        values.posts && values.posts.length > 0 && (
          <div style={ { width: "100%", background: "#000", height: 2, position: "fixed", top: 0, zIndex: 400000 } }>
            {
              values.posts.map((post, idx) => (
                <div key={`post-${idx}`} style={ { height: '100%', width: parseFloat(post.ms_timeline_end / values.posts[values.posts.length - 1].ms_timeline_end * 100) + '%', borderRight: "3px solid #FFF", position: 'absolute', display: (idx == values.posts.length - 1 ? 'none' : 'inline-block') }}>
                  &nbsp;
                </div>
              ))
            }
            <div style={ { height: '100%', background: '#FEC300', width: parseFloat(values.ms_cur / values.posts[values.posts.length - 1].ms_timeline_end * 100) + '%' } }>
            </div>
          </div>
        )
      }

      <img id='story-icon-pause' src="/static/icons/icon-pause.svg" style={ { width: 36, display: 'none', position: 'fixed', top: 'calc(50vh - 18px)', left: 'calc(50vw - 18px)', background: "#FFF" } } />
      <img id='story-icon-play' src="/static/icons/icon-play.svg" style={ { width: 36, display: 'none', position: 'fixed', top: 'calc(50vh - 18px)', left: 'calc(50vw - 18px)', background: "#FFF" } } />

      {/* Display story's posts in order. values.posts[current_post_index] is what's currently showing */}
      <div
        {...swipeHandlers}
      >
        <React.Fragment>
          {
            values.posts.map((post, idx) => (
              <React.Fragment key={`post-${idx}`}>
                {
                  values.current_post_index == idx && (
                    <Post
                      key={post.id}
                      post={post}
                      show_follow={true}
                      story_id={props.story.id}
                      variant="story"
                      callbackDelete={props.callbackDelete}
                      callbackPauseStory={pauseStory}
                      callbackAssetClick={handleStoryClick}
                      callbackVideoSeek={handleVideoSeek}
                    />                  
                  )
                }
              </React.Fragment>
            ))
          }
        </React.Fragment>
      </div>
    </React.Fragment>
  );
}