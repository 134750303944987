import React, { useState, useContext } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Badge, Menu, MenuItem } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import Link from "@material-ui/core/Link";
import { Link as RLink } from "react-router-dom";
import UserAvatar from "components/UserAvatar";
import AlertAbbrev from "components/alerts/AlertAbbrev";
import Loading from "components/Loading";
import SearchUsers from "components/SearchUsers";
import { useChannel, useEvent } from "@harelpls/use-pusher";
import { ThemeContext } from "components/ThemeContext";
import { useTheme } from '@material-ui/core/styles';

    // background: `url("/logo_small_${theme.palette.type}.png")`,

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  offset: theme.mixins.toolbar,
  grow: {
    flexGrow: 1,
  },
  // logo: {
  //   /* See also: HeaderLoggedOut.jsx */
  //   width: 41,
  //   marginTop: 0,
  //   background: `url("/logo-heart.svg")`,
  //   [theme.breakpoints.up("sm")]: {
  //     height: 60,
  //     width: 41,
  //     marginTop: 0,
  //     background: `url("/logo-heart.svg")`,
  //   },
  // },
  menuButton: {
    marginRight: "2%",
    [theme.breakpoints.up("md")]: {
      marginRight: "1%",
    },
  },
  badgeRoot: {
    verticalAlign: "inherit",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.background.paper, 0.15),
    marginRight: 0,
    marginLeft: 0,
    display: "none",
    [theme.breakpoints.up(470)]: {
      display: "block",
    },
  },
  sectionDesktopSm: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function Header(props) {
  const classes = useStyles();

  // Desktop profile dropdown menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const isMenuOpen = Boolean(anchorEl);

  // Mobile full menu list
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  // Alerts dropdown menu
  let initialValues = {
    new_alerts: false,
    alerts_loading: false,
    alerts: [],
    alerts_channel: useChannel("private-alerts-" + window.current_user.id),
    unseen_alerts: window.current_user.unseen_alerts || 0,
    unread_messages: window.current_user.unread_messages || 0,
  };
  const [values, setValues] = useState(initialValues);

  let [themeContext, setThemeContext] = useContext(ThemeContext);
  let theme = useTheme();

  function UnderlineMenuIcon() {
    return (
      <span
        style={{
          position: "absolute",
          display: "block",
          height: 2,
          width: 24,
          background: (themeContext.type == "dark" ? theme.palette.primary.light : theme.palette.primary.dark),
        }}
      ></span>
    );
  }

  // See: alert_service.rb
  useEvent(initialValues.alerts_channel, "alerts", function (data) {
    setValues((state) => ({
      ...state,
      ["new_alerts"]: true,
      ["unseen_alerts"]: (values.unseen_alerts || 0) + 1,
    }));
  });

  // See messaging_controller.rb for where these are sent
  useEvent(initialValues.alerts_channel, "message_alerts", function (data) {
    //console.log("data", data)
    // If not currently on the given conversation page, increment the # of unread messages
    if (
      window.location.pathname != `/messages/${data.from_object_id}` &&
      window.location.pathname != `/messages/${data.conversation_object_id}`
    ) {
      setValues((state) => ({
        ...state,
        ["new_messages"]: true,
        ["unread_messages"]: (values.unread_messages || 0) + 1,
      }));
    }
  });

  // User viewed a conversation and messages were seen, update the unread_messages badge accordingly
  useEvent(initialValues.alerts_channel, "messages_seen", function (data) {
    //console.log("data", data)
    let unread = (values.unread_messages || 0) - data.messages_seen;
    unread = unread < 0 ? 0 : unread;
    setValues((state) => ({
      ...state,
      ["unread_messages"]: unread,
    }));
  });

  const [alertsAnchorEl, setAlertsAnchorEl] = React.useState(null);

  const handleAlertsMenuOpen = (event) => {
    setAlertsAnchorEl(event.currentTarget);
    refreshAlerts();
  };

  const handleAlertsMenuClose = () => {
    setAlertsAnchorEl(null);
  };
  const isAlertsMenuOpen = Boolean(alertsAnchorEl);

  function refreshAlerts() {
    setValues((state) => ({
      ...state,
      ["new_alerts"]: false,
      ["alerts_loading"]: true,
    }));

    $.ajax({
      url: `/alerts?q=latest`,
      type: "GET",
      async: window.rails_env != "test",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      success: function (data) {
        setValues((state) => ({
          ...state,
          ["alerts"]: data.alerts,
          ["unseen_alerts"]: data.unseen_alerts,
          ["alerts_loading"]: false,
        }));
      },
    });
  }

  let pathname = window.location.pathname;
  let page = "";
  // Should match App.jsx
  switch (pathname) {
    case pathname.match(/^\/$/)?.input:
      page = "home";
      break;
    case pathname.match(/^\/explore$/)?.input:
      page = "explore";
      break;
    case pathname.match(/^\/(event|community)/)?.input:
      page = "events";
      break;
    case pathname.match(/^\/messages/)?.input:
      page = "messages";
      break;
    case pathname.match(/^\/alerts/)?.input:
      page = "alerts";
      break;
  }

  function imageClass() {
    return (themeContext.type == "dark" ? "no-dark-mode" : "")
  }

  // Desktop Profile dropdown menu + mobile "more" dropdown menu
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Link to={"/" + window.current_user.username} component={RLink} color="inherit">
        <MenuItem onClick={handleMenuClose} dense={true}>
          <img src="/static/icons/icon-profile.svg" className={ imageClass() } style={{ width: "18px" }} />
          <div style={ { marginLeft: 8 } }><b>Profile</b></div>
        </MenuItem>
      </Link>

      <Link to={"/search"} component={RLink} color="inherit">
        <MenuItem onClick={handleMenuClose} dense={true}>
          <img src="/static/icons/icon-search.svg" className={ imageClass() } style={{ width: "18px" }} />
          <div style={ { marginLeft: 8 } }><b>Search</b></div>
        </MenuItem>
      </Link>

      <Link to={"/settings/filters"} component={RLink} color="inherit">
        <MenuItem onClick={handleMenuClose} dense={true}>
          <img src="/static/icons/icon-filters.svg" className={ imageClass() } style={{ width: "16px" }} />
          <div style={ { marginLeft: 8 } }><b>Filters</b></div>
        </MenuItem>
      </Link>

      <Link to={"/settings/consent"} component={RLink} color="inherit">
        <MenuItem onClick={handleMenuClose} dense={true}>
          <img src="/static/icons/icon-consent.svg" className={ imageClass() } style={{ width: "16px" }} />
          <div style={ { marginLeft: 8 } }><b>Consent</b></div>
        </MenuItem>
      </Link>

{/*      <MenuItem onClick={handleMenuClose} dense={true}>
        <Link to={"/settings/invites"} component={RLink} color="inherit">
          <img src="/static/icons/icon-mail.svg" className={ imageClass() } style={{ width: "16px" }} />
        </Link>
        &nbsp;
        <Link to={"/settings/invites"} component={RLink} color="inherit">
          Invites
        </Link>
      </MenuItem>*/}
      {/* <MenuItem onClick={handleMenuClose} className={classes.sectionMobile} dense={true}>
        <Link to={'/events'} component={RLink}>
          <img className="MuiSvgIcon-root" src={EventsIcon} style={{ width: '16px', height: '16px' }}/>&nbsp;
          Events
        </Link>
      </MenuItem> */}

      <Link href="/logout" data-method="delete" color="inherit">
        <MenuItem onClick={handleMenuClose} dense={true}>
          <img src="/static/icons/icon-logout.svg" className={ imageClass() } style={{ width: "16px" }} />
          <div style={ { marginLeft: 8 } }><b>Log Out</b></div>
        </MenuItem>
      </Link>
    </Menu>
  );

  const renderAlertsMenu = (
    <Menu
      anchorEl={alertsAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id="alerts-menu"
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isAlertsMenuOpen}
      onClose={handleAlertsMenuClose}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      {values.alerts_loading && (
        <div style={{ width: "100%", maxWidth: 300, background: "#FFF" }}>
          <Loading loading={values.alerts_loading} />
        </div>
      )}
      {values.alerts.map((alert) => (
        <div key={alert.id} onClick={handleAlertsMenuClose}>
          <AlertAbbrev alert={alert} />
        </div>
      ))}
      <MenuItem
        dense={true}
        component={Link}
        to="/alerts"
        onClick={handleAlertsMenuClose}
        className={classes.link}
        component={RLink}
      >
        See All Alerts
      </MenuItem>
    </Menu>
  );

  // Prevents an with the user being scrolled down a page, then clicking a header icon, and infinite scroll going crazy on the new page
  function scrollTop() {
    window.scrollTo(0, 0);
  }

  /*
   * Common menu items: logo, search, home, compass, messages and alerts
   * Desktop: forums + events
   * Mobile: forums + events show in the profile dropdown
   */
  return (
    <div id="top-header" className={classes.grow}>
      <AppBar position="fixed" className={classes.root} elevation={0}>
        <Toolbar style={{ paddingLeft: "1%", paddingRight: 2 }}>
          <a href="/">
            {/*<div id="logo" className={classes.logo} />*/}
            <img
              id="logo"
              src={(themeContext.type == 'light' ? `/logo-heart-kinkykin-light.svg` : `/logo-heart-kinkykin-dark.svg`)}
              height={32}
              style={ { marginTop: 12 } }
            />
          </a>

          <div className={classes.grow} />

          {/*<div className={classes.search}>
            <SearchUsers
              history={props.history}
              fullWidth={false}
              color="primary"
            />
          </div>*/}

          <div className={`${classes.menuButton}`}>
            <Link to={"/"} component={RLink} onClick={scrollTop} color="inherit">
              <img
                id="menu-home"
                className={ imageClass() }
                src="/static/icons/home.svg"
                title="Your Wall"
                style={{ height: "24px" }}
              />
            </Link>
            {page == "home" && <UnderlineMenuIcon />}
          </div>

{/*          <div className={classes.menuButton} style={{ position: "relative" }}>
            <Link to={"/explore"} component={RLink} onClick={scrollTop} color="inherit">
              <img
                id="menu-explore"
                className={ imageClass() }
                src="/static/icons/explore.svg"
                title="Explore"
                style={{ height: "24px" }}
              />
            </Link>
            {window.location.pathname.match(/^\/explore/) && (
              <UnderlineMenuIcon />
            )}
          </div>*/}

          <div className={classes.menuButton} style={{ position: "relative" }}>
            <Link to={"/events"} component={RLink} onClick={scrollTop} color="inherit">
              <img
                id="menu-events"
                className={ imageClass() }
                src="/static/icons/events.svg"
                title="Events"
                style={{ height: "24px" }}
              />
            </Link>
            {page == "events" && <UnderlineMenuIcon />}
          </div>

          <div className={classes.menuButton}>
            <Link to={"/messages"} component={RLink} onClick={scrollTop} color="inherit">
              <Badge
                title="Messaging"
                badgeContent={values.unread_messages}
                color="secondary"
                classes={{
                  root: classes.badgeRoot,
                }}
              >
                <img
                  id="menu-messages"
                  className={ imageClass() }
                  src="/static/icons/messages.svg"
                  title="Messages"
                  style={{ height: "24px" }}
                />
              </Badge>
            </Link>
            {page == "messages" && <UnderlineMenuIcon />}
          </div>

          <div
            id="header-alerts-icon"
            className={classes.menuButton}
            onClick={handleAlertsMenuOpen}
          >
            <Badge
              title="Alerts"
              badgeContent={values.unseen_alerts}
              color="secondary"
              classes={{
                root: classes.badgeRoot,
              }}
            >
              <img
                id="menu-alerts"
                className={ `hand ${imageClass()}` }
                src="/static/icons/alerts.svg"
                title="Alerts"
                style={{ height: "24px" }}
              />
            </Badge>
            {page == "alerts" && <UnderlineMenuIcon />}
          </div>

          {/*
          <div className={`${classes.sectionDesktop} ${classes.menuButton}`}>
            <Link to={'/events'} component={RLink}>
              <img  src="/static/icons/events.svg" title="Events" style={{ width: '24px', height: '24px' }}/>
            </Link>
          </div>
          */}

          {/*<div className={`${classes.menuButton}`}>
            <Link to={'/groups'} component={RLink}>
              <img src="/static/icons/forums.svg" title="Discussion Groups" style={{ width: '26px', marginTop: 4 }}/>
            </Link>
          </div> */}

          <div className={classes.menuButton}>
            <a id="menu-profile-open" className="hand" onClick={handleProfileMenuOpen}>
              <UserAvatar
                username={window.current_user.username}
                url={window.current_user.avatar_url}
                border_color={current_user.color_background}
                size="extra_small"
              />
            </a>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.offset} />
      {renderMenu}
      {renderAlertsMenu}
    </div>
  );
}
