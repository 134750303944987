import React, { useState, useEffect, useMemo, useRef } from "react";
import $ from "jquery";
import { makeStyles } from "@material-ui/core";
import Cookies from "vendor/js.cookie.min.mjs";
import CommonStyles from "components/styles/CommonStyles";
import { ThemeContext } from "components/ThemeContext";
import { useTheme } from "@material-ui/core";
//import platform from "components/pwa/platform";
import Button from "@material-ui/core/Button";
import Loading from "components/Loading";
import Content from "components/settings/Content";


const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 700,
    padding: 8,
    color: theme.palette.text.primary,
    marginLeft: "auto",
    marginRight: "auto",
  },
  bordered: {
    border: "1px solid #FFCD00",
    background: theme.palette.background.paper,
    padding: 8,
    paddingBottom: 24
  },
  tagcategory: {
    paddingTop: 8,
    marginBottom: 24,
    width: "90%",
    border: "1px solid #00b80d"    
  },
  tagcatselected: {
    border: "1px solid #FF0000",
    opacity: '33%'
  },
}));

export default function NewUser() {
  const classes = useStyles();
  const commonStyles = CommonStyles();
  const theme = useTheme();

  const initialValues = {
    profileEnabled: false,
    loading: false,
    hide_ids: window.current_user?.tag_category_ids_hide || []
  };

  const [values, setValues] = useState(initialValues);

  // Overrides the usual click events. Once removed they work normally.
  function doNothing(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }

  function headerDisable() {
    $("#top-header img").css("opacity", "25%");
    $("#top-header #logo").css("opacity", "25%");
    $("#top-header input").css("opacity", "25%");
    //$("#search-users").attr("disabled", "disabled");
    $("#top-header").on("click", "*", doNothing);
    $("#top-header").on("hover", "*", doNothing);
  }

  // Initial page load. Enable/disable the header accordingly
  useEffect(() => {
    headerDisable();
  }, []);

  function submit() {
    if (values.hide_ids.length == window.tag_categories.length) {
      alert('Please unhide at least one type of content! If you really want to hide all this, KinkyKin is probably not for you.')
      return
    }
    if (values.hide_ids.length == 0) {
      if (!confirm("Are you sure you don't want to hide any content?")) {
        return
      }
    }

    // To make sure the state updates
    setValues(state => ({
      ...state,
      loading: true
    }))

    $('#hide_ids').val(values.hide_ids.join(","))
    setTimeout(() => {
      $('#form-save-tc-hide').submit()
      $('#btn-continue').hide();
    }, 1)
  }

  return (
    <div className={classes.container} id="new-user">
      <div className={classes.bordered}>
        <div style={ { display: (values.loading ? 'none' : 'block') } }>
          <center>
            <h3>Content Filters</h3>

            <Content />

            <form action="/finish_onboarding" method="POST" id='form-save-tc-hide'>
              <input
                name="authenticity_token"
                type="hidden"
                value={window.auth_token}
              />
              <input
                name="hide_ids"
                id="hide_ids"
                type="hidden"
              />
            </form>

            <Button
              id="btn-continue"
              fullWidth
              variant="contained"
              color="primary"
              onClick={submit}
            >
              Continue
            </Button>          
          </center>
        </div>

        <Loading loading={values.loading} />
      </div>
    </div>
  );
}
