import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Button, TextField, Select } from "@material-ui/core";
import Cookies from "vendor/js.cookie.min.mjs";
import VideoIcon from "@material-ui/icons/Videocam";
import PhotoIcon from "@material-ui/icons/PhotoCamera";
import UserAvatar from "components/UserAvatar";
import PostStyles from "components/styles/PostStyles";
import ImageEditor from "components/ImageEditor";
import Thumbnails from "components/posts/Thumbnails";
import Menu from "components/posts/Menu";
import Loading from "components/Loading";
import { CreatePostContext } from "components/posts/CreatePostContext";
import * as PostService from "../../services/posts.service";
import { useTheme } from '@material-ui/core/styles';
import CommonStyles from "components/styles/CommonStyles";
import Dialog from "@material-ui/core/Dialog";
import { Link, Typography } from "@material-ui/core";
import {
  Slider,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormControl,
} from "@material-ui/core";
import EditCrop from "components/stories/EditCrop";
import EditFilters from "components/stories/EditFilters";
import EditDecoration from "components/stories/EditDecoration";
import EditSecurity from "components/stories/EditSecurity";
import PreviewVideo from "components/stories/PreviewVideo";
import { getImageAsResizedCanvas } from "../../vendor/canvasUtils";
import platform from "components/pwa/platform";

/*
 * File has been chosen for upload
 * Based on file type, go through a 'slide show' of various post-processing steps
 * Crop, filters etc etc
 * When the user is done, do the actual upload to the server
 */
export default function CreatePost(props) {
  const commonStyles = CommonStyles();
  const theme = useTheme();

  let initialValues = {
    orientation: window.screen?.orientation?.type,
    isMobile: platform.isMobile(),
    dialogPostShow: false,
    tagAutoComplete: [],
    tagRelated: [],
    tagTimeout: null,
  };
  const [values, setValues] = useState(initialValues);

  function showDialogCreatePost() {
    // Hack: disable pull-down-to-refresh while open
    // See: Posts.jsx#refresh
    window.disable_pull_down_to_refresh = true

    setValues((state) => ({
      ...state,
      dialogPostShow: true
    }));
  }

  function closeDialogViewStories() {
    window.disable_pull_down_to_refresh = false

    setValues((state) => ({
      ...state,
      dialogPostShow: false
    }));

    // Clear things like Zoom, so if they made changes and exit, the changes will disappear the next time they upload
    setContext((state) => (PostService.initialContext()));
  }

  let all_mime_types = PostService.allMimeTypes();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDrop,
    multiple: false,
    accept: all_mime_types.join(", "),
  });

  /*
   * 1. Shared menu state
   * 2. Shared upload state
   * 3. selectedUpload.state == shared image editor state
   */
  const [context, setContext] = useState(PostService.initialContext());

  useEffect(() => {
    setContext((state) => ({
      ...state,
      community: props.community
    }));
    console.log("props.community", props.community)
  }, [props.community])

  // File dropped onto dropzone
  function onDrop(acceptedFiles) {
    PostService.onDrop(acceptedFiles)
      .then((upload) => {
        let steps = []
        let step = ""

        // png/jpg/gif get cropped
        let image_resized = null
        if (upload.mimeTypeSimple == "image") {
          // croppable
          steps = [ "crop", "decoration", "security" ]
          step = "crop"

          // upload.canvasUploaded: canvas of EXIF-fixed and resized image
          // Cropper
          setContext((state) => ({
            ...state,
            imageResized: upload.canvasUploaded.toDataURL()
          }));
        }

        if (upload.mimeTypeSimple == "gif") {
          steps = [ "security" ]
          step = "security"
        }

        if (upload.mimeTypeSimple == "video") {
          steps = [ "preview", "security" ]
          step = "preview"
        }

        if (step == "") {
          alert("Invalid file! Are you sure it's a picture or video?")
          return
        }

        setContext((state) => ({
          ...state,
          steps: steps,
          step: step,
          upload: upload
        }));

        window.upload = upload

        showDialogCreatePost()
      })
      .catch((error) => {
        console.error("Post error: ", error);
      });
  }

  let loading_img = `/static/loading/loading${Math.floor(Math.random() * Math.floor(12)) + 1}.svg`;

  const getOrientation = () => { return window.screen?.orientation?.type }

  const updateOrientation = event => {
    setValues((state) => ({
      ...state,
      orientation: getOrientation() || 'portrait'
    }));
  }

  useEffect(() => {
    window.addEventListener(
      'orientationchange',
      updateOrientation
    )
    return () => {
      window.removeEventListener(
        'orientationchange',
        updateOrientation
      )
    }
  }, [])

  let isMobileAndLandscape = values.isMobile && (values.orientation?.match(/^landscape/) || false)

  // const eventListenerRef = useRef();

  // (event) => event.preventDefault()

  // // Draft saving code
  // useEffect(() => {
  //   eventListenerRef.current = (event) => {
  //     const returnValue = handler?.(event);
  //     // Handle legacy `event.returnValue` property
  //     // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
  //     if (typeof returnValue === 'string') {
  //       return (event.returnValue = returnValue);
  //     }
  //     // Chrome doesn't support `event.preventDefault()` on `BeforeUnloadEvent`,
  //     // instead it requires `event.returnValue` to be set
  //     // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#browser_compatibility
  //     if (event.defaultPrevented) {
  //       return (event.returnValue = '');
  //     }
  //   };
  // }, [handler]);

  function handleBeforeUnload(event) {
    if (window.upload) {
      event.preventDefault();
      const confirmationMessage = "\\o/";

      // Gecko + IE
      //(e || window.event).returnValue = confirmationMessage;

      // Safari, Chrome, and other WebKit-derived browsers
      return confirmationMessage;
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  function exitCallback() {
    closeDialogViewStories()
  }

  let heightTopMenu = $('#stories-header').outerHeight()

  return (
    <React.Fragment>
      {/* Dropzone element. See StoryThumbs.jsx for props.children */}
      <div
        id="fileinfo"
        className={`dropzone-previews`}
        style={ { position: 'relative' } }
      >
        <span
          {...getRootProps()}
          className="dz-clickable noselect"
        >
          <input {...getInputProps()} />

          {props.children}
        </span>
      </div>

      {/* Dialog to hold the various editing steps after file selection */}
      <Dialog
        id="dialog-create-post"
        open={values.dialogPostShow}
        keepMounted={false}
        onClose={closeDialogViewStories}
        scroll="paper"
        fullScreen={true}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {context.loading && (
          <div
            style={{
              display: "flex",
              height: "300px",
              width: "300px",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              background: "background-color: rgba(0, 0, 0, 0.5)",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 30000
            }}
          >
            <img
              src={loading_img}
              style={{
                width: values.width || "200px",
                height: values.height || "200px",
              }}
            />
          </div>
        )}

        {
          isMobileAndLandscape && (
            <div
              style={{
                display: "flex",
                height: "300px",
                width: "90%",
                maxWidth: 600,
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: "linear-gradient(to bottom, rgba(0,0,0,0.70) 0%, rgba(0,0,0,0.9))",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 30000
              }}
            >
              <center>
                <h2><font color="yellow">Please turn your phone vertically to preview your post</font></h2>
              </center>
            </div>
          )
        }

        {
          context.upload && (
            <div>
              <CreatePostContext.Provider value={[context, setContext]}>
                {
                  context.step == "crop" && (
                    <EditCrop exitCallback={exitCallback} heightTopMenu={heightTopMenu} />
                  )
                }

                { /* <EditFilters exitCallback={exitCallback} /> */ }
                {
                  context.step == "decoration" && (
                    <EditDecoration exitCallback={exitCallback} heightTopMenu={heightTopMenu} />
                  )
                }

                {
                  context.step == "security" && (
                    <EditSecurity exitCallback={exitCallback} heightTopMenu={heightTopMenu} />
                  )
                }

                {
                  context.step == "preview" && (
                    <PreviewVideo exitCallback={exitCallback} heightTopMenu={heightTopMenu} />
                  )
                }
              </CreatePostContext.Provider>
            </div>
          )
        }
      </Dialog>
    </React.Fragment>
  );
}