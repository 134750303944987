import React from "react";
import CommonStyles from "components/styles/CommonStyles";

export default function ImgGrid(props) {
  const commonStyles = CommonStyles();

  return (
    <div id="imggrid" className={classes.container}>
      {(context.tagAutoComplete.length > 0 ||
        context.tagRelated.length > 0) && (
        <div
          id="tag-autocomplete"
          style={{
            zIndex: 9999,
            position: "absolute",
            width: "100%",
            maxWidth: 200,
            background: "#FFF",
            border: "1px solid",
          }}
        >
          {context.tagAutoComplete.length > 0 && (
            <div id="tag-autocomplete-suggestions">
              {context.tagAutoComplete.map((tag) => (
                <div key={tag.tag}>
                  &nbsp;
                  <a
                    className="tag-autocomplete-link"
                    onMouseDown={() => {
                      handleClickTag(tag.tag);
                    }}
                  >
                    {`#${tag.tag} (${tag.posts_num})`}
                  </a>
                </div>
              ))}
            </div>
          )}
          {context.tagRelated.length > 0 && (
            <div id="tag-autocomplete-related">
              &nbsp;<b>Related:</b>
              {context.tagRelated.map((tag) => (
                <div key={tag.tag}>
                  &nbsp;
                  <a
                    className="tag-autocomplete-link"
                    onMouseDown={() => {
                      handleClickTag(tag.tag);
                    }}
                  >
                    {`#${tag.tag} (${tag.num_posts_related})`}
                  </a>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
