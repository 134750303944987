import Cookies from "vendor/js.cookie.min.mjs";

export function detectLocation(usr) {
  let hsh = {};

  let cookie_val = Cookies.get("events_location");
  if (cookie_val) {
    let hsh = JSON.parse(cookie_val);

    if (hsh.country_id && hsh.region_id && hsh.city_id) {
      return {
        country_id: hsh.country_id,
        region_id: hsh.region_id,
        city_id: hsh.city_id,
        country: hsh.country,
        region: hsh.region,
        city: hsh.city,
      };
    }
  }
  if (usr.country_id && usr.region_id && usr.city_id) {
    return {
      country_id: usr.country_id,
      region_id: usr.region_id,
      city_id: usr.city_id,
      country: usr.country,
      region: usr.region,
      city: usr.city,
    };
  } else {
    return {
      country_id: usr.detected_country_id,
      region_id: usr.detected_region_id,
      city_id: usr.detected_city_id,
      country: usr.detected_country,
      region: usr.detected_region,
      city: usr.detected_city,
    };
  }
}
