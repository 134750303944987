import React from "react";
import { Link } from "@material-ui/core";
import { Link as RLink } from "react-router-dom";

export default function LinkedText(props) {
  let content = props.content;

  function urlify(text) {
    return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '">' + url + '</a>';
    })
  }

  return (
    <span className="comment-text">
      {content.replace(/\r\n/, "\n").split(/(\s)/).map(function (word, index) {
        let html = word;
        switch (word) {
          case word.match(/^#[a-zA-Z0-9_!?.,]+$/)?.input: // hashtag
            html = (
              <Link
                key={`word${index}`}
                to={`/stories/tag/${word
                  .replace(/^#+/, "")
                  .replace(/[!?.,]$/, "")
                  .toLowerCase()}`}
                component={RLink}
                color="inherit"
              >
                {word}
              </Link>
            );
            break;
          case word.match(/^@[a-zA-Z0-9_!?.,]+$/)?.input: // user
            html = (
              <Link
                key={`word${index}`}
                to={`/${word.replace(/^@+/, "").replace(/[!?.,]$/, "")}`}
                component={RLink}
                color="inherit"
              >
                {word}
              </Link>
            );
            break;
          case word.match(/\n+/)?.input:
            html = (<br key={`word${index}`} />)
            break;
          case word.match(/\s+/)?.input:
            html = " ";
            break;
          case word.match(/^\/alerts/)?.input:
            html = "alerts";
            break;
          case word.match(/(https?:\/\/[^\s]+)$/)?.input:
            html = (<a key={`word${index}`} href={`${word}`}>{word}</a>);
            break;
        }
        return html;
      })}
    </span>
  );
}
