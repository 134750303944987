import React from "react";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    background: "#FFF",
  },
  container: {
    maxWidth: "614px !important",
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default function About(props) {
  const classes = useStyles();
  return (
    <div className={`${classes.container} ${classes.paper}`}>
      <Typography variant="h4">Invite Program for Content Creators:</Typography>

      <Typography variant="body1">
        <p>
          <a href="/about">KinkKin</a> is a brand new 18+ kink-based social
          media platform.
        </p>

        <p>
          We’d love to see high quality content creators like yourself on our
          platform!
        </p>

        <p>
          We are laying the groundwork for an inclusive social media experience
          where kinksters, sex workers and fetishists have a safe space for
          self-expression. Users can find and create events, make connections,
          and have full autonomy over how they display their content.
        </p>

        <p>
          It’s no secret that platforms made by and for vanillas will never be a
          place that we can call home. Instagram and Twitter have algorithms and
          guidelines that do not favor us. Fetlife has become the out-dated,
          back alley network of kink that we are going to replace.
        </p>

        <p>
          Of course, we are not expecting you to share your best paid content.
          This is an opportunity to be on the ground floor of a brand new
          platform, exclusively for kinksters, to show off your assets and
          provide you a space to expand your network and following.
        </p>

        <p>
          If you are interested, please respond to Ava! Or if you prefer:
          [ava@kinkkin.com] [twitter message] [ instagram message] [manyvids
          message]
        </p>
      </Typography>

      <p>
        <a href="/about">About Us</a> |&nbsp;
        <a href="/contact">Contact Us</a> |&nbsp;
        <a href="/content_guidelines">Content Guidelines</a> |&nbsp;
        <a href="/privacy">Privacy Policy</a> |&nbsp;
        <a href="/terms">Terms</a>
      </p>
      <br />
    </div>
  );
}
