import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    background: "#FFF",
  },
  container: {
    maxWidth: "500px",
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default function ResetPassword(props) {
  var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split("&"),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        return typeof sParameterName[1] === undefined
          ? true
          : decodeURIComponent(sParameterName[1]);
      }
    }
    return false;
  };

  let reset_password_token = getUrlParameter("reset_password_token");

  const classes = useStyles();

  const initialValuesPw = {
    old_password: "",
    new_password: "",
    new_password_confirmation: "",
  };
  const [valuesPw, setValuesPw] = useState(initialValuesPw);

  const handleChangePw = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    let required = event.target.required;

    setValuesPw((state) => ({
      ...state,
      [name]: value,
    }));
  };

  return (
    <div className={`${classes.container} ${classes.paper}`}>
      <form
        className="new_user"
        id="new_user"
        action="/passwords"
        method="post"
      >
        <input type="hidden" name="_method" value="PUT" />

        <Typography component="h5" variant="h5">
          Reset Password
        </Typography>

        <input
          type="hidden"
          name="authenticity_token"
          value={window.auth_token}
        />
        <input
          type="hidden"
          name="reset_password_token"
          value={reset_password_token}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          style={{ width: "50%" }}
          id="input-password-new"
          label="New Password"
          name="new_password"
          type="password"
          value={valuesPw.new_password}
          onChange={handleChangePw}
          autoComplete="off"
          size="small"
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          style={{ width: "50%" }}
          id="input-password-new"
          label="New Password (again)"
          name="new_password_confirmation"
          type="password"
          value={valuesPw.new_password_confirmation}
          onChange={handleChangePw}
          autoComplete="off"
          size="small"
        />

        <Button
          id="btn-reset-password"
          type="submit"
          disabled={
            valuesPw.new_password == "" ||
            valuesPw.new_password_confirmation == "" ||
            valuesPw.new_password != valuesPw.new_password_confirmation
          }
          variant="contained"
          color="primary"
        >
          Reset Password
        </Button>
      </form>
    </div>
  );
}
