import React from "react";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    background: "#FFF",
  },
  container: {
    maxWidth: "614px !important",
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default function Terms(props) {
  const classes = useStyles();
  return (
    <div className={`${classes.container} ${classes.paper}`}>
      <Typography variant="h4">Terms and Conditions:</Typography>

      <Typography variant="body1">
        <p>
          <b>Summary:</b>
        </p>
        <ul>
          <li>No posting content that is illegal in the United States</li>
          <li>No images or videos of anyone under 18, even if "G-rated"</li>
          <li>No circumvention of the privacy or security policy</li>
          <li>No malware or viruses</li>
          <li>No harassment of other users or staff</li>
          <li>You retain full copyright/ownership of any content you post</li>
        </ul>
      </Typography>

      <Typography variant="body1">
        <p>
          <b>For lawyers:</b>
        </p>

        <iframe width="100%" height="300px" src="/terms_and_conditions.html" />
      </Typography>

      <p>
        <a className="light-themed" href="/about">About</a> |&nbsp;
        <a className="light-themed" href="/contact">Contact Us</a> |&nbsp;
        <a className="light-themed" href="/content_guidelines">Content Guidelines</a> |&nbsp;
        <a className="light-themed" href="/privacy">Privacy Policy</a>
      </p>
      <br />
    </div>
  );
}
