import loadImage from 'vendor/javascript_load_image'
import Cookies from 'vendor/js.cookie.min.mjs'

let image_mime_types = ['image/jpeg', 'image/png']
let gif_mime_type = ['image/gif']
let video_mime_types = ['video/webm', 'video/mp4', 'video/quicktime']
let all_mime_types = (image_mime_types.concat(gif_mime_type).concat(video_mime_types))

export const allMimeTypes = () => {
  return all_mime_types
}


export function DataURIToBlob(dataURI) {
  const splitDataURI = dataURI.split(',')
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)

  return new Blob([ia], { type: mimeString })
}

export const initialContext = () => {
  return {
    steps: [],
    step: "",
    upload: null,
    loading: false,
    //hidden: false,
    postText: "",
    imageCropped: null,
    mimeType: null,
    mimeTypeSimple: null,
    imageResized: null,
    croppedAreaPixels: {},
    crop: { x: 0, y: 0 },
    rotation: 0,
    zoom: -1,
    containerMaxWidth: 400,
    containerMaxHeight: 1000,
    background: { colorA: Cookies.get('background_colorA') || "#FFFFFF", colorB: Cookies.get('background_colorB') || "#000000", direction: "top-to-bottom" },
    //current_sticker: null,
    touchX: null,
    touchY: null,

    community: null,

    clickedText: null,
    texts: [], // values of text editor
    dragTexts: [], // Text objects themselves
    current_text: 0,

    clickedBlur: null,
    current_blur: null,
    blurs: [],

    clickedSticker: null,
    stickers: [],

    canvasWithoutSelectedDraggable: null,
    canvasCropped: null,
    canvasOriginal: null,
    canvasFiltered: null,

    /*
    selectedUpload: {},
    uploads: [{}],
    */
    permission: parseInt(Cookies.get('default-permission')) || 2,
    comment_permission: parseInt(Cookies.get('default-comment_permission')) || 2,
    save_to_profile: Cookies.get('default-save_to_profile') == 'true' ? true : false,
    time: parseInt(Cookies.get('default-time')) || -1,
    timeHours: 0,
    currentMenu: "texts",
    currentFilter: 'normal',
    currentBlur: '',
    currentBlurSize: 10,
    currentSticker: '',
    currentStickerSize: 15,
    // font: 'Anton',
    // size: 70,
    // color: '#F06914',
    // category: 'sans-serif',
    // text: '',
    // font_fill: true,
    // showColorPicker: false,
    // timeoutType: null
  }
}

export const disableRightClick = () => {
  setTimeout(() => {
    $('video').prop('volume', parseFloat(Cookies.get('default-video-volume') || 1))

    $("video.disable_right_click").bind("contextmenu", function() {
      return false;
    });

    // $("img.disable_right_click, .MuiAvatar-img").bind("contextmenu", function() {
    //   return false;
    // });
  }, 100)
}

// blobs: finalized versions of the jpg/png images the user uploaded (if any)
// uploads: array of all upload objects created via onDrop, e.g. context.uploads
// returns: a populated FormData object
export function populateFormUploads(fd, upload, callback) {
  let imageNum = 0

  if (upload.mimeType == 'image/jpeg' || upload.mimeType == 'image/png') {
    fd.append('file', upload.file)
    imageNum += 1
  } else {
    fd.append('file', DataURIToBlob(upload.file))
  }

  callback()
}


/*
 * We got a file from DropZone
 * 
 * 1. Reject if it's too big
 * 2. 
 */
export const onDrop = (acceptedFiles) => {
  let file = acceptedFiles[0]
  //console.log("file", file)

  return new Promise((resolve, reject) => {
    let mime_type = file.type
    if (image_mime_types.includes(mime_type)) {
      if (file.size > 1024*1024*50) {
        window.toastr.error("Jpg/png images must be < 50 MB");
        resolve(null)
        return
      }
    }
    if (gif_mime_type.includes(mime_type)) {
      if (file.size > 1024*1024*10) {
        window.toastr.error("Gif images must be < 10 MB");
        resolve(null)
        return
      }
    }
    if (video_mime_types.includes(mime_type)) {
      if (file.size > 1024*1024*100) {
        window.toastr.error("Videos must be < 100 MB");
        resolve(null)
        return
       }
     }

    const reader = new FileReader()
    reader.onabort = function() {
      window.toastr.error("Error while uploading. Wifi connection lost? Please try again");
      resolve(null)
      return
    }
    reader.onerror = function() {
      window.toastr.error("Error while uploading. Wifi connection lost? Please try again");
      resolve(null)
      return
    }
    reader.onprogress = function(evt) {
      //console.log("File read progress", evt)
    }
    reader.onload = function() {
      if (image_mime_types.includes(mime_type)) {
        // After upload, before image editing:
        //   Resize to fit within a 1080x1080 box
        //   Fix EXIF orientation for phones
        //   See: https://github.com/blueimp/JavaScript-Load-Image
        // This reduces # of pixels so filters are more performant AND the uploaded image is much smaller
        loadImage(file, function (canvas, metadata) {
            //console.log("metadata", metadata)
            let upload = {
              originalFilename: file.name,
              file: file,
              fileBase64: canvas.toDataURL(),
              canvasUploaded: canvas,
              thumbnail: null, // canvas.toDataURL()
              mimeType: mime_type,
              mimeTypeSimple: "image"
            }
            resolve(upload)
            return
          },
          {
            meta: true,
            maxWidth: 1080,
            maxHeight: 1080,
            canvas: true
          }
        )
       }
       if (gif_mime_type.includes(mime_type)) {
        let upload = {
          originalFilename: file.name,
          file: file,
          src: reader.result,
          mimeType: mime_type,
          mimeTypeSimple: "gif",
          thumbnail: "/static/thumbnails/thumbnail-gif.png"
         }
        resolve(upload)
        return
       }
       if (video_mime_types.includes(mime_type)) {
        let upload = {
           originalFilename: file.name,
          file: file,
          src: reader.result,
          mimeType: mime_type,
          mimeTypeSimple: "video",
          thumbnail: "/static/thumbnails/thumbnail-video.png"
         }
        resolve(upload)
        return
       }
    }
    reader.readAsDataURL(file)
  })
}