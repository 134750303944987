import React from "react";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    background: "#FFF",
  },
  container: {
    maxWidth: "614px !important",
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default function Privacy(props) {
  const classes = useStyles();
  return (
    <div className={`${classes.container} ${classes.paper}`}>
      <Typography variant="h4">Privacy Policy:</Typography>

      <Typography variant="body1">
        <ul>
          <li>All content you post expires, including user-to-user messages</li>
          <li>You can blur faces or tattoos with our built-in image editor</li>
          <li>We will never sell your data to any 3rd party</li>
          <li>We will make every possible effort to keep your data private</li>
          <li>
            Any pictures, videos, posts or other personal data will only be
            shareable with KinkKin users and only by consent
          </li>
        </ul>

        <p>
          <b>Tech specifics:</b>
        </p>
        <ul>
          <li>
            All pictures and videos are stored on Amazon's S3 cloud as
            completely private.
          </li>
          <li>
            They may only be viewed by the original uploader or any users the
            uploader authorizes to see them.
          </li>
          <li>
            The URLs to pictures/videos are only valid for 60s from each page
            load (e.g. sharing the picture's URL will only work for a max of 60
            seconds).
          </li>
          <li>
            For up to 7 days after deletion, KinkKin employees are still able to
            see all content for the purpose of moderating and banning users.
          </li>
        </ul>
      </Typography>

      <Typography variant="body1">
        <p>
          <b>For lawyers:</b>
        </p>
        <iframe width="100%" height="300px" src="/privacy_policy.html" />
      </Typography>

      <p>
        <a className="light-themed" href="/about">About</a> |&nbsp;
        <a className="light-themed" href="/contact">Contact Us</a> |&nbsp;
        <a className="light-themed" href="/content_guidelines">Content Guidelines</a> |&nbsp;
        <a className="light-themed" href="/terms">Terms</a>
      </p>
      <br />
    </div>
  );
}
