import React from "react";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    background: "#FFF",
  },
  container: {
    maxWidth: "614px !important",
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default function About(props) {
  const classes = useStyles();
  return (
    <div className={`${classes.container} ${classes.paper}`}>
      <Typography variant="h5">Frequently Asked Questions:</Typography>

      <Typography variant="body1">
        <p>
          <b>Will there be a mobile app?</b>
        </p>

        <p>
          <b>Short answer</b>: Yes. It's in development now and should be
          available by the new year.
        </p>

        <p>
          <b>Long answer</b>: due to Apple and Google's very strict NSFW
          policies, we will have to cripple the app to keep it in the Apple and
          Play stores. Essentially it will be limited to messaging, alerts and
          events. This is still a win, as you'll have a secure messaging
          platform with fancy notifications, perfect for texting kinky friends
          and coordinating events.
        </p>

        <p>
          Some good news however, we will also offer a fully-featured
          'sideloaded' Android app, similar to how Fetlife does their app. Apple
          still refuses to allow sideloading for now, but if that changes we
          will offer a fully-featured sideloaded iPhone app as well.
        </p>

        <p>
          <b>Where's my confirmation email?</b>
        </p>

        <p>
          iCloud and Hotmail are currently blocking our emails for some reason.
          We're working on it but if you didn't get your confirmation email,
          please <a href="/contact">contact support</a> or message{" "}
          <a href="/dennis">dennis</a> directly and we'll take care of ya.
        </p>

        <p>
          <b>What content is allowed?</b>
        </p>

        <p>
          Unliked Instagram and Twitter, nudity and sexual content are both
          allowed and encouraged. Kin will always be 18+. Unlike Fetlife
          however, we are trying for a safer and less toxic space so our content
          guidelines are more restrictive. If you're in doubt, check out our{" "}
          <a href="/content_guidelines">content guidelines</a>.
        </p>

        <p>
          <b>What is moderation like?</b>
        </p>

        <p>
          We believe our moderation is the best in the business and it has been
          a focus since the beginning. Trusted community members can be promoted
          to moderators, so safety isn't left in the hands of a tiny staff like
          with Fetlife. Additionally, any content can be flagged, with the more
          serious violation such as human trafficking and underage content going
          straight to the inboxes of staff. Sex offenders and harassers are not
          allowed and will be banned when brought to moderator attention. Any
          violations of our content guidelines can result in anything from a
          24-hour to a permanent ban.
        </p>
      </Typography>

      <p>
        <a href="/about">About</a> |&nbsp;
        <a href="/contact">Contact Us</a> |&nbsp;
        <a href="/privacy">Privacy Policy</a> |&nbsp;
        <a href="/terms">Terms</a>
      </p>
      <br />
    </div>
  );
}
