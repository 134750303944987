import React, { useState, useEffect, useContext } from "react";
import $ from "jquery";
import goBack from "go_back";
import { Container, Badge } from "@material-ui/core";
import { fade, makeStyles } from "@material-ui/core";
import Cookies from "vendor/js.cookie.min.mjs";
import { Link, Typography } from "@material-ui/core";
import { Link as RLink } from "react-router-dom";
import TabPanel from "components/TabPanel";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Dialog from "@material-ui/core/Dialog";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import SearchUsersMessaging from "components/messages/SearchUsersMessaging";
import Loading from "components/Loading";
import Inbox from "components/messages/Inbox";
import CommonStyles from "components/styles/CommonStyles";
import { TextField } from "@material-ui/core";
import { ThemeContext } from "components/ThemeContext";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(1),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 0),
    height: "100%",
    position: "absolute",
    display: "flex",
    right: 4,
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: `calc(100% - 40px)`,
  },
  inputInput: {
    padding: theme.spacing(1, 0, 1, 1),
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    border: "1px solid",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

function NoMessages() {
  return (
    <div>
      <br />
      <center>[ no messages here ]</center>
      <br />
      <br />
    </div>
  );
}
export default function Messages(props) {
  const classes = useStyles();
  const commonStyles = CommonStyles();

  // Should match: messaging_controller.rb#CONVOS_PER_PAGE
  let rowsPerPage = 10;

  let initialInbox = {
    conversations: [],
    users: {},
    unread: 0,
  };
  const initialValues = {
    inboxes: {
      kin: initialInbox,
      general: initialInbox,
      events: initialInbox,
      archived: initialInbox,
    },
    unreads: {},
    page: 0,
    loading: true,
  };
  const [values, setValues] = useState(initialValues);

  let [themeContext, setThemeContext] = useContext(ThemeContext);

  let inbox_names = ["kin", "general", "events", "archived"];

  /* AppBar / Tab Stuff */

  let defaultTabNum = parseInt(Cookies.get("lastTabMessages") || 0);

  const [tab, setTab] = React.useState(defaultTabNum);

  const handleChangeTab = (event, newTab) => {
    setTab(newTab);

    Cookies.set("lastTabMessages", newTab, { expires: 30 });
  };

  function handleClickTab(idx) {
    handleChangeTab({}, idx);
  }

  function loadInboxes(names) {
    let inbox = names.join(",");
    $.ajax({
      url: `/api/v1/messaging/inboxes.json?i=${inbox}`,
      type: "GET",
      async: window.rails_env != "test",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      success: function (data) {
        //console.log("data", data)

        //props.onLoaded(inbox, data.conversations.length, data.unread)
        setValues((state) => ({
          ...state,
          [`inboxes`]: data,
          ["loading"]: false,
        }));
      },
    });
  }

  useEffect(() => {
    loadInboxes(inbox_names);
  }, []);

  // Used by archive/mute
  // ToDo: make this more granular
  function refreshAll() {
    loadInboxes(inbox_names);
  }

  /* Hamburger menu crap */

  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);

  // const handleMenu = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  /* state for which inboxes have loaded */
  function inboxLoaded(inbox, num_conversations, unread) {
    let inboxes = values.inboxes;
    inboxes[inbox] = num_conversations;

    let unreads = values.unreads;
    unreads[inbox] = unread;

    let loading = false;
    if (values.loading) {
      for (let i = 0; i < inbox_names.length; i++) {
        if (!(inbox_names[i] in inboxes)) {
          loading = true;
          break;
        }
      }
    }

    setValues((state) => ({
      ...state,
      loading: loading,
      inboxes: inboxes,
      unread: unreads,
    }));
  }

  // New conversation dialog
  const [openDialogNewConvo, setOpenDialogNewConvo] = useState(false);

  function showDialogNewConvo() {
    setOpenDialogNewConvo(true);
  }

  function closeDialogNewConvo() {
    setOpenDialogNewConvo(false);
  }

  // Compose stuff
  const [composeValues, setComposeValues] = useState({ users: [], draft: "" });

  function handleSelectUser(users) {
    setComposeValues((state) => ({
      ...state,
      users: users,
    }));
  }

  // User is typing a message
  function handleChangeDraft(evt) {
    const { name, value } = evt.target;
    setComposeValues((state) => ({
      ...state,
      ["draft"]: value,
    }));
  }

  // If enter is pressed, send the message
  function handleKeyDown(evt) {
    const { name, value } = evt.target;

    if (evt.keyCode === 13 && !evt.shiftKey) {
      evt.preventDefault();

      if (sendEnabled()) {
        handleMessageSend();
      }
    }
  }

  function sendEnabled() {
    return composeValues.draft.length > 0 && composeValues.users.length > 0;
  }

  function handleMessageSend() {
    let to_ids = composeValues.users.map((user) => user.object_id);

    let fd = new FormData();
    fd.append("from", window.current_user.object_id);
    fd.append("to", to_ids);
    fd.append("message", composeValues.draft);
    fd.append("authenticity_token", window.auth_token);
    $.ajax({
      url: "/api/v1/messaging/conversation/send_message_social",
      type: "POST",
      async: window.rails_env != "test",
      data: fd,
      success: function (data) {
        console.log(data);
        props.history.push(`/messages/${data.conversation_object_id}`);
      },
      error: function (data) {
        console.error(data);
        window.toastr.error("Error sending message");
      },
      cache: false,
      contentType: false,
      processData: false,
    });
  }

  /* Search crap */
  const initialSearchValues = {
    inbox: {
      conversations: [],
      users: [],
      assets: [],
      unread: 0,
    },
    loading: false,
  };
  const [searchValues, setSearchValues] = useState(initialSearchValues);

  function performSearch() {
    let query = $("#search-box").val();

    setSearchValues((state) => ({
      ...state,
      loading: true,
    }));

    setTimeout(function () {
      setTab(4);
    }, 1);

    $.ajax({
      url: `/api/v1/messaging/search?q=${query}`,
      type: "GET",
      async: window.rails_env != "test",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      success: function (data) {
        setSearchValues((state) => ({
          ...state,
          inbox: data.inbox,
          loading: false,
        }));
      },
      error: function (data) {
        setSearchValues((state) => ({
          ...state,
          loading: false,
        }));
        window.toastr.error(data.responseJSON.error);
      },
    });
  }

  function imageClass() {
    return (themeContext.type == "dark" ? "no-dark-mode" : "")
  }

  return (
    <div>
      <div
        className={classes.grow}
        style={{ display: values.loading ? "none" : "block" }}
      >
        <div
          style={{ // no messages in any inbox? Just show now messages yet
            display:
              values.inboxes.kin?.conversations?.length == 0 &&
              values.inboxes.general?.conversations?.length == 0 &&
              values.inboxes.events?.conversations?.length == 0
                ? "block"
                : "none",
            padding: 0,
          }}
        >
          <center>
            <br />
            <Typography>No messages yet!</Typography>
            <p>
              <a onClick={goBack}>Go Back</a>
            </p>
            <p>
              <a id="no-message-new-message" onClick={showDialogNewConvo}>
                New Message
              </a>
            </p>
            <br />
          </center>
        </div>
        <div
          style={{ // if any messages at all, show the inbox selector and search
            display:
              values.inboxes.kin?.conversations?.length > 0 ||
              values.inboxes.general?.conversations?.length > 0 ||
              values.inboxes.events?.conversations?.length > 0
                ? "block"
                : "none",
            padding: 0,
          }}
        >
          {/* Hidden dialog: create new conversation */}
          <Dialog
            id="dialog-new-conversation"
            open={openDialogNewConvo}
            keepMounted={false}
            onClose={closeDialogNewConvo}
            scroll="paper"
            classes={{ paper: commonStyles.dialog500 }}
          >
            <SearchUsersMessaging
              history={history}
              fullWidth={true}
              callbackSelect={handleSelectUser}
              maxUsers={10}
            />
            <div
              style={{
                position: "absolute",
                bottom: 0,
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div style={{ flexGrow: 1, flexShrink: 1 }}>
                <TextField
                  id="input-draft"
                  name="input-draft"
                  variant="outlined"
                  margin="none"
                  size="small"
                  onChange={handleChangeDraft}
                  onKeyDown={handleKeyDown}
                  value={composeValues.draft || ""}
                  inputProps={{ autoComplete: "off", maxLength: 1500 }}
                  multiline
                  rowsMax={10}
                  placeholder=".=[ be nice ]=."
                  fullWidth={true}
                  autoComplete="off"
                />
              </div>
              <div style={{ flexBasis: 32, flexShrink: 1 }}>
                {sendEnabled() && (
                  <img
                    id="id-message-send"
                    src="/static/icons/icon-send-message.svg"
                    width={32}
                    style={{ cursor: "pointer" }}
                    onClick={handleMessageSend}
                  />
                )}
                {!sendEnabled() && (
                  <img
                    id="id-message-send"
                    src="/static/icons/icon-send-message-disabled.svg"
                    width={32}
                  />
                )}
              </div>
            </div>
          </Dialog>

          {/*Appbar #1: inbox selector */}
          <AppBar position="static" color="inherit" elevation={1}>
            <Tabs
              classes={{
                root: commonStyles.tabsRoot,
                scroller: commonStyles.tabsScroller,
              }}
              variant="fullWidth"
              value={tab}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="auto"
            >
              <Tab
                onClick={() => {
                  handleClickTab(0);
                }}
                label={
                  <Badge
                    badgeContent={values.inboxes.kin.unread}
                    color="secondary"
                    variant="dot"
                  >
                    Kin
                  </Badge>
                }
                id={`scrollable-auto-tab-0`}
              />
              <Tab
                onClick={() => {
                  handleClickTab(1);
                }}
                label={
                  <Badge
                    badgeContent={values.inboxes.general.unread}
                    color="secondary"
                    variant="dot"
                  >
                    General
                  </Badge>
                }
                id={`scrollable-auto-tab-1`}
              />
              <Tab
                onClick={() => {
                  handleClickTab(2);
                }}
                label={
                  <Badge
                    badgeContent={values.inboxes.events.unread}
                    color="secondary"
                    variant="dot"
                  >
                    Events
                  </Badge>
                }
                id={`scrollable-auto-tab-2`}
              />
              <Tab
                onClick={() => {
                  handleClickTab(3);
                }}
                label={
                  <Badge
                    badgeContent={values.inboxes.archived.unread}
                    color="secondary"
                    variant="dot"
                  >
                    Archived
                  </Badge>
                }
                id={`scrollable-auto-tab-3`}
              />
              {searchValues.inbox.conversations?.length > 0 && (
                <Tab
                  onClick={() => {
                    setTab(4);
                  }}
                  label="Results"
                  id={`scrollable-auto-tab-4`}
                />
              )}
            </Tabs>
          </AppBar>

          <div id="messages" className={commonStyles.containerNoPadding}>
            {/*Appbar #2: search and new message*/}
            <AppBar position="static" color="transparent" elevation={1}>
              <Toolbar>
                <div className={classes.search}>
                  <div className={classes.searchIcon} onClick={performSearch}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    id="search-box"
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                  />
                </div>
                <div className={classes.grow} />
                <div>
                  <IconButton onClick={showDialogNewConvo} color="inherit">
                    <img
                      id="button-new-message"
                      className={ imageClass() }
                      src="/static/icons/icon-new-message.svg"
                      width={32}
                    />
                  </IconButton>
                  {/*<IconButton
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>Archived</MenuItem>
                  </Menu>*/}
                </div>
              </Toolbar>
            </AppBar>

            <TabPanel id={`tabpanel-kin`} value={tab} index={0} style={{}}>
              {!values.inboxes?.kin?.conversations?.length && (
                <div className={commonStyles.paddingLR}>
                  <p>
                    The 'Kin' inbox is for messages where one of the other users
                    is kin, meaning you have approved them for the highest trust
                    level.
                  </p>
                  <NoMessages />
                </div>
              )}
              <Inbox
                name="kin"
                inbox={values.inboxes.kin}
                callbackRefreshAll={refreshAll}
              />
            </TabPanel>
            <TabPanel id={`tabpanel-general`} value={tab} index={1} style={{}}>
              {!values.inboxes?.general?.conversations?.length && <NoMessages />}
              <Inbox
                name="general"
                inbox={values.inboxes.general}
                callbackRefreshAll={refreshAll}
              />
            </TabPanel>
            <TabPanel id={`tabpanel-events`} value={tab} index={2} style={{}}>
              {!values.inboxes?.events?.conversations?.length && (
                <div className={commonStyles.paddingLR}>
                  <p>
                    The Events inbox is for messages relating to an{" "}
                    <Link to={"/events"} component={RLink} color="inherit">
                      event
                    </Link>
                  </p>
                  <NoMessages />
                </div>
              )}
              <Inbox
                name="events"
                inbox={values.inboxes.events}
                callbackRefreshAll={refreshAll}
              />
            </TabPanel>
            <TabPanel id={`tabpanel-archived`} value={tab} index={3} style={{}}>
              {!values.inboxes?.archived?.conversations?.length && <NoMessages />}
              <Inbox
                name="archived"
                inbox={values.inboxes.archived}
                callbackRefreshAll={refreshAll}
              />
            </TabPanel>
            {/* Search results */}
            <TabPanel
              id={`tabpanel-results`}
              value={tab}
              index={4}
              style={{ padding: 2, marginBottom: 4 }}
            >
              <Loading loading={searchValues.loading} />
              <div style={{ display: searchValues.loading ? "none" : "block" }}>
                {searchValues.inbox.conversations.length == 0 && (
                  <center>
                    <span>[ no results for '{$("#search-box").val()}' ]</span>
                    <br />
                    <br />
                  </center>
                )}
                <Inbox name="results" inbox={searchValues.inbox} />
              </div>
            </TabPanel>
          </div>
        </div>
      </div>
      <Loading loading={values.loading} />
    </div>
  );
}
