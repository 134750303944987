import React from "react";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    background: "#FFF",
  },
  container: {
    maxWidth: "614px !important",
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default function About(props) {
  const classes = useStyles();
  return (
    <div className={`${classes.container} ${classes.paper}`}>

Welcome to KinkyKin, the new horizon for kinky social media.

<p>
  <b>Our mission</b> is to provide a safe and inclusive space for kinksters to share, connect, and build community. 
</p>

<Typography variant="h5">
  Core Values: 
</Typography>

<p>
  <b>Safety</b>: On Kin, we foster a safe environment by giving you control over who sees your photos and who has permission to contact you. You also hold the key to what type of content you’d like to see -- or not see -- on your feed. 
</p>

<p>
  <b>Inclusivity</b>: Whether you’re new to kink or a seasoned pro, Kin is designed to offer a well-moderated platform with familiar features to help you connect with others easily and respectfully. 
</p>

<p>
  <b>Community</b>: At Kin, we’re not concerned with being the largest kinky social media platform around–but we do want to be the best. That’s why we have a commitment to staying small and leaving our moderation processes to actual community members who care about making Kin the top resource for finding IRL events and forming IRL connections.
</p>

<Typography variant="h5">
  About: 
</Typography>

<p>KinkyKin was conceived and built by a small group of kinky people from across the U.S. who got tired of the stringent –- and often discriminatory -– content guidelines on mainstream social media platforms, as well as the predatory, “back alley” feel of current kink-focused alternatives.</p>

<p>It is their mission to continue creating a safe and fun space for kinksters to build community both locally and globally.</p>

{/*<h2>What’s different?</h2>

<p>
Our app has key improvements over Fetlife:
</p>

<ul>
<li> 100% free to use</li>
<li> Fast and modern</li>
<li> Focus on privacy and effective moderation</li>
</ul>

<p>
It also improves on non-kink-friendly spaces such as Instagram, Twitter and Tumblr:
</p>

<ul>
<li> We ❤️ NSFW content here!</li>
<li> Features IRL Events</li>
<li> No worries about the banhammer coming down at random</li>
</ul>*/}

<Typography variant="h5">
  FAQ:
</Typography>

<p><b>Is there a mobile app?</b></p>

<p>We have a <a className="light-themed" target="_new" href="https://en.wikipedia.org/wiki/Progressive_web_app?useskin=vector">Progressive Web App</a>, which you can install from the home page once you're logged in.</p>

<p><b>How is your moderation better?</b></p>

<p>We aim to stay small, which is key to making effective moderation possible.</p>
<p>Moderation is handled by both trusted community members and Kin staff.</p>
<p>We go out of our way to minimize dick pics unless you <i>really</i> want to see them.</p>

<p><b>What can I post?</b></p>

<p>We ❤️ NSFW content here! Nudity is always fine, other kink content is allowed by our <a className="light-themed" href="/content_guidelines">Content Guidelines</a>.</p>

      <p>
        <a className="light-themed" href="/contact">Contact Us</a> |&nbsp;
        <a className="light-themed" href="/privacy">Privacy Policy</a> |&nbsp;
        <a className="light-themed" href="/terms">Terms</a>
      </p>
      <br />
    </div>
  );
}
