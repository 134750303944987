// Layout crap
import { Typography } from "@material-ui/core";
import React from "react";
import { useTheme } from "@material-ui/core";

export default function PageDescription(props) {
  const theme = useTheme();

  return (
    <div
      style={{
        padding: 8,
        maxWidth: 614,
        color: theme.palette.text.primary,
        background: theme.palette.background.paper,
        marginTop: 0,
        marginBottom: 8,
        textAlign: "center",
        border: "1px solid",
        borderColor: theme.palette.text.disabled,
        borderRadius: 2,
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Typography variant="body2" component="p">
        <span dangerouslySetInnerHTML={{ __html: props.text }} />
      </Typography>
    </div>
  );
}
