import React from "react";
import { Link } from "@material-ui/core";
import { Link as RLink } from "react-router-dom";
import UserAvatar from "components/UserAvatar";
import CommonStyles from "components/styles/CommonStyles";

export default function AlertAbbrev(props) {
  const commonStyles = CommonStyles();
  let alert = props.alert;

  return (
    <div
      className={commonStyles.flexCol}
      style={{ width: 300, padding: 4, marginBottom: 4 }}
    >
      <div
        className={commonStyles.flexRow}
        style={{ width: 300, alignItems: "flex-start" }}
      >
        <div style={{ flexBasis: 34, width: 34, maxWidth: 34 }}>
          <Link
            to={{ pathname: `/${alert.username}` }}
            component={RLink}
            style={{ position: "relative" }}
            color="inherit"
          >
            <UserAvatar
              username={alert.username}
              url={alert.avatar_url}
              border_color="red"
              size="extra_small"
            />
          </Link>
        </div>
        <div style={{ flexGrow: 1, paddingLeft: 4 }}>
          {alert.other_user_id &&
            alert.other_user_id != window.current_user.id && (
              <span>
                <Link
                  to={{ pathname: `/${alert.username}` }}
                  component={RLink}
                  style={{ position: "relative" }}
                  color="inherit"
                >
                  <b>{alert.username}</b>
                </Link>{" "}
              </span>
            )}
          {alert.event_type == 0 && (
            <span>
              loved your&nbsp;
              <Link
                to={{ pathname: `/p/${alert.post_id}` }}
                component={RLink}
                style={{ position: "relative" }}
                color="inherit"
              >
                <b>post</b>
              </Link>
            </span>
          )}
          {alert.event_type == 1 && (
            <span>
              commented on your&nbsp;
              <Link
                to={{ pathname: `/p/${alert.post_id}` }}
                component={RLink}
                style={{ position: "relative" }}
                color="inherit"
              >
                <b>post:</b>
              </Link>
              <div
                className="ellipsis"
                style={{
                  maxWidth: 300 - 34 - 8 - 4,
                  paddingTop: 2,
                  paddingBottom: 2,
                }}
              >
                {alert.data.content}
              </div>
            </span>
          )}
          {alert.event_type == 2 && <span>is now following you!</span>}
          {alert.event_type == 3 && <span>added you as kin!</span>}
          {alert.event_type == 4 && <span>One of your posts has expired.</span>}
          {alert.event_type == 6 && (
            <span>
              <Link
                to={{ pathname: `/event/${alert.event_id}` }}
                component={RLink}
                style={{ position: "relative" }}
                color="inherit"
              >
                <b>{alert.data.event_name}</b>
              </Link>{" "}
              <div>{alert.data.starts_in}</div>
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
