import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import $ from "jquery";
import { makeStyles } from "@material-ui/core";
import Cookies from "vendor/js.cookie.min.mjs";
import CommonStyles from "components/styles/CommonStyles";
import { ThemeContext } from "components/ThemeContext";
import { useTheme } from "@material-ui/core";
//import platform from "components/pwa/platform";
import Button from "@material-ui/core/Button";
import Loading from "components/Loading";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 700,
    padding: 8,
    color: theme.palette.text.primary,
    marginLeft: "auto",
    marginRight: "auto",
  },
  bordered: {
    border: "1px solid #FFCD00",
    background: theme.palette.background.paper,
    padding: 8,
    paddingBottom: 24
  },
  tagcategory: {
    paddingTop: 8,
    marginBottom: 24,
    width: "90%",
    border: "1px solid #00b80d"    
  },
  tagcatselected: {
    border: "1px solid #FF0000",
    opacity: '33%'
  },
}));

export default function NewUser() {
  const classes = useStyles();
  const commonStyles = CommonStyles();
  const theme = useTheme();

//console.log("isMobile", platform.isMobile())
  let [themeContext, setThemeContext] = useContext(ThemeContext);

  const initialValues = {
    profileEnabled: false,
    loading: false,
    hide_ids: window.current_user?.tag_category_ids_hide || []
  };

  const [values, setValues] = useState(initialValues);

  const [timeoutSave, setTimeoutSave] = useState(null);

  const MS_UNTIL_SAVE = 750;

  function toggleHidden(id) {
    let arr = values.hide_ids;

    let idx = arr.indexOf(id);
    let hidden = idx > -1
    if (hidden) {
      arr.splice(idx, 1)
    } else {
      arr.push(id)
    }
    setValues(state => ({
      ...state,
      hide_ids: arr
    }))

    clearTimeout(timeoutSave);
    setTimeoutSave(
      setTimeout(() => {
        save_ids();
      }, MS_UNTIL_SAVE)
    );
  }

  function save_ids() {
    let data = {
      hide_ids: values.hide_ids.join(","),
      authenticity_token: window.auth_token
    };

    $.ajax({
      url: "/save_tc_hide",
      type: "POST",
      async: window.rails_env != "test",
      data: data,
      success: function (data) {
        if (data.success) {
          console.log("Success!!", data);
        } else {
          window.toastr.error(data.error);
        }
      },
      error: function (data) {
        window.toastr.error(data.error);
      },
    });    
  }

  function isHidden(id) {
    return values.hide_ids.indexOf(id) > -1
  }

  function imageClass() {
    return (themeContext.type == "dark" ? "no-dark-mode" : "")
  }

  return (
    <div>
      <div style={ { display: (values.loading ? 'none' : 'block') } }>
        <p>Some kinky content can be triggering or otherwise 'yuck' for some. If there is any content you would prefer not to see, please click it below.</p>

        <center>
          {
            window.tag_categories.map((tc) => (
              <div id={`tc-${tc.id}`} key={tc.id} className={`hand ${classes.tagcategory} ${isHidden(tc.id) ? classes.tagcatselected : ''}`} onClick={() => toggleHidden(tc.id) }>
                <div style={ { position: 'relative' } }>
                  {
                    !isHidden(tc.id) && (
                      <img src="/newuser_carousel/thumbsup.svg" style={ { position: 'absolute', right: 8, bottom: 8 } } />
                    )
                  }
                  {
                    isHidden(tc.id) && (
                      <img src="/newuser_carousel/thumbsdown.svg" style={ { position: 'absolute', right: 8, bottom: 2 } } />
                    )
                  }
                  <img src={`/newuser_carousel/${tc.filename}`} className={ imageClass() } style={ { width: '60%', maxWidth: 400 } } />

                  <div style={ { fontSize: "1.5em", marginTop: 4 } }>{tc.name}</div>
                  <div style={ { fontSize: "1.0em", marginTop: 4, marginBottom: 8 } }>{tc.description}</div>
                </div>
              </div>
            ))
          }
        </center>
      </div>

      <Loading loading={values.loading} />
    </div>
  );
}
