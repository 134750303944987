import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    background: "#FFF",
  },
  container: {
    maxWidth: "300px",
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default function ForgotPassword(props) {
  const classes = useStyles();
  let initialValues = {
    email: "",
  };

  // Form values state
  const [values, setValues] = useState(initialValues);

  // User has typed a key or selected a dropdown
  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    setValues((state) => ({
      ...state,
      ["email"]: value,
    }));
  };

  let re_valid_email =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return (
    <div className={`${classes.container} ${classes.paper}`}>
      <form className="new_user" id="new_user" action="/password" method="post">
        <Typography component="h5" variant="h5">
          Forgot Password?
        </Typography>

        <input
          type="hidden"
          name="authenticity_token"
          value={window.auth_token}
        />
        <div className="field">
          <TextField
            id="input-email"
            name="user[email]"
            label="Email Address"
            value={values.email}
            onChange={handleChange}
            required
            autoFocus
            size="small"
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </div>

        <br />

        <Button
          type="submit"
          id="btn-signup"
          fullWidth
          disabled={!re_valid_email.test(values.email)}
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Send Password Reset
        </Button>
      </form>
    </div>
  );
}
