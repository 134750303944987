/*
1-4 hours
6, 12, 18 hours
1-7 days
1-4 weeks
2-12 months
2-10 years

To generate:

let marks = []
for (let i = 0; i < 4; i += 1) {
  marks.push({
    value: marks.length,
    hours: i+1,
    label: (i == 0 ? '1 Hour' : ''),
    caption: `${i+1} Hour${i == 0 ? '' : 's'}`
  })
}
let inc = 6
for (let i = 5; i <= 18; i += inc) {
  marks.push({
    value: marks.length,
    hours: i+1,
    label: '',
    caption: `${i+1} Hour${i == inc ? '' : 's'}`
  })
}
inc = 24
for (let i = inc; i <= inc*6; i += inc) {
  marks.push({
    value: marks.length,
    hours: i,
    label: (i == inc ? '1 Day' : ''),
    caption: `${i / inc} Day${i == inc ? '' : 's'}`
  })
}
inc = 24*7
for (let i = inc; i <= inc*3; i += inc) {
  marks.push({
    value: marks.length,
    hours: i,
    label: '',
    caption: `${i / inc} Week${i == inc ? '' : 's'}`
  })
}

inc = 730
for (let i = inc; i <= inc*11; i += inc) {
  marks.push({
    value: marks.length,
    hours: i,
    label: (i == inc ? '1 Month' : ''),
    caption: `${i / inc} Month${i == inc ? '' : 's'}`
  })
}
inc = 8760
for (let i = inc; i <= inc*10; i += inc) {
  marks.push({
    value: marks.length,
    hours: i,
    label: (i == inc ? '1 Year' : (i == inc*10 ? '10 Years')),
    caption: `${i / inc} Year${i == inc ? '' : 's'}`
  })
}
console.log(JSON.stringify(marks))
*/

export default [{
  "value": 0,
  "hours": 1,
  "label": "1 Hour",
  "caption": "1 Hour"
}, {
  "value": 1,
  "hours": 2,
  "label": "",
  "caption": "2 Hours"
}, {
  "value": 2,
  "hours": 3,
  "label": "",
  "caption": "3 Hours"
}, {
  "value": 3,
  "hours": 4,
  "label": "",
  "caption": "4 Hours"
}, {
  "value": 4,
  "hours": 6,
  "label": "",
  "caption": "6 Hours"
}, {
  "value": 5,
  "hours": 12,
  "label": "",
  "caption": "12 Hours"
}, {
  "value": 6,
  "hours": 18,
  "label": "",
  "caption": "18 Hours"
}, {
  "value": 7,
  "hours": 24,
  "label": "1 Day",
  "caption": "1 Day"
}, {
  "value": 8,
  "hours": 48,
  "label": "",
  "caption": "2 Days"
}, {
  "value": 9,
  "hours": 72,
  "label": "",
  "caption": "3 Days"
}, {
  "value": 10,
  "hours": 96,
  "label": "",
  "caption": "4 Days"
}, {
  "value": 11,
  "hours": 120,
  "label": "",
  "caption": "5 Days"
}, {
  "value": 12,
  "hours": 144,
  "label": "",
  "caption": "6 Days"
}, {
  "value": 13,
  "hours": 168,
  "label": "",
  "caption": "1 Week"
}, {
  "value": 14,
  "hours": 336,
  "label": "",
  "caption": "2 Weeks"
}, {
  "value": 15,
  "hours": 504,
  "label": "",
  "caption": "3 Weeks"
}, {
  "value": 16,
  "hours": 730,
  "label": "1 Month",
  "caption": "1 Month"
}, {
  "value": 17,
  "hours": 1460,
  "label": "",
  "caption": "2 Months"
}, {
  "value": 18,
  "hours": 2190,
  "label": "",
  "caption": "3 Months"
}, {
  "value": 19,
  "hours": 2920,
  "label": "",
  "caption": "4 Months"
}, {
  "value": 20,
  "hours": 3650,
  "label": "",
  "caption": "5 Months"
}, {
  "value": 21,
  "hours": 4380,
  "label": "",
  "caption": "6 Months"
}, {
  "value": 22,
  "hours": 5110,
  "label": "",
  "caption": "7 Months"
}, {
  "value": 23,
  "hours": 5840,
  "label": "",
  "caption": "8 Months"
}, {
  "value": 24,
  "hours": 6570,
  "label": "",
  "caption": "9 Months"
}, {
  "value": 25,
  "hours": 7300,
  "label": "",
  "caption": "10 Months"
}, {
  "value": 26,
  "hours": 8030,
  "label": "",
  "caption": "11 Months"
}, {
  "value": 27,
  "hours": 8760,
  "label": "1 Year",
  "caption": "1 Year"
}, {
  "value": 28,
  "hours": 17520,
  "label": "",
  "caption": "2 Years"
}, {
  "value": 29,
  "hours": 26280,
  "label": "",
  "caption": "3 Years"
}, {
  "value": 30,
  "hours": 35040,
  "label": "",
  "caption": "4 Years"
}, {
  "value": 31,
  "hours": 43800,
  "label": "",
  "caption": "5 Years"
}, {
  "value": 32,
  "hours": 52560,
  "label": "",
  "caption": "6 Years"
}, {
  "value": 33,
  "hours": 61320,
  "label": "",
  "caption": "7 Years"
}, {
  "value": 34,
  "hours": 70080,
  "label": "",
  "caption": "8 Years"
}, {
  "value": 35,
  "hours": 78840,
  "label": "",
  "caption": "9 Years"
}, {
  "value": 36,
  "hours": 87600,
  "label": "10 Years",
  "caption": "10 Years"
}]
