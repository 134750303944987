import React, { useState, useEffect } from "react";
import $ from "jquery";
import DTOW from "vendor/distance_of_time_in_words.js";
import { Badge } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useChannel, useEvent } from "@harelpls/use-pusher";
import InfiniteScroll from "react-infinite-scroll-component";
import { Avatar } from "@material-ui/core";
import UserAvatar from "components/UserAvatar";
import Loading from "components/Loading";
import CommonStyles from "components/styles/CommonStyles";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import UserList from "components/UserList";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    right: 0,
    top: 35,
  },
}));

export default function Inbox(props) {
  const classes = useStyles();
  const commonStyles = CommonStyles();

  // Must match messaging_controller.rb#CONVOS_PER_PAGE
  const CONVOS_PER_PAGE = 20;
  let inbox = props.inbox;
  let inbox_name = props.name;

  const initialValues = {
    loading: false,
    page: 0,
    conversations: [],
    users: {},
    events: {},
    unread: 0,
    last_id: 0,
    hasMore: true,
    group_users: [],
    are_any_checked: false,
    are_any_checked_muted: false,
    are_any_checked_unmuted: false,
  };

  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    if (props.inbox?.conversations?.length > 0) {
      setValues((state) => ({
        ...state,
        conversations: props.inbox?.conversations,
        last_id:
          props.inbox.conversations[props.inbox.conversations.length - 1]
            .updated_at,
        hasMore: props.inbox?.conversations?.length == CONVOS_PER_PAGE,
        users: props.inbox?.users,
        events: props.inbox?.events,
        unread: props.inbox?.unread,
      }));
    }
  }, [props.inbox]);

  // Message received from another user. Update the conversation entry
  let chan = useChannel("private-messages-" + window.current_user.id);

  // Must match logic in messaging_controller.rb#send_message
  function message_process_assets(msg) {
    // Swap out assets for [image] since we can't show the actual image on the messages page
    let message_processed = msg.message; // Truncation not required because it happens via truncate() below
    if (!msg.asset_ids) {
      return msg.message;
    }

    let num_assets = msg.asset_ids.length;
    if (num_assets == 1) {
      message_processed = "[image] " + message_processed;
    }
    if (num_assets > 1) {
      message_processed = "[" + num_assets + "images] " + message_processed;
    }
    return message_processed;
  }

  // See: messaging_controller.rb#send_message
  function callbackMessageReceived(data) {
    let conversations = values.conversations;
    let conversations_reordered = [];
    conversations.forEach((convo) => {
      // ToDo: deal with pagination
      // The new message is for a convo in this inbox, move it to the top and show the message_abbrev
      if (convo.id == data.message.conversation_id) {
        convo.updated_at = data.updated_at;

        convo.last_message = {
          message: message_process_assets(data.message),
          user_from: data.from_user_id,
        };
        convo.unread = convo.unread + 1;

        // Sorts the updated convo to the top
        conversations_reordered.unshift(convo);
      } else {
        conversations_reordered.push(convo);
      }
    });

    setValues((state) => ({
      ...state,
      conversations: conversations_reordered,
    }));
  }

  useEvent(chan, "message_received", callbackMessageReceived);

  function truncate(str, no_words) {
    let lastXWords = str
      .split(" ")
      .splice(0 - no_words, no_words)
      .join(" ");
    if (str.length > lastXWords.length) {
      lastXWords = "... " + lastXWords;
    }
    return lastXWords;
  }

  // Modal to list users in the group
  const [openDialogGroupUsers, setOpenDialogGroupUsers] = useState(false);

  function handleGroupImageClick(user_ids) {
    let users = values.users;
    let group_users = [];
    user_ids.forEach((id) => {
      let user = users[id];
      group_users.push(user);
    });

    setValues((state) => ({
      ...state,
      group_users: group_users,
    }));

    setOpenDialogGroupUsers(true);
  }

  function closeDialogGroupUsers() {
    setOpenDialogGroupUsers(false);
  }

  let history = useHistory();

  function handleClickConvo(from_id) {
    history.push("/messages/" + from_id);
  }

  // Check / mute / archive
  function handleCheckConvo(clicked_convo) {
    let convos = [];
    let are_any_checked = false;
    let are_any_checked_muted = false;
    let are_any_checked_unmuted = false;
    for (let i = 0; i < values.conversations.length; i++) {
      let convo = values.conversations[i];
      if (convo.id == clicked_convo.id) {
        if (convo.checked) {
          convo.checked = false;
        } else {
          convo.checked = true;
        }
        convos.push(convo);
      } else {
        convos.push(convo);
      }
      if (convo.checked) {
        are_any_checked = true;
        if (convo.muted) {
          are_any_checked_muted = true;
        } else {
          are_any_checked_unmuted = true;
        }
      }
    }
    setValues((state) => ({
      ...state,
      conversations: convos,
      are_any_checked: are_any_checked,
      are_any_checked_muted: are_any_checked_muted,
      are_any_checked_unmuted: are_any_checked_unmuted,
    }));
  }

  function handleClickArchive() {
    let checked_ids = [];
    let conversations = [];
    for (let i = 0; i < values.conversations.length; i++) {
      let convo = values.conversations[i];
      if (convo.checked) {
        convo.checked = false;
        checked_ids.push(convo.id);
      }
      conversations.push(convo);
    }

    setValues((state) => ({
      ...state,
      conversations: conversations,
    }));

    checked_ids = checked_ids.join(",");

    if (inbox_name == "archived") {
      // Unarchive
      $.ajax({
        url: `/api/v1/messaging/conversations/archive`,
        method: "POST",
        async: window.rails_env != "test",
        data: { conversation_ids: checked_ids, _method: "DELETE" },
        success: function (data) {
          // Update 'archived' and whatever inbox it went to
          if (props.callbackRefreshAll) {
            props.callbackRefreshAll();
          }
          setValues((state) => ({
            ...state,
            are_any_checked: false,
            are_any_checked_muted: false,
            are_any_checked_unmuted: false,
          }));
        },
        error: function (data) {
          console.log("ERROR", data);
        },
        cache: false,
      });
    } else {
      // Archive
      $.ajax({
        url: `/api/v1/messaging/conversations/archive`,
        method: "POST",
        async: window.rails_env != "test",
        data: { conversation_ids: checked_ids },
        success: function (data) {
          // Update current inbox and 'archived'
          if (props.callbackRefreshAll) {
            props.callbackRefreshAll();
          }
          setValues((state) => ({
            ...state,
            are_any_checked: false,
            are_any_checked_muted: false,
            are_any_checked_unmuted: false,
          }));
        },
        error: function (data) {
          console.log("ERROR", data);
        },
        cache: false,
      });
    }
  }

  function handleClickMute() {
    let checked_ids = [];
    for (let i = 0; i < values.conversations.length; i++) {
      let convo = values.conversations[i];
      if (convo.checked && !convo.muted) {
        convo.checked = false;
        checked_ids.push(convo.id);
      }
    }
    checked_ids = checked_ids.join(",");

    $.ajax({
      url: `/api/v1/messaging/conversations/mute`,
      method: "POST",
      async: window.rails_env != "test",
      data: { conversation_ids: checked_ids },
      success: function (data) {
        let updated_convos = [];
        for (let i = 0; i < values.conversations.length; i++) {
          let convo = values.conversations[i];
          if (checked_ids.includes(convo.id)) {
            convo["muted"] = true;
            convo["muted_by"].push(window.current_user.id);
          }
          updated_convos.push(convo);
        }
        setValues((state) => ({
          ...state,
          conversations: updated_convos,
        }));
        if (props.callbackRefreshAll) {
          props.callbackRefreshAll();
        }
        setValues((state) => ({
          ...state,
          are_any_checked: false,
          are_any_checked_muted: false,
          are_any_checked_unmuted: false,
        }));
      },
      error: function (data) {
        console.log("ERROR", data);
      },
      cache: false,
    });
  }

  function handleClickUnmute() {
    let checked_ids = [];
    for (let i = 0; i < values.conversations.length; i++) {
      let convo = values.conversations[i];
      if (convo.checked && convo.muted) {
        convo.checked = false;
        checked_ids.push(convo.id);
      }
    }
    checked_ids = checked_ids.join(",");

    $.ajax({
      url: `/api/v1/messaging/conversations/mute`,
      method: "POST",
      async: window.rails_env != "test",
      data: { conversation_ids: checked_ids, _method: "DELETE" },
      success: function (data) {
        let updated_convos = [];
        for (let i = 0; i < values.conversations.length; i++) {
          let convo = values.conversations[i];
          if (checked_ids.includes(convo.id)) {
            convo["muted"] = false;
            convo["muted_by"] = []; // not filtered: for the purpose of the inbox we only care if the viewer is muted or not
          }
          updated_convos.push(convo);
        }
        setValues((state) => ({
          ...state,
          conversations: updated_convos,
        }));
        if (props.callbackRefreshAll) {
          props.callbackRefreshAll();
        }
        setValues((state) => ({
          ...state,
          are_any_checked: false,
          are_any_checked_muted: false,
          are_any_checked_unmuted: false,
        }));
      },
      error: function (data) {
        console.log("ERROR", data);
      },
      cache: false,
    });
  }

  function fetchData() {
    $.ajax({
      url: `/api/v1/messaging/inboxes.json?i=${inbox_name}&last_id=${values.last_id}`,
      type: "GET",
      async: window.rails_env != "test",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      success: function (data) {
        let data2 = data[inbox_name];
        let last_updated_at = "";
        if (data2.conversations.length > 0) {
          last_updated_at =
            data2.conversations[data2.conversations.length - 1].updated_at;
        }
        // console.log("data2", data2)
        // console.log("before", values.conversations, "after", data2.conversations)
        console.log("hasMore", data2.conversations.length == CONVOS_PER_PAGE);
        setValues((state) => ({
          ...state,
          conversations: values.conversations.concat(data2.conversations),
          users: Object.assign(values.users, data2.users),
          unread: values.unread + data2.unread,
          last_updated_at: last_updated_at,
          hasMore: data2.conversations.length == CONVOS_PER_PAGE,
          loading: false,
        }));
      },
    });
  }

  return (
    <div
      style={{
        display: values.conversations.length > 0 ? "block" : "none",
        padding: 2,
        marginBottom: 4,
      }}
    >
      <Dialog
        id="dialog-group-message-users"
        open={openDialogGroupUsers}
        keepMounted={false}
        onClose={closeDialogGroupUsers}
        scroll="paper"
        classes={{ paper: commonStyles.dialog90 }}
      >
        <div style={{ marginTop: 12 }}>
          <UserList show_follow={false} users={values.group_users || []} />
        </div>
      </Dialog>

      <div
        style={{ paddingLeft: 4, paddingRight: 4, position: "relative" }}
        className={commonStyles.flexCol}
      >
        <div className={commonStyles.flexRow}>
          <div style={{ paddingRight: 16 }}>
            <Button
              id="btn-archive"
              type="button"
              variant="contained"
              color="primary"
              size="small"
              disabled={!values.are_any_checked}
              onClick={() => {
                handleClickArchive();
              }}
            >
              {inbox_name == "archived" ? "Unarchive" : "Archive"}
            </Button>
          </div>
          <div style={{ paddingRight: 8 }}>
            <Button
              id="btn-mute"
              type="button"
              variant="contained"
              color="primary"
              size="small"
              disabled={!values.are_any_checked_unmuted}
              onClick={() => {
                handleClickMute();
              }}
            >
              Mute
            </Button>
          </div>
          <div>
            <Button
              id="btn-unmute"
              type="button"
              variant="contained"
              color="primary"
              size="small"
              disabled={!values.are_any_checked_muted}
              onClick={() => {
                handleClickUnmute();
              }}
            >
              Unmute
            </Button>
          </div>
          <div style={{ flexGrow: 1 }}></div>
        </div>

        <InfiniteScroll
          dataLength={values.conversations.length}
          next={() => {
            if ($(`#tabpanel-${inbox_name}:visible`).length == 1) {
              fetchData();
            }
          }}
          hasMore={values.hasMore}
          loader={
            <center>
              <img src="/static/loading/loading1.svg" />
            </center>
          }
          scrollThreshold={0.8}
          pullDownToRefresh={false}
          pullDownToRefreshThreshold={50}
          pullDownToRefreshContent={
            <h3 style={{ textAlign: "center" }}>
              &#8595; Pull down to refresh
            </h3>
          }
          releaseToRefreshContent={
            <h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>
          }
          endMessage={
            <p
              style={{
                textAlign: "center",
                display: values.conversations.length > 0 ? "block" : "none",
              }}
            >
              ---
            </p>
          }
        >
          {values.conversations.map((convo) => (
            <div
              key={convo.object_id}
              className={commonStyles.flexRow}
              style={{ cursor: "pointer", paddingTop: 8, paddingBottom: 8 }}
            >
              <div style={{ flexBasis: 64, flexShrink: 0, paddingRight: 8 }}>
                <Badge
                  title="Messaging"
                  badgeContent={convo.unread}
                  color="secondary"
                >
                  {convo.user_ids.length > 1 && (
                    <Avatar
                      variant="circle"
                      title={props.username}
                      src={"/static/icons/icon-group-message.svg"}
                      onClick={(evt) => {
                        handleGroupImageClick(convo.user_ids);
                        evt.preventDefault();
                      }}
                      style={{
                        border: `solid 2px ${props.border_color || "#000"}`,
                        width: 45,
                        height: 45,
                      }}
                    />
                  )}
                  {convo.user_ids.length <= 1 && (
                    <UserAvatar
                      key={convo.user_ids[0]}
                      url={values.users[convo.user_ids[0]].avatar_url}
                      border_color={
                        values.users[convo.user_ids[0]].color_background
                      }
                      size="small"
                    />
                  )}
                </Badge>
                <input
                  type="checkbox"
                  checked={convo.checked}
                  style={{ position: "absolute", left: 44, marginTop: 28 }}
                  onClick={() => {
                    handleCheckConvo(convo);
                  }}
                />
              </div>
              <div
                style={{ flexGrow: 1 }}
                className={commonStyles.flexCol}
                onClick={() => {
                  handleClickConvo(convo.object_id);
                }}
              >
                {/*onClick={() => { handleClickConvo((convo.user_ids.length > 1 ? convo.object_id : values.users.[convo.user_ids[0]].object_id)) }}*/}
                <div className={commonStyles.flexRow} style={{ width: "100%" }}>
                  <div style={{ flexGrow: 1 }} className="ellipsis">
                    {inbox_name == "events" && values.events[convo.event_id] && (
                      <div>
                        {/* Show Re: for general inquries, not for group chat */}
                        {values.events[convo.event_id].event_group_chat ==
                          0 && <span>Re:&nbsp;</span>}
                        {values.events[convo.event_id].name}
                        {/* &nbsp;&middot;&nbsp;
                            { railsUTCtoFriendlyDate(values.events[convo.event_id].dt_start) } */}
                      </div>
                    )}
                    {convo.user_ids.map((user_id, idx) => (
                      <b key={user_id}>
                        {values.users[user_id].username}
                        {idx < convo.user_ids.length - 1 ? ", " : ""}
                      </b>
                    ))}
                  </div>
                  <div
                    style={{
                      flexBasis: 130,
                      textAlign: "right",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {DTOW.distance_of_time_in_words(
                      new Date(),
                      new Date(convo.updated_at * 1000),
                      false,
                      { vague: true }
                    )}
                  </div>
                </div>
                <div className="message-text" style={{ paddingTop: 4 }}>
                  {convo.muted && (
                    <span>
                      <img src="/static/icons/icon-mute.svg" width={16} />{" "}
                    </span>
                  )}
                  {convo.seen && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: truncate(convo.last_message.message, 30),
                      }}
                    ></span>
                  )}
                  {!convo.seen && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          "<b>" +
                          truncate(convo.last_message.message, 30) +
                          "</b>",
                      }}
                    ></span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </InfiniteScroll>
      </div>
      <Loading loading={values.loading} />
    </div>
  );
}
