import React, { useEffect, useState, useContext, useCallback } from "react";
import { CreatePostContext } from "components/posts/CreatePostContext";

export default function TopMenu(props) {
  let [context, setContext] = useContext(CreatePostContext);
  let nextStep = props.next
  let prevStep = props.prev
  let lastStep = props.isLast

  let bkg = "linear-gradient(to bottom, rgba(0,0,0,0.70) 0%, rgba(0,0,0,0.92) 100%)"

  let heightTopMenu = 40

  let variant = props.variant

  let styleRight = { flexGrow: 0, flexShrink: 0, marginRight: 8, right: 8, zIndex: 20000 }

  return (
    <div id='stories-header' style={ { width: '100%', background: '#FFF', height: heightTopMenu, zIndex: 60000, display: 'flex', alignItems: 'center', flexDirection: 'row' } }>

      { /*style={ { position: "absolute", top: 2, left: 8, zIndex: 20000,  } } */ }

      <div style={ { maxWidth: 700, width: '100%', display: 'flex', justifyContent: 'center', margin: 'auto' } }>
        <div id="step-prev" className="hand" onClick={prevStep} style={ { flexGrow: 0, flexShrink: 0, marginLeft: 8 } } >
          <img src="/stories/arrow-left.svg" width={32} />
        </div>

        { /* { position: "absolute", top: 2, left: '50%', transform: 'translateX(-50%)', margin: 'auto', zIndex: 20000, padding: "4px 8px 4px 8px" } */ }
        <div id="step-name" style={ { flexGrow: 1, flexShrink: 1 } } >
          <center>
            <span style={ { fontSize: "1.2em", fontWeight: 'bold', color: "#000" } }>
              {
                context.step == 'preview' && (
                  <span>Preview Story</span>
                )
              }
              {
                context.step == 'crop' && (
                  <span>Resize/Crop</span>
                )
              }
              {
                context.step == 'decoration' && (
                  <span>Filters</span>
                )
              }
              {
                context.step == 'security' && (
                  <span>Caption + Consent</span>
                )
              }
            </span>
          </center>
        </div>


        { /* {  } */ }
        {
          !props.isLast && (
            <div id="step-next" className="hand" onClick={nextStep} style={ styleRight } >
              <span style={ { color: "#0095F6", fontSize: "1.2em" }}>
                Next
              </span>
            </div>
          )
        }
        {
          props.isLast && (
            <div id="step-last" className="hand" onClick={nextStep} style={ styleRight } >
              <span style={ { color: "#0095F6", fontSize: "1.2em" }}>
                {(props.variant == 'edit' ? 'Save' : 'Share')}
              </span>
            </div>
          )
        }
      </div>
    </div>
  )
}