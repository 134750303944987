import React, { useState, useEffect } from "react";
import $ from "jquery";
import { Container } from "@material-ui/core";
import { Link, Typography } from "@material-ui/core";
import { Link as RLink } from "react-router-dom";
import Loading from "components/Loading";
import AlertFull from "components/alerts/AlertFull";
import CommonStyles from "components/styles/CommonStyles";
import goBack from "go_back";

export default function Posts(props) {
  const classes = CommonStyles();

  const initialValues = {
    alerts: [],
    loading: true,
  };
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    refreshAlerts();
  }, []);

  function refreshAlerts() {
    $.ajax({
      url: `/alerts?q=all`,
      type: "GET",
      async: window.rails_env != "test",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      success: function (data) {
        setValues((state) => ({
          ...state,
          ["alerts"]: data.alerts,
          ["unseen_alerts"]: data.unseen_alerts,
          ["loading"]: false,
        }));
      },
    });
  }

  return (
    <div id="messages" className={classes.container}>
      <div style={{ display: values.loading ? "none" : "block" }}>
        {values.alerts.map((alert) => (
          <div key={alert.id}>
            <AlertFull alert={alert} />
          </div>
        ))}
        {values.alerts.length == 0 && (
          <center>
            <Typography>No alerts yet!</Typography>
            <p>
              Try{" "}
              <Link to={{ pathname: `/` }} component={RLink} color="inherit">
                posting
              </Link>{" "}
              something or joining a{" "}
              <Link to={{ pathname: `/groups` }} component={RLink} color="inherit">
                group
              </Link>
            </p>
            <p>
              <a onClick={goBack}>Go Back</a>
            </p>
          </center>
        )}
      </div>
      <Loading loading={values.loading} />
    </div>
  );
}
