import React from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    background: "#FFF",
  },
  container: {
    maxWidth: "614px !important",
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default function ContentGuidelines(props) {
  const classes = useStyles();

  /*
    To update from the google doc:
    
    Download as html
    Run through: https://beautifytools.com/html-beautifier.php
    CSS goes in /public/comm_guidelines.css
    HTML goes below
    class => className
  */

  return (
    <div className={`${classes.container} ${classes.paper}`}>
      <link rel="stylesheet" href="/comm_guidelines.css" />

      <p className="c6">
        <span className="c7">Content Guidelines</span>
      </p>
      <p className="c0">
        <span className="c7"></span>
      </p>
      <p className="c6">
        <span className="c7">
          KinkyKin&rsquo;s Membership in the Community{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c7"></span>
      </p>
      <p className="c6">
        <span className="c2">
          KinkyKin does not want to determine what the word Kink means to the
          community and what it means to each of us. The specificity and lack of
          universality of kink are what makes kink special, and because of that,
          KinkyKin does not believe that anyone but the community itself has the
          power or right to set boundaries as to what is kink includes.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span>
          KinkyKin does not see itself as the true home of the community, nor do
          we see ourselves as indispensable, nor as a place that can encompass
          everything that has to do with Kinks and Fetishes.{" "}
        </span>
        <span className="c12">
          KinkyKin is itself a member of the Kink community
        </span>
        <span className="c2">
          , and like each one of us individuals, KinkyKin must set its own
          limits. Like us as individuals, KinkyKin wants to be clear and
          communicative, and though KinkyKin&rsquo;s boundaries come from a
          different place than perhaps our own hard limits, KinkyKin&rsquo;s
          boundaries are just as vital, valid, and necessary to our functioning.
          We understand that nobody is perfect as much as we understand that
          some things simply cannot be tolerated. Like many of us, we regret
          some of our own boundaries, and like some of us, who wish they could
          fulfill their partner&rsquo;s needs but simply cannot, there are
          places in KinkyKin&rsquo;s content guidelines where this is true as
          well. Like all of us kinksters, KinkyKin has a community membership
          beyond kink, as a website with website needs that must be met.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c2">
          Ultimately, KinkyKin will operate with rules, with communication, and
          with discretion, and will seek to protect the safety and privacy of
          our users whenever we can. KinkyKin will strive to see the best
          intentions and to find forgiveness where it can be found. KinkyKin
          cannot be the arbiter of relationships, it cannot be the investigator
          and judge of events that happen outside our domain. KinkyKin strives
          to empower local leaders, and to give the community the means to hold
          them accountable, because KinkyKin cannot come to the munch or dungeon
          and see for itself.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c8 c13"></span>
      </p>
      <p className="c6">
        <span className="c7">KinkyKin&rsquo;s Disciplinary Process</span>
      </p>
      <p className="c0">
        <span className="c7"></span>
      </p>
      <p className="c6">
        <span className="c2">
          KinkyKin retains the right to control all content on KinkyKin and
          control access to KinkyKin&rsquo;s online services. When issues are
          discovered or brought to our attention that violate the following
          guidelines, KinkyKin will use the following means to leverage our
          control and protect the safety and privacy of our members.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <ol className="c9 lst-kix_buwmt988khja-0 start" start="1">
        <li className="c5 li-bullet-0">
          <span className="c2">Removal of content: </span>
        </li>
      </ol>
      <ol className="c9 lst-kix_buwmt988khja-1 start" start="1">
        <li className="c3 li-bullet-0">
          <span className="c2">
            KinkyKin retains the right to take down any post in any medium or
            any event if it is found in violation of our content and community
            guidelines, or for any reason.{" "}
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c2">
            KinkyKin retains the right to ensure that its public spaces respect
            the privacy and safety of its users at all times.{" "}
          </span>
        </li>
      </ol>
      <ol className="c9 lst-kix_buwmt988khja-0" start="2">
        <li className="c5 li-bullet-0">
          <span className="c2">Timeouts:</span>
        </li>
      </ol>
      <ol className="c9 lst-kix_buwmt988khja-1 start" start="1">
        <li className="c3 li-bullet-0">
          <span className="c2">
            KinkyKin retains the right to prohibit access by a particular user
            to our online services for any amount of time for any reason.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c2">
            In practice, KinkyKin will prefer escalating timeouts, where
            subsequent timeouts will get progressively longer than the timeout
            prior. However, KinkyKin retains ultimate discretion in how long a
            timeout should be for.
          </span>
        </li>
      </ol>
      <ol className="c9 lst-kix_buwmt988khja-0" start="3">
        <li className="c5 li-bullet-0">
          <span className="c2">Permanent Bans:</span>
        </li>
      </ol>
      <ol className="c9 lst-kix_buwmt988khja-1 start" start="1">
        <li className="c3 li-bullet-0">
          <span className="c2">
            KinkyKin retains the right to permanently revoke access to a user if
            they are found to violate out content and community guidelines, or
            for any reason.
          </span>
        </li>
      </ol>
      <ol className="c9 lst-kix_buwmt988khja-0" start="4">
        <li className="c5 li-bullet-0">
          <span className="c2">Extra-site Legal Enforcement</span>
        </li>
      </ol>
      <ol className="c9 lst-kix_buwmt988khja-1 start" start="1">
        <li className="c3 li-bullet-0">
          <span>In extreme cases</span>
          <span className="c2">
            , including but not limited to human trafficking, murder, or revenge
            porn, KinkyKin retains the right to lift certain privacy protections
            to permit law enforcement to take legal action against users who use
            our site to violate certain standards of behavior.
          </span>
        </li>
      </ol>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c7">Content Guidelines</span>
      </p>
      <p className="c0">
        <span className="c7"></span>
      </p>
      <p className="c6">
        <span>The following sections outline our community guidelines.</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c1">
          Content that features or appears to feature underage participants
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c2">
          KinkyKin&rsquo;s stance on this topic comes in 2 classes.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c8">Class 1: </span>
        <span className="c2">
          Any written or visual content that intentionally implies or includes
          the participation or knowledge of underage persons for fetish, kinky,
          or sexual purposes will not be permitted.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c2">
          Users who have been found by KinkyKin to have intentionally and
          knowingly added this content or participated in discussions or
          interactions featuring such topics will be permanently banned from
          KinkyKin.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c2">
          Users who are discovered to share an identity with a previous user who
          has been banned for the above will also be banned. If there are second
          chances, it is not for this and it is not in KinkyKin.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c2">
          Users who are discovered to be underage will be banned.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c8">Class 2: </span>
        <span className="c2">
          Any written or visual content that unintentionally implies or happens
          to include the participation or knowledge of underage persons, which
          had a clear and discernable &lsquo;other&rsquo; purpose than the
          sexualization and fetishization of underage persons.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span>
          This class of content violation is designed to be less punitive but
          rather a catch all justification for removing content that might not
          have meant to sexualize minors, but could be construed differently
          under different circumstances.{" "}
        </span>
        <span>
          This could include a meme that happens to feature an image of a minor
          that was posted or directed in a non-kinky context
        </span>
        <span className="c2">
          . This could mean a personal story that featured retellings and events
          that specifically referenced minors in ways that other users found
          awkward or unnecessary enough to report, but not objectionable enough
          to warrant a full ban.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c2">
          We want our community members to feel like KinkyKin is a place for
          more than just their kinky and sexual lives, and we understand that
          family and our own histories are relevant to those lives. We know that
          mistakes happen and that intent can be hard to relate on the internet,
          so KinkyKin reserves the right to remove content of this
          classification at will without fully expelling a member from the
          community.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c1">Human Trafficking </span>
      </p>
      <p className="c0">
        <span className="c4"></span>
      </p>
      <p className="c6">
        <span className="c2">
          Content created that is sexual or kinky in nature and was created as
          the result of human trafficking, features people who are currently
          trafficked, or is added by someone with a proven history of human
          trafficking, will be removed and appropriate action against the
          responsible users will be taken.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c1">Revenge Porn</span>
      </p>
      <p className="c0">
        <span className="c4"></span>
      </p>
      <p className="c6">
        <span>
          Revenge porn is not tolerated on KinkyKin. If content has been
          reported and is proven to be posted without someone&rsquo;s consent,
          and those non-consenting parties are identified or identifiable in the
          content, and that content is determined to be posted for the purpose
          of exposure, humiliation, revenge, intimidation, that content will be
          removed and the profile posting that content will be placed under
          administrative review. In cases where revenge porn is identified,
          KinkyKin will take action in conjunction with the victim and law
          enforcement agencies, which may include the preservation of user data
          and the nullification of our typical privacy protections.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c4"></span>
      </p>
      <p className="c6">
        <span className="c1">Sex Offenders</span>
      </p>
      <p className="c0">
        <span className="c4"></span>
      </p>
      <p className="c6">
        <span className="c2">
          Users who are proven to be sex offenders will be banned permanently.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c2">
          Users who are proven to be charged of a crime of a sexual nature will
          be deactivated until they have proven their exoneration.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c1">Content that includes unsuspecting persons</span>
      </p>
      <p className="c0">
        <span className="c4"></span>
      </p>
      <p className="c6">
        <span className="c2">
          Pictures or videos that feature unwitting persons and also have a
          sexual or fetishistic nature, if reported, will be removed from the
          website.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c2">
          Exhibitionism that simulates a public setting and exhibitionism in art
          or erotic text are all permitted, so long as minors are not depicted.
          For photography and video content that simulates a public setting, it
          must be clear that the setting depicted did not involve non-consenting
          parties.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c1">Anti-Hate Policy</span>
      </p>
      <p className="c0">
        <span className="c4"></span>
      </p>
      <p className="c6">
        <span className="c2">
          The use of hateful slurs, in any context, cannot be permitted on
          KinkyKin.
        </span>
      </p>
      <p className="c6">
        <span className="c2">
          Hateful slurs include, but are not limited to, racial, ethnic, sexist,
          homophobic, and transphobic slurs.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c2">
          We also recognize that there are more ways to be hateful than simply
          using slurs. Though this sort of hate is much more challenging to
          track using technology, if users are reported to be operating in a
          discriminatory or prejudiced manner in ways that intersect with
          racial, ethnic, homophobic, and transphobic slurs.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span>
          Slurs can be permitted if used to refer to oneself in a
          self-empowering manner.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c1">Harassment</span>
      </p>
      <p className="c0">
        <span className="c4"></span>
      </p>
      <p className="c6">
        <span className="c2">
          Our harassment policy identifies different forms of harassment, not
          including hateful speech and revenge porn, described elsewhere:
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <ol className="c9 lst-kix_o66xvoamhb9q-0 start" start="1">
        <li className="c5 li-bullet-0">
          <span className="c2">Threats of violence:</span>
        </li>
      </ol>
      <ol className="c9 lst-kix_o66xvoamhb9q-1 start" start="1">
        <li className="c3 li-bullet-0">
          <span className="c2">
            The credibility of a threat of violence lies with the target of the
            threat. KinkyKin will take the appropriate action in the case of
            violent threats, which may include up to a permanent ban or the
            lifting of our privacy policy to cooperate with law enforcement.{" "}
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c2">
            Swatting and stalking are considered threats of violence.
          </span>
        </li>
      </ol>
      <ol className="c9 lst-kix_o66xvoamhb9q-0" start="2">
        <li className="c5 li-bullet-0">
          <span className="c2">Doxxing:</span>
        </li>
      </ol>
      <ol className="c9 lst-kix_o66xvoamhb9q-1 start" start="1">
        <li className="c3 li-bullet-0">
          <span className="c2">
            Threatening to post personally identifiable content about another
            KinkyKin user for any purpose that the user does not consent to is
            not permitted on KinkyKin.
          </span>
        </li>
      </ol>
      <ol className="c9 lst-kix_o66xvoamhb9q-0" start="3">
        <li className="c5 li-bullet-0">
          <span className="c2">Brigading: </span>
        </li>
      </ol>
      <ol className="c9 lst-kix_o66xvoamhb9q-1 start" start="1">
        <li className="c3 li-bullet-0">
          <span className="c2">
            Brigading is defined as the coordinated use of KinkyKin&rsquo;s
            services to attack, intimidate, or coerce another member of the
            KinkyKin community.{" "}
          </span>
        </li>
      </ol>
      <ol className="c9 lst-kix_o66xvoamhb9q-0" start="4">
        <li className="c5 li-bullet-0">
          <span className="c2">Spamming: </span>
        </li>
      </ol>
      <ol className="c9 lst-kix_o66xvoamhb9q-1 start" start="1">
        <li className="c3 li-bullet-0">
          <span>Spamming</span>
          <span>
            &nbsp;is defined as any attempt to overwhelm another user of the
            KinkyKin community using KinkyKin services, regardless of intention.
            Means of spamming include, but are not limited to, excessive
            messages, excessive commenting, ban evasion, continued
            communications with others in a user&rsquo;s network to continue to
            get access to that person.{" "}
          </span>
        </li>
      </ol>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c1">Raceplay</span>
      </p>
      <p className="c0">
        <span className="c4"></span>
      </p>
      <p className="c6">
        <span className="c2">
          Organizations, tags, images, videos, and other content centered around
          raceplay all fall under our rules against discrimination and racism.
          We understand that there may be those who can practice these kinks and
          fetishes with respect to the identities and histories involved, but if
          that&rsquo;s the case, then they probably don&rsquo;t need
          KinkyKin&rsquo;s help.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c1">Incest</span>
      </p>
      <p className="c0">
        <span className="c4"></span>
      </p>
      <p className="c6">
        <span className="c2">
          Actual incest depicted in videos and pictures is not permitted.
          Content that has been proven to display objectionable content, even if
          all participants are above-age, cannot be permitted. If participants
          are below age, actions are taken in accordance with anti-pedophilia
          rules.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c4"></span>
      </p>
      <p className="c6">
        <span className="c1">Bestiality and Animal Cruelty</span>
      </p>
      <p className="c0">
        <span className="c4"></span>
      </p>
      <p className="c6">
        <span className="c2">
          All imagery and video, including synthetic imagery and erotic stories,
          that depict sex acts with or between animals is not permitted.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c2">
          Imagery and video, including synthetic imagery and erotic stories,
          that depict animal cruetly or animal killing is not permitted.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c1">Extreme Violence</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c2">
          Violence in imagery, art, language, and other mediums can be tough to
          qualify outside of context. What is acceptable in a video game is
          different from a movie and is different from a book, all of which are
          different from what is acceptable in reality. All of those
          environments are further different from what is acceptable in a kinky
          and negotiated scene. KinkyKin has empathy with those who have extreme
          kinks, especially those who recognize that some of their deepest
          fantasies can never be morally and ethically fulfilled.{" "}
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c1">Scat</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c2">
          Visible fecal matter cannot be displayed in pictures or videos on the
          KinkyKin website. Content that is reported and is found to contain
          visible fecal matter will not be permitted. Fecal matter can be
          present within diapers or pants, and can be implied. Anal play is not
          considered scat.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c1">Blood Play</span>
      </p>
      <p className="c0">
        <span className="c4"></span>
      </p>
      <p className="c6">
        <span>Conten</span>
        <span className="c2">
          t that depicts blood, real or fake, in a sexual context cannot be
          permitted. We encourage users who are interested in these kinks to
          find safe, sane, and consensual settings where these kinks can be
          expressed.
        </span>
      </p>
      <p className="c0">
        <span className="c4"></span>
      </p>
      <p className="c6">
        <span className="c11">Stolen Content</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c2">
          Content that can be proven to be posted without the proper credit is
          not permitted.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c4"></span>
      </p>
      <p className="c6">
        <span className="c11">Profile Pictures that Feature Genitals</span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>
      <p className="c6">
        <span className="c2">
          Profile pictures that feature obvious and exposed genitals are not
          permitted on KinkyKin. While KinkyKin aspires to be a place where
          bodies of all types and interests of many flavors can be celebrated,
          this utopian ideal can only be achieved through a culture of consent.
          Following someone, opting to look at someone’s photos, are all forms
          of consenting to the type of presence that another profile wishes to
          create. Profile pictures, however, can pop up anywhere and everywhere,
          and individuals may not be able to control what they see when reading
          comments or seeing an RSVP list. Therefore, profile pictures featuring
          exposed genitals cannot be permitted.
        </span>
      </p>
      <p className="c0">
        <span className="c2"></span>
      </p>

      <p>
        <a className="light-themed" href="/about">About</a> |&nbsp;
        <a className="light-themed" href="/contact">Contact Us</a> |&nbsp;
        <a className="light-themed" href="/privacy">Privacy Policy</a> |&nbsp;
        <a className="light-themed" href="/terms">Terms</a>
      </p>
      <br />
    </div>
  );
}
