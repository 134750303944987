import React, { useEffect, useState, useContext, useCallback } from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import * as PostService from "../../services/posts.service";
import { useTheme } from '@material-ui/core/styles';
import Post from "components/posts/Post";
import TopMenu from "components/stories/TopMenu";
import useInterval from "vendor/useInterval";
import { CreatePostContext } from "components/posts/CreatePostContext";
// See: https://github.com/ricardo-ch/react-easy-crop
import Cropper from "react-easy-crop";
import { getCroppedImg, getRotatedImage, createImage } from "../../vendor/canvasUtils";
import ReactEasyCropStyles from "components/events/ReactEasyCropStyles";
import {
  Slider,
  Checkbox,
  TextField,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Select,
  Radio,
  RadioGroup,
  Button
} from "@material-ui/core";
import cloneCanvas from "image_editor/clone_canvas.js";
import Cookies from "vendor/js.cookie.min.mjs";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import StoryStyles from "components/styles/StoryStyles";

const useStyles = makeStyles((theme) => ({
  whiteBg: {
    background: '#FFF',
    color: "#000"
  },
}));

export default function EditSecurity(props) {
  let [context, setContext] = useContext(CreatePostContext);

  let initialValues = {
    dialogContentShow: true,
    dialogSecurityShow: false
  };
  const [values, setValues] = useState(initialValues);

  const classes = useStyles();

  const storyClasses = StoryStyles();

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // When cropped image is set, save it as the initial imageFiltered
  useEffect(
    function () {
      if (context.upload.mimeTypeSimple == "image") {
        let canvasFinal = context.canvasFinal
        let canvas = document.getElementById('canvas-preview')

        // Set #canvas-preview to the filtered + bkg'd image
        if (canvas) {
          var contextPreview = canvas.getContext('2d');

          canvas.width = canvasFinal.width;
          canvas.height = canvasFinal.height;
          //context.translate(0.5, 0.5);
          contextPreview.drawImage(canvasFinal, 0, 0);
        }
      }
    },
    []
  );

  function sliderChange(slider_field, value) {
    if (context[slider_field] != value) {
      setContext((state) => ({
        ...state,
        [slider_field]: parseInt(value),
      }));

      if (slider_field == "time") {
        let hours = marksTime[value].hours;
        setContext((state) => ({
          ...state,
          ["timeHours"]: hours,
        }));
      }

      Cookies.set(`default-${slider_field}`, value, { expires: 1 });
    }
  }

  const marksPermission = [
    {
      value: 0,
      label: "Kin Only",
      caption: `Only those you add as 'kin'`,
    },
    {
      value: 1,
      label: "Kin + Followers",
      caption: `Kin and anyone who follows you`,
    },
    {
      value: 2,
      label: "Any Registered User",
      caption: `Anyone on KinkyKin`,
    },
  ];

  const marksCommentPermission = [
    {
      value: -1,
      label: "Comments Disabled",
      caption: `Comments will not be allowed for this scene`,
    },
    {
      value: 0,
      label: "Kin Only",
      caption: `Only those you add as 'kin'`,
    },
    {
      value: 1,
      label: "Kin + Followers",
      caption: `Kin and anyone who follows you`,
    },
    {
      value: 2,
      label: "Any Registered User",
      caption: `Anyone on KinkyKin`,
    },
  ];

  function marksPermissionFromInt(num) {
    for (let i = 0; i < marksPermission.length; i++) {
      if (marksPermission[i].value == num) {
        return marksPermission[i];
      }
    }
    return {};
  }

  function marksCommentPermissionFromInt(num) {
    for (let i = 0; i < marksCommentPermission.length; i++) {
      if (marksCommentPermission[i].value == num) {
        return marksCommentPermission[i];
      }
    }
    return {};
  }

  function post() {
    setContext((state) => ({
      ...state,
      loading: true
    }));

    setTimeout(() => {
      let fd = new FormData();
      let config = {
        background: context.background,
        texts: context.texts,
        masks: context.masks,
        stickers: context.stickers,
      }
      fd.append('config', JSON.stringify(config))
      fd.append('postText', context.postText)
      fd.append('save_to_profile', context.save_to_profile)
      fd.append("permission", context.permission);
      fd.append("comment_permission", context.comment_permission);
      if (context.community?.id) {
        fd.append("community_id", context.community?.id);
      }
      fd.append("authenticity_token", window.auth_token)

      let url = "/stories"
      let action = "POST"
      if (props.variant == 'edit') {
        url = `/posts/${window.post_id}`
        action = "PUT"
      }

      if (context.upload.mimeTypeSimple == "image") {
        context.canvasFinal.toBlob(function(blob) {
          fd.append("file", blob)

          $.ajax({
            url: url,
            type: action,
            async: window.rails_env != "test",
            data: fd,
            success: function (data) {
              if (context.community) {
                window.location.href = "/community/" + context.community.id
              } else {
                window.location.href = "/"
              }
            },
            error: function(data) {
              console.error("Could not post story", data)
            },
            cache: false,
            contentType: false,
            processData: false,
          })
        }, "image/png")
      } else {
        fd.append("file", context.upload.file)

        $.ajax({
          url: "/stories",
          type: "POST",
          async: window.rails_env != "test",
          data: fd,
          success: function (data) {
            window.location.href = "/"
          },
          error: function(data) {
            console.error("Could not post story", data)
          },
          cache: false,
          contentType: false,
          processData: false,
        })
      }
    }, 50)
  }

  function prevStep() {
    let stepIdx = context.steps.indexOf(context.step)
    setContext((state) => ({
      ...state,
      step: context.steps[stepIdx - 1]
    }));
  }

  function bgStyles() {
    return `linear-gradient(171deg, ${context.background.colorA} 0%, ${context.background.colorB} 100%)`
  }

  let heightBottomControls = 55

  function nilll() {

  }

  function openDialogContent() {
    setValues((state) => ({
      ...state,
      dialogContentShow: true
    }));
  }

  function closeDialogContent(evt) {
    // If they re-click the 'color circle', close it. Don't pass the event down to openDialogFilters or it will just re-appear
    if (evt.target.id == 'toggle-content-img') {
      return
      evt.preventDefault()
    }

    setValues((state) => ({
      ...state,
      dialogContentShow: false
    }));
  }

  function openDialogSecurity() {
    setValues((state) => ({
      ...state,
      dialogSecurityShow: true
    }));
  }

  function closeDialogSecurity(evt) {
    // If they re-click the 'color circle', close it. Don't pass the event down to openDialogFilters or it will just re-appear
    if (evt.target.id == 'toggle-security-img') {
      return
      evt.preventDefault()
    }

    setValues((state) => ({
      ...state,
      dialogSecurityShow: false
    }));
  }

  function handleChangeSaveToProfile(blah, val) {
    setContext((state) => ({
      ...state,
      save_to_profile: !context.save_to_profile,
    }));
    Cookies.set(`default-save_to_profile`, !context.save_to_profile, { expires: 7 })
  }

  function handleChangePostText(evt) {
    let value = evt.target.value;
    setContext((state) => ({
      ...state,
      postText: value
    }));
  }

  let variant = props.variant || 'edit'

  return (
    <React.Fragment>
      <TopMenu next={post} prev={prevStep} exitCallback={props.exitCallback} isLast={true} variant={variant} />

      <div className={storyClasses.showContainer} style={ { height: `calc(100vh - ${props.heightTopMenu}px)`, background: bgStyles() } }>
        <div className={storyClasses.canvasContainer}> {/*, display: 'flex'*/}
          {
            (context.upload.mimeTypeSimple == "image") &&
              <canvas
                id="canvas-preview"
                style={{ maxWidth: "100%", maxHeight: '100%', margin: 'auto' }}
                draggable="false"
              />
          }
          {context.upload.mimeType == "image/gif" && (
            <div className={storyClasses.videoContainer}>
              <img
                src={context.upload.src}
                style={{ width: "100%", maxHeight: "100%" }}
              />
            </div>
          )}
          {/* Should match logic in PreviewVideo.jsx } */}
          {context.upload.mimeType == "video/mp4" && (
            <div className={storyClasses.videoContainer}>
              <video
                src={context.upload.src}
                preload="auto"
                style={{ width: "100%", maxHeight: "100%" }}
                type="video/mp4"
                controls={true}
              />
            </div>
          )}
          {context.upload.mimeType == "video/quicktime" && (
            <div className={storyClasses.videoContainer}>
              <p>
                <b>Note</b>: iPhone/Quicktime videos may not render properly
                in the preview, but will look correct within a few minutes
                of posting.
              </p>
              <video
                src={context.upload.src}
                preload="auto"
                style={{ width: "100%", maxHeight: "100%" }}
                type="video/mov"
                controls={true}
              />
            </div>
          )}
        </div>


        {
          values.dialogContentShow && (
            <div style={ { position: 'absolute', background: 'rgb(0,0,0,0.7)', color: '#FFF', bottom: 0, width: '100%' } } >
              <div id='menu-content' style={ { padding: 8, width: '100%', margin: 'auto', maxWidth: 700 } }>
                {
                  context.community && (
                    <div>
                      Posting to "{context.community.name}"
                    </div>
                  )
                }

                <TextField
                  id="input-post-text"
                  variant="outlined"
                  margin="dense"
                  maxrows={5}
                  minrows={1}
                  fullWidth={true}
                  InputProps={{ className: classes.whiteBg }}
                  label=""
                  placeholder="Caption and #hashtags"
                  name="post_text"
                  multiline={true}
                  value={context.postText}
                  onChange={handleChangePostText}
                  autoComplete=""
                  size="small"
                />

                {
                  context.permission == 2 && !context.community && (
                    <small>Add #hashtags if you'd like this post to be discoverable by Kin users who aren't following you.<br /></small>
                  )
                }

                <FormControl component="div" fullWidth={true} margin={'none'}>
                  {/*[{context.save_to_profile.toString()}]*/}
                  <RadioGroup name="radio-save_to_profile" value={context.save_to_profile} onChange={handleChangeSaveToProfile}>
                    {
                      !context.community && (
                        <React.Fragment>
                          <FormControlLabel value={false} control={<Radio />} label="Story: 24 hours" />
                          <FormControlLabel value={true} control={<Radio />} label="Save to profile: 1 year" />
                        </React.Fragment>
                      )
                    }
                    {
                      context.community && (
                        <React.Fragment>
                          <FormControlLabel value={false} control={<Radio />} label="24 hours" />
                          <FormControlLabel value={true} control={<Radio />} label="1 year" />
                        </React.Fragment>
                      )
                    }
                  </RadioGroup>
                </FormControl>


                <div>
                  <p>Who may see this?</p>

                  <select
                    id="select-permission"
                    value={context.permission}
                    onChange={(event) =>
                      sliderChange("permission", event.target.value)
                    }
                    style={ { width: 200 } }
                  >
                    {
                      !context.community && (
                        <React.Fragment>
                          <option value={0}>Kin Only</option>
                          <option value={1}>Kin + Followers</option>
                          <option value={2}>Anyone on KinkyKin</option>
                        </React.Fragment>
                      )
                    }
                    {
                      context.community && (
                        <React.Fragment>
                          <option value={-1}>Community Members Only</option>
                          <option value={0}>Community + Kin</option>
                          <option value={1}>Community + Kin + Followers</option>
                          <option value={2}>Community + Anyone on Kin</option>
                        </React.Fragment>
                      )
                    }
                  </select>

                  {/*<p>
                    {(marksPermissionFromInt(context.permission) || {}).caption}
                  </p>*/}
                </div>

                <div>
                  <p>Who may comment?</p>

                  <select
                    id="select-comment-permission"
                    value={context.comment_permission}
                    onChange={(event) =>
                      sliderChange("comment_permission", event.target.value)
                    }
                    style={ { width: 200 } }
                  >
                    {
                      !context.community && (
                        <React.Fragment>
                          <option value={-1}>Comments Disabled</option>
                          <option value={0}>Kin Only</option>
                          <option value={1}>Kin + Followers</option>
                          <option value={2}>Anyone on KinkyKin</option>
                        </React.Fragment>
                      )
                    }
                    {
                      context.community && (
                        <React.Fragment>
                          <option value={-1}>Comments Disabled</option>
                          <option value={-2}>Community Members Only</option>
                          <option value={0}>Community + Kin</option>
                          <option value={1}>Community + Kin + Followers</option>
                          <option value={2}>Community + Anyone on Kin</option>
                        </React.Fragment>
                      )
                    }
                  </select>

                  {/*<p>
                    {
                      (
                        marksCommentPermissionFromInt(context.comment_permission) ||
                        {}
                      ).caption
                    }
                  </p>*/}
                </div>
              </div>
            </div>
          )
        }

        {/*{
          values.dialogSecurityShow && (
            <div style={ { position: 'absolute', color: '#FFF', background: 'rgb(0,0,0,0.7)', width: '100%', left: 0, bottom: 4 + 55 } } >
              <ClickAwayListener onClickAway={closeDialogSecurity}>
                <div id='menu-security' style={ { padding: 8 } }>

                </div>
              </ClickAwayListener>
            </div>
          )
        }*/}
      </div>

      {/*<div className={classes.controls} style={ { bottom: 0, height: heightBottomControls } }>
        <div className="hand" onClick={openDialogContent} style={{ width: 36, height: 36, marginLeft: 12, background: 'rgb(0,0,0,0.55)', borderRadius: '50%', display: 'flex' }}>
          <img id="toggle-content-img" src="/stories/hashtag.svg" height='22' style={ { margin: 'auto' } } />
        </div>

        <div className="hand" onClick={openDialogSecurity} style={{ width: 36, height: 36, marginLeft: 12, background: 'rgb(0,0,0,0.55)', borderRadius: '50%', display: 'flex' }}>
          <img id="toggle-security-img" src="/stories/lock.svg" height='22' style={ { margin: 'auto' } } />
        </div>
      </div>
      */}

    </React.Fragment>
  );
}