import React, { useCallback, useState, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import cloneCanvas from "image_editor/clone_canvas.js";
import { Sticker } from "image_editor/draggables.js";

import { CreatePostContext } from 'components/posts/CreatePostContext'
import PostStyles from 'components/styles/PostStyles'

export default function EditStickers(props) {
  const stickers = window.stickers

  const classes = PostStyles();

  const [context, setContext] = useContext(CreatePostContext)

  // See: Decorator.jsx

  function save() {
    setContext(state => ({
      ...state,
      clickedSticker: null
    }));

    props.redraw()
  }

  function del() {
    let stickers = context.stickers.filter(
      (draggable) => draggable != context.clickedSticker
    );

    setContext((state) => ({
      ...state,
      stickers: stickers,
      clickedSticker: null
    }));

    props.redraw()
  }
  
  const handleChangeSticker = (event) => {
    let stickerId = event.target.id

    setContext(state => ({
      ...state,
      currentSticker: stickerId
    }));

    if (context.clickedSticker) {
      context.clickedSticker.erase(
        window.contextDecorator.canvasWithoutSelectedDraggable
      );

      // Change sticker
      let clickedSticker = context.clickedSticker
      clickedSticker.id = stickerId;
      new_sticker.svg = $(`#${stickerId}`)[0]

      setContext((state) => ({
        ...state,
        clickedSticker: clickedSticker
      }));
    } else {
      window.contextDecorator.canvasWithoutSelectedDraggable = cloneCanvas(
        $("#canvas-preview")[0]
      );

      // Add sticker
      let new_sticker = new Sticker(
        stickerId,
        context.currentStickerSize
      );
      new_sticker.svg = $(`#${stickerId}`)[0]
      new_sticker.sequence_id = context.stickers.length + 1;

      let stickers = context.stickers
      stickers.push(new_sticker)

      setContext((state) => ({
        ...state,
        stickers: stickers,
        clickedSticker: new_sticker
      }));
    }

    props.redraw();
  }

  const handleChangeStickerSize = (event) => {
    let val = event.target.value

    setContext(state => ({
      ...state,
      currentStickerSize: parseInt(val),
    }));

    if (context.clickedSticker) {
      let sticker = context.clickedSticker;

      sticker.erase(window.contextDecorator.canvasWithoutSelectedDraggable);
      sticker.resize(val);
    }

    props.redraw()
  }

  let stickerChosen = (sticker_id) => {

  };

  let clickedSticker = null
  let size = context.currentStickerSize; // default to whatever's already chosen in the menu
  // if the user has clicked a sticker in the image editor, switch to that sticker's size
  if (context.clickedSticker) {
    clickedSticker = context.clickedSticker
    size = clickedSticker.size
  }

  return (
    <React.Fragment>
      <div style={{ maxHeight: 84, overflowY: 'auto' }}>
        {
          stickers.map(sticker => {
            let selected = 'borderTransparent';
            if (clickedSticker && sticker == clickedSticker.id) {
              selected = 'borderSelected';
            }
            let fn = `${sticker}.svg`;

            return (
              <img onClick={handleChangeSticker} className={`hand ${selected}`} src={`/static/stickers/${fn}`} id={sticker} key={sticker} width={24} height={24} />
            )
          })
        }
      </div>
      {
        clickedSticker && 
          <div className={classes.flexCols} style={{ alignContent: 'center' }}>
            <div style={{ 'flexBasis': '100px' }}>
              Sticker #{clickedSticker.sequence_id} size: 
            </div>
            <div style={{ 'flexBasis': '50px' }}>
              <input onChange={handleChangeStickerSize} id="sticker-size-slider" value={context.currentStickerSize} type="range" min="5" max="40" step="2" data-orientation="horizontal" />
            </div>
            <div style={{ 'flexBasis': '40px' }}>
              &nbsp;&nbsp;<font color='black'><img src='/static/icons/icon-save.svg' onClick={save} width={20} height={20} /></font>
            </div>
            <div style={{ 'flexBasis': '30px' }}>
              <font color='red'><DeleteIcon onClick={del} /></font>
            </div>
          </div>
      }
      {
        !clickedSticker &&
          <div>
            Click a sticker to add to the picture
          </div>
      }    </React.Fragment>
  );
}