import React, { useState, useEffect } from "react";
import $ from "jquery";
import Post from "components/posts/Post";
import Loading from "components/Loading";
import PostStyles from "components/styles/PostStyles";
import CommonStyles from "components/styles/CommonStyles";
import default_error from "default_error";
import goBack from "go_back";
import { useTheme } from "@material-ui/core";

import { useParams } from "react-router-dom";

/*
 * View the post "big" with all comments and info
 *
 * Either a separate page (/p/###) or within a model in <Posts> or <Explore>
 */

export default function PostFull(props) {
  const theme = useTheme();

  let isModal = props.isModal;

  // If viewing full page, e.g. /p/###
  let { post_id } = useParams();

  // If viewing from a PostBrowseDialog
  if (props.postId && props.postId > 0) {
    post_id = props.postId;
  }

  const classes = PostStyles();
  const commonClasses = CommonStyles();

  if (!post_id) {
    return <span>Post not found</span>;
  }

  const initialValues = {
    loading: true,
    load_error: "",
    show_set_spotlight: false,
    post: {
      assets: [{}],
      user: {},
      comments: [],
      liked: false,
    },
  };
  const [values, setValues] = useState(initialValues);

  function fetchData() {
    $.ajax({
      url: `/posts/${post_id}.json`,
      type: "GET",
      async: window.rails_env != "test",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      success: function (data) {
        let show_set_spotlight =
          current_user.admin &&
          exploring_tag &&
          data.assets &&
          data.assets[0] &&
          data.assets[0].category == "image";
        setValues((state) => ({
          ...state,
          loading: false,
          post: data,
          show_set_spotlight: show_set_spotlight,
        }));
      },
      error: function (data) {
        setValues((state) => ({
          ...state,
          ["load_error"]:
            (data && data.responseJSON && data.responseJSON.error) ||
            default_error,
        }));
      },
      cache: false,
      contentType: false,
      processData: false,
    });
  }

  // useEffect(() => {
  //   fetchData()
  // }, [])

  useEffect(() => {
    fetchData();
  }, [post_id]);

  // Copied from Posts.jsx
  let deletePost = (id) => {
    if (confirm("Are you sure you want to delete this post?")) {
      $.ajax({
        url: `/posts/${id}?authenticity_token=${encodeURIComponent(
          window.auth_token
        )}`,
        type: "DELETE",
        async: window.rails_env != "test",
        success: function (data) {
          window.toastr.success("Post deleted!");
          setTimeout(function () {
            goBack();
          }, 1500);
        },
        error: function (data) {
          window.toastr.error(
            data.responseText || data.responseJSON.error || default_error
          );
        },
      });
    }
  };

  let setSpotlightTag = (asset_id, tag) => {
    $.ajax({
      url: `/assets/${asset_id}/set_spotlight`,
      type: "POST",
      async: window.rails_env != "test",
      data: { tag: tag, authenticity_token: window.auth_token },
      success: function (data) {
        window.toastr.success("Spotlight set!");
      },
      error: function (data) {
        window.toastr.error(
          data.responseText || data.responseJSON.error || default_error
        );
      },
    });
  };

  let post = values.post;

  let exploring_tag = null;
  let tag_url_matches = window.location.pathname.match(/^\/explore\/tag\/(.+)/);
  if (tag_url_matches) {
    exploring_tag = tag_url_matches[1];
  }

  return (
    <div id="post-full" className={classes.container}>
{/*      {current_user.admin && (
        <div style={{ position: "absolute", right: 12, top: 12 }}>
          {values.show_set_spotlight && (
            <div>
              <a
                onClick={() => {
                  setSpotlightTag(post.assets[0].id, exploring_tag);
                }}
              >
                Set as spotlight for #{exploring_tag}
              </a>
            </div>
          )}
        </div>
      )}
*/}
      {!values.loading && post && (
        <Post
          key={post.id}
          post={post}
          callbackDelete={deletePost}
          variant="full"
          show_follow={props.show_follow || false}
        />
      )}
      <Loading loading={values.loading} load_error={values.load_error} />
    </div>
  );
}
