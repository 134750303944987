import React from "react";
import { Link } from "@material-ui/core";
import Comment from "components/posts/Comment";
import { Link as RLink } from "react-router-dom";

export default function Comments(props) {
  let post = props.post;

  return (
    <div>
      {props.comments.map(function (comment, index) {
        let comment_jsx = (
          <Comment
            variant={props.variant}
            key={comment.id || index}
            comment={comment}
          />
        );

        // If !variant, show a 'View All' link for all but the first and last 3 comments
        let num_comments = props.comments.length;
        if (num_comments > 5 && props.variant == "abbrev") {
          if (index == 0) {
            let more_jsx = (
              <Link
                key={comment.id + "_comment"}
                to={{
                  pathname: `/p/${comment.post_id || -1}`,
                  state: { modal: true },
                }}
                component={RLink}
               color="inherit"
              >
                View all {num_comments} comments
              </Link>
            );
            return [comment_jsx, more_jsx];
          }
          if (index >= num_comments - 3) {
            return comment_jsx;
          } else {
            return null;
          }
        } else {
          return comment_jsx;
        }
      })}
    </div>
  );
}
