import React, { useState, useEffect } from "react";
import $ from "jquery";
import { makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Avatar } from "@material-ui/core";
import ImgGrid from "components/explore/ImgGrid";
import { TextField } from "@material-ui/core";
import Loading from "components/Loading";
import CommonStyles from "components/styles/CommonStyles";
import { PostBrowseContext } from "components/posts/PostBrowseContext";
import PostBrowseDialog from "components/posts/PostBrowseDialog";
import Cookies from "vendor/js.cookie.min.mjs";
import { useTheme } from "@material-ui/core";
import { useParams, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 940,
    width: "100%",
    paddingLeft: 6,
    paddingRight: 0,
    paddingTop: 6,
    margin: "auto",
    background: theme.palette.background.paper,
    color: theme.palette.text.primary
  },
}));

/*
 * Kinks select - browse and follow/unfollow tags
 *
 * For Explore.jsx and NewUser.jsx
 */
export default function Kink(props) {
  const commonStyles = CommonStyles();
  const classes = useStyles();
  const theme = useTheme();

  let initialPostBrowseContext = {
    dialog_open: false,
    cur_post_id: -1,
    cur_post_index: -1,
    post_ids: [],
  };

  const [postBrowseContext, setPostBrowseContext] = useState(
    initialPostBrowseContext
  );

  let { tag } = useParams();

  const initialValues = {
    clicked_tag_name: props.clickedTag,
    posts: [],
    tag: { tag: null, posts: [] },
    tags: [],
    loading: true,
    following_tag: false,
    following_tags: [],
    tag_spotlight_image_url: null,
    related_tags: [],
    searchPostQuery: "",
    tagAutoComplete: [],
    tagRelated: [],
  };
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    if (tag) {
      handleClickTagName(tag);
    }
  }, [tag]);

  // useEffect(() => {
  //   fetchDataTags();
  // }, []);

  // When the list of post_ids changes, notify the lower-level components such as AssetWide
  useEffect(() => {
    let post_ids = [];
    for (let i = 0; i < values.posts.length; i++) {
      post_ids.push(values.posts[i].id);
    }
    setPostBrowseContext((state) => ({
      ...state,
      post_ids: post_ids,
    }));
  }, [values.posts]);

  // Populates the list of 30 tags to browse through
  // function fetchDataTags() {
  //   let url = "stories/tags";
  //   $.ajax({
  //     url: url,
  //     type: "GET",
  //     async: window.rails_env != "test",
  //     success: function (data) {

  //       setValues((state) => ({
  //         ...state,
  //         tags: data.tags, // We don't want to clear tags when we are just clicking one
  //         following_tags: data.following_tags,
  //         loading: false,
  //       }));

  //       // Tag was not passed in from being clicked on /home or /following -- auto-"click" the first tag returned
  //       if (
  //         !props.clickedTag &&
  //         props.showFollowingTags &&
  //         data.following_tags.length > 0
  //       ) {
  //         handleClickTagName(data.following_tags[0].tag);
  //       }
  //     },
  //     error: function (data) {
  //       if (data.status == 404) {
  //         setValues((state) => ({
  //           ...state,
  //           found: false,
  //           loading: false,
  //         }));
  //       }
  //     },
  //     cache: false,
  //     contentType: false,
  //     processData: false,
  //   });
  // }

  function chunkify(a, n, balanced) {
    if (n < 2) return [a];

    var len = a.length,
      out = [],
      i = 0,
      size;

    if (len % n === 0) {
      size = Math.floor(len / n);
      while (i < len) {
        out.push(a.slice(i, (i += size)));
      }
    } else if (balanced) {
      while (i < len) {
        size = Math.ceil((len - i) / n--);
        out.push(a.slice(i, (i += size)));
      }
    } else {
      n--;
      size = Math.floor(len / n);
      if (len % size === 0) size--;
      while (i < size * n) {
        out.push(a.slice(i, (i += size)));
      }
      out.push(a.slice(size * n));
    }

    return out;
  }

  function handleClickTagName(tag_name) {
    let url = "/stories/tag?tag=" + tag_name;
    $.ajax({
      url: url,
      type: "GET",
      async: window.rails_env != "test",
      success: function (data) {
        setValues((state) => ({
          ...state,
          clicked_tag_name: tag_name,
          tag_spotlight_image_url: data.tag_spotlight_image_url,
          related_tags: data.related_tags,
          tag: data.tag,
          following_tag: data.following_tag,
          posts: data.posts,
          loading: false,
        }));
      },
      error: function (data) {
        if (data.status == 404) {
          setValues((state) => ({
            ...state,
            loading: false,
          }));
        }
      },
      cache: false,
      contentType: false,
      processData: false,
    });
  }

  function fetchDataNext() {
    // ToDo: implement last_quality so we can infinite scroll the imggrid
    console.log("fetchDataNext", fetchDataNext);
  }

  function followTag(tag_id) {
    let data = {
      authenticity_token: window.auth_token,
    };

    $.post(`/tags/follow/${tag_id}`, data)
      .done(function (data) {
        console.log("Data", data);

        let ftns = values.following_tags;
        ftns.unshift(values.tag);

        window.current_user.following_tags = ftns;
        setValues((state) => ({
          ...state,
          following_tags: ftns,
          following_tag: true,
        }));

        if (props.callbackFollowingTagsChanged) {
          props.callbackFollowingTagsChanged(ftns.length);
        }
      })
      .fail(function (data) {
        console.log("FAIL");
      });

    // setContext(state => ({
    //   ...state,
    //   following_tag: true
    // }))
  }

  function unfollowTag(tag_id) {
    if (values.following_tags?.length <= 3) {
      alert("Sorry, you must follow at least 3 tags.");
      return;
    }
    let data = {
      authenticity_token: window.auth_token,
      _method: "delete",
    };
    $.post(`/tags/follow/${tag_id}`, data)
      .done(function (data) {
        let following_tags = values.following_tags;
        let index = -1;
        for (var i = 0; i < following_tags.length; i++) {
          let id = following_tags[i].id;
          if (id == tag_id) {
            index = i;
            break;
          }
        }
        if (index > -1) {
          following_tags.splice(index, 1);
        }

        // Used by Explore, Kinks, Posts, NewUser etc
        window.current_user.following_tags = following_tags;
        setValues((state) => ({
          ...state,
          following_tags: following_tags,
          following_tag: false,
        }));

        if (props.callbackFollowingTagsChanged) {
          props.callbackFollowingTagsChanged(following_tags.length);
        }
      })
      .fail(function (data) {
        console.log(`Could not unfollow tag: ${tag_id}`, data);
      });

    // setContext(state => ({
    //   ...state,
    //   following_tag: false
    // }))
  }

  function unfollowTagConfirm(tag_id) {
    if (confirm("Are you sure you want to unfollow this tag?")) {
      unfollowTag(tag_id);
    }
  }

  // Typing in the search bar
  // const handleChangeText = (event) => {
  //   let value = event.target.value;

  //   // Update the textarea
  //   setValues((state) => ({
  //     ...state,
  //     ["searchPostQuery"]: value,
  //   }));

  //   //if (value.match(/^#\w+$/) && value.length > 2) {
  //   // Get tag suggestions from /search/tags
  //   clearTimeout(values.tagTimeout);
  //   setValues((state) => ({
  //     ...state,
  //     ["tagTimeout"]: setTimeout(function () {
  //       searchTagAjax(value);
  //     }, 200),
  //   }));
  //   // } else {
  //   //   // Just typing or short hashtag, do not suggest
  //   //   tagSuggestClose()
  //   // }
  // };

  // User has clicked within the textarea
  // const handleClickText = (event) => {
  //   if (event.target.selectionStart) {
  //     // todo: why is this sometimes null?
  //     handleChangeText(event);
  //   }
  // };

  // const handleFocusText = (event) => {
  //   handleChangeText(event);
  // };

  // const handleBlurText = (event) => {
  //   setValues((state) => ({
  //     ...state,
  //     tagAutoComplete: [],
  //     tagRelated: [],
  //   }));
  // };

  // function searchTagAjax(value) {
  //   $.ajax({
  //     url: "/search/tags?q=" + encodeURIComponent(value),
  //     type: "GET",
  //     async: window.rails_env != "test",
  //     success: function (data) {
  //       setValues((state) => ({
  //         ...state,
  //         tagAutoComplete: data.autocomplete,
  //         tagRelated: data.related,
  //       }));
  //     },
  //     error: function (data) {
  //       if (data.status == 404) {
  //         console.error("tag search err", data);
  //       }
  //     },
  //     cache: false,
  //     contentType: false,
  //   });
  // }

  // function tagSuggestClose() {
  //   setValues((state) => ({
  //     ...state,
  //     tagAutoComplete: [],
  //     tagRelated: [],
  //   }));
  // }

  // function handleClickSearch() {
  //   let q = values.searchPostQuery;
  //   if (q.match(/^#\w+$/)) {
  //     q = q.substring(1);

  //     props.handleClickTag(q);
  //   } else {
  //     let url = "/explore/search/" + q.replace(/#/, "");

  //     setValues((state) => ({
  //       ...state,
  //       posts: [],
  //       tag: null,
  //       query: q,
  //       lastKinkUrl: url,
  //     }));

  //     props.history.push(url);
  //   }
  // }

  return (
    <div className={classes.container}>
      {/*[{JSON.stringify(values.posts)}]*/}
      {!values.loading && (
        <div>
          {values.tag &&
            values.tag.tag && ( // if tag.tag is set, we have data from the backend
              <div
                className={commonStyles.flexRow}
                style={{ display: "inline-flex", width: "100%", marginTop: 12 }}
              >
                <div
                  style={{
                    flexBasis: 170,
                    flexShrink: 0,
                    flexGrow: 0,
                    paddingLeft: 10,
                  }}
                >
                  <Avatar
                    variant="circle"
                    src={
                      values.tag_spotlight_image_url || "/default-profile.svg"
                    }
                    style={{
                      border: `solid 2px ${props.border_color || "white"}`,
                      width: 150,
                      height: 150,
                    }}
                  />
                </div>

                <div style={{ flexGrow: 0.05 }}></div>

                <div
                  style={{ flexGrow: 0.95, alignItems: "left", minWidth: 0 }}
                >
                  <div style={{ marginTop: 4, fontSize: "1.5em" }}>
                    #{values.tag.tag}
                  </div>

                  <div style={{ paddingTop: 0 }}>
                    {!values.following_tag && (
                      <a
                        className="hand"
                        id="follow"
                        onClick={() => followTag(values.tag.id)}
                      >
                        <b>
                          <font color="red">follow</font>
                        </b>
                      </a>
                    )}
                    {values.following_tag && (
                      <span>
                        {values.following_tags.length <= 3 && (
                          <a
                            className="hand"
                            id="unfollow-text-disabled"
                            onClick={() =>
                              alert("You must follow 3+ tags to unfollow")
                            }
                          >
                            <b>
                              <font color="#DDD">unfollow</font>
                            </b>
                          </a>
                        )}
                        {values.following_tags.length > 3 && (
                          <a
                            className="hand"
                            id="unfollow-text-enabled"
                            onClick={() => unfollowTag(values.tag.id)}
                          >
                            <b>
                              <font color="red">unfollow</font>
                            </b>
                          </a>
                        )}
                      </span>
                    )}
                  </div>

                  {values.related_tags && (
                    <div
                      className="tags-browse"
                      style={{
                        marginTop: 14,
                        marginBottom: 10,
                        paddingBottom: 4,
                        width: "100%",
                      }}
                    >
                      {values.related_tags.map((tag, index) => (
                        <span key={`related-${tag}`} className="pill">
                          <a
                            className="hand"
                            onClick={() => {
                              handleClickTagName(tag);
                            }}
                          >
                            {`#${tag}`}
                          </a>{" "}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}

          <PostBrowseContext.Provider
            value={[postBrowseContext, setPostBrowseContext]}
          >
            <PostBrowseDialog posts={values.posts} />

            {values.posts?.length > 0 && (
              <ImgGrid
                fetchDataNext={fetchDataNext}
                posts={values.posts || []}
                tag={values.tag}
              />
            )}
            {values.posts?.length == 0 && values.clicked_tag_name && (
              <div
                style={{
                  marginTop: 12,
                  marginBottom: 8,
                  paddingTop: 4,
                  paddingBottom: 4,
                  maxWidth: 500,
                  marginLeft: "auto",
                  marginRight: "auto",
                  fontWeight: 700,
                  border: "1px solid rgb(219, 219, 219)",
                }}
              >
                <center>
                  No posts found for #{values.clicked_tag_name}.{" "}
{/*                  <a onClick={props.history.goBack}>
                    <font color="red">Back</font>
                  </a>*/}
                </center>
              </div>
            )}
          </PostBrowseContext.Provider>
        </div>
      )}
      <Loading loading={values.loading} />
    </div>
  );
}
