import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Link } from "@material-ui/core";
import { Link as RLink } from "react-router-dom";
import dayjs from "dayjs";
import {
  railsUTCtoFriendlyLocalTime,
  railsUTCtoICSDate,
} from "services/dates.service.js";

const useStyles = makeStyles({
  root: {
    maxWidth: 271,
  },
  media: {
    height: 180,
  },
  gradient: {
    top: 0,
    height: "100%",
    width: "100%",
    position: "absolute",
    backgroundImage: "transparent",
    backgroundImage:
      "-webkit-linear-gradient(rgba(15,20,31,0) 0, rgba(15,20,31,.1) 10%, rgba(15,20,31,.9) 100%)",
    backgroundImage:
      "linear-gradient(rgba(15,20,31,0) 0, rgba(15,20,31,.1) 10%, rgba(15,20,31,.9) 100%)",
  },
});

function numToDow(num) {
  if (num == 0) {
    return "Sun";
  }
  if (num == 1) {
    return "Mon";
  }
  if (num == 2) {
    return "Tue";
  }
  if (num == 3) {
    return "Wed";
  }
  if (num == 4) {
    return "Thu";
  }
  if (num == 5) {
    return "Fri";
  }
  if (num == 6) {
    return "Sat";
  }
}

export default function EventAbbrev(props) {
  const classes = useStyles();

  let event = props.event;
  let dayjs_start = dayjs(event.dt_start);

  let history = useHistory();

  function handleClickCard() {
    history.push("/event/" + event.id);
  }

  let is_owner = event.poster_id == window.current_user.id;

  let height_adjustment_px = 104;

  console.log("EVENT", event)
  if (!event.dt_start_friendly) {
    event.dt_start_friendly = railsUTCtoFriendlyLocalTime(event.dt_start)
  }

  return (
    <React.Fragment>
      <div style={
          {
            width: '100%',
            marginBottom: 8,
            position: 'relative',
            maxHeight: `calc(100vh - ${height_adjustment_px}px`, display: 'flex', alignItems: 'center',
            justifyContent: 'center'
          }
        }>
        <img
          src={event.image_url || "/default_event_854.png"}
          style={{ maxWidth: "100%", maxHeight: "calc(100vh - 150px)" }}
        />

        <div className={classes.gradient} />
        <div style={{ position: "absolute", bottom: 4, left: 8 }}>
          <h5 style={{ marginTop: 2, marginBottom: 2 }}>
            {event.dt_start_friendly} - {event.starts_in}
          </h5>

          <h3 style={{ marginTop: 2, marginBottom: 2 }}>
            <span
              onClick={handleClickCard}
              className="hand"
            >
              {event.name}
              {event.poster_id != window.current_user.id &&
                event.rsvp_type != null && (
                  <span>
                    &nbsp;&nbsp;
                    <font color="red">
                      {event.rsvp_type == 1 && event.status == 1 ? "going" : ""}
                    </font>
                  </span>
                )}
              {event.poster_id == window.current_user.id && (
                <span>
                  &nbsp;&nbsp;<font color="red">organizing</font>
                </span>
              )}
            </span>
          </h3>
          <h4 style={{ marginTop: 2, marginBottom: 2 }}>
            {event.community.name}
          </h4>
          <h5 style={{ marginTop: 2, marginBottom: 2 }}>
            {event.event_type == "virtual" && <span>Virtual Event</span>}
            {event.event_type != "virtual" && (
              <span>
                {event.city},&nbsp;{event.region}
              </span>
            )}
            &nbsp;&middot; {event.num_rsvps}&nbsp;RSVP
            {event.num_rsvps == 1 ? "" : "s"}
          </h5>
        </div>
      </div>
      {/*is_owner && (
        <div>
          <Link to={`/events/edit/${event.id}`} component={RLink}>
            Edit Event
          </Link>
          &nbsp;&middot;{" "}
          <a href={`/events/dupe/${event.id}`} component={RLink}>
            Duplicate Event
          </a>
        </div>
      )*/}
    </React.Fragment>
  );
}
