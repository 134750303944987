import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import UserList from "components/UserList";
import { makeStyles } from "@material-ui/core/styles";
import $ from "jquery";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    color: "inherit",
    padding: theme.spacing(1, 1, 1, 1),
    // vertical padding + font size from searchIcon
    //paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
  },
}));

export default function SearchUsersMessaging(props) {
  const classes = useStyles();

  let maxUsers = props.maxUsers || 10;

  /* Autocomplete stuff */
  let initAutocompleteValues = {
    users: [],
    usersSelected: [],
    value: "",
  };
  const [autoCompleteValues, setAutocompleteValues] = useState(
    initAutocompleteValues
  );

  function handleAutocompleteType(event, value, reason) {
    let username = "value";

    setAutocompleteValues((state) => ({
      ...state,
      value: value,
    }));

    if (reason == "input" && value.length > 1) {
      $.ajax({
        url: "/api/v1/messaging/search_users?q=" + value,
        type: "GET",
        async: window.rails_env != "test",
        success: function (data) {
          setAutocompleteValues((state) => ({
            ...state,
            users: data,
          }));
        },
        cache: false,
        contentType: false,
      });
    }
  }

  function handleAutocompleteSelect(event, value, reason) {
    if (value) {
      let usersSelected = autoCompleteValues.usersSelected;
      let found = false;
      usersSelected.forEach((usr) => {
        if (usr.id == value.id) {
          found = true;
        }
      });
      if (found) {
        window.toastr.info("User already selected");
      } else {
        usersSelected.push(value);
        setAutocompleteValues((state) => ({
          ...state,
          value: "",
          usersSelected: usersSelected,
        }));

        // ToDo: why is this necessary?
        setTimeout(() => {
          setAutocompleteValues((state) => ({
            ...state,
            value: "",
          }));
        }, 1);

        if (props.callbackSelect) {
          props.callbackSelect(usersSelected);
        }
      }
    }
  }

  function del_user(id) {
    let usersSelected = autoCompleteValues.usersSelected;
    let found = false;
    usersSelected = usersSelected.filter((usr) => {
      if (usr.id != id) {
        return usr;
      }
    });
    setAutocompleteValues((state) => ({
      ...state,
      usersSelected: usersSelected,
    }));

    if (props.callbackSelect) {
      props.callbackSelect(usersSelected);
    }
  }

  return (
    <div>
      <Autocomplete
        id="search-users-messaging"
        size="small"
        fullWidth={props.fullWidth}
        style={{ width: "100%", padding: 0 }}
        options={autoCompleteValues.users}
        inputValue={autoCompleteValues.value}
        getOptionDisabled={(option) => {
          return !option.can_message;
        }}
        getOptionLabel={(option) => {
          return option.username;
        }}
        disabled={autoCompleteValues.usersSelected.length >= maxUsers}
        onInputChange={handleAutocompleteType}
        onChange={handleAutocompleteSelect}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Recipients"
            margin="none"
            size="small"
            variant="outlined"
            disabled={autoCompleteValues.usersSelected.length >= maxUsers}
            style={{ width: props.fullWidth ? "100%" : "130px" }}
            classes={{
              root: classes.inputRoot,
            }}
          />
        )}
      />
      <UserList
        show_follow={false}
        show_delete={true}
        callback_delete={del_user}
        users={autoCompleteValues.usersSelected}
        no_users_text={"Choose at least 1 user to message"}
      />
    </div>
  );
}
