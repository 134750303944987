import React, { useEffect, useState, useContext, useCallback, useReducer } from "react";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import * as PostService from "../../services/posts.service";
import { useTheme } from '@material-ui/core/styles';
import Post from "components/posts/Post";
import useInterval from "vendor/useInterval";
import { Draggable, Sticker, Blur, Text } from "image_editor/draggables.js";
import { CreatePostContext } from "components/posts/CreatePostContext";
// See: https://github.com/ricardo-ch/react-easy-crop
import Cropper from "react-easy-crop";
import { getCroppedImg, getRotatedImage } from "../../vendor/canvasUtils";
import ReactEasyCropStyles from "components/events/ReactEasyCropStyles";
import { Button, TextField, FormControlLabel, Select, MenuItem, InputLabel, FormHelperText } from '@material-ui/core';
import FontPicker from "font-picker-react";
import { CirclePicker } from "react-color"
import cloneCanvas from "image_editor/clone_canvas.js";

export default function EditTexts(props) {
  let [context, setContext] = useContext(CreatePostContext);

  // When cropped image is set, save it as the initial imageFiltered
  // useEffect(
  //   function () {
  //     var imgObj = new Image();
  //     // Base64 data url
  //     console.log("context.imageFiltered", context.imageFiltered)
  //     imgObj.src = context.imageFiltered.src;

  //     setContext((state) => ({
  //       ...state,
  //       imageText: imgObj
  //     }));
  //   },
  //   []
  // );


  let fonts = [ 'Anton', 'Kanit', 'Open Sans', 'Pacifico', 'Arbutus', 'Domine', 'Dancing Script', 'Covered By Your Grace', 'Norican', 'Roboto Slab', 'Fira Mono', 'Major Mono', 'Source Code Pro' ]
  let categories = ["sans-serif", "serif", "display"]

  // let initialValues = {
  //   fonts_downloaded: ['Anton']
  // };
  // const [values, setValues] = useState(initialValues);

  // Hack: forces context / state update, this lets us avoid setTimeouts
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  function handleChangeFont(nextFont) {
    let texts = context.texts
    texts[context.current_text]['font'] = nextFont.family

    forceUpdate()

    // Font has already been downloaded?
    if (document.fonts.check(`1em ${nextFont.family}`)) {
      textValuesChanged()
    } else {
      // It's currently downloading -- show loading stuff
      setContext((state) => ({
        ...state,
        loading: true,
      }));

      // Wait for font to finish loading. See: https://stackoverflow.com/questions/5680013/how-to-be-notified-once-a-web-font-has-loaded/32292880#32292880
      document.fonts.onloadingdone = function (fontFaceSetEvent) {
        setContext(state => ({
          ...state,
          loading: false
        }))

        textValuesChanged()
      };

      // It didn't work w/ onloadingdone? Just in case, redraw after 3s
      setTimeout(function() {
        setContext(state => ({
          ...state,
          loading: false
        }))
        textValuesChanged()
      }, 3000) // Wait 5s sec for font to load
    }
  }

  function handleChangedSize(event) {
    let val = event.target.value

    let texts = context.texts
    texts[context.current_text]['size'] = val

    textValuesChanged()
  }

  function handleChangedColor(color) {
    let texts = context.texts
    console.log("texts", texts, "color", color, context.current_text, "texts[context.current_text]", texts[context.current_text])
    texts[context.current_text]['color'] = color

    textValuesChanged()

    //toggleColorPicker()
  }

  // function toggleColorPicker() {
  //   let picker = $('#text-color-picker .swatches-picker')

  //   let show = false
  //   if (picker.css('display') == 'none') {
  //     show = true
  //   }

  //   if (show) {
  //     picker.show()
  //   } else {
  //     picker.hide()
  //   }
  // }

  function handleChangeText() {
    let value = event.target.value
console.warn("context.texts", context.texts)
    clearTimeout(context.timeoutType)

    let texts = context.texts
    if (texts.length == 0) {
      texts.push({ font: "Anton", size: 100, color: "#F5B041", category: "sans-serif", text: value })
    }
    texts[context.current_text]['text'] = value

    // Force re-render, since we're not (yet) changing the context and want to have the textbox below update
    forceUpdate()

    context.timeoutType = setTimeout(() => {
      textValuesChanged()
    }, 300)
  }


  // let textValuesChanged = () => {
  //   let vals = {
  //     text: context.texts[context.current_text]?.text,
  //     color: context.texts[context.current_text]?.color,
  //     font: context.texts[context.current_text]?.font,
  //     size: context.texts[context.current_text]?.size,
  //   };

  //   if (context.clickedText) {
  //     context.clickedText.erase(
  //       window.contextDecorator.canvasWithoutSelectedDraggable, 100
  //     );

  //     if (context.texts[context.current_text].text) {
  //       context.clickedText.update(vals);
  //     } else {
  //       // Delete it
  //       context.dragTexts = context.dragTexts.filter(
  //         (draggable) => draggable != context.clickedText
  //       );
  //       context.clickedText.erase(
  //         window.contextDecorator.canvasWithoutSelectedDraggable, 100
  //       );
  //       context.clickedText = null;
  //     }
  //   } else {
  //     //{font: "Anton", size: "30em", color: "#000", category: "sans-serif", text: "asefasefeesseef", …}
  //     if (vals.text) {
  //       let tmpCanvas = cloneCanvas(
  //         $("#canvas-preview")[0]
  //       )

  //       // Add text
  //       let new_text = new Text(vals);

  //       let dragTexts = context.dragTexts
  //       dragTexts.push(new_text)
  //       console.warn("== new_text", new_text)
  //       setContext((state) => ({
  //         ...state,
  //         dragTexts: dragTexts,
  //         clickedText: new_text
  //       }));
  //       window.contextDecorator.canvasWithoutSelectedDraggable = tmpCanvas
  //     }
  //   }

  //   props.redraw()
  // };

  let textValuesChanged = () => {
    let vals = {
      text: context.texts[context.current_text].text,
      color: context.texts[context.current_text].color,
      font: context.texts[context.current_text].font,
      size: context.texts[context.current_text].size,
    };

console.log("context.clickedText", context.clickedText)
    if (context.clickedText) {
      context.clickedText.erase(
        window.contextDecorator.canvasWithoutSelectedDraggable, 100
      );

      if (context.texts[context.current_text].text) {
        context.clickedText.update(vals);
      } else {
        // Delete it
        context.dragTexts = context.dragTexts.filter(
          (draggable) => draggable != context.clickedText
        );
        context.clickedText.erase(
          window.contextDecorator.canvasWithoutSelectedDraggable, 100
        );
        context.clickedText = null;
      }
    } else { // new text
      //{font: "Anton", size: "30em", color: "#000", category: "sans-serif", text: "asefasefeesseef", …}
      if (vals.text) {
        let tmpCanvas = cloneCanvas(
          $("#canvas-preview")[0]
        )

        // Add text
        let new_text = new Text(vals);

        let dragTexts = context.dragTexts
        dragTexts.push(new_text)
        console.warn("== new_text", new_text)
        setContext((state) => ({
          ...state,
          dragTexts: dragTexts,
          clickedText: new_text
        }));
        window.contextDecorator.canvasWithoutSelectedDraggable = tmpCanvas
      }
    }

    props.redraw()
  };

  let colors = ['#FFFFFF', '#000000', '#F47373', '#F88379', '#EC5800', '#ff8a65', '#AA4A44', '#D22B2B', '#770737', '#8B8000', '#E49B0F', '#F5B041', '#D35400', '#697689', '#097969', '#228B22', '#32CD32', '#2CCCE4', '#191970', '#0047AB', '#555555', '#CD7F32', '#6E260E', '#808000', '#ba68c8', '#FF00FF', '#7D3C98', '#800080']

  return (
    <div id='menu-texts' style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
        <FontPicker
          apiKey="AIzaSyBmezob8g1uyPr0vmBqaNkGfcHGy5jLIqM"
          activeFontFamily={context.texts[context.current_text]?.font || 'Anton'}
          families={fonts}
          categories={categories}
          onChange={handleChangeFont}
        />
        <div style={ { paddingLeft: 4 } }>
          <span>Size:</span>
        </div>
        <div style={ { flexGrow: 1 } }>
          {/*ToDo: size min/max/step should be defined by image size*/}
          <input
            id="text-size-slider"
            value={context.texts[context.current_text]?.size || 20}
            type="range"
            min="50"
            max="500"
            step="25"
            style={ { width: '100%' } }
            onChange={handleChangedSize}
            data-orientation="horizontal"
          />
        </div>
        <div style={ { paddingLeft: 4 } }>
          X
        </div>
      </div>

      <div id='texts-color-picker' style={ { marginTop: 4, marginBottom: 4 }} >
        <ul id='text-color-picker' className="hs full">
          {
            colors.map((color) => (
              <li className={`font-border mask hand`} onClick={() => handleChangedColor(color)}>
                <div style={ { height: 16, width: 16, backgroundColor: color, borderRadius: "50%", display: "inline-block", border: (color == context.texts[context.current_text]?.color ? 'solid 1px #FFF' : 'none') }}></div>
              </li>
            ))
          }
        </ul>
      </div>

      <div style={ { marginTop: 4, marginBottom: 4 }}>
        <input
          id="text-content"
          value={context.texts[context.current_text]?.text || ""}
          type="text"
          style={ { width: '100%' } }
          onChange={handleChangeText}
          placeholder="Type some text here"
          autoComplete="off"
        />
      </div>

    </div>
  );
}