import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Button, TextField, Select } from "@material-ui/core";
import Cookies from "vendor/js.cookie.min.mjs";
import VideoIcon from "@material-ui/icons/Videocam";
import PhotoIcon from "@material-ui/icons/PhotoCamera";
import UserAvatar from "components/UserAvatar";
import PostStyles from "components/styles/PostStyles";
import ImageEditor from "components/ImageEditor";
import Thumbnails from "components/posts/Thumbnails";
import Menu from "components/posts/Menu";
import Loading from "components/Loading";
import { CreatePostContext } from "components/posts/CreatePostContext";
import * as PostService from "../../services/posts.service";
import { useTheme } from '@material-ui/core/styles';
import CommonStyles from "components/styles/CommonStyles";
import Dialog from "@material-ui/core/Dialog";
import { Link, Typography } from "@material-ui/core";
import {
  Slider,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormControl,
} from "@material-ui/core";
import EditCrop from "components/stories/EditCrop";
import EditFilters from "components/stories/EditFilters";
import EditDecoration from "components/stories/EditDecoration";
import EditSecurity from "components/stories/EditSecurity";
import PreviewVideo from "components/stories/PreviewVideo";
import { getImageAsResizedCanvas } from "../../vendor/canvasUtils";
import platform from "components/pwa/platform";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

/*
 * File has been chosen for upload
 * Based on file type, go through a 'slide show' of various post-processing steps
 * Crop, filters etc etc
 * When the user is done, do the actual upload to the server
 */
export default function CreatePost(props) {
  const commonStyles = CommonStyles();
  const theme = useTheme();

  let { post_id } = useParams();
  window.post_id = post_id

  let initialValues = {
    loading: true,
    orientation: window.screen?.orientation?.type,
    isMobile: platform.isMobile(),
    dialogPostShow: false,
    tagAutoComplete: [],
    tagRelated: [],
    tagTimeout: null,
  };
  const [values, setValues] = useState(initialValues);

  const [context, setContext] = useState(PostService.initialContext());

  /*
   * Get post and turn it into upload
   */
  useEffect(() => {
    $.ajax({
      url: `/posts/${post_id}.json`,
      type: "GET",
      async: window.rails_env != "test",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      success: function (data) {
        let asset = data.asset;
        let upload = null;

        setContext((state) => ({
          ...state,
          postText: data.content,
          background: data.background,
          permission: data.perms,
          save_to_profile: data.save_to_profile
        }));

        setValues((state) => ({
          ...state,
          loading: false,
        }));

        fetch(asset.url)
          .then(resp => resp.blob())
          .then(blob => {
            console.warn("?resp.blob()", blob)

            var file = new File([blob], "name" + asset.ext, { type: asset.content_type });

            PostService.onDrop([file])
              .then((upload) => {
                console.log("upload", upload)
                let steps = []
                let step = ""

                // png/jpg/gif get cropped
                let image_resized = null
                if (upload.mimeTypeSimple == "image") {
                  // croppable
                  steps = [ "crop", "decoration", "security" ]
                  step = "crop"

                  // upload.canvasUploaded: canvas of EXIF-fixed and resized image
                  // Cropper
                  setContext((state) => ({
                    ...state,
                    imageResized: upload.canvasUploaded.toDataURL()
                  }));
                }

                if (upload.mimeTypeSimple == "video" || upload.mimeTypeSimple == "gif") {
                  steps = [ "preview", "security" ]
                  step = "preview"
                }

                if (step == "") {
                  alert("Invalid file! Are you sure it's a picture or video?")
                  return
                }

                setContext((state) => ({
                  ...state,
                  steps: steps,
                  step: step,
                  upload: upload
                }));

                window.upload = upload
              })
              .catch((error) => {
                console.error("Post error: ", error);
              });
            }
          )
          .catch(() => alert('oh no!'));


        // fetch(asset.url)
        //   .then(function(response) {
        //     console.log("=001")
        //     return response.blob()
        //   })
        //   .then(function(blob) {
        //     console.log("=002")
        //     retrievedAsset(blob)
        //     // here the image is a blob
        //   });

        let steps = [ "crop", "decoration", "security" ]
        let step = "crop"
        if (asset.category != 'image') {
          steps = [ "preview", "security" ]
          step = "preview"
        }

        setContext((state) => ({
          ...state,
          steps: steps,
          step: step,
          postText: data.content,
          permission: data.perms
        }));
      },
    });
  }, []);

  let history = useHistory();

  function exitCallback() {
    // ToDo: if changes, pop up 'are you sure?'
    history.goBack()
  }

  let topHeaderHeight = $('#top-header').outerHeight()
  let heightTopMenu = $('#stories-header').outerHeight() + topHeaderHeight

  return (
    <React.Fragment>
      {
        context.upload && (
          <div style={ { width: '100%', height: `calc(100vh - ${topHeaderHeight}px)`, position: 'relative' } }>
            <CreatePostContext.Provider value={[context, setContext]}>
              {
                context.step == "crop" && (
                  <EditCrop exitCallback={exitCallback} heightTopMenu={heightTopMenu} />
                )
              }

              { /* <EditFilters exitCallback={exitCallback} /> */ }
              {
                context.step == "decoration" && (
                  <EditDecoration exitCallback={exitCallback} heightTopMenu={heightTopMenu} />
                )
              }

              {
                context.step == "security" && (
                  <EditSecurity exitCallback={exitCallback} heightTopMenu={heightTopMenu} variant="edit" />
                )
              }

              {
                context.step == "preview" && (
                  <PreviewVideo exitCallback={exitCallback} heightTopMenu={heightTopMenu} />
                )
              }
            </CreatePostContext.Provider>
          </div>
        )
      }
    </React.Fragment>
  );
}