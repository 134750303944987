import React, { useEffect, useState, useContext } from "react";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import * as PostService from "../../services/posts.service";
import { useTheme } from '@material-ui/core/styles';
import UserAvatar from "components/UserAvatar";
import StoryView from "components/stories/StoryView";
import CreatePost from "components/stories/CreatePost";
import CommonStyles from "components/styles/CommonStyles";
import { Avatar } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { ThemeContext } from "components/ThemeContext";

export default function CreateStoryIcon(props) {
  const commonStyles = CommonStyles();

  let [themeContext, setThemeContext] = useContext(ThemeContext);

  function imageClass() {
    return (themeContext.type == "dark" ? "no-dark-mode" : "")
  }

  return (
    <div>
      {
        // Option 1: User has no story, show the blank "plus" image
        !props.current_story && window.current_user.confirmed_at && (
          <CreatePost
            current_user={window.current_user}
            avatar_url={window.current_user_avatar_url}
            color_background={window.current_user.color_background}
            tab='home'
            community={props.community}
          >
            <img
              id="click-to-post"
              src="/static/icons/icon-plus.svg"
              width="50"
              className={imageClass()}
            />
          </CreatePost>
        )
      }

      {
        // Option 2: User has story: show avatar
        props.current_story && (
          <React.Fragment>
            <CreatePost
              current_user={window.current_user}
              avatar_url={window.current_user_avatar_url}
              color_background={window.current_user.color_background}
              tab='home'
              community={props.community}
            >
              <img
                id="add-to-story"
                style={ { position: 'absolute', left: 28, zIndex: 1000 } }
                src="/stories/icon-plus-white.svg"
                title="Add to your story"
                width={26}
                className={imageClass()}
              />
            </CreatePost>

            <Avatar
              id="preview-story"
              variant="circle"
              src={window.current_user_avatar_url}
              title="Preview your story"
              onClick={props.handleAvatarClick}
              style={{
                width: 50,
                height: 50,
                border: `solid 2px ${window.current_user.color_background || 'white'}`,
              }}
            />
          </React.Fragment>
        )
      }

      {
        !window.current_user.confirmed_at && (
          <React.Fragment>
            <img
              id="confirm-to-post"
              src="/static/icons/icon-plus-disabled.svg"
              width="50"
              onClick={() => { alert("Your account must be confirmed to create a story") }}
            />
          </React.Fragment>
        )
      }
    </div>
  )
}