import React, { useState, useContext } from "react";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Post from "components/posts/Post";
import { ThemeContext } from "components/ThemeContext";
import { useTheme } from '@material-ui/core/styles';

import $ from "jquery";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    color: "inherit",
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    //paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    background: theme.palette.background.paper
  },
  container: {
    maxWidth: "614px !important",
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 16
  },
}));

export default function Search(props) {
  const classes = useStyles();

  let [themeContext, setThemeContext] = useContext(ThemeContext);
  let theme = useTheme();

  /* Autocomplete stuff */
  let initAutocompleteValues = {
    options: [],
    value: ""
  };
  const [autoCompleteValues, setAutocompleteValues] = useState(
    initAutocompleteValues
  );

  let initialValues = {
    posts: []
  };
  const [values, setValues] = useState(initialValues);

  const [timeoutSearchPosts, setTimeoutSearchPosts] = useState(null);

  function handleAutocompleteType(event, value, reason) {
    if (value.match(/^@/)) {
      if (value.length > 2) {
        $.ajax({
          url: "/search/users?q=" + value,
          type: "GET",
          async: window.rails_env != "test",
          success: function (data) {
            setAutocompleteValues((state) => ({
              ...state,
              options: data,
            }));
          },
          cache: false,
          contentType: false,
        });
      }
    } else if (value.match(/^#/)) {
      if (value.length > 2) {
        $.ajax({
          url: "/search/tags?q=" + value.replace(/^#/, "") + '&arr=true',
          type: "GET",
          async: window.rails_env != "test",
          success: function (data) {
            setAutocompleteValues((state) => ({
              ...state,
              options: data,
            }));
          },
          cache: false,
          contentType: false,
        });
      }
    } else {
      if (value.length > 1) {
        clearTimeout(timeoutSearchPosts);
        setTimeoutSearchPosts(
          setTimeout(() => {
            searchPosts(value);
          }, 500)
        );
      }
    }
  }

  function searchPosts(value) {
    $.ajax({
      url: "/search/posts?q=" + value,
      type: "GET",
      async: window.rails_env != "test",
      success: function (data) {
        setValues((state) => ({
          ...state,
          posts: data.posts
        }))
        setAutocompleteValues((state) => ({
          ...state,
          options: [],
        }));
      },
      cache: false,
      contentType: false,
    });
  }

  function handleAutocompleteSelect(event, value, reason) {
    if (value) {
      if (value.match(/^@/)) {
        let path = "/" + value.replace(/^@/, "");

        setAutocompleteValues((state) => ({
          ...state,
          value: "",
        }));
        if (window.location.pathname != path) {
          setAutocompleteValues((state) => ({
            ...state,
            options: [],
          }));
          props.history.push("/" + value.replace(/^@/, ""));
        }
      }

      if (value.match(/^#/)) {
        let path = "/stories/tag/" + value.replace(/^#/, "");

        setAutocompleteValues((state) => ({
          ...state,
          value: "",
        }));
        if (window.location.pathname != path) {
          setAutocompleteValues((state) => ({
            ...state,
            options: [],
          }));
          props.history.push("/stories/tag/" + value.replace(/^#/, ""));
        }
      }
    }
  }

  return (
    <div className={`${classes.container} ${classes.paper}`}>
      <Autocomplete
        id="search-text"
        disableClearable
        freeSolo
        size="small"
        fullWidth={true}
        style={{ width: "100%", padding: 0 }}
        value={autoCompleteValues.value}
        options={autoCompleteValues.options}
        onInputChange={handleAutocompleteType}
        onChange={handleAutocompleteSelect}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search"
            margin="none"
            size="small"
            variant="outlined"
            color={props.color ? props.color : "primary"}
            style={{ width: true ? "100%" : "130px" }}
            classes={{
              root: classes.inputRoot,
            }}
          />
        )}
      />

      <div style={ { fontSize: 12, color: theme.palette.text.primary, paddingBottom: 12 } }>
        Use an @ sign to search for @usernames.
      </div>

      {
        values.posts.map(function (post, index) {
          return (
            <div style={ { marginBottom: 12 } }>
              <Post
                key={`post-${post.id}`}
                post={post}
                show_follow={true}
                callbackDelete={() => {}}
                story_id={-1}
                variant="feed"
              />
            </div>
          );
        })
      }

    </div>
  );

}
